import {
  Button,
  Classes,
  Collapse,
  Divider,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  Popover,
} from "@blueprintjs/core";
import { cn } from "@stockifi/shared";
import InputActionConfirmationDialogue from "components/input-action-confirmation-dialogue";
import _ from "lodash";
import React, { useState } from "react";
import alertActions from "redux/alert/actions";
import { useAppDispatch } from "redux/hooks";
import { User } from "redux/users/types";
import module from "../index.module.scss";

type Props = {
  oldData: Partial<User>;
  newData: Partial<User>;
  setNewData: React.Dispatch<React.SetStateAction<Partial<User>>>;
  formGroupProps: Record<string, string | boolean>;
  CustomLabel: (key: string) => React.ReactNode;
};

const CustomTypes = ({
  oldData,
  newData,
  setNewData,
  formGroupProps,
  CustomLabel,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [newCustomType, setNewCustomType] = useState<string>("");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    attr: string
  ) => {
    const newValue = Object.assign({}, newData[attr], {
      [e.target.name]: e.target.value,
    });
    setNewData({
      ...newData,
      [attr]: newValue,
    });
  };

  function confirmDeleteCustomType(key: string) {
    const newCustomTypes = _.omit(newData.customTypes, key);
    setNewData({
      ...newData,
      customTypes: newCustomTypes ?? {},
    });
  }

  function onConfirmAddNewCustomType() {
    if (newCustomType !== "") {
      if (Object.keys(oldData.customTypes ?? {}).includes(newCustomType)) {
        dispatch(
          alertActions.ERROR({
            message: `Custom type ${newCustomType} already exists`,
            data: {
              newCustomType: newCustomType,
              oldData: oldData,
            },
          })
        );
        return { error: true };
      } else {
        const newUser = {
          ...newData,
          customTypes: {
            ...newData.customTypes,
            [newCustomType]: newCustomType,
          },
        };
        setNewData(newUser);
        setNewCustomType("");
      }
    }
  }

  function renderAddCustomTypePopover() {
    return (
      <div>
        <InputGroup
          placeholder="New Custom Type"
          value={newCustomType}
          onChange={(e) => {
            setNewCustomType(e.currentTarget.value);
          }}
          className="mb-2"
        />
        <div>
          <Button
            className={cn(Classes.POPOVER_DISMISS, "button-margin-right")}
          >
            Cancel
          </Button>
          <span className="p-1"></span>
          <Button
            id="confirm-button"
            intent={Intent.DANGER}
            disabled={newCustomType === ""}
            className={Classes.POPOVER_DISMISS}
            onClick={onConfirmAddNewCustomType}
            onKeyDown={onConfirmAddNewCustomType}
          >
            Add
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex flex-row align-items-center mt-2">
        <div
          className="d-flex flex-row align-items-center"
          onClick={() => {
            setOpen((prev) => !prev);
          }}
          style={{ cursor: "pointer", userSelect: "none" }}
        >
          <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
          <p className={module.more_txt}>Custom Types</p>
        </div>
        <Divider className="w-100" />
      </div>
      <Collapse isOpen={isOpen}>
        <div>
          {Object.keys(newData.customTypes ?? {})
            .sort(function (a, b) {
              return a.localeCompare(b);
            })
            .map((key) => {
              return (
                <div
                  key={key}
                  className={cn("mb-2", module.custom_types_input)}
                >
                  <FormGroup
                    {...formGroupProps}
                    className="mb-0"
                    label={CustomLabel(key)}
                  >
                    <InputGroup
                      name={key}
                      value={
                        newData.customTypes ? newData.customTypes[key] : ""
                      }
                      onChange={(e) => handleInputChange(e, "customTypes")}
                    />
                  </FormGroup>
                  <InputActionConfirmationDialogue
                    hasDoubleConfirmation={false}
                    onConfirm={() => confirmDeleteCustomType(key)}
                    title="Delete custom type"
                    confirmationText="DELETE"
                  >
                    <Button minimal icon="cross" />
                  </InputActionConfirmationDialogue>
                </div>
              );
            })}

          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Popover
              popoverClassName={Classes.POPOVER_CONTENT_SIZING}
              content={renderAddCustomTypePopover()}
            >
              <Button className="mr-3" icon="add" text="Add custom types" />
            </Popover>

            <span className="mx-1">
              {" "}
              <Button
                text="Reset"
                className="me-1"
                disabled={_.isEqual(oldData.customTypes, newData.customTypes)}
                onClick={() => {
                  setNewData({
                    ...newData,
                    customTypes: oldData.customTypes ?? {},
                  });
                }}
              />
            </span>
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default CustomTypes;
