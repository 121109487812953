import { Timestamp } from "firebase/firestore";
import { all, call, put, takeLatest } from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { User } from "redux/users/types";
import {
  geDefaultUserProfile,
  getAllFields,
  getLookerConfig,
  getLookerEmbeds,
  getScrapersConfig,
  getSettings,
  updateDefaultUserProfile,
  updateLookerConfig,
  updateLookerEmbeds,
  updateScrapersConfig,
  updateSettings,
} from "services/settings";
import { COMMON } from "utils/constants";
import actions from "./actions";
import { LookerConfig, LookerEmbed, SettingsState } from "./types";

function* GET_SETTINGS() {
  yield put(actions.SET_STATE({ loading: true }));

  const { data, error } = yield call(getSettings);

  if (data) {
    yield put(
      actions.SET_STATE({
        loading: false,
        editableFields: data.editableAttributes,
        docsTypes: data.docsTypes,
        defaultInvoiceItemTags: data.defaultInvoiceItemTags,
        invoicesSortByField: data.invoicesSortByField ?? "createdAt",
        invoiceHighlightColors: data.invoiceHighlightColors,
        alertTimeout: data.alertTimeout,
        userStatusValues: data.userStatusValues,
        dataVotingConfig: data.dataVotingConfig,
        userStatusSalesFilter: data.userStatusSalesFilter
          ? data.userStatusSalesFilter.map((x: Timestamp | null) =>
              x ? x.toDate() : null
            )
          : undefined,
      })
    );
  } else if (error) {
    yield put(actions.SET_STATE({ loading: false }));
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

function* GET_SCRAPERS_CONFIG() {
  yield put(actions.SET_STATE({ loading: true }));

  const { data, error } = yield call(getScrapersConfig);

  if (data) {
    yield put(
      actions.SET_STATE({
        loading: false,
        scrapersConfig: {
          frequency: data.frequency,
        },
      })
    );
  } else if (error) {
    yield put(actions.SET_STATE({ loading: false }));
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

function* GET_DEFAULT_USER_PROFILE() {
  yield put(actions.SET_STATE({ loading: true }));

  const { data, error } = yield call(geDefaultUserProfile);

  if (data) {
    yield put(
      actions.SET_STATE({
        loading: false,
        defaultUserProfile: data,
      })
    );
  } else if (error) {
    yield put(actions.SET_STATE({ loading: false }));
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_SCRAPERS_CONFIG_Payload {
  frequency: number;
}

function* UPDATE_SCRAPERS_CONFIG({
  payload,
}: ReturnType<typeof actions.UPDATE_SCRAPERS_CONFIG>) {
  yield put(actions.SET_STATE({ loadingSave: true }));

  const { data, error } = yield call(updateScrapersConfig, payload);

  if (data) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(alertActions.SUCCESS("Scrapers Config Updated Successfully."));
    yield put(actions.GET_SCRAPERS_CONFIG());
  } else if (error) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_SETTINGS_Payload {
  data: Partial<SettingsState>;
}

function* UPDATE_SETTINGS(input: ReturnType<typeof actions.UPDATE_SETTINGS>) {
  yield put(actions.SET_STATE({ loadingSave: true }));

  const { data, error } = yield call(updateSettings, input.payload.data);

  if (data) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(alertActions.SUCCESS("Settings Updated Successfully."));
    yield put(actions.GET_SETTINGS());
  } else if (error) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

function* GET_ALL_FIELDS() {
  yield put(actions.SET_STATE({ loading: true }));

  const { data, error } = yield call(getAllFields);

  if (data) {
    yield put(
      actions.SET_STATE({
        modules: data.modules,
        allFields: data.fields,
      })
    );
  }

  if (error) {
    yield put(
      actions.SET_STATE({
        modules: [],
        allFields: {},
      })
    );
  }

  yield put(actions.SET_STATE({ loading: false }));
}

function* GET_LOOKER_CONFIG() {
  yield put(actions.SET_STATE({ loading: true }));

  const { data, error } = yield call(getLookerConfig);

  if (data) {
    yield put(
      actions.SET_STATE({
        lookerConfig: data,
      })
    );
  } else if (error) {
    yield put(
      actions.SET_STATE({
        lookerConfig: {} as LookerConfig,
      })
    );
  }

  yield put(actions.SET_STATE({ loading: false }));
}

export interface UPDATE_LOOKER_CONFIG_Payload {
  newLookerConfig: LookerConfig;
}

function* UPDATE_LOOKER_CONFIG({
  payload,
}: ReturnType<typeof actions.UPDATE_LOOKER_CONFIG>) {
  yield put(actions.SET_STATE({ loadingSave: true }));

  const { data, error } = yield call(
    updateLookerConfig,
    payload.newLookerConfig
  );

  if (data) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(alertActions.SUCCESS("Looker Config Updated Successfully."));
    yield put(actions.GET_LOOKER_CONFIG());
  } else if (error) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

function* GET_LOOKER_EMBEDS() {
  yield put(actions.SET_STATE({ loading: true }));

  const { data, error } = yield call(getLookerEmbeds);

  if (data) {
    yield put(
      actions.SET_STATE({
        lookerEmbeds: data,
      })
    );
  } else if (error) {
    yield put(
      actions.SET_STATE({
        lookerEmbeds: [],
      })
    );
  }

  yield put(actions.SET_STATE({ loading: false }));
}

export interface UPDATE_LOOKER_EMBEDS_Payload {
  newLookerEmbeds: LookerEmbed[];
}

function* UPDATE_LOOKER_EMBEDS({
  payload,
}: ReturnType<typeof actions.UPDATE_LOOKER_EMBEDS>) {
  yield put(actions.SET_STATE({ loadingSave: true }));

  const { data, error } = yield call(
    updateLookerEmbeds,
    payload.newLookerEmbeds
  );

  if (data) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(alertActions.SUCCESS("Looker Embeds Updated Successfully."));
    yield put(actions.GET_LOOKER_EMBEDS());
  } else if (error) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_DEFAULT_USER_PROFILE_Payload {
  newDefaultUserProfile: Partial<User>;
}

function* UPDATE_DEFAULT_USER_PROFILE({
  payload,
}: ReturnType<typeof actions.UPDATE_DEFAULT_USER_PROFILE>) {
  yield put(actions.SET_STATE({ loadingSave: true }));

  const { data, error } = yield call(
    updateDefaultUserProfile,
    payload.newDefaultUserProfile
  );

  if (data) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(
      alertActions.SUCCESS("Default User Profile Successfully updated.")
    );
    yield put(actions.GET_LOOKER_EMBEDS());
  } else if (error) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_SETTINGS, GET_SETTINGS),
    takeLatest(actions.UPDATE_SETTINGS, UPDATE_SETTINGS),
    takeLatest(actions.GET_SCRAPERS_CONFIG, GET_SCRAPERS_CONFIG),
    takeLatest(actions.UPDATE_SCRAPERS_CONFIG, UPDATE_SCRAPERS_CONFIG),
    takeLatest(actions.GET_ALL_FIELDS, GET_ALL_FIELDS),
    takeLatest(actions.GET_LOOKER_CONFIG, GET_LOOKER_CONFIG),
    takeLatest(actions.UPDATE_LOOKER_CONFIG, UPDATE_LOOKER_CONFIG),
    takeLatest(actions.GET_LOOKER_EMBEDS, GET_LOOKER_EMBEDS),
    takeLatest(actions.UPDATE_LOOKER_EMBEDS, UPDATE_LOOKER_EMBEDS),
    takeLatest(actions.GET_DEFAULT_USER_PROFILE, GET_DEFAULT_USER_PROFILE),
    takeLatest(
      actions.UPDATE_DEFAULT_USER_PROFILE,
      UPDATE_DEFAULT_USER_PROFILE
    ),
  ]);
}
