import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeEvery,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { ServiceReturn } from "redux/types";
import { setSuppliersListener } from "services/listeners/suppliers";
import {
  addSupplier,
  getSuppliers,
  updateSupplier,
  updateSuppliers,
} from "services/supplier";
import { COMMON } from "utils/constants";
import actions from "./actions";
import { Supplier } from "./types";

export function* GET_SUPPLIERS() {
  yield put(actions.SET_STATE({ loading: true }));

  const data: Supplier[] = yield call(getSuppliers);
  yield put(actions.SET_STATE({ data, loading: false }));
}

export interface SUBSCRIBE_TO_SUPPLIERS_Payload {
  includeDeleted: boolean;
}

function* SUBSCRIBE_TO_SUPPLIERS({
  payload,
}: ReturnType<typeof actions.SUBSCRIBE_TO_SUPPLIERS>) {
  const { includeDeleted } = payload;
  yield put(actions.SET_STATE({ loading: true }));

  const channel: EventChannel<boolean> = yield call(
    setSuppliersListener,
    includeDeleted
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_SUPPLIERS);
    channel.close();
  });

  try {
    while (true) {
      const suppliers: Supplier[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          data: suppliers,
          loading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loading: false }));
    }
  }
}

export interface ADD_SUPPLIER_Payload {
  supplier: Supplier;
}
export function* ADD_SUPPLIER({
  payload,
}: ReturnType<typeof actions.ADD_SUPPLIER>) {
  const { supplier } = payload;
  yield put(actions.SET_STATE({ loadingUpdate: true }));

  const result: ServiceReturn = yield call(addSupplier, supplier);
  if (result.data) {
    // set success alert
    yield put(alertActions.SUCCESS("Suppliers Updated"));
    yield put(actions.SET_STATE({ loadingUpdate: false }));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_SUPPLIER_Payload {
  id: string;
  supplier: Partial<Supplier>;
}
export function* UPDATE_SUPPLIER({
  payload,
}: ReturnType<typeof actions.UPDATE_SUPPLIER>) {
  const { id, supplier } = payload;
  yield put(actions.SET_STATE({ loadingUpdate: true }));
  const result: ServiceReturn = yield call(updateSupplier, id, supplier);
  if (result.data) {
    // set success alert
    yield put(alertActions.SUCCESS("Supplier Updated"));
    yield put(actions.SET_STATE({ loadingUpdate: false }));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
    yield put(actions.SET_STATE({ loadingUpdate: false }));
  }
}

export interface UPDATE_SUPPLIERS_Payload {
  suppliers: Supplier[];
}
export function* UPDATE_SUPPLIERS({
  payload,
}: ReturnType<typeof actions.UPDATE_SUPPLIERS>) {
  const { suppliers } = payload;

  const result: ServiceReturn = yield call(updateSuppliers, suppliers);
  if (result.data) {
    // get all suppliers
    yield put(actions.GET_SUPPLIERS());
    // set success alert
    yield put(alertActions.SUCCESS("Suppliers Updated"));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SUPPLIERS, GET_SUPPLIERS),
    takeEvery(actions.ADD_SUPPLIER, ADD_SUPPLIER),
    takeEvery(actions.SUBSCRIBE_TO_SUPPLIERS, SUBSCRIBE_TO_SUPPLIERS),
    takeEvery(actions.UPDATE_SUPPLIERS, UPDATE_SUPPLIERS),
    takeEvery(actions.UPDATE_SUPPLIER, UPDATE_SUPPLIER),
  ]);
}
