import { Icon } from "@blueprintjs/core";

type Props = {
  summaryKey: string;
  summaryValue: string | number;
  onClickFunction: () => void;
};

function InvoiceListItemSummary({
  summaryKey,
  summaryValue,
  onClickFunction,
}: Props) {
  return (
    <div className="row row-margin-bottom" onClick={(e) => e.stopPropagation()}>
      <div className="col-sm-5">{summaryKey}</div>
      <div className="col-sm-7 invoice-header-details">
        {summaryValue}
        <Icon
          className="copy-invoice-detail"
          icon="clipboard"
          size={15}
          title="Copy to Clipboard"
          onClick={onClickFunction}
        />
      </div>
    </div>
  );
}

export default InvoiceListItemSummary;
