import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { OcrState } from "./types";

const initialState: OcrState = {
  invoiceSelections: [],
  ocrItemQuery: "",
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addCase(actions.UPDATE_OCR_ITEM_QUERY_STATE, (state, action) => ({
    ...state,
    ocrItemQuery: action.payload.ocrItemQuery,
  }));
  builder.addCase(actions.REMOVE_OCR_SELECTION_STATE, (state, action) => ({
    ...state,
    invoiceSelections: state.invoiceSelections.filter(
      (invoiceSelection) =>
        invoiceSelection.invoiceId !== action.payload.invoiceId
    ),
  }));
  builder.addCase(actions.PUSH_SELECTION, (state, action) => {
    // find the invoice by ID
    const found = state.invoiceSelections.find(
      (invoice) => invoice.invoiceId === action.payload.invoiceId
    );
    if (!found) {
      const newSelection = {
        invoiceId: action.payload.invoiceId,
        ocrClicks: [...action.payload.ocrClicks],
      };
      return {
        ...state,
        invoiceSelections: [...state.invoiceSelections, newSelection],
      };
    } else {
      return {
        ...state,
        invoiceSelections: state.invoiceSelections.map((s) => {
          if (s.invoiceId === action.payload.invoiceId) {
            return {
              ...s,
              ocrClicks: [...s.ocrClicks, ...action.payload.ocrClicks],
            };
          }
          return s;
        }),
      };
    }
  });
  builder.addDefaultCase((state) => state);
});
