import { createActions } from "redux/create-actions";
import { UPDATE_USER_PRIORITIES_Payload } from "./sagas";
import { UserPriorityState } from "./types";

const actions = createActions("user-priorities", {
  SET_STATE: {} as Partial<UserPriorityState>,
  SUBSCRIBE_TO_USER_PRIORITIES: undefined,
  UNSUBSCRIBE_FROM_USER_PRIORITIES: undefined,
  UPDATE_USER_PRIORITIES: {} as UPDATE_USER_PRIORITIES_Payload,
});

export default actions;
