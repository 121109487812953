import { createReducer } from "@reduxjs/toolkit";
import actions from "redux/notification-centre/actions";
import { NotificationState } from "redux/notification-centre/types";

export const initialState: NotificationState = {
  notifications: [],
  navbarNotifications: [],
  toastsNotifications: [],
  loading: false,
  navbarNotificationsLoading: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addCase(actions.UPDATE_TOAST_NOTIFICATION, (state, action) => {
    if (action.payload.operation === "add") {
      state.toastsNotifications.push({
        id: Math.random().toString().slice(2, 11),
        status: "unread",
        message: action.payload.message ?? "",
        level: 0,
        data: action.payload.data ?? {},
        createdAt: new Date(),
        type: "toast",
      });
    } else if (action.payload.operation === "update") {
      const index = state.toastsNotifications.findIndex(
        (notification) => notification.id === action.payload.id
      );
      if (index !== -1) {
        state.toastsNotifications[index].status = "done";
      }
    }
  });
  builder.addDefaultCase((state) => state);
});
