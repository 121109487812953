import _ from "lodash";
import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import supplierAction from "redux/suppliers/actions";

import {
  Button,
  Collapse,
  Dialog,
  DialogBody,
  Divider,
  FormGroup,
  Icon,
} from "@blueprintjs/core";
import PinnedAttributesEditorPanel, {
  Attribute,
} from "components/edit-pinned-attributes";
import { Supplier } from "redux/suppliers/types";
import AttributeEditor from "../attribute-editor";
import style from "./index.module.scss";

type Props = {
  data: Supplier;
  pinnedProps: Attribute[];
  setPinnedProps: React.Dispatch<React.SetStateAction<Attribute[]>>;
  attrMap: Map<string, string>;
};

const EditSupplierBtn = ({
  data,
  pinnedProps,
  setPinnedProps,
  attrMap,
}: Props) => {
  const dispatch = useAppDispatch();
  const loadingUpdate = useAppSelector(
    (state) => state.suppliers.loadingUpdate
  );
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [newData, setNewData] = useState<Supplier>(data);

  useEffect(() => {
    setNewData(data);
  }, [data]);

  const saveData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const changedData = _.omitBy(
      newData,
      (v, k) => data[k as keyof Supplier] === v
    );
    dispatch(
      supplierAction.UPDATE_SUPPLIER({
        id: data.id,
        supplier: changedData,
      })
    );
  };

  return (
    <div
      style={{
        display: "inline-block",
        textAlign: "center",
      }}
    >
      <Button onClick={() => setIsEditOpen(true)} icon="edit" title="Edit" />
      <Dialog
        isOpen={isEditOpen}
        onClose={() => {
          setNewData(data);
          setIsEditOpen(false);
        }}
        autoFocus
        enforceFocus
        usePortal={true}
        className="pb-0"
        icon="edit"
        title={`Edit Supplier | ${data.id}`}
      >
        <DialogBody>
          <form
            onSubmit={(e) => saveData(e)}
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            {/* DEFAULT ATTRIBUTES */}
            <PinnedAttributesEditorPanel
              module="suppliers"
              attrMap={attrMap}
              oldData={data}
              newData={newData}
              setNewData={setNewData}
              pinnedProps={pinnedProps}
              setPinnedProps={setPinnedProps}
            />

            {/* MORE ATTRIBUTES */}
            <div className="d-flex flex-row align-items-center mt-2">
              <div
                className="d-flex flex-row align-items-center"
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: "pointer", userSelect: "none" }}
              >
                <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
                <p className={style.more_txt}>More properties</p>
              </div>
              <Divider className="w-100" />
            </div>

            <Collapse isOpen={isOpen} className="mb-0">
              <div className="mt-3">
                {Array.from(attrMap.keys())
                  .filter(
                    (attr) => !pinnedProps.find((prop) => prop.name === attr)
                  )
                  .sort()
                  .map((attr, idx) => (
                    <AttributeEditor
                      key={attr + idx}
                      attribute={attr}
                      attrMap={attrMap}
                      oldData={data}
                      newData={newData}
                      setNewData={setNewData}
                      pinnedProps={pinnedProps}
                    />
                  ))}
              </div>
            </Collapse>

            <FormGroup className="mb-0 mt-2">
              <Button
                className="w-100"
                type="submit"
                disabled={_.isEqual(data, newData)}
                loading={loadingUpdate}
              >
                Save
              </Button>
            </FormGroup>
          </form>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default EditSupplierBtn;
