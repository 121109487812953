import { createActions } from "redux/create-actions";
import { AlertPayload, AlertState } from "./types";

const actions = createActions("alert", {
  SET_STATE: {} as Partial<AlertState>,
  INFO: {} as AlertPayload,
  SUCCESS: {} as AlertPayload,
  WARNING: {} as AlertPayload,
  ERROR: {} as AlertPayload,
});

export default actions;
