import {
  Alert,
  Button,
  FocusStyleManager,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Navbar,
  NavbarGroup,
  NavbarHeading,
  Popover,
  Switch,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import { Loader } from "@stockifi/shared";
import LoadActiveUsersIndicator from "components/load-active-users-indicator";
import NotificationDropdown from "components/notifications/notification-dropdown";
import { roles } from "components/permissions/constants";
import Settings from "components/settings";
import React, {
  JSX,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import actions from "redux/auth/actions";
import actionsConfig from "redux/config/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actionsSettings from "redux/settings/actions";
import { SettingsRef } from "redux/types";
import actionsUser from "redux/users/actions";
import { isLocalhost } from "services/firebase";
import tracker from "tracker";
import { APP_VERSION, INITIAL_SEARCH_PROPS } from "utils/constants";
import { claims, hasAccess } from "../permissions";
import "./index.scss";
import NavbarTabs from "./navbar";
import NavSearchBar from "./search-bar";
import ViewAsDialogBox from "./view-as-dialog-box";
import ViewAsStatusBar from "./view-as-status-bar";
import { getUser } from "services/users";

type SearchProps = {
  value: string;
  placeholder: string;
  readOnly: boolean;
};

type ContextType = {
  searchProps: SearchProps;
  setSearchProps: React.Dispatch<SearchProps>;
  focus: () => void;
};

function LayoutOutlet() {
  FocusStyleManager.onlyShowFocusOnTabs();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [isOnline, set_isOnline] = useState(true);
  const [_loadAllUsers, setLoadAllUsers] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const loadAllUsers = useAppSelector((state) => state.settings.loadAllUsers);
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const userAuth = useAppSelector((state) => state.auth.user);
  const loadingPermissions = useAppSelector(
    (state) => state.appConfig.permissionsLoading
  );
  const [userName, setUserName] = useState("");

  const [viewAsDialogIsOpen, setViewAsDialogIsOpen] = useState<boolean>(false);
  const handleClose = () => setViewAsDialogIsOpen(false);
  const [viewAs, setViewAs] = useState({
    isViewing: false,
    viewAsRole: "",
  });

  const [searchProps, setSearchProps] =
    useState<SearchProps>(INITIAL_SEARCH_PROPS);

  const settingsRef = useRef<SettingsRef>(null);

  const currentRole = useMemo(() => {
    if (!authClaims) return "-";
    return roles.find((role) => authClaims[role.code])?.name ?? "-";
  }, [authClaims, roles]);

  const InternetChecker = () => set_isOnline(navigator.onLine === true);
  const interval = setInterval(InternetChecker, 1000);
  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, [interval]);

  useEffect(() => {
    dispatch(actions.GET_CURRENT_USER());
    dispatch(actionsConfig.GET_PERMISSIONS());
  }, [dispatch]);

  useEffect(() => {
    if (isLocalhost) return;
    tracker?.start();
  }, []);

  const getLoggedInUserName = useCallback(
    async (userId: string) => {
      const user = await getUser(userId);
      setUserName(user?.data?.name ?? userId);
    },
    [userAuth]
  );

  useEffect(() => {
    if (!userAuth) return;
    getLoggedInUserName(userAuth);
    tracker?.setUserID(userName);
    tracker?.setMetadata("UID", userAuth);
  }, [userAuth, userName]);

  useEffect(() => {
    setSearchProps(INITIAL_SEARCH_PROPS);

    const moduleName = pathname.split("/")[1];
    document.title = `${
      moduleName
        ? moduleName.charAt(0).toUpperCase() + moduleName.slice(1)
        : "Home"
    } - admin panel`;
  }, [pathname]);

  const logout = () => {
    if (!userAuth) return;
    if (authClaims?.invoices) {
      dispatch(
        actionsUser.UPDATE_USER({
          user: { resolvingUserId: null },
          userId: userAuth,
        })
      );
    } else if (hasAccess(authClaims, claims.checkingPriorityList)) {
      dispatch(
        actionsUser.UPDATE_USER({
          user: { checkingUserId: null },
          userId: userAuth,
        })
      );
    }

    dispatch(actions.LOGOUT());
    tracker?.stop();
  };

  const InternetStatusBar = () => {
    return (
      <div className="offline_bottom_bar">
        <div className="offline_bottom_bar_text">
          <span> You are offline </span>
        </div>
      </div>
    );
  };

  const handleLoadAllUsers = () => {
    dispatch(actionsSettings.SET_STATE({ loadAllUsers: _loadAllUsers }));
    setAlertOpen(false);
  };

  const handleCancelLoadAllUsers = () => {
    setLoadAllUsers((prev) => !prev);
    setAlertOpen(false);
  };

  const getFunctionsButton = (): JSX.Element => {
    return (
      <Menu key="navbar-functions-menu">
        <MenuItem text={`${userName} (${currentRole})`} disabled />
        {hasAccess(authClaims, claims.settings) && (
          <MenuItem
            text="Settings"
            icon="cog"
            onClick={() => {
              if (settingsRef.current) {
                settingsRef.current.handleOpenDialog();
              }
            }}
          />
        )}
        {hasAccess(authClaims, claims.viewAs) && !viewAs.isViewing && (
          <MenuItem
            text="View As"
            icon="eye-open"
            onClick={() => {
              setViewAsDialogIsOpen(true);
            }}
          />
        )}
        {hasAccess(authClaims, claims.loadAllUsers) && (
          <>
            <MenuDivider />
            <div style={{ padding: "5px 10px 5px 5px" }}>
              <Switch
                className="m-0"
                label="Load All Users"
                checked={loadAllUsers}
                onChange={(e) => {
                  setLoadAllUsers(e.target.checked);
                  setAlertOpen(true);
                }}
              />
            </div>
            <MenuDivider />
          </>
        )}
        <MenuItem text="Logout" icon="power" onClick={logout} />
      </Menu>
    );
  };

  const isWindows = useMemo(() => {
    return navigator.appVersion.indexOf("Win") !== -1;
  }, []);

  const focus = () => {
    const searchBar = document.getElementById("search-bar");
    if (searchBar) searchBar.focus();
  };

  return (
    <>
      <Alert
        isOpen={isAlertOpen}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        canOutsideClickCancel
        intent={Intent.DANGER}
        onConfirm={handleLoadAllUsers}
        onCancel={handleCancelLoadAllUsers}
        onClose={handleCancelLoadAllUsers}
      >
        <p>
          Are you sure you want to load{" "}
          {_loadAllUsers ? "all users" : "active users only"}?
        </p>
      </Alert>
      <div className="scroll-bar">
        {viewAs.isViewing && (
          <ViewAsStatusBar viewAs={viewAs} setViewAs={setViewAs} />
        )}

        <Loader loading={loadingPermissions} loaderHeight={0}>
          <Navbar className="navbar-main">
            <NavbarGroup className="navbar-header w-100 px-lg-2">
              <NavbarHeading>
                Stockifi{" "}
                <Tag minimal intent="primary">
                  v{APP_VERSION}
                </Tag>
              </NavbarHeading>

              <NavSearchBar
                searchProps={searchProps}
                setSearchProps={setSearchProps}
              />
              <NavbarGroup className="navbar-header-right">
                <LoadActiveUsersIndicator />
                {isWindows && (
                  <Tooltip content="Run Calculator" position="bottom">
                    <a href="calculator://">
                      <Button icon="calculator" minimal />
                    </a>
                  </Tooltip>
                )}
                {!loadingPermissions &&
                  hasAccess(authClaims, claims?.viewNotifications) && (
                    <NotificationDropdown />
                  )}
                <Popover
                  portalClassName="cog-btn-popover"
                  content={getFunctionsButton()}
                  placement="bottom-start"
                >
                  {!loadingPermissions && <Button icon="menu" minimal />}
                </Popover>
              </NavbarGroup>
            </NavbarGroup>
            <NavbarTabs />
          </Navbar>
        </Loader>

        <div>
          {!loadingPermissions && (
            <Outlet context={{ searchProps, setSearchProps, focus }} />
          )}
        </div>

        {isOnline !== true ? <InternetStatusBar /> : ""}

        <ViewAsDialogBox
          isOpen={viewAsDialogIsOpen}
          onClose={handleClose}
          setViewAs={setViewAs}
        />

        <Settings ref={settingsRef} />
      </div>
    </>
  );
}

export function useSearch() {
  return useOutletContext<ContextType>();
}

export default LayoutOutlet;
