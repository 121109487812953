import {
  AnchorButton,
  Button,
  Classes,
  Divider,
  EditableText,
  FormGroup,
  H5,
  InputGroup,
  Intent,
  Popover,
  Tooltip,
} from "@blueprintjs/core";
import ActionConfirmationDialogue from "components/action-confirmation-dialogue";
import { DocumentReference, Timestamp } from "firebase/firestore";
import Fuse from "fuse.js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import alertActions from "redux/alert/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import supplierActions from "redux/suppliers/actions";
import { Supplier } from "redux/suppliers/types";
import { isSupplierDeleted, updateSupplier } from "services/supplier";
import { SUPPLIER } from "utils/constants";

type Props = {
  onFinish: (createdSupplier?: Supplier) => void;
  searchedSupplier: string;
};

function SuppliersCRUD({ onFinish, searchedSupplier }: Props) {
  const dataRedux = useAppSelector((state) => state.suppliers.data);
  const loading = useAppSelector((state) => state.suppliers.loading);
  const loadingUpdate = useAppSelector(
    (state) => state.suppliers.loadingUpdate
  );
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const [query, setQuery] = useState("");
  const [hasChanges, setHasChanges] = useState<Supplier[]>([]);
  const [resultSuppliers, setResultSuppliers] = useState<Supplier[]>([]);
  const [data, setData] = useState<Supplier[]>([]);
  const [newSupplier, setNewSupplier] = useState(
    searchedSupplier.charAt(0).toUpperCase() + searchedSupplier.slice(1)
  );
  const [revertDeletedSupplier, setRevertDeletedSupplier] =
    useState<DocumentReference | null>(null);
  const [loadingCreateSupplier, setLoadingCreateSupplier] = useState(false);

  const dispatch = useAppDispatch();
  useEffect(() => {
    setData(dataRedux);
    setResultSuppliers(dataRedux);
  }, [dataRedux]);

  const fuse = useMemo(() => {
    return new Fuse<Supplier>(data, {
      includeMatches: true,
      keys: [{ name: "name", weight: 3 }],
      ignoreLocation: true,
      useExtendedSearch: true,
    });
  }, [data]);

  const filterSuppliers = useCallback(() => {
    if (query === "") return data;
    const results = fuse.search(query);
    return results.map((i) => i.item);
  }, [fuse, query, data]);

  const handleChange = (
    e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>
  ) => {
    let name = e.currentTarget.value;
    name = name.charAt(0).toUpperCase() + name.slice(1);
    setNewSupplier(name);
  };

  function updateData(supplierId: string, name: string) {
    if (name === "") return;

    const idx = data.findIndex((s) => s.id === supplierId);
    const copy = [...data];
    // Capitalize first letter of name
    copy[idx].name = name.charAt(0).toUpperCase() + name.slice(1);
    copy[idx].updatedAt = Timestamp.now();
    setData(copy);
    setHasChanges([...hasChanges, copy[idx]]);
  }

  useEffect(() => {
    if (data.length > 0) {
      const timeOutId = setTimeout(
        () => setResultSuppliers(filterSuppliers),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [query, data.length, filterSuppliers]);

  function onSave() {
    if (hasChanges.length > 0) {
      dispatch(supplierActions.UPDATE_SUPPLIERS({ suppliers: hasChanges }));
    }

    const existingSupplier = data.find((s) => s.name === newSupplier);

    onFinish(existingSupplier);
  }

  async function createNewSupplier() {
    const existingSupplier = data.find((s) => s.name === newSupplier);
    if (existingSupplier) {
      dispatch(alertActions.ERROR("Supplier already exists."));
      return;
    }

    // check is supplier deleted
    setLoadingCreateSupplier(true);
    const isDeletedSupplier = await isSupplierDeleted(newSupplier);

    if (isDeletedSupplier.data) {
      setRevertDeletedSupplier(isDeletedSupplier.data);
      setLoadingCreateSupplier(false);
      return;
    }

    if (newSupplier.trim() !== "" && existingSupplier === undefined) {
      const tempSupplier = new Supplier(newSupplier, { name: newSupplier });
      onFinish(tempSupplier);
      dispatch(supplierActions.ADD_SUPPLIER({ supplier: tempSupplier }));
      setLoadingCreateSupplier(false);
      return;
    }
    setLoadingCreateSupplier(false);
    return;
  }

  function handleDeleteSupplier(supplier: Supplier) {
    dispatch(
      supplierActions.UPDATE_SUPPLIERS({
        suppliers: [{ ...supplier, deleted: true }],
      })
    );
  }
  return (
    <div>
      <FormGroup label="Name" labelFor="name">
        <InputGroup
          id="name"
          name="name"
          onChange={handleChange}
          value={newSupplier}
        />
        <Popover
          enforceFocus={true}
          isOpen={revertDeletedSupplier !== null}
          fill
          content={
            <div>
              <H5>{`Restore Supplier`}</H5>
              <p>{`The supplier already exists but has been deleted. Do you want to restore this supplier?`}</p>
              <div className="button-display">
                <Button
                  text="Cancel"
                  onClick={() => setRevertDeletedSupplier(null)}
                  style={{ marginRight: 10 }}
                />
                <Button
                  id="confirm-button"
                  intent={Intent.DANGER}
                  className={Classes.POPOVER_DISMISS}
                  onClick={async () => {
                    if (revertDeletedSupplier) {
                      await updateSupplier(revertDeletedSupplier.id, {
                        deleted: false,
                      });
                      setRevertDeletedSupplier(null);
                    }
                  }}
                  onKeyDown={async () => {
                    if (revertDeletedSupplier) {
                      await updateSupplier(revertDeletedSupplier.id, {
                        deleted: false,
                      });
                      setRevertDeletedSupplier(null);
                    }
                  }}
                  text={"Ok"}
                />
              </div>
            </div>
          }
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        >
          <Button
            loading={loadingCreateSupplier}
            className="mt-2"
            fill
            type="submit"
            onClick={createNewSupplier}
          >
            Create
          </Button>
        </Popover>
      </FormGroup>

      <Divider />
      <FormGroup label="Search" labelFor="search" className="pt-2">
        <InputGroup
          value={query}
          fill
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Search Suppliers..."
        />
      </FormGroup>
      <div className="pt-1 row ">
        <PerfectScrollbar>
          <div className="height-300">
            {resultSuppliers.map((supplier, idx) => {
              if (supplier.deleted) return null;
              return (
                <div
                  className="row mt-1"
                  key={`supplier_row_${supplier.id}_${idx}`}
                >
                  <div className="col-10">
                    <EditableText
                      multiline={false}
                      maxLines={1}
                      defaultValue={supplier.name}
                      disabled={supplier.deleted}
                      // isEditing={editable !== supplier.id}
                      onConfirm={(value: string) =>
                        updateData(supplier.id, value)
                      }
                    />
                  </div>
                  <div className="col-2">
                    <div className="float-right">
                      <Tooltip
                        compact
                        content={`${
                          !(
                            authClaims?.admin ||
                              authClaims?.supervisor ||
                              authClaims?.headDataManager
                          )
                            ? "You don't have the permission to delete"
                            : ""
                        }`}
                      >
                        <ActionConfirmationDialogue
                          title="Confirm Deletion"
                          text={SUPPLIER.CONFIRM_DELETE_SUPPLIER}
                          onConfirm={() => handleDeleteSupplier(supplier)}
                          disabled={
                            !(
                              authClaims?.admin ||
                              authClaims?.supervisor ||
                              authClaims?.headDataManager
                            )
                          }
                        >
                          <AnchorButton
                            loading={loading || loadingUpdate}
                            icon="trash"
                            disabled={
                              !(
                                authClaims?.admin ||
                                authClaims?.supervisor ||
                                authClaims?.headDataManager
                              )
                            }
                          />
                        </ActionConfirmationDialogue>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </PerfectScrollbar>
        <Button
          loading={loading || loadingUpdate}
          className="mt-2"
          fill
          type="submit"
          onClick={onSave}
        >
          Update
        </Button>
      </div>
    </div>
  );
}

export default SuppliersCRUD;
