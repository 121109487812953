import { createReducer } from "@reduxjs/toolkit";
import actions from "redux/counts/actions";
import { CountsState } from "./types";

export const countsInitialState: CountsState = {
  loadingCounts: false,
  loadingUpdate: false,
  loadingBulkEdit: false,
  counts: [],
};

export default createReducer(countsInitialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
