import { Icon, MenuItem, Popover } from "@blueprintjs/core";
import { ItemRenderer, ItemRendererProps, Select } from "@blueprintjs/select";
import { cn } from "@stockifi/shared";
import React from "react";
import { Filter } from "redux/config/types";
import { COMPARISON_OPERATORS } from "utils/constants";
import { DEFAULT_SELECTOR_PROPS, MultiSelectItem } from "../multiselect";
import module from "./index.module.scss";

type Props = {
  selItem: MultiSelectItem;
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  handleRefilter: (filters: Filter[]) => Promise<void>;
  value: string | number | undefined;
};

const MultiSelectTag = ({
  selItem,
  filters,
  setFilters,
  handleRefilter,
  value,
}: Props) => {
  const operatorDropdown: React.RefObject<Popover<any>> = React.createRef();

  const key = selItem.attribute;
  const opStr = selItem.operator;
  const selectDisabled = typeof value !== "number" && key !== "appVersion";

  const renderOperators: ItemRenderer<string> = (
    operator,
    { handleClick, handleFocus, modifiers }: ItemRendererProps
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={"op-" + operator}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        selected={opStr === operator}
        shouldDismissPopover={false}
        text={`${operator} (${COMPARISON_OPERATORS.get(operator)})`}
      />
    );
  };

  const handleOperatorSelect = async (operator: string) => {
    const newSelected = filters.map((filter) => {
      if (filter.id !== selItem.id) return filter;
      else
        return {
          ...filter,
          operator,
        };
    });
    setFilters(newSelected);
    await handleRefilter(newSelected);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select<string>
        {...DEFAULT_SELECTOR_PROPS}
        disabled={selectDisabled}
        itemRenderer={renderOperators}
        items={Array.from(COMPARISON_OPERATORS.keys())}
        onItemSelect={handleOperatorSelect}
        popoverRef={operatorDropdown}
      >
        <div key={"selOp-" + key}>
          {`${key} ${COMPARISON_OPERATORS.get(opStr || "equal to")} `}
          <span style={{ color: "#bb86fc" }}>{value}</span>
          <Icon
            className={cn("ms-2", selectDisabled && module.rm)}
            icon="caret-down"
          />
        </div>
      </Select>
    </div>
  );
};

export default MultiSelectTag;
