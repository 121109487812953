import {
  Card,
  Checkbox,
  Elevation,
  Icon,
  Position,
  Tooltip,
} from "@blueprintjs/core";
import { cn } from "@stockifi/shared";
import { Attribute } from "components/edit-pinned-attributes";
import { Pagination } from "components/pagination";
import React, { useState, useEffect, useMemo, JSX } from "react";
import docsActions from "redux/docs/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/users/actions";
import EditColumnsButton from "./edit-columns-btn";
import style from "./index.module.scss";
import TableRow from "./table-row";

type Props = {
  module: string;

  data: any[];
  firstColumn: string;
  pinnedCols: Attribute[];
  setPinnedCols: React.Dispatch<React.SetStateAction<Attribute[]>>;
  getActions: (
    datum: any,
    expanded: boolean,
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  ) => JSX.Element;
  isSelectedAll: Record<number, boolean>;

  selectedData: any[];
  handleSelectAll: (e: React.FormEvent<HTMLInputElement>) => void;
  handleSelectRow: (e: React.FormEvent<HTMLInputElement>) => void;

  getFieldValue: (field: string, value: any) => string;
  attrMap: Map<string, string>;

  groupFilter?: (uniqueValues: any[], item: any) => boolean;
  expandedContent?: (
    datum: any,
    expanded: boolean,
    index: number,

    rowRef: React.RefObject<any>
  ) => JSX.Element;
  previewNext?: string;
  isGrouped?: boolean;
  setIsGrouped?: React.Dispatch<React.SetStateAction<boolean>>;

  setSortedData: React.Dispatch<React.SetStateAction<any[]>>;

  sortedData: any[];

  filterPerPage: any;
  setFilterPerPage: React.Dispatch<React.SetStateAction<number>>;

  filterFrom: any;
  setFilterFrom: React.Dispatch<React.SetStateAction<number>>;
  setOpenEditAttribute?: React.Dispatch<React.SetStateAction<boolean>>;
  setInitialAttributeToEdit?: React.Dispatch<
    React.SetStateAction<Attribute | undefined>
  >;
  differentAttributes?: {
    attribute: string;
    trueCount: number;
    falseCount: number;
  }[];
  showMisMatch?: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const Table = ({
  module,
  data,
  firstColumn,
  pinnedCols,
  setPinnedCols,
  getActions,
  isSelectedAll,
  selectedData,
  handleSelectAll,
  handleSelectRow,
  getFieldValue,
  attrMap,
  groupFilter,
  expandedContent,
  previewNext,
  isGrouped,
  setIsGrouped,
  setSortedData,
  sortedData,
  filterPerPage,
  setFilterPerPage,
  filterFrom,
  setFilterFrom,
  setOpenEditAttribute,
  setInitialAttributeToEdit,
  differentAttributes,
  showMisMatch,
  page,
  setPage,
}: Props) => {
  const dispatch = useAppDispatch();
  const loadingSaveColumnOrder = useAppSelector(
    (state) => state.users.loadingSaveColumnOrder
  );
  const adminDocs = useAppSelector((state) => state.docs.adminDocs);

  const [sortField, setSortField] = useState<string>("");
  const [order, setOrder] = useState("asc");
  const [isNumberedList, setIsNumberedList] = useState(true);

  useEffect(() => {
    setSortedData(data);
    if (sortField && order) {
      handleSorting(sortField, order);
    }
  }, [data]);

  useEffect(() => {
    dispatch(docsActions.GET_DOCS({ docName: "Admin Panel" }));
  }, [dispatch, module]);

  useEffect(() => {
    dispatch(actions.GET_USER_TABLE_COLUMNS({ module }));
  }, [dispatch, module]);

  const sliceSortedData = useMemo(() => {
    return sortedData.slice(filterFrom, filterFrom + filterPerPage);
  }, [sortedData, filterFrom, filterPerPage]);

  const handleSaveColumnOrder = () => {
    let reordered = {};
    pinnedCols
      .filter((col) => col.id !== 1)
      .forEach((col, index) => {
        const newObject = Object.assign({}, reordered, {
          [col.name]: index + 2,
        });
        reordered = newObject;
      });

    dispatch(
      actions.UPDATE_USER_TABLE_COLUMNS({
        data: reordered,
        module: module,
      })
    );
  };

  const handleSortingChange = (field?: string) => {
    if (!field) return;
    const sortOrder = field === sortField && order === "asc" ? "desc" : "asc";
    setSortField(field);
    setOrder(sortOrder);
    handleSorting(field, sortOrder);
  };

  const handleSorting = (sortField: string, sortOrder: string) => {
    if (sortField) {
      const sorted = [...data].sort((a, b) => {
        const valueA: any = a[sortField];

        const valueB: any = b[sortField];

        if (valueA == null) return 1;
        if (valueB == null) return -1;
        if (valueA == null && valueB == null) return 0;

        return (
          (valueA ?? "")
            .toString()
            .localeCompare((valueB ?? "").toString(), "en", { numeric: true }) *
          (sortOrder === "asc" ? 1 : -1)
        );
      });
      setSortedData(sorted);
    }
  };

  const sortIconName = (field?: string) => {
    return sortField === field && order === "asc" ? (
      <Icon
        onClick={() => {
          handleSortingChange(field);
        }}
        style={{ cursor: "pointer" }}
        icon="caret-up"
      />
    ) : sortField === field && order === "desc" ? (
      <Icon
        onClick={() => {
          handleSortingChange(field);
        }}
        style={{ cursor: "pointer" }}
        icon="caret-down"
      />
    ) : (
      <Icon
        onClick={() => {
          handleSortingChange(field);
        }}
        style={{ cursor: "pointer" }}
        icon="double-caret-vertical"
      />
    );
  };

  const getHeaderDescription = (name: any) => {
    return (
      adminDocs.filter((data) => data.id === "tableColumns")[0]?.keys[name]
        ?.description || name
    );
  };

  useEffect(() => {
    if (!isGrouped || !groupFilter) {
      setSortedData(data);
      if (sortField && order) {
        handleSorting(sortField, order);
      }
    } else {
      const uniqueValues: any[] = [];
      setSortedData(
        data.filter((datum: any) => groupFilter(uniqueValues, datum))
      );
    }
  }, [data, isGrouped]);

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <EditColumnsButton
          module={module}
          pinnedCols={pinnedCols}
          setPinnedCols={setPinnedCols}
          attrMap={attrMap}
          handleSaveColumnOrder={handleSaveColumnOrder}
          loadingSaveColumnOrder={loadingSaveColumnOrder}
          isGrouped={isGrouped}
          setIsGrouped={setIsGrouped}
          isNumberedList={isNumberedList}
          setIsNumberedList={setIsNumberedList}
        />
        <div className="d-flex" style={{ gap: 5 }}>
          <Pagination
            initialPage={page}
            total={sortedData.length}
            size={filterPerPage}
            onPageChange={(page) => {
              setPage(page);
              setFilterFrom(filterPerPage * (page - 1));
            }}
            onSizeChange={(size) => {
              setFilterPerPage(size);
            }}
          />
        </div>
      </div>
      <Card elevation={Elevation.ONE}>
        <div className={style.container} id={`table-${module}`}>
          <table className={style.table}>
            <thead>
              <tr className={style.tr}>
                <th className={cn(style.th, "d-flex align-items-center")}>
                  {/* Check All Functionality */}
                  <Checkbox
                    style={{ margin: 0 }}
                    onChange={(e) => handleSelectAll(e)}
                    checked={!!isSelectedAll[page]}
                  />
                  <span>({selectedData.length})</span>
                </th>
                <th
                  className={style.th}
                  onClick={() =>
                    handleSortingChange(
                      pinnedCols.find((col) => col.id === 1)?.name
                    )
                  }
                  data-testid="pinned columns"
                >
                  <Tooltip
                    content={getHeaderDescription(
                      pinnedCols.find((col) => col.id === 1)?.name
                    )}
                    position={Position.TOP}
                  >
                    <div>
                      {pinnedCols.find((col) => col.id === 1)?.name}
                      <span style={{ paddingRight: "10px" }}></span>
                      {sortIconName(
                        pinnedCols.find((col) => col.id === 1)?.name
                      )}
                    </div>
                  </Tooltip>
                </th>
                {pinnedCols
                  .filter((col) => col.id !== 1)
                  .map((col, idx) => {
                    return (
                      <th
                        className={style.th}
                        key={idx}
                        data-testid="pinned columns"
                      >
                        <Tooltip
                          content={getHeaderDescription(col.name)}
                          position={Position.TOP}
                        >
                          <div>
                            {col.name}
                            <span
                              style={{
                                paddingRight:
                                  module === "users"
                                    ? selectedData.length > 0
                                      ? ""
                                      : "36px"
                                    : "10px",
                              }}
                            ></span>
                            {module === "users" && selectedData.length > 0 && (
                              <>
                                <span style={{ paddingLeft: "10px" }}></span>
                                <Icon
                                  onClick={() => {
                                    setOpenEditAttribute?.(true);
                                    setInitialAttributeToEdit?.({ ...col });
                                  }}
                                  icon="edit"
                                  className={style.edit_icon}
                                  style={{ cursor: "pointer" }}
                                />
                                <span style={{ paddingRight: "10px" }}></span>
                              </>
                            )}
                            {sortIconName(col.name)}
                          </div>
                        </Tooltip>
                      </th>
                    );
                  })}
                <th className={style.th} style={{ width: "10px" }}>
                  <span>Actions</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {sliceSortedData.map((datum, idx) => (
                <TableRow
                  key={datum.id || idx}
                  idx={idx + filterFrom}
                  module={module}
                  datum={datum}
                  firstColumn={firstColumn}
                  selectedData={selectedData}
                  isNumberedList={isNumberedList}
                  handleSelectRow={handleSelectRow}
                  pinnedCols={pinnedCols}
                  getActions={getActions}
                  getFieldValue={getFieldValue}
                  expandedContent={expandedContent}
                  attrMap={attrMap}
                  previewNext={previewNext}
                  differentAttributes={differentAttributes}
                  showMisMatch={showMisMatch}
                  allDataLength={sortedData.length}
                />
              ))}
            </tbody>
          </table>
        </div>
      </Card>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 10,
        }}
      >
        <Pagination
          initialPage={page}
          total={sortedData.length}
          size={filterPerPage}
          onPageChange={(page) => {
            setPage(page);
            setFilterFrom(filterPerPage * (page - 1));
          }}
          onSizeChange={(size) => {
            setFilterPerPage(size);
          }}
        />
      </div>
    </>
  );
};

export default Table;
