import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { SuppliersState } from "./types";

const initialState: SuppliersState = {
  data: [],
  loading: false,
  loadingUpdate: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
