import {
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { Items } from "redux/invoice/types";
import { db } from "services/firebase";

function globalDataUserItems(doc: QueryDocumentSnapshot[]): Items[] {
  return doc.map((x) => {
    const id = x.id;
    const data = x.data();
    data.cost = Number(data.cost);

    if (data.globalData) {
      const { name, ...globalData } = data.globalData;
      return { ...data, ...globalData, id: id };
    }
    return { ...data, id: id };
  });
}

export function setUserItemsListener(userId: string): EventChannel<Items[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const unsub = onSnapshot(
      collection(db, "users", userId, "items"),
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data = globalDataUserItems(snapshot.docs);
        const userItems: Items[] = [...data];
        emitter(userItems);
      }
    );

    return () => unsub();
  });
}
