const PAGES_TO_SHOW = 5;
const PAGES_ON_EITHER_SIDE = 2;
export const getPaginationState = (table) => {
    let startPage = 0;
    let endPage = 0;
    let showStartEllipsis = false;
    let showEndEllipsis = false;
    const currentPage = table.getState().pagination.pageIndex + 1;
    const totalPages = table.getPageCount();
    if (totalPages <= PAGES_TO_SHOW) {
        // Show all if total pages is less than PAGES_TO_SHOW
        startPage = 1;
        endPage = totalPages;
    }
    else {
        if (currentPage <= PAGES_TO_SHOW - PAGES_ON_EITHER_SIDE) {
            // more than PAGINATION_THRESHOLD total pages so calculate start and end pages
            startPage = 1;
            endPage = PAGES_TO_SHOW;
            showEndEllipsis = true;
        }
        else if (currentPage + PAGES_ON_EITHER_SIDE >= totalPages) {
            // current page approaching the total pages
            startPage = totalPages - (PAGES_TO_SHOW - 1);
            endPage = totalPages;
            showStartEllipsis = true;
        }
        else {
            // current page is somewhere in the middle
            startPage = currentPage - PAGES_ON_EITHER_SIDE;
            endPage = currentPage + PAGES_ON_EITHER_SIDE;
            showStartEllipsis = true;
            showEndEllipsis = true;
        }
    }
    const pages = Array.from({ length: endPage + 1 - startPage }, (_, i) => startPage + i);
    return {
        showStartEllipsis,
        showEndEllipsis,
        startPage,
        endPage,
        pages,
        currentPage,
        totalPages,
    };
};
