import {
  Button,
  Card,
  Classes,
  Elevation,
  InputGroup,
  Label,
  MenuItem,
  Popover,
  Position,
  Switch,
  Tag,
} from "@blueprintjs/core";
import { ItemPredicate, Select } from "@blueprintjs/select";
import { cn } from "@stockifi/shared";
import ActionConfirmationDialogue from "components/action-confirmation-dialogue";
import ItemValues from "components/invoices/item-values";
import ItemSearch from "components/invoices/items-search";
import _ from "lodash";
import { DataLabelingContext } from "pages/data-labeling";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NumericFormat } from "react-number-format";
import alertActions from "redux/alert/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/invoice/actions";
import { Alias, Invoice, InvoiceItem, Items } from "redux/invoice/types";
import { Selection } from "redux/ocr/types";
import { getUserCurrency } from "redux/users/helpers";
import { formatNumber } from "utils/common";
import { INVOICE } from "utils/constants";
import { claims, hasAccess } from "../../../permissions";
import { getBorderColor, getPercentageTextColor } from "../color.helpers";
import InvoicePopover from "../invoice-popover";
import { reorder } from "../invoice/helpers";
import ItemAddAlias from "../item-add-alias";
import {
  ITEM_TAGS,
  isItemPrefilled,
  isVoteValueGetPrefilled,
} from "../tag.helpers";
import EditAliasContext from "./EditAliasContext";
import Highlighter from "./Highlighter";
import ItemContext from "./ItemContext";
import { setItemOcrInFocus, setItemOcrInFocusClear } from "./helpers";
import "./index.scss";
import ItemSelectButton from "components/data-voting/invoice-votes-results/item-select-button";
import { Link } from "react-router-dom";
import { TAX_DEDUCTIONS } from "../invoice/constants";

type Props = {
  isTable?: boolean;
  setSelectedImage: (image: string) => void;
  item: InvoiceItem;
  newInvoice: Invoice;
  newItems: InvoiceItem[];
  setNewItems: React.Dispatch<React.SetStateAction<InvoiceItem[]>>;
  handleDeleteItem: (uuid: string) => void;
  onItemValueChange: (item: InvoiceItem) => void;
  setHasInputError: React.Dispatch<
    React.SetStateAction<{
      date: boolean;
      number: boolean;
      item: boolean;
      food: boolean;
    }>
  >;
  searchInput: string;
  setInvoiceOcrInFocus: (obj: Selection[]) => void;
  stackableTags: {
    percentChanged: InvoiceItem[];
    percentChanged75: InvoiceItem[];
    new: InvoiceItem[];
    negative: InvoiceItem[];
    nameChanged: InvoiceItem[];
    openItem: InvoiceItem[];
    autoTotal: InvoiceItem[];
    autoQuantity: InvoiceItem[];
    wasOpenItem: InvoiceItem[];
    hasError: InvoiceItem[];
  };
  initialTags: Record<string, InvoiceItem[]>;
  itemLength: number;
  data: Invoice;
  setNewInvoice: React.Dispatch<React.SetStateAction<Invoice>>;
  userItemsAndFees: Items[];
  openItemIdSelected: (
    selectedInvoiceItem: Items,
    item: InvoiceItem,
    isNew: boolean,
    searchQuery?: string
  ) => void;
  onCreateItemButtonClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    idx: number,
    query: string
  ) => void;
  initialQuery?: string;
  searchInsideItemQuery?: string;
  searchInsideItemFocus?: string;
  setIsItemAlreadyRender?: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  isItemAlreadyRender?: Record<string, boolean>;
  index: number;
  quantityFocus: string;
  onMarkAsDuplicate: (item: InvoiceItem) => void;
  ocrClicks: Selection[] | undefined;
  setOcrClicks: React.Dispatch<React.SetStateAction<Selection[] | undefined>>;
  imgFile: string;
  openItemSearchFocus?: string;
  setOpenItemSearchFocus?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setActiveOCRItemCard: React.Dispatch<React.SetStateAction<number | null>>;
  activeOCRItemCard: number | null;
  setPopoverSearchItemSelectOpen: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  queryTrigger?: string;
  fillConvertToOpenItem: (value: string, itemUuid: string) => void;
};

type ItemWithInitTag = InvoiceItem & { initTag: string };

function Item({
  isTable,
  setSelectedImage,
  imgFile,
  item,
  newInvoice,
  newItems,
  setNewItems,
  handleDeleteItem,
  onItemValueChange,
  setHasInputError,
  searchInput,
  setInvoiceOcrInFocus,
  stackableTags,
  initialTags,
  itemLength,
  data,
  setNewInvoice,
  userItemsAndFees,
  openItemIdSelected,
  onCreateItemButtonClick,
  initialQuery,
  setIsItemAlreadyRender,
  isItemAlreadyRender,
  index,
  quantityFocus,
  onMarkAsDuplicate,
  ocrClicks,
  setOcrClicks,
  openItemSearchFocus,
  setOpenItemSearchFocus,
  setActiveOCRItemCard,
  activeOCRItemCard,
  searchInsideItemQuery,
  searchInsideItemFocus,
  setPopoverSearchItemSelectOpen,
  queryTrigger,
  fillConvertToOpenItem,
}: Props) {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.invoices.userId);
  const userInvoices = useAppSelector((state) => state.invoices.invoices);
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const userAuth = useAppSelector((state) => state.auth.user);
  const { modification } = useContext(DataLabelingContext);

  const [isAddAliasesOpen, setIsAddAliasesOpen] = useState(false);
  const [newAliases, setNewAliases] = useState<Alias[]>([]);
  const itemIndex = useMemo(() => item.index + 1, [item]);
  const [editableIndex, setEditableIndex] = useState<string>(
    itemIndex ? itemIndex.toString() : ""
  );
  const [openItemCreate, setOpenItemCreate] = useState(false);
  const invoices = userInvoices;
  const [newItemName, setNewItemName] = useState<string>(item.name);

  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const searchInSelectItemRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (openItemSearchFocus !== undefined || openItemSearchFocus !== null) {
      if (openItemSearchFocus === item.uuid) {
        searchInputRef.current?.focus();
      }
    }
  }, [openItemSearchFocus, item.uuid]);

  useEffect(() => {
    if (searchInsideItemFocus !== undefined || searchInsideItemFocus !== null) {
      if (searchInsideItemFocus === item.uuid) {
        searchInSelectItemRef.current?.focus();
      }
    }
  }, [searchInsideItemFocus, item.uuid]);

  const itemId = useMemo(() => item.id, [item.id]);
  const currency = useMemo(() => getUserCurrency(userId), [userId]);

  const filteredInitialTags = useMemo(() => {
    if (Object.keys(initialTags).length === 0) return {};
    return ITEM_TAGS.reduce(
      (acc, tag) => {
        return {
          ...acc,
          [tag.name]: initialTags[tag.name].filter((item) => {
            return !(stackableTags as Record<string, InvoiceItem[]>)[
              tag.name
            ]?.some((x) => x.uuid === item.uuid);
          }),
        };
      },
      {} as Record<string, InvoiceItem[]>
    );
  }, [initialTags, stackableTags]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  const isAliasNew = useCallback(
    (alias: Alias) => {
      if (!alias || !newInvoice || !newInvoice.newAliases) {
        return false;
      }
      const aliasArray = newInvoice.newAliases[itemId];
      if (!aliasArray) {
        return false;
      }
      return aliasArray.some((x) => x.name === alias.name);
    },
    [itemId, newInvoice.newAliases]
  );

  const itemsFromInitialTag = useMemo(() => {
    if (Object.keys(filteredInitialTags).length === 0) return [];
    const items: ItemWithInitTag[] = Object.keys(filteredInitialTags).reduce<
      ItemWithInitTag[]
    >((acc, key) => {
      const foundItem = filteredInitialTags[
        key as keyof typeof filteredInitialTags
      ].find((x) => x.uuid === item.uuid);
      if (foundItem) {
        acc.push({ ...foundItem, initTag: key });
      }
      return acc;
    }, []);

    return items;
  }, [item.uuid, filteredInitialTags]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    if (item) {
      const initialAliases =
        (item.aliases &&
          item.aliases.map((x: string) => ({
            name: x,
            addedBy: "?",
            itemIndex: item.index,
          }))) ??
        [];
      setNewAliases(initialAliases);
    }
  }, [item.aliases]);

  const aliasToRender = useMemo(() => {
    const sliceAliases: Alias[] = newAliases.slice(0, 3);
    if (newAliases.length <= 3) return sliceAliases;

    if (isAliasNew(newAliases[newAliases.length - 1])) {
      sliceAliases[2] = newAliases[newAliases.length - 1];
    }
    return sliceAliases;
  }, [newAliases, isAliasNew]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    setNewAliases((prevAliases) => {
      const aliases =
        newInvoice.newAliases &&
        newInvoice.newAliases[itemId]?.filter(
          (x) => x.name && !prevAliases.some((y) => y.name === x.name)
        );
      if (aliases) return [...prevAliases, ...aliases];
      else return [...prevAliases];
    });
  }, [newInvoice.newAliases]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    if (data.state === "resolved" && modification === "dataVoting") {
      const aliases = data.newAliases[itemId]?.filter(
        (x) => x.addedBy === data.votedBy && x.name
      );
      if (aliases) setNewAliases((prevAliases) => [...prevAliases, ...aliases]);
    }
  }, [data]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      newInvoice &&
      newInvoice.newAliases &&
      Object.keys(newInvoice.newAliases).length > 0 &&
      newInvoice.newAliases[itemId] &&
      newInvoice.newAliases[itemId].length > 0
    ) {
      setNewInvoice((prev) => ({
        ...prev,
        newAliases: {
          ...prev.newAliases,
          [itemId]: (prev.newAliases[itemId] ?? []).map((x) => {
            if (typeof x === "string") {
              return {
                name: x,
                addedBy: "?",
                itemIndex: item.index,
              };
            }
            return x;
          }),
        },
      }));
    }
  }, [itemId]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  const newerInvoices = useMemo(() => {
    if (newInvoice.deliveryDate) {
      const result = invoices
        .filter((invoice) =>
          invoice.items ? Object.keys(invoice.items).includes(item.id) : false
        )
        .filter((invoice) => invoice.deliveryDate > newInvoice.deliveryDate);
      return result;
    }

    return [];
  }, [newInvoice.deliveryDate, invoices]);

  function saveItemOrder() {
    if (!newItems) return;

    const itemIndex = newItems.findIndex((v) => v.uuid === item.uuid);
    if (itemIndex === -1) return;

    const reorderedItems = reorder(
      newItems,
      itemIndex,
      Number(editableIndex) - 1
    ).map((item, index) => ({ ...item, index }));
    if (ocrClicks) {
      const newOcrClicks = ocrClicks.map((x) => {
        if (x.selectedItemIndex === undefined) return x;
        const matchingItem = newItems.find(
          (item) => item.index === x.selectedItemIndex
        );
        if (!matchingItem) return x;
        const updatedItem = reorderedItems.find(
          (x) => x.uuid === matchingItem.uuid
        );
        if (!updatedItem) return x;
        return { ...x, selectedItemIndex: updatedItem.index };
      });
      setOcrClicks(newOcrClicks);
    }
    setNewItems(reorderedItems);
    setNewInvoice((prev) => ({ ...prev, itemsList: reorderedItems }));
  }

  function saveNewAlias(aliasName: string, newAliasName: string) {
    if (!newItems) return;
    if (newAliasName === aliasName || !newAliasName) return;
    if (newAliases?.map((x) => x.name).includes(newAliasName)) {
      dispatch(alertActions.ERROR("Alias already exists"));
      return { error: true };
    } else {
      const targetAliasIndex = newAliases.findIndex(
        (x) => x.name === aliasName
      );
      if (targetAliasIndex === -1) return;
      const newAliasesCopy = [...newAliases];

      newAliasesCopy[targetAliasIndex].name = newAliasName;
      setNewAliases(newAliasesCopy);
      const newData: Invoice = { ...newInvoice };
      const oldData = newData.newAliases[itemId] ?? [];
      const targetAlias = oldData.find(
        (x) => x.addedBy === userAuth && x.name && x.name === aliasName
      );
      if (targetAlias) {
        targetAlias.name = newAliasName;
        newData.newAliases = {
          ...newData.newAliases,
          [itemId]: [...oldData],
        };
      }

      setNewInvoice(newData);
    }
  }

  function deleteNewAlias(aliasName: string) {
    if (!newItems) return;
    const newAliasesCopy = newAliases.filter(
      (x) => x.name && x.name !== aliasName
    );
    setNewAliases(newAliasesCopy);
    const newData: Invoice = { ...newInvoice };
    const oldData = newData.newAliases[itemId] ?? [];
    newData.newAliases = {
      ...newData.newAliases,
      [itemId]: [
        ...oldData.filter(
          (x) => x.addedBy === userAuth && x.name && x.name !== aliasName
        ),
      ],
    };

    setNewInvoice(newData);
  }

  function handleIndexChange(e: React.FormEvent<HTMLInputElement>) {
    setEditableIndex(e.currentTarget.value);
  }

  const isItemFound = useMemo(() => {
    if (!!data && !!newItems) {
      const itemToFind = newItems.filter((v: InvoiceItem) => v.id === itemId);

      return !!itemToFind[0];
    }
    return false;
  }, [data, newItems, itemId]);

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    // TO-DO: BIND A KEY TO WORK THE SAME WAY AS THE ENTER KEY
    // if (e.key === "Enter" || e.key === "Space") {
    //   saveItemOrder();
    // }
    if (e.key === "Enter") {
      saveItemOrder();
    }
  }

  const handleGoToInvoicePage = () => {
    if (item.invoicePage) {
      setSelectedImage(data.files[item.invoicePage - 1]);
    }
  };

  const handleConvertToOpenItem = () => {
    setNewItems((prev) =>
      prev.map((x) => {
        if (
          x.id === item.id &&
          x.uuid === item.uuid &&
          x.index === item.index
        ) {
          if (x.ocrQuery) {
            const filteredAliases = newInvoice.newAliases?.[itemId]?.filter(
              (y) => y.name !== x.ocrQuery
            );
            if (filteredAliases) {
              dispatch(
                actions.SET_USER_ITEM_ALIASES({
                  itemId: item.id,
                  aliases: filteredAliases.map((x) => x.name),
                })
              );
              setNewInvoice((prev) => {
                const updatedAliases = {
                  ...prev.newAliases,
                  [itemId]: filteredAliases,
                };

                if (filteredAliases.length === 0) {
                  delete updatedAliases[itemId];
                }

                return {
                  ...prev,
                  newAliases: updatedAliases,
                };
              });
            }
            fillConvertToOpenItem(x.ocrQuery, item.uuid);
          }
          return {
            ...item,
            id: "",
            name: "",
            type: "",
            size: 0,
            unit: "",
            variety: "",
            isOpenItem: true,
            wasOpenItem: false,
            isNew: false,
            nameChanged: false,
            aliases: [],
            isFee: false,
          };
        }
        return x;
      })
    );
  };

  const handleSaveEditName = (
    newAliases: {
      name: string;
      addedBy: string;
      isNew?: boolean | undefined;
      itemIndex: number;
    }[]
  ) => {
    setIsAddAliasesOpen(false);
    const newData: Invoice = _.cloneDeep(newInvoice);
    const oldData = newData.newAliases[itemId] ?? [];

    const newAliasToAdd = _.differenceWith(
      newAliases.map((x) => x.name).filter((x) => !item.aliases?.includes(x)),
      oldData.map((x) => x.name),
      _.isEqual
    ).map((x) => ({ name: x, addedBy: userAuth ?? "", itemIndex: item.index }));

    const newAliasToRemove = _.differenceWith(
      oldData.map((x) => x.name),
      newAliases.map((x) => x.name),
      _.isEqual
    );

    const newAliasesToSave = [...oldData, ...newAliasToAdd].filter(
      (alias) => !newAliasToRemove.includes(alias.name)
    );

    newData.newAliases = {
      ...newData.newAliases,
      [itemId]: newAliasesToSave,
    };

    setNewInvoice(newData);
    const action = item.isFee
      ? actions.SET_USER_FEES_ALIASES
      : actions.SET_USER_ITEM_ALIASES;

    dispatch(
      action({ itemId: itemId, aliases: [...newAliases.map((x) => x.name)] })
    );
    setNewItems((prev) =>
      prev.map((x) => {
        if (
          x.id === item.id &&
          x.uuid === item.uuid &&
          x.index === item.index
        ) {
          return {
            ...x,
            aliases: newAliases.map((x) => x.name),
          };
        }
        return x;
      })
    );

    // Condition to handle saving change from item name
    if (newItemName !== item.name) {
      if (!newItemName.trim()) {
        dispatch(alertActions.ERROR("Item name cannot be empty"));
        return;
      }
      if (
        modification === "dataVoting" ||
        item.isFee ||
        !(
          authClaims?.admin ||
          authClaims?.supervisor ||
          authClaims?.headDataManager
        )
      ) {
        dispatch(
          alertActions.INFO(
            "Just save new aliases to invoice, unable to save changes for Data Voting user or in the role of Data Manager."
          )
        );
        return;
      }

      setNewItems((prev) =>
        prev.map((x) => {
          if (
            x.id === item.id &&
            x.uuid === item.uuid &&
            x.index === item.index
          ) {
            return {
              ...x,
              name: newItemName,
              nameChanged: !_.isEqual(item.name, newItemName),
            };
          }
          return x;
        })
      );

      dispatch(
        actions.UPDATE_ITEM({
          item: {
            ...item,
            name: newItemName,
            aliases: newAliases.map((x) => x.name),
          },
          userId,
        })
      );
    }
  };

  const onItemSelect = (selectedInvoiceItem: Items, searchQuery: string) => {
    openItemIdSelected(selectedInvoiceItem, item, false, searchQuery);
  };

  const onItemSearchSelect = (
    selectedInvoiceItem: Items,
    searchQuery: string,
    isNew?: boolean | undefined
  ) => {
    openItemIdSelected(selectedInvoiceItem, item, !!isNew, searchQuery);
  };

  const filterTaxDeduction: ItemPredicate<string> = (
    query,
    tax,
    _index,
    exactMatch
  ) => {
    if (tax) {
      const normalizedQuery = query.toLowerCase();

      if (exactMatch) {
        return `${tax}` === normalizedQuery;
      } else {
        return `${tax}`.indexOf(normalizedQuery) >= 0;
      }
    }
    return false;
  };

  const handleApplyTaxDeduction = (taxAsString: string) => {
    const taxAsNumber = Number(taxAsString);
    if (isNaN(taxAsNumber)) return;

    const itemsCopy = newItems.map((x) => {
      if (x.id === itemId && x.uuid === item.uuid && x.index === item.index) {
        const originalTotal = x.totalAfterTax || x.total;
        const newTotal =
          taxAsNumber > 0
            ? (originalTotal / (100 + taxAsNumber)) * 100
            : originalTotal * (1 - taxAsNumber / 100);

        const finalTotal = taxAsNumber !== 0 ? newTotal : originalTotal;

        return {
          ...x,
          taxDeduction: taxAsNumber,
          total: finalTotal,
          cost: finalTotal / x.quantity,
        };
      }
      return x;
    });

    setNewItems(itemsCopy);
  };

  const handleUserNewTax = (tax: string) => {
    const taxAsNumber = Number(tax);
    if (isNaN(taxAsNumber)) {
      dispatch(
        alertActions.ERROR({
          message: "Please input a valid number without %.",
          position: Position.TOP,
        })
      );
      return;
    }
    handleApplyTaxDeduction(tax);
  };

  // biome-ignore lint:react-hooks/exhaustive-deps
  const renderAlias = useMemo(
    () => (
      <>
        {aliasToRender.map((alias, idx) => (
          <span
            className={Classes.TEXT_MUTED}
            key={idx}
            style={{ display: "block" }}
          >
            {isAliasNew(alias) ? (
              <EditAliasContext
                aliasName={alias.name}
                saveNewAlias={saveNewAlias}
                deleteNewAlias={deleteNewAlias}
              />
            ) : (
              alias.name
            )}
          </span>
        ))}
      </>
    ),

    [aliasToRender]
  );

  // to avoid autofocus on the open item with initial query in resolved invoices
  const isInitialItem = useMemo(() => {
    const uuid = item.uuid;
    return data.itemsList?.some((x) => x.uuid === uuid);
  }, [data.itemsList, item]);

  return (
    <div data-index={index}>
      <Card
        className={getBorderColor({
          ...item,
          isActiveOCRItemCard: activeOCRItemCard === index,
        })}
        onMouseEnter={() =>
          setItemOcrInFocus(
            ocrClicks,
            item,
            data.files,
            imgFile,
            setInvoiceOcrInFocus,
            newItems
          )
        }
        onMouseLeave={() => setItemOcrInFocusClear(setInvoiceOcrInFocus)}
        elevation={Elevation.TWO}
        id={`invoiceItems-${item.index}`}
        tabIndex={0}
        style={{
          height: "calc(245px - 1rem)",
          paddingTop: "10px",
          paddingRight: "10px",
          paddingLeft: "10px",
          paddingBottom: "0px",
          zIndex: openItemCreate ? 1 : 0,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setActiveOCRItemCard((prev) => {
            if (prev === index) return null;
            return index;
          });
        }}
      >
        <div className="col-md-12 p-0">
          <div className="row" style={{ padding: "5px 10px 5px 5px" }}>
            {!(item.isOpenItem || item.id === "") ? (
              <>
                <ItemSelectButton
                  currency={currency}
                  targetItem={item}
                  onItemSelect={onItemSelect}
                  idx={itemIndex - 1}
                  onCreateItemButtonClick={onCreateItemButtonClick}
                  userItemsAndFees={userItemsAndFees}
                  initialQuery={searchInsideItemQuery}
                  ref={searchInSelectItemRef}
                  onButtonClick={() => setActiveOCRItemCard(index)}
                  saveQuery={true}
                  renderedIndex={index}
                  setPopoverSearchItemSelectOpen={
                    setPopoverSearchItemSelectOpen
                  }
                />
                {renderAlias}
              </>
            ) : item.isOpenItem || item.id === "" ? (
              <ItemSearch
                userItemsAndFees={userItemsAndFees}
                onItemSelect={onItemSearchSelect}
                initialQuery={{
                  value: initialQuery ?? item.name ?? "",
                }}
                queryTrigger={queryTrigger}
                isOpenLine={false}
                openItemCreate={openItemCreate}
                setOpenItemCreate={setOpenItemCreate}
                onCreateClick={(
                  event: React.MouseEvent<HTMLElement, MouseEvent>,
                  query: string
                ) => onCreateItemButtonClick(event, itemIndex - 1, query)}
                isAutoFocus={!item.isFromFloatingButtons && !isInitialItem} // set auto focus if open item is not from bulk adding process
                setOcrEmptyItemQuery={
                  !item.name && setOpenItemSearchFocus
                    ? () => setOpenItemSearchFocus(undefined)
                    : undefined
                }
                ref={searchInputRef}
                onSearchClick={() => setActiveOCRItemCard(index)}
              />
            ) : (
              <div className="item-title" data-testid="item-title">
                <Highlighter highlight={searchInput}>{newItemName}</Highlighter>
                {!item.isFee && (
                  <>
                    , {item.size}
                    {item.unit}, {item.type}, {item.variety},{" "}
                    <span
                      className={
                        item.cost - item.oldCost ? "text-line-through" : ""
                      }
                    >
                      {item.oldCost ? +item.oldCost.toFixed(2) : "-"}
                      {currency}
                    </span>
                    <span>
                      {", "}
                      {item.cost - item.oldCost
                        ? `${+item.cost.toFixed(2)}${currency}`
                        : "-"}
                      ,{" "}
                      <span
                        className={
                          item.oldCost
                            ? getPercentageTextColor(item.cost, item.oldCost)
                            : ""
                        }
                      >
                        {item.cost - item.oldCost
                          ? (
                              ((item.cost - item.oldCost) / item.oldCost) *
                              100
                            ).toFixed(2) + "%"
                          : "-"}
                      </span>
                      {item.isNew && (
                        <span className="text-yellow">{" NEW"}</span>
                      )}
                    </span>
                  </>
                )}
                {renderAlias}
              </div>
            )}
            <div
              className={cn(
                "p-absolute-top-right",
                isTable ? "table-item-action-button" : ""
              )}
              onClick={(e) => e.stopPropagation()}
            >
              <Popover
                disabled={
                  (item.fromVoting && isItemPrefilled(item)) ||
                  (item.fromVoting &&
                    (item.isOpenItem || item.wasOpenItem) &&
                    isVoteValueGetPrefilled(item))
                }
                content={
                  <div className="list-container">
                    <div className="list-item">
                      <div>
                        <ActionConfirmationDialogue
                          title="Confirm Action"
                          text={
                            item.isDuplicate
                              ? "Remove duplicate mark from this and below items?"
                              : "Mark this and below items as duplicate?"
                          }
                          actionName="OK"
                          onConfirm={() => onMarkAsDuplicate(item)}
                        >
                          <Button
                            icon="double-chevron-down"
                            minimal
                            intent={item.isDuplicate ? "primary" : "none"}
                            text={
                              item.isDuplicate
                                ? "Remove duplicate mark from this and below items"
                                : "Mark this and below items as duplicate"
                            }
                          />
                        </ActionConfirmationDialogue>
                      </div>
                    </div>
                    <div className="list-item">
                      <Switch
                        checked={item.isSponsored ?? false}
                        onChange={(e) => {
                          const itemsCopy = [...newItems];
                          const targetItem = itemsCopy.find(
                            (x) =>
                              x.id === itemId &&
                              x.uuid === item.uuid &&
                              x.index === item.index
                          );
                          if (targetItem) {
                            targetItem.cost = targetItem.oldCost;
                            targetItem.isSponsored = e.currentTarget.checked;
                            setNewItems(itemsCopy);
                          }
                        }}
                        style={{ margin: 0 }}
                        className="extra-row-editor"
                      />
                      <div>Sponsored Item</div>
                    </div>
                    <div className="list-item">
                      {item.total ? (
                        <Select<string>
                          items={TAX_DEDUCTIONS}
                          itemPredicate={filterTaxDeduction}
                          createNewItemFromQuery={(query: string) => {
                            return query;
                          }}
                          createNewItemRenderer={(val) => {
                            return (
                              <MenuItem
                                key={val}
                                text={`${val}%`}
                                icon="add"
                                roleStructure="listoption"
                                onClick={() => handleUserNewTax(val)}
                              />
                            );
                          }}
                          createNewItemPosition="first"
                          noResults={
                            <MenuItem
                              disabled={true}
                              text="No results."
                              roleStructure="listoption"
                            />
                          }
                          itemRenderer={(
                            taxAsString,
                            { handleClick, modifiers }
                          ) => {
                            const taxAsNumber = Number(taxAsString);
                            const finalTotal = taxAsString
                              ? item.totalAfterTax
                                ? formatNumber(
                                    (item.totalAfterTax / (100 + taxAsNumber)) *
                                      100
                                  )
                                : formatNumber(
                                    (item.total / (100 + taxAsNumber)) * 100
                                  )
                              : item.total;
                            return (
                              <MenuItem
                                active={modifiers.active}
                                key={taxAsString}
                                onClick={handleClick}
                                text={`${taxAsString}% (${finalTotal} ${currency})`}
                              />
                            );
                          }}
                          onItemSelect={(option) => {
                            handleApplyTaxDeduction(option);
                          }}
                          filterable
                          activeItem={item.taxDeduction?.toString() ?? "0"}
                          popoverProps={{ minimal: true }}
                          menuProps={{
                            style: { overflowY: "auto", maxHeight: "200px" },
                          }}
                        >
                          <Button
                            text={`${item.taxDeduction?.toString() ?? "0"}%`}
                            rightIcon="double-caret-vertical"
                          />
                        </Select>
                      ) : (
                        <Button
                          text={`${item.taxDeduction?.toString() ?? "0"}%`}
                          disabled
                          rightIcon="double-caret-vertical"
                        />
                      )}
                      <div className="offset-1">Tax Deduction</div>
                    </div>
                    <div className="list-item">
                      <div>
                        <Button
                          icon="flow-review"
                          disabled={item.isOpenItem}
                          minimal
                          onClick={handleConvertToOpenItem}
                          text={"Convert To Open Item"}
                        />
                      </div>
                    </div>
                    <div className="list-item">
                      <div>
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          to={`/lists/${userId}/${itemId}`}
                        >
                          <Button
                            minimal
                            icon="edit"
                            disabled={!itemId}
                            text={"Open Edit User Item"}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                }
                popoverClassName={Classes.POPOVER_CONTENT_SIZING}
              >
                <Button icon="more" minimal small />
              </Popover>
              <ActionConfirmationDialogue
                title="Confirm Deletion"
                text={INVOICE.CONFIRM_DELETE_ITEM}
                onConfirm={() => handleDeleteItem(item.uuid)}
              >
                <Button
                  icon="trash"
                  disabled={
                    (item.fromVoting && isItemPrefilled(item)) ||
                    (item.fromVoting &&
                      (item.isOpenItem || item.wasOpenItem) &&
                      isVoteValueGetPrefilled(item))
                  }
                  minimal
                  small
                  style={{ marginBottom: "2px" }}
                />
              </ActionConfirmationDialogue>
              {!item.isOpenItem && (
                <Button
                  icon="edit"
                  minimal
                  small
                  style={{ marginBottom: "2px" }}
                  onClick={() => setIsAddAliasesOpen((prevState) => !prevState)}
                />
              )}
            </div>
            {isAddAliasesOpen && (
              <div onClick={(e) => e.stopPropagation()}>
                <ItemAddAlias
                  invoice={newInvoice}
                  item={item}
                  newItemName={newItemName}
                  setNewItemName={setNewItemName}
                  newAliases={newAliases}
                  isOpen={isAddAliasesOpen}
                  setIsOpen={setIsAddAliasesOpen}
                  handleSaveAliases={handleSaveEditName}
                />
              </div>
            )}
          </div>
          <div>
            <ItemValues
              item={item}
              onDataChange={onItemValueChange}
              hasInputError={(val) =>
                setHasInputError((prevState) => ({
                  ...prevState,
                  item: val,
                }))
              }
              isQuantityFocus={quantityFocus === item.uuid}
              isItemAlreadyRender={isItemAlreadyRender}
              setIsItemAlreadyRender={setIsItemAlreadyRender}
              isResolvedInvoice={data.state === "resolved"}
              isActiveOCRItemCard={activeOCRItemCard === index}
            />
          </div>
        </div>
        <div className="d-grid g-template-item-card-footer p-absolute-bottom">
          <div className="float-left" onClick={(e) => e.stopPropagation()}>
            <div className="item__details__wrapper height-15">
              <ItemContext
                defaultChild={<>{`${itemIndex}/${itemLength}`}</>}
                inputField={
                  <div className="col p-1 testyy">
                    <Label className="m-1">
                      {" "}
                      <NumericFormat
                        placeholder={itemIndex.toString()}
                        style={{ width: "35px" }}
                        customInput={InputGroup}
                        onChange={handleIndexChange}
                        onKeyDown={handleKeyDown}
                      />
                    </Label>
                  </div>
                }
                onSave={saveItemOrder}
                menuItemDisable={!isItemFound}
                itemLength={itemLength}
              />
              <div
                style={{ cursor: "pointer" }}
                onClick={handleGoToInvoicePage}
              >
                p
                {`${
                  item.invoicePage ? item.invoicePage : "-"
                }/${data?.files?.length}`}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {ITEM_TAGS.filter((tag) => !tag.hidden)
              .filter((tag) => tag.filterFn(item))
              .map((tag) => {
                const tagKey = tag.name as keyof typeof stackableTags;
                const colorClassName = tag.bgColorClass;
                return (
                  <Tag
                    className={cn(colorClassName, "m-1 mb-2")}
                    key={tag.name}
                  >
                    {tag.text}
                    {stackableTags[tagKey]?.length > 1 && (
                      <Tag className="tag-stacked" round={true}>
                        {stackableTags[tagKey].findIndex((v) => v === item) + 1}
                      </Tag>
                    )}
                  </Tag>
                );
              })}
            {itemsFromInitialTag.map((item) => {
              return ITEM_TAGS.filter((tag) => !tag.hidden)
                .filter((tag) => tag.name === item.initTag)
                .filter((tag) => !tag.exludeFromInitialTags)
                .map((tag) => {
                  const tagKey = tag.name as keyof typeof filteredInitialTags;
                  const colorClassName = "initial-tag";
                  return (
                    <Tag
                      className={cn(colorClassName, "m-1 mb-2")}
                      key={tag.name}
                    >
                      {tag.text}
                      {filteredInitialTags[tagKey].length > 1 && (
                        <Tag className="tag-stacked" round={true}>
                          {filteredInitialTags[tagKey].findIndex(
                            (v) => v === item
                          ) + 1}
                        </Tag>
                      )}
                    </Tag>
                  );
                });
            })}
            {newInvoice &&
              newInvoice.newAliases &&
              Object.values(newInvoice.newAliases).flat(1).length > 0 &&
              Object.keys(newInvoice.newAliases).includes(itemId) && (
                <Tag className="tag-light-green m-1 mb-2">
                  New Aliases
                  {Object.values(newInvoice.newAliases).flat(1).length > 1 && (
                    <Tag className="tag-stacked" round={true}>
                      {Object.values(newInvoice.newAliases).flat(1).length}
                    </Tag>
                  )}
                </Tag>
              )}
            {modification === "dataVoting" &&
              !isItemPrefilled(item) &&
              data.state === "unresolved" && (
                <Tag className={cn("m-1 mb-2", "tag-purple")}>
                  Not Prefilled
                  {newItems.filter((item) => !isItemPrefilled(item)).length >
                    1 && (
                    <Tag className="tag-stacked" round={true}>
                      {newItems
                        .filter((item) => !isItemPrefilled(item))
                        .findIndex((x) => x.uuid === item.uuid) + 1}
                    </Tag>
                  )}
                </Tag>
              )}
          </div>
          {newerInvoices.length > 0 &&
            hasAccess(
              authClaims,
              claims.viewInvoiceItemsFoundInNewerInvoices
            ) && (
              <div className="p-absolute-bottom-message">
                <span className="text-danger">
                  Item found in {newerInvoices.length} newer Invoice
                  {newerInvoices.length > 1 ? "s. " : ". "}
                </span>
                <Popover
                  content={
                    <InvoicePopover data={newerInvoices} itemId={item.id} />
                  }
                  placement="right"
                  popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                >
                  <span className="text-danger">
                    <u className="handle" style={{ fontWeight: "bold" }}>
                      Here
                    </u>
                    .
                  </span>
                </Popover>
              </div>
            )}
        </div>
      </Card>
    </div>
  );
}

export default Item;
