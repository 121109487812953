import { InputGroup, NumericInput } from "@blueprintjs/core";
import { startCase } from "lodash";
import React from "react";
import { LookerConfig } from "redux/settings/types";
import style from "../index.module.scss";

type InputProps = {
  newLookerConfig: LookerConfig;
  setNewLookerConfig: React.Dispatch<React.SetStateAction<LookerConfig>>;
  field: string;
};

const LookerConfigInput = ({
  field,
  newLookerConfig,
  setNewLookerConfig,
}: InputProps) => {
  return (
    <div className={style.form__group}>
      <div>{startCase(field)}:</div>
      {field === "sessionLength" ? (
        <NumericInput
          fill
          value={newLookerConfig.sessionLength}
          onValueChange={(valueAsNumber: number) =>
            setNewLookerConfig({
              ...newLookerConfig,
              sessionLength: valueAsNumber,
            })
          }
          id="sessionLength-input"
        />
      ) : (
        <InputGroup
          value={newLookerConfig[field as keyof LookerConfig] as string}
          onChange={(e) =>
            setNewLookerConfig({
              ...newLookerConfig,
              [field]: e.target.value,
            })
          }
          id={`${field}-input`}
        />
      )}
    </div>
  );
};

type Props = {
  newLookerConfig: LookerConfig;
  setNewLookerConfig: React.Dispatch<React.SetStateAction<LookerConfig>>;
};

const LookerConfigurations = ({
  newLookerConfig,
  setNewLookerConfig,
}: Props) => {
  return (
    <>
      <h3>Configurations</h3>
      <div className="content">
        {["host", "modelName", "secretToken", "sessionLength"].map((field) => {
          return (
            <LookerConfigInput
              field={field}
              newLookerConfig={newLookerConfig}
              setNewLookerConfig={setNewLookerConfig}
              key={`${field}-input`}
            />
          );
        })}
      </div>
    </>
  );
};

export default LookerConfigurations;
