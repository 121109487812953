import { createActions } from "redux/create-actions";
import { GET_OCR_SELECTION_Payload, SAVE_DATA_Payload } from "./sagas";
import { OcrState, Selection } from "./types";

const actions = createActions("ocr", {
  SET_STATE: {} as Partial<OcrState>,
  UPDATE_OCR_ITEM_QUERY_STATE: {} as { ocrItemQuery: string },
  PUSH_SELECTION: {} as { invoiceId: string; ocrClicks: Selection[] },
  SAVE_DATA: {} as SAVE_DATA_Payload,
  GET_OCR_SELECTION: {} as GET_OCR_SELECTION_Payload,
  REMOVE_OCR_SELECTION_STATE: {} as { invoiceId: string },
});

export default actions;
