import {
  DocumentData,
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { Comment, Doc, FolderData } from "redux/docs/types";
import { checkCounter } from "services/counter";
import { db } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export async function createFolder(folderData: FolderData) {
  if (!db) return { data: null, error: "No db" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const collRef = collection(db, "docs");

  // check if there is a folder with same parent and name
  const querySnapshot = await getDocs(collRef);
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const doc = querySnapshot.docs[i];
    const data = doc.data();
    if (data.parent === folderData.parent && data.name === folderData.name) {
      return { data: null, error: `${folderData.name} already exists` };
    }
  }

  return addDoc(collRef, {
    ...folderData,
    createdAt: Timestamp.now(),
  })
    .then((docRef) => {
      return { data: docRef, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function createDoc(docData: FolderData) {
  if (!db) return { data: null, error: "No db" };

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const collRef = collection(db, "docs");

  // check if there is a folder with same parent and name
  const querySnapshot = await getDocs(collRef);
  for (let i = 0; i < querySnapshot.docs.length; i++) {
    const doc = querySnapshot.docs[i];
    const data = doc.data();
    if (data.parent === docData.parent && data.name === docData.name) {
      return { data: null, error: `${docData.name} already exists` };
    }
  }

  return addDoc(collRef, {
    ...docData,
    createdAt: Timestamp.now(),
    keys: [],
    comments: [],
    topMarkdown: "",
    bottomMarkdown: "",
    createdManually: true,
  })
    .then((docRef) => {
      return { data: docRef, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function getDocsCollection(docName: string) {
  if (!db) return { data: null, error: "No db" };

  const data: DocumentData[] = [];
  const collRef =
    docName === "Admin Panel"
      ? collection(db, "docs-admin")
      : collection(db, "docs-hrms");

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const querySnapshot = await getDocs(collRef);
    querySnapshot.forEach((doc) => {
      const newDoc = doc.data();
      newDoc.refPath = doc.ref.path;
      newDoc.id = doc.id;
      data.push(newDoc);
    });
    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function updateDocDocument(
  refPath: string,
  newData: DocumentData
) {
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  if (!db) return { data: null, error: "No db" };
  const docRef = doc(db, refPath);
  return updateDoc(docRef, { keys: newData })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateMarkdown(
  refPath: string,
  newData: string,
  position: string
) {
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  if (!db) return { data: null, error: "No db" };
  const docRef = doc(db, refPath);
  const target =
    position === "top"
      ? {
          topMarkdown: newData,
        }
      : { bottomMarkdown: newData };

  return updateDoc(docRef, target)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateComments(refPath: string, data: Comment[]) {
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  if (!db) return { data: null, error: "No db" };
  const docRef = doc(db, refPath);

  return updateDoc(docRef, { comments: data })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function createAttribute(refPath: string, data: Doc) {
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  if (!db) return { data: null, error: "No db" };
  const docRef = doc(db, refPath);

  // get previous data
  const docDocument = await getDoc(docRef);
  const prevData = docDocument.data();

  return updateDoc(docRef, { keys: { ...prevData?.keys, ...data } })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}
