import { Button, Icon, Tag } from "@blueprintjs/core";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import {
  Invoice,
  InvoiceItem,
  InvoiceItemFirebase,
  Items,
} from "redux/invoice/types";
import { v4 as uuidv4 } from "uuid";
import "./index.scss";

import { getUserCurrency } from "redux/users/helpers";
import { calculateTotals } from "../invoice/helpers";

type Props = {
  data: Invoice[];
  itemId?: string;
  userItemProp?: Items[];
};

function InvoicePopover({ data, itemId, userItemProp }: Props) {
  const userItemRedux = useAppSelector((state) => state.invoices.userItems);
  const suppliers = useAppSelector((state) => state.suppliers.data);
  const userId = useAppSelector((state) => state.invoices.userId);

  const userItems = userItemProp || userItemRedux;

  const [items, setItems] = useState<InvoiceItem[]>([]);
  const [foodTotal, setFoodTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [feesTotal, setFeesTotal] = useState(0);
  const [supplier, setSupplier] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [currentData, setCurrentData] = useState(data[currentPage]);

  const currency = useMemo(() => getUserCurrency(userId), [userId]);

  useEffect(() => {
    setCurrentData(data[currentPage]);
    setItems([]);
  }, [currentPage, data]);

  useEffect(() => {
    if (!currentData.itemsList) {
      currentData.items = {} as Record<string, InvoiceItemFirebase>;
    } else {
      currentData.itemsList.forEach((item) => {
        const total = item.quantity * item.cost;
        setItems((newItems) => [
          ...newItems,
          {
            index: item.index,
            id: item.id,
            name: item?.name,
            cost: item.cost,
            oldCost: item.cost,
            quantity: item.quantity,
            total: total,
            isNew: true,
            type: item?.type,
            size: item?.size,
            unit: item?.unit,
            variety: item?.variety,
            errorCost: "",
            errorQuantity: "",
            errorTotal: "",
            changedProperty: "",
            isOpenItem: false,
            wasOpenItem: false,
            nameChanged: false,
            autoTotal: false,
            autoQuantity: false,
            invoicePage: undefined,
            uuid: uuidv4(),
            hasError: false,
            aliases: item?.aliases,
            isFee: item?.isFee,
          },
        ]);
      });
    }

    if (currentData.itemsList) {
      let grandTotal = 0;
      currentData.itemsList.forEach((value) => {
        const total = value.quantity * value.cost;
        grandTotal += total;
      });
      setGrandTotal(grandTotal);

      const totals = calculateTotals(
        currentData.itemsList,
        currentData.foodTotal
      );
      setFeesTotal(totals.feeTotal.total);
      setFoodTotal(totals.matFoodTotal);
    }

    const supplier = suppliers.find((x) => x.id === currentData?.supplierId);
    setSupplier(supplier ? supplier.name : "");
  }, [currentData, suppliers, userItems]);

  function getImgSrc(file: string, invoice: Invoice): string {
    if (invoice.thumbnails && invoice.thumbnails[file])
      return currentData?.thumbnails[file] ?? "";
    return file;
  }

  function handleClickLeft() {
    if (currentPage === 0) {
      return;
    }
    setCurrentPage((prevState: number) => prevState - 1);
  }

  function handleClickRight() {
    if (currentPage === data.length - 1) {
      return;
    }
    setCurrentPage((prevState: number) => prevState + 1);
  }

  return (
    <>
      {data.length > 1 && (
        <div className="d-flex justify-content-between">
          <Button
            className="col"
            minimal
            onClick={handleClickLeft}
            disabled={currentPage === 0}
          >
            <Icon icon="chevron-left" iconSize={30} />
          </Button>
          <h2 className="d-flex justify-content-center page-header">
            {currentPage + 1}/{data.length}
          </h2>
          <Button
            className="col"
            minimal
            onClick={handleClickRight}
            disabled={currentPage === data.length - 1}
          >
            <Icon icon="chevron-right" iconSize={30} />
          </Button>
        </div>
      )}
      <div className="popover">
        {currentData.deleted && (
          <Tag className="m-1 mb-2" style={{ backgroundColor: "red" }}>
            Deleted
          </Tag>
        )}

        <div className="row row-margin">
          <div className="col-md-6">
            <b>Delivery Date:</b>
          </div>
          <div className="col-md-6">
            {moment(currentData?.deliveryDate).format("dddd, DD MMM, YYYY")}
          </div>
        </div>

        <div className="row row-margin">
          <div className="col-md-6">
            <b>Invoice #:</b>
          </div>
          <div className="col-md-6">{currentData?.number}</div>
        </div>

        <div className="row row-margin">
          <div className="col-md-6">
            <b>Supplier:</b>
          </div>
          <div className="col-md-6">{supplier}</div>
        </div>

        <div className="row row-margin">
          <div className="col-md-6">
            <b>Food Total:</b>
          </div>
          <div className="col-md-6">{foodTotal.toFixed(2)}</div>
        </div>

        <div className="row row-margin">
          <div className="col-md-6">
            <b>Fees Total:</b>
          </div>
          <div className="col-md-6">{feesTotal.toFixed(2)}</div>
        </div>

        <div className="row row-margin">
          <div className="col-md-6">
            <b>Grand Total:</b>
          </div>
          <div className="col-md-6">{grandTotal.toFixed(2)}</div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <b>Invoices:</b>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="row p-2">
              {currentData.files &&
                currentData.files.map((imgFile: string, j: number) => (
                  <div
                    key={"image_" + imgFile + "_" + j}
                    className="col-md-3 p-1"
                  >
                    <img
                      className="image handle"
                      loading="lazy"
                      src={getImgSrc(imgFile, currentData)}
                      alt="empty"
                      onClick={() => window.open(imgFile, "_blank")}
                    />
                  </div>
                ))}
              {!(currentData.files && currentData.files.length) && (
                <span>No invoices uploaded.</span>
              )}
            </div>
          </div>
        </div>

        <div className="row row-margin">
          <div className="col-md-12">
            <b>Items:</b>
          </div>
        </div>
        {items.length === 0 && (
          <div className="row">
            <div className="col-md-12">
              <p> No items found.</p>
            </div>
          </div>
        )}
        {items.length > 0 && (
          <div className="div-items">
            {itemId
              ? currentData.itemsList
                  .sort((a, b) => a.index - b.index)
                  .filter((item) => item.id === itemId)
                  .map((item: InvoiceItem) => (
                    <div
                      key={"item_" + item.id + "_" + item.index}
                      className="row border-match"
                    >
                      <div className="col-md-12 m-2">
                        <p>
                          <i>
                            {item.name}, {item.size}
                            {item.unit}, {item.type}/{item.variety},{" "}
                            {item.cost.toFixed(2)}
                            {currency}
                          </i>
                        </p>
                        <p>
                          Cost: {item.cost.toFixed(2)} | Quantity:{" "}
                          {item.quantity} | Total: {item.total.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  ))
              : items
                  .sort((a, b) => a.index - b.index)
                  .map((item: InvoiceItem) => (
                    <div
                      key={"item_" + item.id + "_" + item.index}
                      className="row"
                    >
                      <div className="col-md-12 m-2">
                        <p>
                          <i>
                            {item.name}, {item.size}
                            {item.unit}, {item.type}/{item.variety},{" "}
                            {item.cost.toFixed(2)}
                            {currency}
                          </i>
                        </p>
                        <p>
                          Cost: {item.cost.toFixed(2)} | Quantity:{" "}
                          {item.quantity} | Total: {item.total.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  ))}
          </div>
        )}
      </div>
    </>
  );
}

export default InvoicePopover;
