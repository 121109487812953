import {
  Button,
  Collapse,
  Divider,
  Icon,
  Switch,
  Tooltip,
} from "@blueprintjs/core";
import moment from "moment";
import { Ref, forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/invoice/actions";
import { UserListRef } from "redux/types";
import { User } from "redux/users/types";
import {
  getPriorityGroupColor,
  getUserPriorityWithOldestDateGroups,
} from "utils/common";
import { getUnresolvedCount } from "../count.helpers";
import style from "./index.module.scss";

type UserButtonProps = {
  user: User;
  i: number;
  keyUsed: "withNoOldestDate" | "withOldestDate";
};

type Props = {
  username: string;
  priorityList: User[];
  onButtonUserClick: (
    userIdToGo: string,
    isInvoiceVotingEnabled: boolean,
    state: string
  ) => void;
};

const PriorityList = forwardRef(
  (
    { onButtonUserClick, username, priorityList }: Props,
    ref: Ref<UserListRef>
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const authClaims = useAppSelector((state) => state.auth.authClaims);
    const isForSupervisorFilter = useAppSelector(
      (state) => state.invoices.isForSupervisorFilter
    );
    const invoiceLoading = useAppSelector((state) => state.invoices.loading);
    const loadingInvoiceVote = useAppSelector(
      (state) => state.invoices.loadingVotes
    );
    const loadingInvoice = invoiceLoading || loadingInvoiceVote;

    useImperativeHandle(ref, () => {
      return {
        handleOpenList: () => setIsOpen(true),
        handleCloseList: () => setIsOpen(false),
      };
    });

    const totalUnresolvedInv = useMemo(() => {
      if (!priorityList.length) return 0;

      if (isForSupervisorFilter) {
        const totalUnresolvedPriorityList = priorityList
          .map((user) => user.invoicesCount.unresolvedForSupervisor ?? 0)
          .reduce((a, b) => a + b);
        return totalUnresolvedPriorityList;
      }
      const totalUnresolvedPriorityList = priorityList
        .map((user) =>
          authClaims?.admin
            ? (user.invoicesCount?.unresolved ?? 0)
            : (user.invoicesCount?.unresolvedNonAdmin ?? 0)
        )
        .reduce((a, b) => a + b);
      return totalUnresolvedPriorityList;
    }, [priorityList, isForSupervisorFilter, authClaims]);

    const priorityUserWithVotingEnabled = useMemo(() => {
      const count = priorityList.filter(
        (user) => user.isInvoiceVotingEnabled
      ).length;
      const percentage = count
        ? ((count / priorityList.length) * 100).toFixed(2)
        : "0";
      return { count, percentage };
    }, [priorityList]);

    const priorityGroups = useMemo(() => {
      const list = priorityList.filter((x) => {
        if (isForSupervisorFilter)
          return x.invoicesCount.unresolvedForSupervisor > 0;
        return true;
      });
      return getUserPriorityWithOldestDateGroups(list);
    }, [priorityList, isForSupervisorFilter]);

    const UserButton = ({ user, i, keyUsed }: UserButtonProps) => (
      <Button
        key={`invoice_button_${keyUsed}_` + i}
        className="me-2 mb-2"
        onClick={() =>
          onButtonUserClick(user.id, user.isInvoiceVotingEnabled, "unresolved")
        }
        text={
          <div>
            <div>
              {`${user.name} (${getUnresolvedCount(
                authClaims,
                isForSupervisorFilter,
                user.invoicesCount
              )})`}
              {user.resolvingUsers.length > 0 && (
                <>
                  <span> </span>
                  <span style={{ color: "#bb86fc" }}>
                    {user.resolvingUsers.map((u: User) => u.name).join(", ")}
                  </span>
                </>
              )}
              {user.isExcludedFromPriorityListAtInvoices && (
                <Tooltip
                  content="Excluded from priority list"
                  position="bottom"
                >
                  <Icon
                    icon="pause"
                    style={{
                      margin: "0 0 0 5px",
                      color: "#ffc33d",
                    }}
                  />
                </Tooltip>
              )}
              {user.isNewAtInvoices && (
                <Tooltip content="New user" position="bottom">
                  <Icon
                    icon="new-person"
                    style={{
                      margin: "0 0 0 5px",
                      color: "#ffc33d",
                    }}
                  />
                </Tooltip>
              )}
              {user.isInvoiceVotingEnabled &&
                (authClaims?.supervisor ||
                  authClaims?.admin ||
                  authClaims?.headDataManager) && (
                  <Tooltip content="Voting enabled" position="bottom">
                    <Icon
                      icon="take-action"
                      style={{
                        margin: "0 0 0 5px",
                        color: "#3dd46f",
                      }}
                    />
                  </Tooltip>
                )}
            </div>
            <div
              style={{
                fontSize: 12,
                color: "#B2B2B2",
              }}
            >
              {(authClaims?.admin
                ? (user.invoicesCount?.noOldestDate ?? 0)
                : (user.invoicesCount?.noOldestDateNonAdmin ?? 0)) === 0 &&
              user.oldestUnresolvedAssumptionDate
                ? moment(user.oldestUnresolvedAssumptionDate.toDate()).format(
                    "DD/MM/YYYY"
                  )
                : "-"}{" "}
              <Tooltip
                compact
                content={`${
                  authClaims?.admin
                    ? (user.invoicesCount?.noOldestDate ?? 0)
                    : (user.invoicesCount?.noOldestDateNonAdmin ?? 0)
                } with no assumption date`}
                hoverOpenDelay={500}
              >
                {`(${
                  authClaims?.admin
                    ? (user.invoicesCount?.noOldestDate ?? 0)
                    : (user.invoicesCount?.noOldestDateNonAdmin ?? 0)
                }`}
              </Tooltip>
              /
              {`${
                authClaims?.admin
                  ? (user?.invoicesCount?.unresolved ?? 0)
                  : !authClaims?.supervisor && !authClaims?.headDataManager
                    ? (user?.invoicesCount?.unresolvedDataManager ?? 0)
                    : (user?.invoicesCount?.unresolvedNonAdmin ?? 0)
              })`}
            </div>
          </div>
        }
        outlined={user.name === username}
        active={user.name === username}
        loading={loadingInvoice && user.name === username}
      />
    );

    return (
      <>
        <div className="d-flex flex-row align-items-center mt-4 mb-3">
          <Tooltip
            compact
            content={`active: true, is not (demo / access level: 0 / access level: 1), has 'invoiceCount.unresolved'${
              !authClaims?.admin &&
              !authClaims?.supervisor &&
              !authClaims?.headDataManager
                ? ", isExcludedFromPriorityListAtInvoices: false, isNewAtInvoices: false"
                : ""
            }`}
          >
            <div
              className="d-flex flex-row align-items-center"
              onClick={() => setIsOpen(!isOpen)}
              style={{ cursor: "pointer", userSelect: "none" }}
            >
              <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
              <p className="all_users_drop_down">
                Invoice Priority List (Users: {priorityList.length}) [Voting
                enabled: {priorityUserWithVotingEnabled.count} (
                {priorityUserWithVotingEnabled.percentage}%)] [Unresolved:{" "}
                {totalUnresolvedInv}]
              </p>
            </div>
          </Tooltip>
          {(authClaims?.admin ||
            authClaims?.supervisor ||
            authClaims?.headDataManager) && (
            <Switch
              checked={isForSupervisorFilter}
              label="Filter for supervisor"
              className={style.switch_filter_for_supervisor}
              onChange={(e) => {
                dispatch(
                  actions.SET_STATE({ isForSupervisorFilter: e.target.checked })
                );
              }}
            />
          )}
          <Divider className="w-100" />
        </div>
        <Collapse isOpen={isOpen} className="mb-3">
          <div
            className="d-flex mb-2 mt-3"
            style={{ flexDirection: "column", gap: "0.5rem" }}
          >
            {Object.keys(priorityGroups)
              .sort((a, b) => b.localeCompare(a))
              .map((priority) => {
                if (
                  !priorityGroups[priority].withNoOldestDate.length &&
                  !priorityGroups[priority].withOldestDate.length
                )
                  return null;
                return (
                  <div
                    key={`priority-list-${priority}`}
                    style={{ display: "flex", gap: 5, marginBottom: 20 }}
                  >
                    <div style={{ display: "flex", gap: 5 }}>
                      <h5 style={{ margin: 0 }}>{priority}</h5>
                      <div
                        style={{
                          backgroundColor: getPriorityGroupColor(
                            Number(priority)
                          ),
                          minWidth: "6px",
                        }}
                      />
                    </div>
                    <div>
                      {priorityGroups[priority].withNoOldestDate.length > 0 && (
                        <div
                          style={{ display: "flex", gap: 10, marginBottom: 20 }}
                        >
                          <div style={{ display: "flex", gap: 5 }}>
                            <div
                              style={{
                                backgroundColor: "#0096FF",
                                minWidth: "6px",
                              }}
                            />
                          </div>
                          <div>
                            {priorityGroups[priority].withNoOldestDate.map(
                              (user, i) => {
                                return (
                                  <UserButton
                                    key={`invoice_button_with_no_oldest_date_${user.id}`}
                                    user={user}
                                    i={i}
                                    keyUsed="withNoOldestDate"
                                  />
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                      {priorityGroups[priority].withOldestDate.length > 0 && (
                        <div style={{ marginLeft: 15 }}>
                          {priorityGroups[priority].withOldestDate.map(
                            (user, i) => {
                              return (
                                <UserButton
                                  key={`invoice_button_with_oldest_date_${user.id}`}
                                  user={user}
                                  i={i}
                                  keyUsed="withOldestDate"
                                />
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </Collapse>
      </>
    );
  }
);

export default PriorityList;
