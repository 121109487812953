import { doc, updateDoc } from "firebase/firestore";
import { ServiceReturn } from "redux/types";
import { db } from "services/firebase";

export async function updateAccessLevels(
  data: Record<string, string>
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  return await updateDoc(doc(db, "accessLevels", "--levels"), data)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}
