import { createActions } from "redux/create-actions";
import {
  BATCH_UPDATE_COUNTS_Payload,
  GET_COUNTS_Payload,
  UPDATE_COUNT_Payload,
} from "./sagas";
import { CountsState } from "./types";

const actions = createActions("counts", {
  SET_STATE: {} as Partial<CountsState>,
  SUBSCRIBE_TO_COUNTS: {} as GET_COUNTS_Payload,
  UNSUBSCRIBE_FROM_COUNTS: undefined,
  UPDATE_COUNT: {} as UPDATE_COUNT_Payload,
  BATCH_UPDATE_COUNTS: {} as BATCH_UPDATE_COUNTS_Payload,
});

export default actions;
