import { Button, InputGroup, Intent, Label } from "@blueprintjs/core";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { InvoiceItem } from "redux/invoice/types";
import { compareNumbersWithMargin } from "../invoice/helpers";
import useLocalItem from "./hooks";

type FieldProps = NumericFormatProps & {
  label?: string;
  intent?: Intent;
  addOn?: React.ReactNode;
  errMessage: string;
  inputRef?: RefObject<HTMLInputElement>;
  item?: InvoiceItem;
  quantityRef?: RefObject<HTMLInputElement>;
};

export const ItemValueField = ({
  label,
  addOn,
  errMessage,
  item,
  intent,
  quantityRef,
  ...props
}: FieldProps) => {
  return (
    <div className="col" style={{ paddingLeft: "8px" }}>
      <Label className="m-1">
        {label}:{addOn}
        {label === "Total" &&
          item?.total !== 0 &&
          item?.totalAfterTax &&
          (item?.isFee || item?.total !== item?.totalAfterTax) &&
          item?.taxDeduction && (
            <span className="text-tax">
              {item?.isFee ? item.total : item.totalAfterTax} (
              {item.taxDeduction}%)
            </span>
          )}
        <NumericFormat
          customInput={InputGroup}
          thousandSeparator={false}
          decimalScale={2}
          getInputRef={quantityRef ? quantityRef : undefined}
          // intent={intent}
          {...props}
        />
        <span className="text-danger">{errMessage}</span>
      </Label>
    </div>
  );
};

type Props = {
  item: InvoiceItem;
  isResolvedInvoice: boolean;
  onDataChange: (itm: InvoiceItem) => void;
  hasInputError: (val: boolean) => void;
  isQuantityFocus?: boolean;
  setIsItemAlreadyRender?: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  isItemAlreadyRender?: Record<string, boolean>;
  isActiveOCRItemCard?: boolean;
};

function ItemValues({
  item,
  onDataChange,
  hasInputError,
  isQuantityFocus,
  setIsItemAlreadyRender,
  isItemAlreadyRender,
  isResolvedInvoice,
  isActiveOCRItemCard,
}: Props) {
  const [cursor, setCursor] = useState<number | null>(null);
  const {
    localItem,
    onValueChange,
    isCalculateCost,
    setIsCalculateCost,
    handlePropertyChange,
  } = useLocalItem(
    item,
    onDataChange,
    hasInputError,
    setCursor,
    isResolvedInvoice
  );
  const quantityRef = useRef<HTMLInputElement>(null);

  const isValueValid = useMemo(
    () =>
      compareNumbersWithMargin(
        Number(item.total),
        Number(item.quantity) * Number(item.cost),
        0.01
      ),
    [item]
  );

  const isValueFromVoteValid = useMemo(
    () =>
      compareNumbersWithMargin(
        Number(item.itemVoteValue?.total),
        Number(item.itemVoteValue?.quantity) * Number(item.itemVoteValue?.cost),
        0.01
      ),
    [item]
  );

  useEffect(() => {
    if (
      isQuantityFocus &&
      isItemAlreadyRender &&
      setIsItemAlreadyRender &&
      !isItemAlreadyRender[item.uuid]
    ) {
      setIsItemAlreadyRender({ [item.uuid]: true });
      quantityRef.current?.focus();
    }
  }, [isQuantityFocus, isItemAlreadyRender]);

  useEffect(() => {
    if (!quantityRef || !quantityRef.current) return;
    quantityRef.current.setSelectionRange(cursor, cursor);
  }, [cursor, localItem.quantity]);

  const handleMultiplyQuantity = (value: number) => {
    const newQuantity = localItem.quantity * value;
    handlePropertyChange(
      "quantity",
      newQuantity,
      newQuantity.toString(),
      localItem
    );
  };

  return (
    <>
      <div
        className="row"
        onClick={(e) => isActiveOCRItemCard && e.stopPropagation()}
      >
        <ItemValueField
          name="cost"
          label="Unit cost"
          value={localItem.cost || ""}
          placeholder="Unit Cost"
          onValueChange={onValueChange}
          intent={localItem.errorCost !== "" ? Intent.DANGER : Intent.NONE}
          errMessage={localItem.errorCost}
          disabled
        />
        <ItemValueField
          name="quantity"
          label="Quantity"
          value={localItem.quantity || ""}
          placeholder="Quantity"
          onValueChange={onValueChange}
          intent={
            localItem.errorTotal !== ""
              ? Intent.DANGER
              : localItem.autoQuantity
                ? Intent.SUCCESS
                : Intent.NONE
          }
          errMessage={localItem.errorQuantity}
          addOn={
            localItem.autoQuantity && (
              <span className="text-green-olive">Qty Autofilled</span>
            )
          }
          autoComplete="off"
          inputRef={quantityRef}
          disabled={
            item.fromVoting &&
            item.itemVoteValue?.quantity !== 0 &&
            isValueValid &&
            isValueFromVoteValid
          }
          onFocus={(e) => {
            if (item.autoQuantity) e.target.select();
          }}
        />
        <Button
          style={{ width: 1, height: 5, marginTop: 35 }}
          minimal
          small
          icon={
            !isCalculateCost || localItem.totalComputed
              ? "arrow-right"
              : "arrow-left"
          }
          onClick={() => setIsCalculateCost(!isCalculateCost)}
        />
        <ItemValueField
          name="total"
          label="Total"
          value={localItem.isSponsored ? 0 : localItem.total || ""}
          placeholder="Total"
          onValueChange={onValueChange}
          intent={
            localItem.errorTotal !== ""
              ? Intent.DANGER
              : localItem.autoTotal
                ? Intent.SUCCESS
                : Intent.NONE
          }
          addOn={
            localItem.autoTotal && (
              <span className="text-green-autofill">Autofilled</span>
            )
          }
          errMessage={localItem.errorTotal}
          disabled={
            item.isSponsored ||
            (item.fromVoting &&
              item.itemVoteValue?.total !== 0 &&
              isValueValid &&
              isValueFromVoteValid)
          }
          item={item}
        />
      </div>{" "}
      <div
        style={{ display: "flex", justifyContent: "center" }}
        onClick={(e) => e.stopPropagation()}
      >
        <span
          onClick={() => handleMultiplyQuantity(24)}
          style={{ marginRight: 13, cursor: "pointer" }}
        >
          x24
        </span>
        <span
          onClick={() => handleMultiplyQuantity(6)}
          style={{ marginRight: 13, cursor: "pointer" }}
        >
          x6
        </span>
        <span
          onClick={() => handleMultiplyQuantity(12)}
          style={{ cursor: "pointer" }}
        >
          x12
        </span>
      </div>
    </>
  );
}

export default ItemValues;
