import { Button, Card, Dialog, MenuItem } from "@blueprintjs/core";
import { ItemListPredicate, ItemRenderer, Suggest } from "@blueprintjs/select";
import { createSelector } from "@reduxjs/toolkit";
import SuppliersCRUD from "components/invoices/suppliers-crud";
import Fuse from "fuse.js";
import { claims, hasAccess } from "permissions";
import React, { useEffect, useState, useRef, useMemo } from "react";
import actions from "redux/alert/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Invoice } from "redux/invoice/types";
import { RootState } from "redux/store";
import { Supplier } from "redux/suppliers/types";

type Props = {
  ItemSelect: (selectedSupplier: Supplier) => void;
  selectedSupplierId: string;
  initialQuery: string;
  invoiceData?: Invoice;
  disable?: boolean;
};

function Suppliers({
  ItemSelect,
  selectedSupplierId,
  initialQuery,
  invoiceData,
  disable,
}: Props) {
  const dispatch = useAppDispatch();
  const selectSuppliersData = (state: RootState) => state.suppliers.data;
  const selectNotDeletedSuppliersData = createSelector(
    [selectSuppliersData],
    (data) => data.filter((s) => !s.deleted)
  );
  const data = useAppSelector(selectNotDeletedSuppliersData);
  const [openSuppliercrud, setOpenSuppliercrud] = useState(false);
  const [query, setQuery] = useState(initialQuery);
  const inputAddSuppliersButton = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const authClaims = useAppSelector((state) => state.auth.authClaims);

  const selected = useMemo(() => {
    const targetData = data.find((s) => s.id === selectedSupplierId);
    if (!targetData && invoiceData && invoiceData.supplierName) {
      return new Supplier(invoiceData.supplierName, {
        name: invoiceData.supplierName,
      });
    }
    return data.find((s) => s.id === selectedSupplierId) ?? null;
  }, [data, selectedSupplierId, invoiceData]);

  useEffect(() => {
    setQuery(initialQuery);
    if (inputRef.current && initialQuery !== "") {
      inputRef.current.focus();
    }
  }, [initialQuery]);

  // fuse.js configuration
  const fuse = new Fuse<Supplier>(data, {
    includeMatches: true,
    keys: [{ name: "name", weight: 3 }],
    ignoreLocation: true,
    useExtendedSearch: true,
  });

  const renderSupplier: ItemRenderer<Supplier> = (
    item,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        key={item.id}
        onClick={handleClick}
        text={item.name}
      />
    );
  };

  const filterItems: ItemListPredicate<Supplier> = (query) => {
    if (query === "") return data;
    const results = fuse.search(query);
    return results.map((i) => i.item);
  };

  // add this to lose focus on button click and then open modal
  function onAddSuppliersButtonClick(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) {
    event.currentTarget.blur();
    setOpenSuppliercrud(!openSuppliercrud);
  }

  function onSupplierCreated(createdSupplier?: Supplier) {
    if (createdSupplier != null) {
      ItemSelect(createdSupplier);
      dispatch(
        actions.SUCCESS(
          "Supplier temporarily added, supplier will only be added to database when invoice is checked"
        )
      );
    }
    setOpenSuppliercrud(false);
  }

  return (
    <div className="row g-0">
      <div className="col-11">
        <Suggest<Supplier>
          className="mt-2 supplier-select"
          fill
          items={data}
          disabled={disable}
          itemListPredicate={filterItems}
          itemRenderer={renderSupplier}
          noResults={<MenuItem disabled={true} text="No results." />}
          onItemSelect={(item) => {
            if (item.id && item.id !== "") {
              ItemSelect(item);
              const AddSuppliersButton = document.querySelector(
                ".AddSuppliersButton"
              ) as HTMLElement | null;
              if (AddSuppliersButton != null) {
                AddSuppliersButton.focus();
              }
            }
          }}
          menuProps={{ className: "custom-class" }}
          popoverProps={{ minimal: true }}
          inputValueRenderer={(item) => item.name}
          selectedItem={selected}
          resetOnSelect={true}
          resetOnQuery={true}
          query={query}
          onQueryChange={(q) => {
            setQuery(q);
          }}
          inputProps={{ inputRef: inputRef }}
        />
      </div>
      <div className="col-1 mt-2">
        {hasAccess(authClaims, claims.addSupplier) && (
          <Button
            className="AddSuppliersButton"
            ref={inputAddSuppliersButton}
            icon={"add"}
            onClick={onAddSuppliersButtonClick}
            disabled={disable}
          />
        )}
      </div>

      <div>
        <Dialog
          isOpen={openSuppliercrud}
          onClose={() => setOpenSuppliercrud(!openSuppliercrud)}
          autoFocus
          enforceFocus
          usePortal
          title="Add/Edit Suppliers"
        >
          <Card>
            <SuppliersCRUD
              onFinish={(selected) => onSupplierCreated(selected)}
              searchedSupplier={query}
            />
          </Card>
        </Dialog>
      </div>
    </div>
  );
}

export default Suppliers;
