import React from "react";

type Props = {
  
  children: any;
  
  highlight: any;
};

function escapeRegex(string: string) {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");
}

const Highlighter = ({ children, highlight }: Props) => {
  if (!highlight) return children;

  if (children) {
    const regexp = new RegExp(escapeRegex(highlight), "gi");
    const matches = children.toString().match(regexp);
    if (!matches) return children;

    const parts = children
      .toString()
      .split(new RegExp(`${escapeRegex(highlight)}`, "gi"));
    for (let i = 0; i < parts.length; i++) {
      if (i !== parts.length - 1) {
        const match = matches[i];
        parts[i] = (
          <React.Fragment key={i}>
            {parts[i]}
            <span style={{ color: "black", backgroundColor: "#EC9A3B" }}>
              {match}
            </span>
          </React.Fragment>
        );
      }
    }
    return <span className="highlighter">{parts}</span>;
  } else {
    return null;
  }
};

export default Highlighter;
