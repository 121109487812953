import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { SilhouetteState } from "./types";

export const silhouetteInitialState: SilhouetteState = {
  loadingSave: false,
  loadingDelete: false,
  loadingConnectItems: false,
  silhouettes: [],
};

export default createReducer(silhouetteInitialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
