import { convertFromPdf, isPdf } from "components/ap-transactions/utils";
import {
  DocumentData,
  Timestamp,
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  UPDATE_INVOICE_Payload,
  UPDATE_VOTE_Payload,
} from "redux/invoice/sagas";
import { CheckedInvoice, Fee, Invoice, Items } from "redux/invoice/types";
import { ServiceReturn } from "redux/types";
import { uploadInvoiceFile } from "services/ap-transactions";
import { checkCounter } from "services/counter";
import { auth, db, functions, storage } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export async function getUserInvoices(userId: string): Promise<ServiceReturn> {
  const data: Invoice[] = [];
  if (!db) return { data: null, error: "No db connection" };
  const coll = collection(db, "users/" + userId + "/invoices/");

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const querySnapshot = await getDocs(coll);
    querySnapshot.forEach((doc) => {
      const newdoc: Invoice = doc.data() as Invoice;
      newdoc.refPath = doc.ref.path;
      newdoc.id = doc.id;
      data.push(newdoc);
    });
    return { data, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getInvoice(
  userId: string,
  invoiceId: string
): Promise<ServiceReturn<Invoice | null>> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, "users", userId, "invoices", invoiceId);

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return {
        data: new Invoice(docSnap.id, docRef.path, docSnap.data()),
        error: null,
      };
    } else {
      return { data: null, error: "No such document" };
    }
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function uploadAndCreateInvoice(
  userId: string,
  fileArray: File[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!storage) return Promise.reject("No storage connection");
  try {
    await Promise.all(
      fileArray.map(async (file) => {
        if (!db) return null;
        if (isPdf(file.name)) {
          const blobUrl = URL.createObjectURL(file);
          const jpegBlobUrls = await convertFromPdf(blobUrl);
          const jpegBlobUrlsMapped = jpegBlobUrls.map((x) => ({
            ...x,
            source: file.name.replace(".pdf", ".jpeg"),
          }));
          const fileUrls: string[] = [];
          for (let i = 0; i < jpegBlobUrlsMapped.length; i++) {
            const data = jpegBlobUrlsMapped[i];
            const fileName = `${new Date().getTime()}-users-${userId}-invoices-page${
              data.page + 1
            }-${file.name.replace(".pdf", ".jpeg")}`;
            const image = await fetch(data.url).then((x) => x.arrayBuffer());
            const newUrl = await uploadInvoiceFile(userId, fileName, image);

            fileUrls.push(newUrl);
          }
          const invoiceRef = collection(db, "users", userId, "invoices");
          await addDoc(invoiceRef, {
            url: fileUrls[0],
            files: fileUrls,
            userId: userId,
            state: "unresolved",
            createdAt: new Date(),
            updatedAt: new Date(),
            isFromApTransaction: false,
            isFromAdminPanel: true,
            isChecked: false,
            deleted: false,
          });
        } else {
          const fileName = `${new Date().getTime()}-users-${userId}-invoices-${
            file.name
          }`;
          const newUrl = await uploadInvoiceFile(userId, fileName, file);
          const fileUrls: string[] = [];
          fileUrls.push(newUrl);
          const invoiceRef = collection(db, "users", userId, "invoices");
          await addDoc(invoiceRef, {
            url: fileUrls[0],
            files: fileUrls,
            userId: userId,
            state: "unresolved",
            createdAt: new Date(),
            updatedAt: new Date(),
            isFromApTransaction: false,
            isFromAdminPanel: true,
            isChecked: false,
            deleted: false,
          });
        }
      })
    );

    return { data: true, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function updateInvoice(
  payload: UPDATE_INVOICE_Payload
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const { refPath, invoice, checkData } = payload;

  const targetRef = doc(db, refPath);

  if (checkData) {
    if (invoice.deliveryDate && invoice.deliveryDate > new Date()) {
      return { data: null, error: "Delivery date cannot be in the future" };
    }

    let ok = true;
    if (invoice.itemsList)
      invoice.itemsList.forEach((itemList) => {
        if (
          !itemList.cost ||
          !itemList.quantity ||
          (!itemList.isOpenItem && !itemList.name)
        ) {
          ok = false;
        }
      });

    if (!ok) {
      return {
        data: null,
        error: { message: "Cost and quantity are required for all items" },
      };
    }
  }

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return await updateDoc(targetRef, { ...invoice, updatedAt: new Date() })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateVote(
  payload: UPDATE_VOTE_Payload
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const { vote } = payload;
  if (!vote.refPath) return { data: null, error: "No vote refPath" };
  const targetRef = doc(db, vote.refPath);

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return await updateDoc(targetRef, { ...vote })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function checkInvoice(
  invoice: CheckedInvoice | Invoice,
  updateAliasPayload: Items[],
  updateFeeAliasPayload?: Fee[],
  votes?: { ref: string; accuracy: number }[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };

  const targetRef = doc(
    db,
    "users",
    invoice.userId,
    "invoices",
    invoice.invoiceId
  );

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const batch = writeBatch(db);
    const supplierRef = doc(collection(db, "suppliers"));
    if (invoice.isChecked) {
      if (updateAliasPayload.length > 0) {
        for (let i = 0; i < updateAliasPayload.length; i++) {
          const item = updateAliasPayload[i];
          const itemRef = doc(
            db,
            "users/" + invoice.userId + "/items/" + item.id
          );
          batch.update(itemRef, {
            name: item.name,
            aliases: item.aliases,
            nameChanged: item.nameChanged,
          });
        }
      }

      if (updateFeeAliasPayload && updateFeeAliasPayload.length > 0) {
        for (let i = 0; i < updateFeeAliasPayload.length; i++) {
          const fee = updateFeeAliasPayload[i];
          const feeRef = doc(db, "users/" + invoice.userId + "/fees/" + fee.id);
          batch.update(feeRef, { aliases: fee.aliases });
        }
      }

      if (invoice.supplierId === invoice.supplierName) {
        batch.set(supplierRef, {
          createdAt: Timestamp.now(),
          name: invoice.supplierName,
          updatedAt: Timestamp.now(),
        });
        invoice.supplierId = supplierRef.id;
      }
    }

    batch.update(targetRef, { ...invoice, updatedAt: Timestamp.now() });

    // Resolve all votes in Invoice Voting Results
    if (!votes) {
      const coll = collection(db, "users/" + invoice.userId + "/invoiceVotes/");
      const q = query(coll, where("invoiceId", "==", invoice.invoiceId));
      const voteDocs = await getDocs(q);
      if (!voteDocs.empty) {
        voteDocs.forEach((doc) => {
          batch.update(doc.ref, { state: "resolved" });
        });
      }
    } else if (votes) {
      votes.forEach((vote) => {
        if (db) {
          const targetRef = doc(db, vote.ref);
          batch.update(targetRef, {
            accuracy: vote.accuracy,
            state: "resolved",
          });
        }
      });
    }

    await batch.commit();

    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function voteInvoice(
  invoice: Invoice,
  maxVotes = 3
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!auth) return { data: null, error: "No auth connection" };
  const currentUser = auth.currentUser?.uid;

  const { userId } = invoice;
  const voteColl = collection(db, `users/${userId}/invoiceVotes`);

  // check if invoice already voted
  const q = query(
    voteColl,
    where("invoiceId", "==", invoice.invoiceId ?? invoice.id),
    where("deleted", "==", false)
  );
  const querySnapshot = await getDocs(q);

  const prevVotes: Invoice[] = [];
  querySnapshot.forEach((doc) => {
    prevVotes.push(new Invoice(doc.id, doc.ref.path, doc.data()));
  });

  const alreadyVoted = prevVotes.find((x) => x.votedBy === currentUser);

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const { id, ...rest } = invoice;
  if (alreadyVoted) {
    return await updateDoc(doc(db, alreadyVoted.refPath), { ...rest })
      .then(() => {
        return { data: true, error: null };
      })
      .catch((err) => {
        return { data: null, error: err };
      });
  } else {
    if (prevVotes.length >= maxVotes)
      return { data: null, error: { message: `Max ${maxVotes} votes` } };
    return await addDoc(voteColl, {
      ...rest,
      invoiceId: id,
      votedBy: currentUser,
      votedAt: new Date(),
    })
      .then(() => {
        return { data: true, error: null };
      })
      .catch((err) => {
        return { data: null, error: err };
      });
  }
}

export async function batchCheckInvoices(
  invoicesArray: CheckedInvoice[],
  updateAliasPayload: {
    [key: string]: Items[];
  }
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const createdSuppliers: Record<string, string>[] = [];

    while (invoicesArray.length > 0) {
      const batch = writeBatch(db);
      for (const invoice of invoicesArray.splice(0, 500)) {
        const { invoiceId, userId } = invoice;
        if (invoice.isChecked) {
          if (updateAliasPayload[invoiceId]?.length > 0) {
            for (let i = 0; i < updateAliasPayload[invoiceId].length; i++) {
              const item = updateAliasPayload[invoiceId][i];
              const itemRef = doc(db, "users/" + userId + "/items/" + item.id);
              batch.update(itemRef, {
                name: item.name,
                aliases: item.aliases,
                nameChanged: item.nameChanged,
              });
            }
          }
          if (invoice.supplierId === invoice.supplierName) {
            const newSupplierId = createdSuppliers.find(
              (x) => x.name === invoice.supplierName
            )?.id;

            if (!newSupplierId) {
              const newSupplierRef = doc(collection(db, "suppliers"));
              batch.set(newSupplierRef, {
                createdAt: Timestamp.now(),
                name: invoice.supplierName,
                updatedAt: Timestamp.now(),
              });
              createdSuppliers.push({
                id: newSupplierRef.id,
                name: invoice.supplierName,
              });
              invoice.supplierId = newSupplierRef.id;
            } else {
              invoice.supplierId = newSupplierId;
            }
          }
        }
        const ref = doc(
          db,
          "users",
          invoice.userId,
          "invoices",
          invoice.invoiceId
        );
        batch.update(ref, { ...invoice });

        const invoiceRefPath = `users/${invoice.userId}/invoices/${invoice.invoiceId}`;
        const coll = collection(
          db,
          "users/" + invoice.userId + "/invoiceVotes/"
        );
        const q = query(coll, where("invoiceRefPath", "==", invoiceRefPath));
        await getDocs(q).then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            batch.update(doc.ref, { state: "resolved" });
          });
        });
      }
      await batch.commit();
    }

    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function fileUpload(
  userId: string,
  file: File
): Promise<ServiceReturn> {
  if (!storage) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return await new Promise(function (resolve, reject) {
    if (!storage) return;
    const path = "users/" + userId + "/invoices/" + file.name;
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      function (snapshot) {
        return;
      },
      function error(err) {
        reject({ data: null, error: err });
      },
      function complete() {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve({ data: downloadURL, error: null });
        });
      }
    );
  });
}

export async function addDownloadURL(
  id: string,
  userId: string,
  downloadURL: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    await updateDoc(doc(db, "users/" + userId + "/invoices/" + id), {
      files: arrayUnion(downloadURL),
    });
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function deleteImage(
  id: string,
  userId: string,
  imageFile: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    await updateDoc(doc(db, "users/" + userId + "/invoices/" + id), {
      files: arrayRemove(imageFile),
    });
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getOcrData(
  userId: string,
  fileUrl: string
): Promise<ServiceReturn> {
  const data: DocumentData[] = [];

  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  const q = query(
    collection(db, `users/${userId}/invoiceOCRData`),
    where("fileName", "==", fileUrl)
  );
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), ref: doc.ref, id: doc.id });
    });

    return { data: data, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getResolvedInvoices(
  userId: string,
  deliveryDateFrom?: Date,
  deliveryDateTo?: Date
): Promise<ServiceReturn> {
  const data: DocumentData[] = [];

  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  const constraints = [
    where("state", "==", "resolved"),
    where("deleted", "==", false),
  ];

  if (deliveryDateFrom && deliveryDateTo) {
    constraints.push(
      where("deliveryDate", ">=", deliveryDateFrom),
      where("deliveryDate", "<=", deliveryDateTo)
    );
  }
  const q = query(collection(db, `users/${userId}/invoices`), ...constraints);
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data.push(new Invoice(doc.id, doc.ref.path, doc.data()));
    });

    return {
      data: data.sort(
        (a, b) =>
          new Date(b.deliveryDate).getTime() -
          new Date(a.deliveryDate).getTime()
      ),
      error: null,
    };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getDocumentAIData(
  userId: string,
  fileUrl: string
): Promise<ServiceReturn> {
  const data: DocumentData[] = [];

  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  const q = query(
    collection(db, `users/${userId}/invoiceDocumentAI`),
    where("fileName", "==", fileUrl)
  );
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), ref: doc.ref, id: doc.id });
    });

    return { data: data, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updateDocumentAI(
  data: DocumentData
): Promise<ServiceReturn> {
  const { ref, ...documentAI } = data;

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    await updateDoc(ref, { ...documentAI });
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function runVoterBot(
  invoiceRefPath: string,
  functionName: string
): Promise<ServiceReturn<{ result: string | Invoice; votedBy: string }>> {
  if (!functions) return { data: null, error: "No functions connection" };
  if (!db) return { data: null, error: "No db connection" };

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const userId = invoiceRefPath.split("/")[1];
    const invoiceId = invoiceRefPath.split("/")[3];

    const invoiceOCRQuery = query(
      collection(db, "users/" + userId + "/invoiceOCRData"),
      where("invoiceId", "==", invoiceId)
    );

    const invoiceOCRQuerySnapshot = await getDocs(invoiceOCRQuery);
    const refPath = invoiceOCRQuerySnapshot.docs[0].ref.path;

    const invoiceVote = httpsCallable(functions, functionName);
    const response = await invoiceVote({ refPath, userId });

    type Modify<T, R> = Omit<T, keyof R> & R;
    type ReturnedInvoice = Modify<
      Invoice,
      {
        deliveryDate: string;
        createdAt: string;
        updatedAt: string;
        votedAt: string;
      }
    >;

    const { result: returnedInvoice, votedBy } = response.data as {
      result: ReturnedInvoice;
      votedBy: string;
    };

    const result = returnedInvoice as unknown as Invoice;

    result.deliveryDate = new Date(returnedInvoice.deliveryDate);
    result.createdAt = new Date(returnedInvoice.createdAt);
    result.updatedAt = new Date(returnedInvoice.updatedAt);
    result.votedAt = new Date(returnedInvoice.votedAt);

    return { data: { result: result as Invoice, votedBy }, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function deleteVotes(invoiceRefPath: string, userId: string) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  const q = query(
    collection(db, `users/${userId}/invoiceVotes`),
    where("invoiceRefPath", "==", invoiceRefPath)
  );

  try {
    const querySnapshot = await getDocs(q);

    const updatePromises = querySnapshot.docs.map((doc) => {
      return updateDoc(doc.ref, { deleted: true, state: "resolved" });
    });

    await Promise.all(updatePromises);

    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getDuplicateInvoices(
  userId: string,
  invoiceNumber: string,
  isAdmin: boolean
) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  const collectionRef = collection(db, "users", userId, "invoices");

  const constraints = [where("number", "==", invoiceNumber)];

  if (!isAdmin) {
    const currTime: Date = new Date();
    currTime.setDate(currTime.getDate() - 89);
    constraints.push(where("createdAt", ">=", currTime));
  }

  const q = query(collectionRef, ...constraints);

  try {
    const querySnapshot = await getDocs(q);
    const data: Invoice[] = [];
    querySnapshot.forEach((doc) => {
      data.push(new Invoice(doc.id, doc.ref.path, doc.data()));
    });

    return { data, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}
