import { Button, Classes, MenuItem, TextArea } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select";
import { Loader, cn } from "@stockifi/shared";
import { httpsCallable } from "firebase/functions";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import actions from "redux/alert/actions";
import { useAppDispatch } from "redux/hooks";
import { functions } from "services/firebase";
import { CALLABLE_FUNCTIONS } from "utils/callable-functions/constants";
import { LANGUAGES } from "utils/constants";
import styles from "./index.module.scss";

interface Language {
  label: string;
  value: string;
}

const languages: Language[] = Object.keys(LANGUAGES).map((key) => ({
  value: key,
  label: LANGUAGES[key],
}));

interface Props {
  ocrItemQuery: string;
  onEnter: (text: string) => void;
}

export default function Translation({ ocrItemQuery, onEnter }: Props) {
  const dispatch = useAppDispatch();
  const [text, setText] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [loading, setLoading] = useState(false);
  const [sourceLanguage, setSourceLanguage] = useState("no");
  const [targetLanguage, setTargetLanguage] = useState("en");

  const translateText = async (text: string, target = "en", source = "") => {
    setLoading(true);
    setTranslatedText("");
    if (!functions) return console.log("functions is null");
    try {
      const translate = httpsCallable(
        functions,
        CALLABLE_FUNCTIONS.translateText
      );
      const res = await translate({
        text,
        target,
        source,
      });

      const data = res.data as {
        translated: string;
        source: string;
      };
      setTranslatedText(data.translated);
      setSourceLanguage(data.source);
    } catch (err) {
      dispatch(actions.ERROR(`${err}`));
    } finally {
      setLoading(false);
    }
  };

  const debouncedText = debounce(
    (text) => translateText(text, targetLanguage, sourceLanguage),
    500
  );

  useEffect(() => {
    setText(ocrItemQuery);
  }, [ocrItemQuery]);

  useEffect(() => {
    if (text) translateText(text, targetLanguage, sourceLanguage);
  }, [targetLanguage, sourceLanguage]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (text) {
      timeoutId = setTimeout(() => {
        debouncedText(text);
      }, 500);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [text]);

  const getLanguage = (code: string) => {
    if (code === "") return "Detect language";
    const lang = new Intl.DisplayNames(["en"], { type: "language" });
    return lang.of(code);
  };

  const filterLanguage: ItemPredicate<Language> = (
    query,
    language,
    _index,
    exactMatch
  ) => {
    const normalizedTitle = language.label.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
  };

  const renderLanguage: ItemRenderer<Language> = (
    language,
    { handleClick, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        key={language.value}
        onClick={handleClick}
        text={highlightText(language.label, query)}
      />
    );
  };

  const highlightText = (text: string, query: string) => {
    const normalizedTitle = text.toLowerCase();
    const normalizedQuery = query.toLowerCase();
    const startIndex = normalizedTitle.indexOf(normalizedQuery);
    const endIndex = startIndex + query.length;

    if (startIndex < 0) {
      // no match
      return [text];
    }

    const before = text.slice(0, startIndex);
    const match = text.slice(startIndex, endIndex);
    const after = text.slice(endIndex);

    return [before, <strong key="match">{match}</strong>, after];
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { key } = e;

    if (key === "Enter" && !e.shiftKey) {
      e.preventDefault();

      if (text) {
        onEnter(text);
      }
    }
  };

  return (
    <div className={styles.rootContainer}>
      <div className={styles.container}>
        <div className={styles.languageSelect}>
          <h4>Translate from</h4>
          <Select
            items={[
              {
                value: "",
                label: "Detect language",
              },
              ...languages,
            ]}
            itemPredicate={filterLanguage}
            itemRenderer={renderLanguage}
            onItemSelect={(item) => setSourceLanguage(item.value)}
            noResults={<MenuItem disabled={true} text="No results." />}
            popoverProps={{ popoverClassName: styles.popover }}
            className={styles.select}
          >
            <Button
              text={getLanguage(sourceLanguage)}
              rightIcon="double-caret-vertical"
            />
          </Select>
        </div>
        <TextArea
          className={styles.textarea}
          fill
          placeholder="Type here..."
          value={text}
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setTranslatedText("");
              setSourceLanguage("");
            }
            setText(e.target.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </div>

      <div className={styles.container}>
        <div className={styles.languageSelect}>
          <h4>Translate to</h4>
          <Select
            items={languages}
            itemPredicate={filterLanguage}
            itemRenderer={renderLanguage}
            onItemSelect={(item) => setTargetLanguage(item.value)}
            noResults={<MenuItem disabled={true} text="No results." />}
            popoverProps={{ popoverClassName: styles.popover }}
            className={styles.select}
          >
            <Button
              text={getLanguage(targetLanguage)}
              rightIcon="double-caret-vertical"
            />
          </Select>
        </div>

        {loading && (
          <Loader loading={loading} loaderHeight={0}>
            <></>
          </Loader>
        )}

        <div
          className={cn(Classes.INPUT, Classes.FILL, styles.textarea)}
          dangerouslySetInnerHTML={{ __html: translatedText }}
        />
      </div>
    </div>
  );
}
