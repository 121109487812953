import { createActions } from "redux/create-actions";
import {
  BATCH_CONNECT_USER_ITEMS_payload,
  DELETE_SILHOUETTE_payload,
  UPDATE_SILHOUETTE_payload,
} from "./sagas";
import { SilhouetteState } from "./types";

const actions = createActions("silhouettes", {
  SET_STATE: {} as Partial<SilhouetteState>,
  GET_SILHOUETTES: undefined,
  UPDATE_SILHOUETTE: {} as UPDATE_SILHOUETTE_payload,
  DELETE_SILHOUETTE: {} as DELETE_SILHOUETTE_payload,
  BATCH_CONNECT_USER_ITEMS: {} as BATCH_CONNECT_USER_ITEMS_payload,
});

export default actions;
