import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import style from "./index.module.css";
import { Icon } from "@blueprintjs/core";
import useEditAttributeDialog from "../edit-attribute-dialog";
export default function TableCell({ cellProps, }) {
    var _a, _b;
    const { getValue, column, table } = cellProps;
    const editableFields = (_b = (_a = table.options.meta) === null || _a === void 0 ? void 0 : _a.fieldProps) === null || _b === void 0 ? void 0 : _b.editableFields;
    const editable = isEditable(column.id, editableFields);
    const value = getValue();
    // If the cell is not editable, don't render the edit dialog
    if (!editable)
        return (_jsx("div", { style: { cursor: "default", display: "inline-flex" }, children: _jsx("span", { className: style.value, title: value, children: value }) }));
    const { onOpen, DialogComponent } = useEditAttributeDialog(cellProps);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: style.row_value, onClick: () => onOpen(), children: [value !== "" && (_jsx("span", { className: style.value, title: value, children: value })), _jsx(Icon, { icon: "edit", className: style.edit_icon })] }), DialogComponent] }));
}
const isEditable = (columnId, editableFields) => {
    return (editableFields === null || editableFields === void 0 ? void 0 : editableFields.includes("*")) || (editableFields === null || editableFields === void 0 ? void 0 : editableFields.includes(columnId));
};
