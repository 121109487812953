import { Button, Menu, MenuItem, Popover } from "@blueprintjs/core";
// COMPONENTS
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import accessLevelsActions from "redux/access-levels/actions";
// REDUX
import { useAppDispatch } from "redux/hooks";
import supplierActions from "redux/suppliers/actions";
import "./index.css";
import { useDataLabelingData } from "pages/data-labeling";
import {
  Outlet,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { ItemRenderer } from "@blueprintjs/select";
import InvoicePageUsersList from "components/invoices/invoice-page-users-list";
import TableView from "components/invoices/invoices-table-view";
import { UseSearchContext, useSearch } from "layouts";
import { UserListRef } from "redux/types";
import { LOCALES } from "utils/constants";
import { getInvoice } from "../../services/invoice";

type ContextType = UseSearchContext & {
  selectedUserContext: {
    id: string;
    userName: string;
    invoicesCount: Record<string, number>;
    isExcludedFromPriorityListAtInvoices: boolean;
    isNewAtInvoices: boolean;
  };
  tabContext: string;
  setTabContext: Dispatch<SetStateAction<string>>;
  setModificationOutlet: Dispatch<SetStateAction<string>>;
  resolvingUsers: string[];
  userIdContext: string;
};

function Invoices({
  setModification,
}: {
  setModification?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const dispatch = useAppDispatch();

  const { searchProps, setSearchProps } = useSearch();
  const { userIdParam } = useParams<{ userIdParam: string }>();
  const { setModificationContext } = useDataLabelingData();
  const userId = useMemo(() => (userIdParam ? userIdParam : ""), [userIdParam]);
  const [tabContext, setTabContext] = useState<string>("");
  const [initialLoad, setInitialLoad] = useState(true);

  const [isTableView, setTableView] = useState(false);
  const [filterByLocale, setFilterByLocale] = useState<Set<string>>(
    new Set<string>().add("en").add("no").add("hr")
  );
  const usersListRef = useRef<UserListRef>(null);
  const priorityListRef = useRef<UserListRef>(null);
  const checkingPriorityListRef = useRef<UserListRef>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const params: Record<string, string> = useMemo(() => {
    let paramObj = {};
    for (const [key, value] of searchParams) {
      paramObj = { ...paramObj, [key]: value };
    }

    return paramObj;
  }, [searchParams]);

  const setModificationUsed = setModification
    ? setModification
    : setModificationContext;

  useEffect(() => {
    dispatch(accessLevelsActions.SUBSCRIBE_TO_ACCESS_LEVELS());
    dispatch(supplierActions.SUBSCRIBE_TO_SUPPLIERS({ includeDeleted: false }));

    return () => {
      dispatch(accessLevelsActions.UNSUBSCRIBE_FROM_ACCESS_LEVELS());
      dispatch(supplierActions.UNSUBSCRIBE_FROM_SUPPLIERS());
    };
  }, [dispatch]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    const keys = Object.keys(params);
    // Set the tab param when search param is present
    if (userId && keys.includes("search")) {
      const newParam: Record<string, string> = { ...params, tab: tabContext };
      if (tabContext === "resolved") {
        if (!keys.includes("sub")) newParam.sub = "unchecked";
      } else {
        if (keys.includes("sub")) delete newParam.sub;
      }
      setSearchParams(newParam);
    }
  }, [userId, tabContext, params]);

  // biome-ignore lint:react-hooks/exhaustive-deps
  useEffect(() => {
    if (userIdParam && params.tab && initialLoad) {
      // If invoiceId is present, get the invoice and set the correct tab context
      if (params.invoiceId) {
        getInvoice(userIdParam, params.invoiceId).then((res) => {
          if (!res.error && res.data) {
            const state = res.data.state;
            setTabContext(state);
            if (state === "resolved") {
              const newParam: Record<string, string> = { ...params };
              newParam.sub = "unchecked,checked";
              setSearchParams(newParam);
            }
          }
        });
      } else {
        setTabContext(params.tab);
      }
      setInitialLoad(false);
    }
  }, [params]);

  function handleFilterByLocale(filter: string) {
    const newFilters = new Set(filterByLocale);
    if (newFilters.has(filter) && newFilters.size > 1) {
      newFilters.delete(filter);
    } else {
      newFilters.add(filter);
    }
    setFilterByLocale(newFilters);
  }

  const localeRenderer: ItemRenderer<{ name: string; value: string }> = (
    locale,
    { handleClick, modifiers, handleFocus }
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={locale.value}
        text={`${locale.name} (${locale.value})`}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        selected={filterByLocale.has(locale.value)}
        shouldDismissPopover={false}
      />
    );
  };

  const handleExpandAllUsersLists = () => {
    priorityListRef.current?.handleOpenList();
    checkingPriorityListRef.current?.handleOpenList();
    usersListRef.current?.handleOpenList();
  };

  const handleContractAllUsersLists = () => {
    priorityListRef.current?.handleCloseList();
    checkingPriorityListRef.current?.handleCloseList();
    usersListRef.current?.handleCloseList();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          text={isTableView ? "Normal View" : "Table View"}
          onClick={() => setTableView((prev) => !prev)}
          icon={isTableView ? "circle-arrow-left" : "panel-table"}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <span
              style={{
                color: "#bb86fc",
                cursor: "pointer",
              }}
              onClick={handleExpandAllUsersLists}
            >
              Expand All
            </span>
            <span>|</span>
            <span
              style={{
                color: "#bb86fc",
                cursor: "pointer",
              }}
              onClick={handleContractAllUsersLists}
            >
              Contract All
            </span>
          </div>
          <Popover
            placement="left"
            content={
              <Menu>
                {LOCALES.map((locale, idx) =>
                  localeRenderer(locale, {
                    index: idx,
                    handleClick: () => handleFilterByLocale(locale.value),
                    modifiers: {
                      active: filterByLocale.has(locale.value),
                      disabled: false,
                      matchesPredicate: false,
                    },
                    query: "",
                  })
                )}
              </Menu>
            }
          >
            <Button icon="translate" text={`Filter by Locale`} />
          </Popover>
        </div>
      </div>
      {isTableView ? (
        <TableView setModification={setModificationUsed} />
      ) : (
        <>
          <InvoicePageUsersList
            setTabContext={setTabContext}
            setModificationUsed={setModificationUsed}
            filterByLocale={filterByLocale}
            usersListRef={usersListRef}
            priorityListRef={priorityListRef}
            checkingPriorityListRef={checkingPriorityListRef}
            userId={userId}
            tabContext={tabContext}
          />
          {!userId ? (
            "Please select a user."
          ) : (
            <Outlet
              context={{
                searchProps,
                setSearchProps,
                tabContext: tabContext,
                setTabContext: setTabContext,
                setModificationOutlet: setModificationUsed,
                userIdContext: userId,
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export function useInvoiceData() {
  return useOutletContext<ContextType>();
}

export default Invoices;
