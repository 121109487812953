import { FocusStyleManager, H1, Tab, Tabs } from "@blueprintjs/core";
// COMPONENTS
import { ModuleLoader } from "@stockifi/shared";
import React, { createContext, useEffect, useMemo, useState } from "react";

// REDUX
import { useAppDispatch, useAppSelector } from "redux/hooks";
import "./index.css";
import actionsUsers from "redux/users/actions";
import { claims, hasAccess } from "../../permissions";

import { UseSearchContext, useSearch } from "layouts";
import {
  Link,
  Outlet,
  useLocation,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";

interface Props {
  features: string[];
}
interface DataLabelingContextProps {
  modification: string;
}

export const DataLabelingContext = createContext<DataLabelingContextProps>({
  modification: "dataLabeling",
});

type ContextType = UseSearchContext & {
  setModificationContext: React.Dispatch<React.SetStateAction<string>>;
};

function DataLabeling({ features }: Props) {
  const { searchProps, setSearchProps, focus } = useSearch();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loadingUsers = useAppSelector((state) => state.users.loading);
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const [modification, setModification] = useState<string>("dataLabeling");

  const [urlParams, setParams] = useSearchParams();
  const pathParams = useParams();

  const { userIdParam } = pathParams;

  const params: Record<string, string> = useMemo(() => {
    let paramObj = {};
    for (const [key, value] of urlParams) {
      paramObj = { ...paramObj, [key]: value };
    }

    return paramObj;
  }, [urlParams]);

  const { search } = params;

  FocusStyleManager.onlyShowFocusOnTabs();

  const activeTab = useMemo(() => {
    const path = location.pathname
      .split("/")
      .filter((x) => x !== "" && x !== "data-labeling");
    if (path.length > 0) return path[0];
    else return "invoices";
  }, [location]);

  useEffect(() => {
    dispatch(actionsUsers.SUBSCRIBE_TO_USERS({ loadAllUsers: true }));

    return () => {
      dispatch(actionsUsers.UNSUBSCRIBE_FROM_USERS());
    };
  }, [dispatch]);

  useEffect(() => {
    // reset search when changing tab
    if (userIdParam) {
      setSearchProps((prev) => ({ ...prev, value: "" }));
      setParams({ ...params, search: "" });
    }
  }, [userIdParam]);

  useEffect(() => {
    setParams({ ...params, search: searchProps.value });
  }, [searchProps.value]);

  useEffect(() => {
    if (search) {
      focus();
      setSearchProps((prev) => ({ ...prev, value: search ?? "" }));
    }
  }, [search]);

  return (
    <ModuleLoader loading={loadingUsers}>
      <DataLabelingContext.Provider value={{ modification }}>
        <div className="container-fluid p-0">
          <H1 className="mb-4">Data Labeling</H1>

          <Tabs
            animate={true}
            selectedTabId={activeTab}
            renderActiveTabPanelOnly={true}
            className="mb-3"
          >
            {hasAccess(authClaims, claims.accessInvoices) &&
              features.includes("invoices") && (
                <Tab
                  id="invoices"
                  children={<Link to="/data-labeling/invoices">Invoices</Link>}
                />
              )}
            {hasAccess(authClaims, claims.accessPOSItemTasks) &&
              features.includes("pos-item-tasks") && (
                <Tab
                  id="pos-item-tasks"
                  children={
                    <Link to="/data-labeling/pos-item-tasks">
                      POS Item Tasks
                    </Link>
                  }
                />
              )}
            {hasAccess(authClaims, claims.accessInvoiceVotesResults) &&
              features.includes("invoice-votes-results") && (
                <Tab
                  id="invoice-votes-results"
                  children={
                    <Link to="/data-labeling/invoice-votes-results">
                      Invoice Votes Results
                    </Link>
                  }
                />
              )}
          </Tabs>
          <Outlet
            context={{
              searchProps,
              setSearchProps,
              setModificationContext: setModification,
            }}
          />
        </div>
      </DataLabelingContext.Provider>
    </ModuleLoader>
  );
}
export function useDataLabelingData() {
  return useOutletContext<ContextType>();
}
export default DataLabeling;
