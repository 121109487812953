import { InvoiceItem } from "redux/invoice/types";

export const percentageCheck = (item: InvoiceItem) => {
  const { oldCost, cost } = item;
  if (oldCost === cost) return false;
  const percentage = ((cost - oldCost) / oldCost) * 100;
  if (!!cost && !!oldCost && cost !== Infinity) {
    return (
      (percentage >= 15 || percentage <= -15) &&
      !(percentage >= 75 || percentage <= -75)
    );
  }
  return false;
};

export const percentage75Check = (item: InvoiceItem) => {
  const { oldCost, cost } = item;
  if (oldCost === cost) return false;
  const percentage = ((cost - oldCost) / oldCost) * 100;
  if (!!cost && !!oldCost && cost !== Infinity) {
    return percentage >= 75 || percentage <= -75;
  }
  return false;
};

export const newItemCheck = (item: InvoiceItem) => {
  return !!item.isNew && item.isNew === true && !item.isOpenItem;
};

export const nameChangedCheck = (item: InvoiceItem) => {
  return item.nameChanged;
};

export const openItemCheck = (item: InvoiceItem) => {
  return item.isOpenItem;
};

export const negativeCheck = (item: InvoiceItem) => {
  return item.cost < 0 || item.quantity < 0 || item.total < 0;
};

export const autoTotalfillItemsCheck = (item: InvoiceItem) => {
  return item.autoTotal;
};

export const autoQuantityfillItemsCheck = (item: InvoiceItem) => {
  return item.autoQuantity;
};

export const wasOpenItemCheck = (item: InvoiceItem) => {
  return item.wasOpenItem;
};

export const hasErrorCheck = (item: InvoiceItem) => {
  return item.hasError;
};

export const sponsoredItemCheck = (item: InvoiceItem) => {
  return item.isSponsored;
};

export const taxDeductionCheck = (item: InvoiceItem) => {
  return !!item.taxDeduction && item.totalAfterTax !== item.total;
};

export const itemDuplicateCheck = (item: InvoiceItem) => {
  return item.isDuplicate;
};

export const itemTotalComputed = (item: InvoiceItem) => {
  return item.totalComputed;
};

export const isItemPrefilled = (item: InvoiceItem) => {
  return item.isIdReachConsensus && isVoteValueGetPrefilled(item);
};

export const isVoteValueGetPrefilled = (item: InvoiceItem) => {
  return (
    item.itemVoteValue &&
    item.itemVoteValue.quantity !== 0 &&
    item.itemVoteValue.total !== 0 &&
    item.itemVoteValue.cost !== 0
  );
};

export interface ItemTag {
  name: string;
  text: string;
  filterFn: (item: InvoiceItem) => boolean | undefined;
  bgColorClass: string;
  exludeFromInitialTags?: boolean;
}

export const ITEM_TAGS = [
  {
    name: "percentChanged",
    text: "Change +/- 15%",
    filterFn: percentageCheck,
    bgColorClass: "tag-orange",
  },
  {
    name: "percentChanged75",
    text: "Change +/- 75%",
    filterFn: percentage75Check,
    bgColorClass: "tag-dark-orange",
  },
  {
    name: "new",
    text: "New",
    filterFn: newItemCheck,
    bgColorClass: "tag-yellow",
  },
  {
    name: "negative",
    text: "Negative",
    filterFn: negativeCheck,
    bgColorClass: "tag-red",
    hidden: true,
  },
  {
    name: "nameChanged",
    text: "Name Changed",
    filterFn: nameChangedCheck,
    bgColorClass: "tag-alt-name",
  },
  {
    name: "openItem",
    text: "Open Item",
    filterFn: openItemCheck,
    bgColorClass: "tag-open-item",
    hidden: true,
  },
  {
    name: "autoTotal",
    text: "Autofilled",
    filterFn: autoTotalfillItemsCheck,
    bgColorClass: "tag-green",
    hidden: true,
  },
  {
    name: "autoQuantity",
    text: "Qty Autofilled",
    filterFn: autoQuantityfillItemsCheck,
    bgColorClass: "tag-green-olive",
    hidden: true,
  },
  {
    name: "wasOpenItem",
    text: "Was Open Item",
    filterFn: wasOpenItemCheck,
    bgColorClass: "tag-was-open-item",
  },
  {
    name: "hasError",
    text: "Error",
    filterFn: hasErrorCheck,
    bgColorClass: "tag-has-error",
  },
  {
    name: "sponsored",
    text: "Sponsored",
    filterFn: sponsoredItemCheck,
    bgColorClass: "tag-sponsored",
  },
  {
    name: "taxDeduction",
    text: "Tax Deduction",
    filterFn: taxDeductionCheck,
    bgColorClass: "tag-tax-deduction",
  },
  {
    name: "duplicate",
    text: "Duplicate",
    filterFn: itemDuplicateCheck,
    bgColorClass: "tag-duplicate",
    exludeFromInitialTags: true,
  },
  {
    name: "totalComputed",
    text: "Total Computed",
    filterFn: itemTotalComputed,
    bgColorClass: "tag-total-computed",
    exludeFromInitialTags: true,
  },
];
