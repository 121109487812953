import { Divider, Intent, Tag } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { useEffect, useState } from "react";
import type { Module } from "redux/settings/types";
import styles from "./index.module.scss";

interface Props {
  module: Module;
  editableFields: string[];
  allFields: string[];
  onChange: (code: string, fields: string[]) => void;
}

export default function Field({
  module,
  editableFields,
  allFields,
  onChange,
}: Props) {
  const [selected, setSelected] = useState<string[]>(editableFields);

  useEffect(() => {
    onChange(module.code, selected);
  }, [selected]);

  return (
    <div>
      <div className={styles.text_wrapper}>
        <p className={styles.text}>{module.text}</p>
        <Divider className="w-100" />
      </div>
      <MultiSelect
        data-testid="setting multi select"
        items={allFields}
        selectedItems={selected}
        onItemSelect={(item: any) => {
          if (selected.includes(item)) {
            setSelected(selected.filter((i) => i !== item));
          } else {
            setSelected([...selected, item]);
          }
        }}
        itemRenderer={(item: any, { handleClick, modifiers }) => {
          if (!modifiers.matchesPredicate) {
            return null;
          }
          return (
            <Tag
              active={modifiers.active}
              intent={selected.includes(item) ? Intent.SUCCESS : Intent.NONE}
              onClick={handleClick}
              key={item}
              minimal
              className={styles.tag}
            >
              {item}
            </Tag>
          );
        }}
        tagRenderer={(item: any) => item}
        tagInputProps={{
          onRemove: (item: any, index: any) => {
            setSelected(selected.filter((i) => i !== item));
          },
        }}
        popoverProps={{
          matchTargetWidth: true,
          popoverClassName: styles.popover,
        }}
        itemListPredicate={(query, items) => {
          return items.filter((item) =>
            item.toLowerCase().includes(query.toLowerCase())
          );
        }}
        placeholder="Select editable fields"
        onClear={() => setSelected([])}
      />
    </div>
  );
}
