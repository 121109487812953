export const initialHighlightColorSettings = {
  boxBorder: {
    hover: "rgba(24, 124, 245, 1)",
    unselected: "rgba(0, 0, 0, 0.1)",
    selected: "rgba(187, 134, 252, 1)",
    unresolved: "rgba(255,165,0, 1)",
    resolved: "rgba(60, 179, 113, 1)",
    autofillQuantity: "rgba(85, 107, 47, 1)",
    autofillTotal: "rgba(50, 164, 103, 1)",
    autoMultiplier: "rgba(255, 0, 0, 1)",
  },
  rowHighlight: {
    selected: "rgba(104, 31, 194, 1)",
    focus: "rgba(104, 31, 194, 0.2)",
    idle: "rgba(187, 134, 252, 0.2)",
    unresolved: "rgba(255,165,0,0.2)",
    resolved: "rgba(60, 179, 113, 0.2)",
  },
  floatingButtons: {
    selectedRow: "rgba(104, 31, 194, 1)",
    name: "rgba(104, 31, 194, 1)",
    quantity: "rgba(104, 31, 194, 1)",
    total: "rgba(104, 31, 194, 1)",
    multiplier: "rgba(104, 31, 194, 1)",
  },
};

export const highlightColorTitleLabel = {
  boxBorder: "Box Border",
  rowHighlight: "Row Highlight",
  floatingButtons: "Floating Buttons",
};

export const highlightColorLabel = {
  focus: "Focused Item",
  idle: "Selected Boxes",
  hover: "Hovered Item",
  unselected: "Unselected Boxes",
  selected: "Selected Boxes",
  unresolved: "Unresolved Item",
  resolved: "Resolved Item",
  autofillQuantity: "Autofill Quantity",
  autofillTotal: "Autofill Total",
  quantity: "Quantity",
  total: "Total",
  multiplier: "Multiplier",
  selectedRow: "Selected Row",
  name: "Name",
  autoMultiplier: "Auto Multiplier",
};
