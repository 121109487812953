import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { ServiceReturn } from "redux/types";
import { checkCounter } from "services/counter";
import { db } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export const getUserCounts = async (userId: string): Promise<ServiceReturn> => {
  if (!db) return { data: null, error: "No db connection" };

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const docRef = query(
    collection(db, "users", userId, "counts"),
    orderBy("sortKey", "asc")
  );
  try {
    const querySnapshot = await getDocs(docRef);
    const data: DocumentData[] = [];
    querySnapshot.forEach((doc) => {
      const d = doc.data();

      if (!d.deleted) data.push({ ...d, id: doc.id });
    });

    return { data: data, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
};

export const getUserCountAreas = async (
  userId: string
): Promise<ServiceReturn> => {
  if (!db) return { data: null, error: "No db connection" };

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const docRef = query(
    collection(db, "users", userId, "countAreas"),
    orderBy("name", "asc")
  );

  try {
    const querySnapshot = await getDocs(docRef);
    const data: DocumentData[] = [];
    querySnapshot.forEach((doc) => {
      const d = doc.data();
      if (!d.deleted) data.push({ ...d, id: doc.id });
    });

    return { data: data, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
};

export const getVendorOtp = async (): Promise<ServiceReturn> => {
  if (!db) return { data: null, error: "No db connection" };

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const docRef = doc(db, "adminPanelSettings/otp");
    const querySnapshot = await getDoc(docRef);

    const data = querySnapshot.data();

    return { data: data, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
};

export const updateOtp = async (data: DocumentData): Promise<ServiceReturn> => {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, "adminPanelSettings", "otp");

  if (!checkCounter) {
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  }

  return updateDoc(docRef, data)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
};
