import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { ServiceReturn } from "redux/types";
import { updateAccessLevels } from "services/access-levels";
import { setAccessLevelsListener } from "services/listeners/accessLevels";
import { COMMON } from "utils/constants";
import actions from "./actions";

/**
 * A listener to accessLevels collection.
 * @category Redux
 */
function* SUBSCRIBE_TO_ACCESS_LEVELS() {
  yield put(actions.SET_STATE({ loading: true }));
  const channel: EventChannel<boolean> = yield call(setAccessLevelsListener);

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_ACCESS_LEVELS);
    channel.close();
  });

  try {
    while (true) {
      const accessLevels: Record<string, string> = yield take(channel);
      yield put(
        actions.SET_STATE({
          accessLevels,
          loading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
    }
  }
}

/**
 * Payload to update accessLevels collection.
 * @category Redux
 */
export interface UPDATE_ACCESS_LEVELS_Payload {
  /** New access levels data. */
  accessLevels: Record<string, string>;
}

/**
 * Update accessLevels collection.
 * @param action - Payload to update accessLevels collection.
 * @category Redux
 */
function* UPDATE_ACCESS_LEVELS(
  action: ReturnType<typeof actions.UPDATE_ACCESS_LEVELS>
) {
  const { accessLevels } = action.payload;

  const result: ServiceReturn = yield call(updateAccessLevels, accessLevels);
  if (result.data) {
    yield put(alertActions.SUCCESS("Access level labels updated."));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBSCRIBE_TO_ACCESS_LEVELS, SUBSCRIBE_TO_ACCESS_LEVELS),
    takeLatest(actions.UPDATE_ACCESS_LEVELS, UPDATE_ACCESS_LEVELS),
  ]);
}
