import { useMemo } from "react";
import { useAppSelector } from "redux/hooks";
import { getUserName } from "redux/users/helpers";

type Props = {
  isTable?: boolean;
  modification: string;
  data: {
    state: string;
    voters?: string[];
    id: string;
  };
};

function InvoiceListItemResolverStatus({ isTable, modification, data }: Props) {
  const users = useAppSelector((state) => state.users.users);
  const userOnInvoice = useMemo(() => {
    return {
      resolvingInvoices: users.filter((user) =>
        user.resolvingInvoiceId?.includes(data.id)
      ),
      checkingInvoices: users.filter((user) =>
        user.checkingInvoiceId?.includes(data.id)
      ),
    };
  }, [users, data.id]);
  if (
    isTable ||
    (userOnInvoice.resolvingInvoices.length === 0 &&
      userOnInvoice.checkingInvoices.length === 0)
  ) {
    return null;
  }

  const statusText =
    modification === "dataVoting"
      ? "voted"
      : data.state === "resolved"
        ? "checked "
        : "resolved ";

  const userNames =
    modification === "dataVoting"
      ? data.voters?.map((s) => getUserName(s)).join(", ")
      : data.state === "unresolved"
        ? userOnInvoice.resolvingInvoices.map((u) => u.name).join(", ")
        : userOnInvoice.checkingInvoices.map((u) => u.name).join(", ");

  return (
    <span>
      Being {statusText} by :{" "}
      <span style={{ color: "#bb86fc" }}>{userNames}</span>
    </span>
  );
}

export default InvoiceListItemResolverStatus;
