import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { AuthState } from "./types";

export const initialState: AuthState = {
  user: undefined,
  error: undefined,
  loading: false,
  authClaims: undefined,
  loadingToken: false,
  token: undefined,
  allUserClaims: [],
  allUserClaimsLoading: false,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
