import { Button } from "@blueprintjs/core";
import { httpsCallable } from "firebase/functions";
import moment from "moment";
import { useState } from "react";
import alertActions from "redux/alert/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Invoice, Items } from "redux/invoice/types";
import { functions } from "services/firebase";
import { CALLABLE_FUNCTIONS } from "utils/callable-functions/constants";
import { isValidNumber } from "../invoice-list/helpers";

type Props = { data: Invoice; userName: string; isTable?: boolean };

function PriceChanges({ data, userName, isTable }: Props) {
  const dispatch = useAppDispatch();
  const items = useAppSelector((state) => state.invoices.userItems);
  const itemsTable = useAppSelector(
    (state) => state.invoices.userItemsTable[data.userId]?.userItems
  );
  const userItems = isTable ? itemsTable : items;
  const suppliers = useAppSelector((state) => state.suppliers.data);
  const [loadingDownload, setLoadingDownload] = useState(false);

  function getItem(id: string): Items {
    const ret = userItems?.find((item) => item.id === id);
    if (ret == null) return {} as Items;
    return ret as Items;
  }

  const handleDownload = async () => {
    // XLSX Header
    const sheetData: (string | number | undefined)[][] = [
      [""],
      [
        "Item",
        "Old Price",
        "New Price",
        "% Change",
        "Supplier",
        "Type",
        "Delivery Date",
        "Invoice #",
        "Comment",
      ],
    ];

    const drinkRows: (string | number | undefined)[][] = [];
    const foodRows: (string | number | undefined)[][] = [];
    const newDrinkRows: (string | number | undefined)[][] = [];
    const newFoodRows: (string | number | undefined)[][] = [];

    const invData = data;
    const deliveryDate = moment(invData.deliveryDate).format("YYYY-MM-DD");
    const supplierName = suppliers.find(
      (x) => x.id === invData.supplierId
    )?.name;

    for (const itemId in invData.updatedCosts) {
      const { old: oldCost, new: newCost } = invData.updatedCosts[itemId];
      const { isNew } = invData.items[itemId] ?? {};
      if (oldCost?.toFixed(2) !== newCost?.toFixed(2) || isNew) {
        const item = getItem(itemId);
        const {
          name: itemName,
          size: itemSize,
          unit: itemUnit,
          type: itemType,
        } = item;

        const quantity = invData.items[itemId]?.quantity ?? 0;
        const change = newCost / oldCost - 1;

        const row = [
          itemId,
          itemName,
          itemSize,
          itemUnit,
          quantity,
          isNew ? "" : oldCost,
          newCost,
          isNew ? "" : change,
          supplierName,
          itemType,
          deliveryDate,
          invData.number,
          isNew ? "New Item" : "",
        ];

        const isFood = itemType?.trim() === "Mat";

        if (isFood) isNew ? newFoodRows.push(row) : foodRows.push(row);
        else isNew ? newDrinkRows.push(row) : drinkRows.push(row);
      }
    }

    // sort drink & food items by % change
    [drinkRows, foodRows].map((rows) =>
      rows.sort((x, y) => {
        const changeX = Number(x[3]);
        const changeY = Number(y[3]);
        return changeY - changeX;
      })
    );

    // sort new items by item name
    [newDrinkRows, newFoodRows].sort((x, y) => {
      const nameX = x[0]?.toString() ?? "";
      const nameY = y[0]?.toString() ?? "";
      return nameX.localeCompare(nameY);
    });

    // insert empty row after each list of items
    [drinkRows, foodRows, newDrinkRows, newFoodRows].map((x) => x.push([""]));

    // push lists to sheet data
    if (drinkRows.length > 1) sheetData.push(...drinkRows);
    if (foodRows.length > 1) sheetData.push(...foodRows);
    if (newDrinkRows.length > 1) sheetData.push(...newDrinkRows);
    if (newFoodRows.length > 1) sheetData.push(...newFoodRows);

    if (sheetData.length > 2) {
      const fileName = `${userName} - Stockifi Price Changes.xlsx`;

      // x = base value, y = number of decimals
      const roundDecimals = (x: number, y: number) =>
        Math.round((x + Number.EPSILON) * Math.pow(10, y)) / Math.pow(10, y);

      // Download the Report
      const cols: number[] = [];
      const formattedReport = sheetData.map((row) =>
        row.map((value, index) => {
          const formattedValue =
            typeof value === "number"
              ? index !== 3
                ? roundDecimals(value, 2)
                : roundDecimals(value, 4)
              : value;
          const oldValue = cols[index] || 0;
          const newValue = formattedValue?.toString().length ?? 0 + 4;
          cols[index] = newValue > oldValue ? newValue : oldValue;

          return !isValidNumber(Number(formattedValue))
            ? String(formattedValue)
            : formattedValue;
        })
      );

      const reportObject: { [key: string]: string | number | undefined }[] = [];
      formattedReport.forEach((row, index) => {
        if (index > 1) {
          if (row[0] === "") return;

          const [
            itemId,
            name,
            size,
            unit,
            quantity,
            oldPrice,
            price,
            percentChange,
            supplier,
            type,
            deliveryDate,
            number,
            comment,
          ] = row;

          const reportRow = {
            itemId,
            name,
            size,
            unit,
            quantity,
            oldPrice,
            price,
            percentChange,
            supplier,
            type,
            deliveryDate,
            number,
            comment,
          };

          reportObject.push(reportRow);
        }
      });

      if (!functions) return;

      interface ReportResponse {
        file: string;
        name: string;
      }

      interface ReportRequest {
        data: { [key: string]: string | number | undefined }[];
        user: string;
      }

      const getReport = httpsCallable<ReportRequest, ReportResponse>(
        functions,
        CALLABLE_FUNCTIONS.getPriceChangesReport
      );

      setLoadingDownload(true);
      try {
        const res = await getReport({ data: reportObject, user: userName });

        const data = JSON.parse(res.data.file);
        const { data: arrayBuffer } = data;

        const buffer = Buffer.from(arrayBuffer);

        // download the file (xlsx)
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();

        setLoadingDownload(false);
      } catch (_error) {
        dispatch(alertActions.ERROR("Failed to download the report."));
        setLoadingDownload(false);
      }
    } else {
      dispatch(alertActions.WARNING("Invoice didn't update any cost(s)."));
    }
  };

  return (
    <>
      <Button
        intent="primary"
        icon="download"
        onClick={async () => await handleDownload()}
        text="Price Changes"
        loading={loadingDownload}
      />
    </>
  );
}

export default PriceChanges;
