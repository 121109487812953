import ImageSearch from "components/side-panel/image";
import Translation from "components/side-panel/translation";
import { useState } from "react";
import Split from "react-split";
import { useAppSelector } from "redux/hooks";
import style from "./index.module.scss";
import "./index.scss";

const SidePanel = () => {
  const [text, setText] = useState("");
  const ocrItemQuery = useAppSelector((state) => state.invoiceOcr.ocrItemQuery);
  const handleEnter = (text: string) => {
    setText(text);
  };
  return (
    <div>
      <Split
        className={style.split}
        direction="vertical"
        cursor="row-resize"
        sizes={[33, 67]}
        minSize={150}
        expandToMin={false}
        gutterSize={8}
        gutterAlign="center"
        snapOffset={30}
        dragInterval={1}
      >
        <div className={style.wrapper}>
          <div title="Translator" className={style.translate}>
            <Translation ocrItemQuery={ocrItemQuery} onEnter={handleEnter} />
          </div>
        </div>
        <div className={style.wrapper}>
          <div title="Image Search" className={style.image}>
            <ImageSearch text={ocrItemQuery ? ocrItemQuery : text} />
          </div>
        </div>
      </Split>
    </div>
  );
};

export default SidePanel;
