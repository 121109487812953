import { Classes, Icon, Text } from "@blueprintjs/core";
import { cn } from "@stockifi/shared";
import { Attribute } from "components/edit-pinned-attributes";
import EditAccessLevel from "components/users/edit-access-levels";
import EditUserPriorities from "components/users/edit-user-priorities";
import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import module from "./index.module.scss";

type Props = {
  attribute: string;
  attrMap: Map<string, string>;
  pinnedCols: Attribute[];
  setPinnedCols: React.Dispatch<React.SetStateAction<Attribute[]>>;
  handleProps?: DraggableProvidedDragHandleProps | null;
};

const Attributes = ({
  attribute,
  attrMap,
  pinnedCols,
  setPinnedCols,
  handleProps,
}: Props) => {
  const handleClickLock = (e: React.MouseEvent, key: string) => {
    const exist = pinnedCols.find((prop) => prop.name === key);

    return exist
      ? setPinnedCols(pinnedCols.filter((prop) => prop.name !== key))
      : setPinnedCols([
          ...pinnedCols,
          {
            id: pinnedCols.length + 1,
            name: key,
          },
        ]);
  };

  const getAttrDataType = (attr: string) => {
    const dataType = attrMap.get(attr);
    if (dataType === "string" || dataType === "number") {
      const dropdowns = ["organizationName", "priority", "accessLevel"];
      if (dropdowns.includes(attr)) {
        return dataType + "-dropdown";
      } else {
        return dataType + "-input";
      }
    }
    return dataType;
  };

  const CustomLabel = (key: string) => (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <div
        className="d-flex flex-row align-items-center"
        style={{ width: "180px" }}
      >
        {pinnedCols.find((prop) => prop.name === key) && (
          <Icon
            className={cn(Classes.MINIMAL, "me-2")}
            style={{ opacity: "50%" }}
            icon="drag-handle-vertical"
            {...handleProps}
          />
        )}
        <Icon
          style={{ cursor: "pointer" }}
          icon={
            pinnedCols.find((prop) => prop.name === key) ? "lock" : "unlock"
          }
          size={10}
          className="me-2"
          color={
            pinnedCols.find((prop) => prop.name === key)
              ? "#FD7AFEF0"
              : "#bb86fc80"
          }
          onClick={(e) => handleClickLock(e, key)}
          data-testid={key}
        />
        <Text
          className={
            pinnedCols.find((prop) => prop.name === key) ? "" : module.blur
          }
        >
          {key}
        </Text>
      </div>
      <div>
        <Text className={module.blur}>{getAttrDataType(attribute)}</Text>
      </div>
      {key === "accessLevel" && <EditAccessLevel />}
      {key === "priority" && <EditUserPriorities />}
    </div>
  );

  return <span>{CustomLabel(attribute)}</span>;
};

export default Attributes;
