import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DragDropContext, Droppable, Draggable, } from "react-beautiful-dnd";
import AttributeRow from "../attribute-row";
export default function VisibleColsPanel({ table }) {
    const columns = table
        .getVisibleLeafColumns()
        .filter((col) => !col.getIsPinned());
    const handleDragEnd = (result) => {
        if (!result.destination)
            return;
        const cols = columns.map((d) => d.id);
        table.setColumnOrder(reorder(cols, result.source.index - 1, result.destination.index - 1));
    };
    return (_jsx(DragDropContext, { onDragEnd: handleDragEnd, children: _jsx(Droppable, { droppableId: "droppable", children: (provided, snapshot) => (_jsxs("div", Object.assign({ className: "d-flex flex-column" }, provided.droppableProps, { ref: provided.innerRef, children: [columns.map((column, idx) => (_jsx(Draggable, { draggableId: idx.toString(), index: idx + 1, children: (provided, snapshot) => (_jsx("div", Object.assign({ className: "my-2", ref: provided.innerRef }, provided.draggableProps, { children: _jsx(AttributeRow, { column: column, handleProps: provided.dragHandleProps }) }))) }, idx))), provided.placeholder] }))) }) }));
}
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
