import React, { useState } from "react";
import CopyIcon from "../copy-icon";
import styles from "./index.module.scss";

interface TotalItemProps {
  label: string;
  value: number | string;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export default function TotalItem({ value, label, as = "h4" }: TotalItemProps) {
  const [copyIconVisible, setCopyIconVisible] = useState(false);

  return (
    <div
      className={styles.total_item}
      onMouseEnter={() => setCopyIconVisible(true)}
      onMouseLeave={() => setCopyIconVisible(false)}
    >
      {React.createElement(as, undefined, <strong>{label}:</strong>)}
      {React.createElement(as, undefined, value)}
      {copyIconVisible && <CopyIcon text={value} />}
    </div>
  );
}
