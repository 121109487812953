import { createActions } from "redux/create-actions";
import {
  BATCH_UPDATE_NOTIFICATION_STATUS_Payload,
  SUBSCRIBE_TO_NAVBAR_NOTIFICATIONS_Payload,
  SUBSCRIBE_TO_NOTIFICATIONS_Payload,
  UPDATE_NOTIFICATION_STATUS_Payload,
} from "./sagas";
import { NotificationState } from "./types";

const actions = createActions("notification-centre", {
  SET_STATE: {} as Partial<NotificationState>,
  SUBSCRIBE_TO_NOTIFICATIONS: {} as SUBSCRIBE_TO_NOTIFICATIONS_Payload,
  UNSUBSCRIBE_FROM_NOTIFICATIONS: undefined,
  SUBSCRIBE_TO_NAVBAR_NOTIFICATIONS:
    {} as SUBSCRIBE_TO_NAVBAR_NOTIFICATIONS_Payload,
  UNSUBSCRIBE_FROM_NAVBAR_NOTIFICATIONS: undefined,
  UPDATE_NOTIFICATION_STATUS: {} as UPDATE_NOTIFICATION_STATUS_Payload,
  BATCH_UPDATE_NOTIFICATION_STATUS:
    {} as BATCH_UPDATE_NOTIFICATION_STATUS_Payload,
  UPDATE_TOAST_NOTIFICATION: {} as {
    id?: string;
    operation: string;
    message?: string;
    data?: Record<string, unknown>;
  },
  GET_DONE_NOTIFICATIONS: undefined,
});

export default actions;
