import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { CustomClaims } from "redux/auth/types";
import { ServiceReturn } from "redux/types";
import { checkCounter } from "services/counter";
import { auth, functions } from "services/firebase";
import { getUser } from "services/users";
import { CALLABLE_FUNCTIONS } from "utils/callable-functions/constants";
import { SERVER_COUNTS } from "utils/constants";

export const NOT_ACTIVE = "User not active";

export const logInWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  if (!auth) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  const { user } = await signInWithEmailAndPassword(auth, email, password);

  const userId = user.uid;
  const { data: userData } = await getUser(userId);

  if (userData.active === false) {
    return { data: null, error: NOT_ACTIVE };
  } else {
    return { data: user, error: null };
  }
};

export const logout = () => {
  if (!auth) return { data: null, error: "No db connection" };

  signOut(auth);
};

export const getUserClaims = async () => {
  if (!auth) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return await auth.currentUser?.getIdTokenResult().then((result) => {
    return result.claims;
  });
};

export const getAllUserClaims = async () => {
  if (!functions) return console.log("functions is null");
  const getCustomClaims = httpsCallable<undefined, CustomClaims[]>(
    functions,
    CALLABLE_FUNCTIONS.getCustomClaims
  );
  const customClaims = await getCustomClaims();
  return customClaims.data;
};

export const getCurrentUser = async () => {
  if (!auth) return null;
  return auth.currentUser;
};

export async function getAuthToken(): Promise<ServiceReturn<string | null>> {
  if (!auth) return { data: null, error: "No db connection" };
  if (auth.currentUser === null)
    return { data: null, error: "User not logged in" };

  const result = auth.currentUser
    .getIdToken(true)
    .then(function (idToken) {
      return { data: idToken, error: null };
    })
    .catch(function (error) {
      return { data: null, error: error };
    });
  return result;
}
