import { IconName, TabId } from "@blueprintjs/core";
import { claims } from "../permissions";

interface ITEM_TYPES_interface {
  [key: string]: {
    [key: string]: string[];
  };
}

interface ICostSummaryDrinkTypes {
  [key: string]: {
    [key: string]: string;
  };
}

export const dateInvoiceKey = [
  "createdAt",
  "updatedAt",
  "deliveryDate",
  "oldestDate",
  "checkedAt",
  "resolvedAt",
  "votedAt",
  "startedAt",
  "lastVotedAt",
  "deletedAt",
  "dueDate",
];

//VALIDATIONS
export const INVOICE = {
  MISSING_DATA: "Didn't save. Data is missing.",
  CONFIRM_SAVE_ZERO_COST: "Are you sure to want to save invoice with 0 cost?",
  CONFIRM_SAVE_UPLOADED_BY_USER:
    "Are you sure you want to save an invoice uploaded by a user?",
  CONFIRM_SAVE_HUNDRED_K:
    "Are you sure you want to save an invoice with a total value over 100k?",
  CONFIRM_SAVE_FIVE_HUNDRED:
    "Are you sure you want to save an invoice with a total value under 500?",
  CONFIRM_SAVE_DATE_MORE_THAN_SIXTY_DAYS:
    "Are you sure you want to save an invoice with delivery date more than 60 days ago?",
  CONFIRM_DELETE_ITEM: "Are you sure you want to delete this item?",
  CONFIRM_DELETE_IMAGE: "Are you sure you want to delete this image?",
  CONFIRM_DELETE_INVOICE: "Are you sure you want to delete this invoice?",
  CONFIRM_UNDELETE_INVOICE: "Are you sure want to undelete this invoice?",
  IMAGE_UPLOADED: "Image successfully uploaded.",
  IMAGE_DELETED: "Image has been deleted.",
  INVALID_INPUT: "Invalid input.",
  INVOICE_NUMBER_EXISTS: "Invoice number already used",
  INVOICE_NUMBER_EXISTS_FROM_VOTE: "Invoice number marked as duplicate",
  PERCENTAGE_WARNING: "Price change is higher than +/-75%.",
  INVALID_DATE_FORMAT: "Invalid date format, please input it manually.",
  CONFIRM_SAVE_NO_ITEMS:
    "Are you sure you want to save an invoice with no items?",
};

export const INVOICE_DATA_MISSING_FIELD = {
  INVOICE_NUMBER_MISSING: "Didn't save. Invoice number is missing.",
  SUPPLIER_MISSING: "Didn't save. Supplier is missing or invalid.",
  DELIVERY_DATE_MISSING:
    "Didn't save. Delivery date is missing or more than 60 days from today's date.",
  DELIVERY_DATE_INVALID: "Didn't save. Delivery date is invalid.",
  FOOD_TOTAL_MISSING: "Didn't save. Food total is missing.",
  INVOICE_ITEM_UNIT_COST_MISSING: "Didn't save. Item unit cost is missing.",
  INVOICE_ITEM_QUANTITY_MISSING: "Didn't save. Item quantity is missing.",
  INVOICE_ITEM_TOTAL_MISSING: "Didn't save. Item Total is missing.",
  INVOICE_ITEM_TOTAL_UNMATCHED: "Didn't save. Item Total is unmatched.",
};

export const USER = {
  DISMISS_USER: "Are you sure you want to dismiss this user?",
  USER_DISMISSED: "User has been dismissed.",
  TRANSACTION_RESOLVED: "Transaction has been marked as resolved.",
  TRANSACTION_UNRESOLVED: "Transaction has been marked as unresolved.",
  INVOICES_COUNT_UPDATE: "User invoices count has been updated.",
  USER_CONFIRMED_STATUS: "User confirmed status has been updated.",
  USER_COUNT_NOTE_UPDATE: "User count's note has been updated.",
};

export const SUPPLIER = {
  CONFIRM_DELETE_SUPPLIER: "Are you sure you want to delete this supplier?",
};
export const APP_VERSION = parseFloat(process.env.__VERSION__ ?? "1") / 1000;

export const COMMON = {
  REQUEST_ERROR: "An error was encountered while processing the request.",
};

export const STATES = [
  { property: "", state: "Users", users: [] },
  { property: "started", state: "Started", users: [] },
  { property: "pending", state: "Pending", users: [] },
  { property: "complete", state: "Complete", users: [] },
];

export const userStatusWarnings = {
  PREV_COUNT_NOT_COMPLETE: "There are previous counts that are not complete.",
  OVERLAP_PERIOD:
    "There are previous counts that overlap with the latest count.",
  NOT_CONNECT_PERIOD:
    "The endTime of the first previous count does not connect with the startTime of the latest count.",
  INVOICE_RESOLVED_FOR_PREV_PERIOD:
    "The invoice is resolved for the previous period.",
};

export const SERVER_COUNTS = {
  ERROR_MAX_COUNT: "Error: Max server calls reached.",
};

export const OCR_SELECTION = {
  DATA_SAVED: "Ocr selections saved.",
  DATA_SAVE_ERROR: "Error saving ocr selections.",
  DATA_LOAD_ERROR: "Error loading ocr selections.",
};

export interface NavItem {
  id: TabId | string;
  group: number;
  name: string;
  icon: IconName;
  path: string;
  accessCheck: string | null;
}

export const NAV_ITEMS: NavItem[] = [
  {
    id: "home",
    group: 0,
    name: "Home",
    icon: "home",
    path: "/",
    accessCheck: null,
  },

  {
    id: "data-labeling",
    group: 1,
    name: "Data Labeling",
    icon: "document",
    path: "/data-labeling",
    accessCheck: claims?.dataLabelling,
  },
  {
    id: "ap-transactions",
    group: 1,
    name: "AP Transactions",
    icon: "dollar",
    path: "/ap-transactions",
    accessCheck: claims?.apTransactions,
  },
  {
    id: "integration-monitor",
    group: 1,
    name: "Integration Monitor",
    icon: "data-connection",
    path: "/integration-monitor",
    accessCheck: claims?.integrationMonitor,
  },
  {
    id: "user-status",
    group: 2,
    name: "User Status",
    icon: "user",
    path: "/user-status",
    accessCheck: claims?.userStatus,
  },
  {
    id: "users",
    group: 2,
    name: "Users",
    icon: "th-list",
    path: "/users",
    accessCheck: claims?.users,
  },
  {
    id: "suppliers",
    group: 2,
    name: "Suppliers",
    icon: "th-list",
    path: "/suppliers",
    accessCheck: claims?.suppliers,
  },
  {
    id: "lists",
    group: 2,
    name: "Lists",
    icon: "new-person",
    path: "/lists",
    accessCheck: claims?.lists,
  },
  {
    id: "silhouettes",
    group: 2,
    name: "Silhouettes",
    icon: "draw",
    path: "/silhouettes",
    accessCheck: claims?.silhouettes,
  },
  {
    id: "counts",
    group: 2,
    name: "Counts",
    icon: "new-person",
    path: "/counts",
    accessCheck: claims?.counts,
  },
  {
    id: "sales",
    group: 2,
    name: "Sales",
    icon: "dollar",
    path: "/sales",
    accessCheck: claims?.sales,
  },
  {
    id: "scripts",
    group: 3,
    name: "Scripts",
    icon: "code",
    path: "/scripts",
    accessCheck: claims?.scripts,
  },
  {
    id: "app-tutorials",
    group: 4,
    name: "App Tutorials",
    icon: "walk",
    path: "/app-tutorials",
    accessCheck: claims?.appTutorials,
  },
  {
    id: "docs",
    group: 4,
    name: "Docs",
    icon: "manual",
    path: "/docs",
    accessCheck: claims?.docs,
  },
  {
    id: "login-token",
    group: 4,
    name: "Login Token",
    icon: "log-in",
    path: "/login-token",
    accessCheck: claims?.loginToken,
  },
  {
    id: "permissions",
    group: 4,
    name: "Permissions",
    icon: "key",
    path: "/permissions",
    accessCheck: claims?.permissions,
  },
  {
    id: "table-pdf2xlsx",
    group: 4,
    name: "Table PDF to Excel",
    icon: "th-derived",
    path: "/table-pdf2xlsx",
    accessCheck: claims?.tablePDFtoCSV,
  },
  {
    id: "predefined-filter-manager",
    group: 4,
    name: "Predefined Filter Manager",
    icon: "filter",
    path: "/predefined-filter-manager",
    accessCheck: claims?.predefinedFilterManager,
  },
];

export const allTypesInEnglishKey: ICostSummaryDrinkTypes = {
  no: {
    Various: "Diverse",
    Tobacco: "Tobakksvarer",
    Food: "Mat",
    "Alcohol Free": "Alkoholfritt",
    "Low alcohol": "Alkoholsvak",
    Cider: "Cider",
    "RTD & FAB": "RTD & FAB",
    Beer: "Øl",
    Liqueur: "Likører",
    Sake: "Sake",
    "Strong beer": "Starkøl",
    "Fortified wine": "Starkvin",
    Wine: "Vin",
    Spirits: "Brennevin",
    Taptails: "Taptails",
  },
  eng: {
    Various: "Various",
    Tobacco: "Tobacco",
    Food: "Food",
    "Alcohol Free": "Alcohol Free",
    "Low alcohol": "Low alcohol",
    Cider: "Cider",
    "RTD & FAB": "RTD & FAB",
    Beer: "Beer",
    Liqueur: "Liqueur",
    Sake: "Sake",
    "Strong beer": "Strong beer",
    "Fortified wine": "Fortified wine",
    Wine: "Wine",
    Spirits: "Spirits",
  },
  hr: {
    "Alcohol Free": "Bezalkoholno piće",
    "Low alcohol": "Slabo alkoholno piće",
    Spirits: "Žestoko piće",
    Cider: "Cider",
    Various: "Ostalo",
    Liqueur: "Liker",
    Food: "Hrana",
    Beer: "Pivo",
    "RTD & FAB": "RTD & FAB",
    "Strong beer": "Jako pivo",
    Sake: "Sake",
    "Fortified wine": "Jako vino",
    Tobacco: "Duhanski proizvod",
    Wine: "Vino",
  },
};

export const costSummaryDrinkTypes: ICostSummaryDrinkTypes = {
  no: {
    drink: "Alkoholfritt + Brennevin + Øl + Kaffe & Te + Taptails + Vin",
    nonAlcoholicAndSpirit: "Total Alkoholfritt + Brennevin",
  },
  hr: {
    drink: "Bezalkoholno piće + Žestoko piće + Pivo + Kava i čaj + Vino",
    nonAlcoholicAndSpirit: "Total Bezalkoholno piće + Žestoko piće",
  },
  // temporary en still using no types
  eng: {
    drink: "Alkoholfritt + Brennevin + Øl + Kaffe & Te + Taptails + Vin",
    nonAlcoholicAndSpirit: "Total Alkoholfritt + Brennevin",
  },
};

export const ITEM_UNITS = ["ml", "g", "pcs"];

export const ITEM_TYPES: ITEM_TYPES_interface = {
  no: {
    Diverse: ["Diverse", "Ice", "Pant"],
    Tobakksvarer: ["Sigarer", "Sigaretter", "Snus"],
    Mat: ["Mat", "Snacks"],
    Alkoholfritt: ["Alkoholfritt", "Kaffe & Te", "Recipe", "Sirup & Pure"],
    Alkoholsvak: ["Alkoholsvak"],
    Cider: ["Cider"],
    "RTD & FAB": ["RTD & FAB"],
    Øl: ["Fatøl", "Tankøl", "Flaske Øl"],
    Likører: ["Likører"],
    Sake: ["Sake"],
    Starkøl: ["Fatøl", "Flaske Øl", "Tankøl"],
    Starkvin: ["Starkvin"],
    Vin: [
      "Champagne",
      "Dessertvin",
      "Fruktvin",
      "Hvitvin",
      "Musserende",
      "Oransjevin",
      "Rødvin",
      "Rosevin",
    ],
    Brennevin: [
      "Akvavit",
      "Armagnac",
      "Brandy",
      "Brennevin Diverse",
      "Calvados",
      "Cognac",
      "Gin",
      "Grappa",
      "Likører",
      "Mezcal",
      "Pisco",
      "Rum",
      "Tequila",
      "Vodka",
      "Whisky",
    ],
    Taptails: ["Taptails"],
  },
  hr: {
    "Bezalkoholno piće": [
      "Bezalkoholno piće",
      "Kava i čaj",
      "Recept",
      "Sirup & Pure",
    ],
    "Slabo alkoholno piće": ["Slabo alkoholno piće"],
    "Žestoko piće": [
      "Aquavit",
      "Armagnac",
      "Brandy",
      "Žestoko ostalo",
      "Calvados",
      "Cognac",
      "Gin",
      "Rakija",
      "Liker",
      "Mezcal",
      "Pisco",
      "Rum",
      "Tequila",
      "Vodka",
      "Whisky",
      "Domaće žestoko",
    ],
    Cider: ["Cider"],
    Ostalo: ["Ostalo", "Led", "Kaucija"],
    Liker: ["Liker"],
    Hrana: ["Hrana", "Grickalice"],
    Pivo: ["Točeno pivo", "Pivo u boci"],
    "RTD & FAB": ["RTD & FAB"],
    "Jako pivo": ["Točeno pivo", "Pivo u boci"],
    Sake: ["Sake"],
    "Jako vino": ["Jako vino"],
    "Duhanski proizvod": ["Cigare", "Cigarete", "Duhan"],
    Vino: [
      "Šampanjac",
      "Desertno vino",
      "Voćno vino",
      "Bijelo vino",
      "Pjenušavo vino",
      "Narančasto vino",
      "Crveno vino",
      "Crno vino",
      "Rose vino",
    ],
  },
};

export const COMPARISON_OPERATORS = new Map([
  ["equal to", "="],
  ["not equal to", "≠"],
  ["greater than", ">"],
  ["less than", "<"],
  ["greater than or equal to", "≥"],
  ["less than or equal to", "≤"],
]);

export const INITIAL_SEARCH_PROPS = {
  value: "",
  placeholder: "Search is inactive...",
  readOnly: true,
};

interface Language {
  [key: string]: string;
}

export const LANGUAGES: Language = {
  af: "Afrikaans",
  sq: "Albanian",
  ar: "Arabic",
  az: "Azerbaijani",
  eu: "Basque",
  bn: "Bengali",
  be: "Belarusian",
  bg: "Bulgarian",
  ca: "Catalan",
  "zh-CN": "Chinese Simplified",
  "zh-TW": "Chinese Traditional",
  hr: "Croatian",
  cs: "Czech",
  da: "Danish",
  nl: "Dutch",
  en: "English",
  eo: "Esperanto",
  et: "Estonian",
  tl: "Filipino",
  fi: "Finnish",
  fr: "French",
  gl: "Galician",
  ka: "Georgian",
  de: "German",
  el: "Greek",
  gu: "Gujarati",
  ht: "Haitian Creole",
  iw: "Hebrew",
  hi: "Hindi",
  hu: "Hungarian",
  is: "Icelandic",
  id: "Indonesian",
  ga: "Irish",
  it: "Italian",
  ja: "Japanese",
  kn: "Kannada",
  ko: "Korean",
  la: "Latin",
  lv: "Latvian",
  lt: "Lithuanian",
  mk: "Macedonian",
  ms: "Malay",
  mt: "Maltese",
  no: "Norwegian",
  fa: "Persian",
  pl: "Polish",
  pt: "Portuguese",
  ro: "Romanian",
  ru: "Russian",
  sr: "Serbian",
  sk: "Slovak ",
  sl: "Slovenian",
  es: "Spanish",
  sw: "Swahili",
  sv: "Swedish",
  ta: "Tamil",
  te: "Telugu ",
  th: "Thai",
  tr: "Turkish",
  uk: "Ukrainian",
  ur: "Urdu",
  vi: "Vietnamese",
  cy: "Welsh",
  yi: "Yiddish",
};

export const LOCALES = [
  { name: "English", value: "en" },
  { name: "Norwegian", value: "no" },
  { name: "Croatian", value: "hr" },
];
