import { InputGroup } from "@blueprintjs/core";
import React from "react";
import { LookerEmbed } from "redux/settings/types";
import style from "../index.module.scss";

type Props = {
  newLookerEmbeds: LookerEmbed[];
  setNewLookerEmbeds: React.Dispatch<React.SetStateAction<LookerEmbed[]>>;
};

const LookerEmbeds = ({ newLookerEmbeds, setNewLookerEmbeds }: Props) => {
  return (
    <>
      <h3>Embeds</h3>
      <div className="content">
        <div className={style.embed__header}>
          <p>Looker ID</p>
          <p>Embed Name</p>
        </div>
        {newLookerEmbeds.map((embed) => {
          return (
            <div className={style.embed__wrapper} key={embed.id}>
              <InputGroup
                value={embed.lookerId}
                onChange={(event) => {
                  setNewLookerEmbeds(
                    newLookerEmbeds.map((e) => {
                      if (e.id === embed.id) {
                        return {
                          ...e,
                          lookerId: event.target.value,
                        };
                      }
                      return e;
                    })
                  );
                }}
              />
              <InputGroup
                value={embed.name}
                onChange={(event) => {
                  setNewLookerEmbeds(
                    newLookerEmbeds.map((e) => {
                      if (e.id === embed.id) {
                        return {
                          ...e,
                          name: event.target.value,
                        };
                      }
                      return e;
                    })
                  );
                }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default LookerEmbeds;
