import { Button, Card, Dialog, InputGroup, Label } from "@blueprintjs/core";
import { startCase } from "lodash";
import { ChangeEvent, useState } from "react";
import alertActions from "redux/alert/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/suppliers/actions";
import { Supplier } from "redux/suppliers/types";

const INITIAL_STATE = {
  name: "",
  companyNumber: "",
  address: "",
  zip: "",
  city: "",
  country: "",
};

const AddSupplier = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.suppliers.loadingUpdate);
  const [isOpen, setOpen] = useState(false);
  const [newSupplier, setNewSupplier] = useState(INITIAL_STATE);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewSupplier({ ...newSupplier, [e.target.name]: e.target.value });
  };

  const addSupplier = () => {
    if (!newSupplier.name) {
      dispatch(alertActions.ERROR("Name is required"));
      return;
    }

    dispatch(
      actions.ADD_SUPPLIER({
        supplier: newSupplier as Supplier,
      })
    );
    setNewSupplier(INITIAL_STATE);
    return;
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>Add Supplier</Button>
      <Dialog
        isOpen={isOpen}
        onClose={() => setOpen(!isOpen)}
        autoFocus
        enforceFocus
        usePortal
        title="Add Supplier"
      >
        <Card>
          {["name", "companyNumber", "address", "zip", "city", "country"].map(
            (field) => (
              <Label key={field}>
                {startCase(field)}
                <InputGroup
                  name={field}
                  value={newSupplier[field as keyof typeof newSupplier]}
                  onChange={onChange}
                />
              </Label>
            )
          )}
          <Button fill className="mt-4" loading={loading} onClick={addSupplier}>
            Add Supplier
          </Button>
        </Card>
      </Dialog>
    </>
  );
};

export default AddSupplier;
