import {
  DocumentData,
  QuerySnapshot,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import { EventChannel, eventChannel } from "redux-saga";
import { ClassificationCountData } from "redux/ap-transactions/types";
import { db } from "services/firebase";

export function setApMetricsListener(
  dateFrom: Date,
  dateTo: Date
): EventChannel<ClassificationCountData[]> {
  if (!db) return eventChannel(() => () => console.log("No DB connection"));
  const metricsRef = query(
    collection(db, "metricsApTransaction"),
    where("createdAt", ">=", moment(dateFrom).startOf("day").toDate()),
    where("createdAt", "<=", moment(dateTo).endOf("day").toDate())
  );

  return eventChannel((emitter) => {
    const unsub = onSnapshot(
      metricsRef,
      (snapshot: QuerySnapshot<DocumentData>) => {
        if (snapshot) {
          const data = snapshot.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
              }) as ClassificationCountData
          );
          emitter(data);
        }
      }
    );
    return () => unsub();
  });
}
