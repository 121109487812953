import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { SalesState } from "./types";

export const initialState: SalesState = {
  loadingSales: false,
  loadingSalesRefs: false,
  sales: [],
  firstSaleId: null,
  lastSaleId: null,
  salesRefs: [],
  dailySale: null,
  loadingDailySales: false,
  notDeletedSales: [],
  userDailySales: {
    data: [],
  },
  loadingUserDailySales: false,
  loadingWastages: false,
  wastages: [],
  notDeletedWastages: [],
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
