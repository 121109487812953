import { DocumentData, collection, doc, onSnapshot } from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { Integration } from "redux/integrations/types";
import { db } from "services/firebase";

function sortCredentials(credentials: Record<string, string>) {
  if (!credentials) return JSON.stringify({});
  const credentialsKeys = Object.keys(credentials).filter(
    (x) => x.toLowerCase() !== "password" && x.toLowerCase() !== "username"
  );
  credentialsKeys.sort((a, b) => a.localeCompare(b));
  if (Object.keys(credentials).some((x) => x.toLowerCase() === "password")) {
    credentialsKeys.unshift("password");
  }
  if (Object.keys(credentials).some((x) => x.toLowerCase() === "username")) {
    credentialsKeys.unshift("username");
  }
  return JSON.stringify(
    credentialsKeys.reduce(
      (acc, key) => {
        acc[key] = credentials[key];
        return acc;
      },
      {} as Record<string, string>
    )
  );
}

export function setIntegrationsListener(): EventChannel<DocumentData> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const collRef = collection(db, "integrations");

    const unsub = onSnapshot(collRef, (snapshot) => {
      const data = snapshot.docs.map(
        (x) => new Integration(x.id, x.data(), "false")
      );
      const dataWithSortedCredentials = data.map((x) => ({
        ...x,
        credentials: sortCredentials(JSON.parse(x.credentials)),
      }));

      emitter(dataWithSortedCredentials as Integration[]);
    });
    return () => unsub();
  });
}

export function setManualIntegrationsListener(): EventChannel<DocumentData> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const collRef = doc(db, "adminPanelSettings", "manualIntegrations");

    const unsub = onSnapshot(collRef, (snapshot) => {
      const returnData: Integration[] = [];
      const data = snapshot.data();
      if (data) {
        for (const key of Object.keys(data)) {
          returnData.push(new Integration(key, data[key], "true"));
        }
      }

      emitter(returnData);
    });
    return () => unsub();
  });
}
