import {
  DocumentData,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { Silhouette } from "redux/silhouettes/types";
import { ServiceReturn } from "redux/types";
import { checkCounter } from "services/counter";
import { db, storage } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export async function getSilhouettes(): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const collRef = collection(db, "silhouettes");

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const q = query(collRef, where("deleted", "==", false));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return { data: null, error: "empty" };
    } else {
      const silhouettes = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          if (!storage || !doc.data().path) {
            return new Silhouette(doc.id, doc.ref.path, "", doc.data());
          }
          const imageRef = ref(storage, doc.data().path);
          const imageUrl = await getDownloadURL(imageRef);

          const silhouette = new Silhouette(
            doc.id,
            doc.ref.path,
            imageUrl,
            doc.data()
          );

          return silhouette;
        })
      );
      return { data: silhouettes, error: null };
    }
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updateSilhouette(
  refPath: string,
  data: DocumentData
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, refPath);

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return await updateDoc(docRef, { ...data, updatedAt: new Date() })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function deleteSilhouette(
  refPath: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, refPath);

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  /**
   * TODO: Remove silhouettePath from connected items // Handle this in a cloud function
   */

  return await updateDoc(docRef, { deleted: true, updatedAt: new Date() })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function batchConnectUserItems(
  userId: string,
  itemIds: string[],
  silhouettePath: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const batch = writeBatch(db);
    for (const itemId of itemIds) {
      const itemRef = doc(db, "users", userId, "items", itemId);
      batch.update(itemRef, {
        silhouettePath,
      });
    }
    await batch.commit();
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}
