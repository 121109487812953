// STYLES
import { Classes, Menu, MenuDivider, MenuItem, TabId } from "@blueprintjs/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { cn } from "@stockifi/shared";
import { useActivePath } from "layouts/hooks";
import { useAppSelector } from "redux/hooks";
import { NavItem } from "utils/constants";
import { hasAccess } from "../../permissions";

type TabletNavProps = {
  popoverNav: NavItem[];
};

const PopoverNav = ({ popoverNav }: TabletNavProps) => {
  const navigate = useNavigate();
  const isActive = useActivePath();
  const authClaims = useAppSelector((state) => state.auth.authClaims);

  const handleMouseDown = (
    e: React.MouseEvent<HTMLAnchorElement>,
    url: string
  ) => {
    if (e.button === 1) window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleClick = (id: TabId, path: string) => navigate(path);

  const RenderDivider = ({ groupId }: { groupId: number }) => {
    if (groupId !== popoverNav.length - 1) {
      return (
        <MenuDivider
          key={`divider-${groupId}`}
          className={`menu-divider-${groupId}`}
        />
      );
    } else {
      return <></>;
    }
  };

  let currentGroup = 4;
  const RenderNavMenu = (item: NavItem, idx: number) => {
    if (item.group !== currentGroup) {
      currentGroup = item.group;
      return [
        <RenderDivider key={`div-${idx}`} groupId={item.group} />,
        <MenuItem
          key={`menu-${idx}`}
          className={`${isActive(item.path) && "active-font"} menu-group-${
            item.group
          }`}
          active={isActive(item.path)}
          id={item.id.toString()}
          icon={item.icon}
          text={item.name}
          onClick={() => handleClick(item.id, item.path)}
          onMouseDown={(e) => handleMouseDown(e, item.path)}
        />,
      ];
    }
    return (
      <MenuItem
        key={idx}
        className={`${isActive(item.path) && "active-font"} menu-group-${
          item.group
        }`}
        active={isActive(item.path)}
        id={item.id.toString()}
        icon={item.icon}
        text={item.name}
        onClick={() => handleClick(item.id, item.path)}
        onMouseDown={(e) => handleMouseDown(e, item.path)}
      />
    );
  };

  return (
    <Menu className={cn(Classes.DRAWER_BODY, "drawer-menu")}>
      {popoverNav.map((item, idx) => {
        return item.accessCheck
          ? hasAccess(authClaims, item.accessCheck) && RenderNavMenu(item, idx)
          : RenderNavMenu(item, idx);
      })}
    </Menu>
  );
};

export default PopoverNav;
