import {
  DocumentData,
  QueryConstraint,
  QuerySnapshot,
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { db } from "services/firebase";

export function setCountsListener(
  userId: string,
  includeDeleted = false
): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const constraints: QueryConstraint[] = [orderBy("sortKey")];

    if (!includeDeleted) constraints.push(where("deleted", "==", false));

    const countsQuery = query(
      collection(db, "users", userId, "counts"),
      ...constraints
    );
    const unsub = onSnapshot(
      countsQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: DocumentData[] = [];

        snapshot.forEach((countsDoc) => {
          data.push({
            ...countsDoc.data(),
            id: countsDoc.id,
            ref: countsDoc.ref,
          });
        });

        emitter(data);
      }
    );

    return () => unsub();
  });
}
