import { Divider, InputGroup, TextArea } from "@blueprintjs/core";
import _ from "lodash";
import React from "react";
import { NumericFormat } from "react-number-format";
import { VertexSettings } from "redux/settings/types";

interface DataVotingProps {
  maxVotes: number;
  setMaxVotes: React.Dispatch<React.SetStateAction<number>>;
  minConsensus: number;
  setMinConsensus: React.Dispatch<React.SetStateAction<number>>;
  voterBotWeight: number;
  setVoterBotWeight: React.Dispatch<React.SetStateAction<number>>;
  vertexBotWeight: number;
  setVertexBotWeight: React.Dispatch<React.SetStateAction<number>>;
  vertexSettings: VertexSettings;
  setVertexSettings: React.Dispatch<React.SetStateAction<VertexSettings>>;
  vertexTemplate: string;
  setVertexTemplate: React.Dispatch<React.SetStateAction<string>>;
  geminiProVisionWeight: number;
  setGeminiProVisionWeight: React.Dispatch<React.SetStateAction<number>>;
}

const DataVotingConfig = (props: DataVotingProps) => {
  const {
    maxVotes,
    setMaxVotes,
    minConsensus,
    setMinConsensus,
    voterBotWeight,
    setVoterBotWeight,
    vertexBotWeight,
    setVertexBotWeight,
    vertexSettings,
    setVertexSettings,
    vertexTemplate,
    setVertexTemplate,
    geminiProVisionWeight,
    setGeminiProVisionWeight,
  } = props;

  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="row">
        <div className="col">Max Votes:</div>
        <div className="col mb-2">
          <NumericFormat
            aria-label="max-votes"
            customInput={InputGroup}
            value={maxVotes}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setMaxVotes(parseInt(e.currentTarget.value))
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col">Minimum Consensus (Minimum Total Weight):</div>
        <div className="col mb-2">
          <NumericFormat
            aria-label="min-consensus"
            customInput={InputGroup}
            value={minConsensus}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setMinConsensus(parseInt(e.currentTarget.value))
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col">Voter Bot Weight:</div>
        <div className="col mb-2">
          <NumericFormat
            aria-label="voter-bot-weight"
            customInput={InputGroup}
            value={voterBotWeight}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setVoterBotWeight(Number(e.currentTarget.value))
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col">Vertex Bot Weight:</div>
        <div className="col mb-2">
          <NumericFormat
            aria-label="vertext-bot-weight"
            customInput={InputGroup}
            value={vertexBotWeight}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setVertexBotWeight(Number(e.currentTarget.value))
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col">Gemini Pro Vision Weight:</div>
        <div className="col mb-2">
          <NumericFormat
            aria-label="gemini-pro-vision-weight"
            customInput={InputGroup}
            value={geminiProVisionWeight}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setGeminiProVisionWeight(Number(e.currentTarget.value))
            }
          />
        </div>
      </div>

      <Divider />
      <h3>Vertex Bot Configurations</h3>

      <TextInput
        label="API Endpoint"
        value={vertexSettings?.apiEndpoint}
        onChange={(e) =>
          setVertexSettings((prev) => ({
            ...prev,
            apiEndpoint: e.currentTarget.value,
          }))
        }
      />

      <TextInput
        label="Model ID"
        value={vertexSettings?.modelId}
        onChange={(e) =>
          setVertexSettings((prev) => ({
            ...prev,
            modelId: e.currentTarget.value,
          }))
        }
      />

      <NumberInput
        label="Temperature"
        value={vertexSettings?.parameters.temperature}
        onChange={(e) =>
          setVertexSettings((prev) => ({
            ...prev,
            parameters: {
              ...prev.parameters,
              temperature: Number(e.currentTarget.value),
            },
          }))
        }
      />

      <NumberInput
        label="Top P"
        value={vertexSettings?.parameters.topP}
        onChange={(e) =>
          setVertexSettings((prev) => ({
            ...prev,
            parameters: {
              ...prev.parameters,
              topP: Number(e.currentTarget.value),
            },
          }))
        }
      />

      <NumberInput
        label="Top K"
        value={vertexSettings?.parameters.topK}
        onChange={(e) =>
          setVertexSettings((prev) => ({
            ...prev,
            parameters: {
              ...prev.parameters,
              topK: Number(e.currentTarget.value),
            },
          }))
        }
      />

      <NumberInput
        label="Max Output Tokens"
        value={vertexSettings?.parameters.maxOutputTokens}
        onChange={(e) =>
          setVertexSettings((prev) => ({
            ...prev,
            parameters: {
              ...prev.parameters,
              maxOutputTokens: Number(e.currentTarget.value),
            },
          }))
        }
      />

      <div className="row">
        <div className="col">Vertex Template</div>
        <div className="col mb-2">
          <TextArea
            aria-label="vertex-template"
            value={vertexTemplate}
            onChange={(e) => setVertexTemplate(String(e.currentTarget.value))}
            fill
            style={{ resize: "vertical" }}
          />
        </div>
      </div>
    </div>
  );
};

export default DataVotingConfig;

interface TextInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  label: string;
}

function TextInput(props: TextInputProps) {
  const { onChange, value, label } = props;
  return (
    <div className="row">
      <div className="col">{label}</div>
      <div className="col mb-2">
        <InputGroup
          aria-label={_.kebabCase(label)}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

interface NumberInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: number;
  label: string;
}

function NumberInput(props: NumberInputProps) {
  const { onChange, value, label } = props;
  return (
    <div className="row">
      <div className="col">{label}</div>
      <div className="col mb-2">
        <NumericFormat
          aria-label={_.kebabCase(label)}
          customInput={InputGroup}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
