import { DocumentData, DocumentReference } from "firebase/firestore";
import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { APTransaction } from "redux/ap-transactions/types";
import { ServiceReturn } from "redux/types";
import { ConfirmedStatus, User } from "redux/users/types";
import {
  setNoteSuggestions,
  setSelectedUserListener,
  setUserTransactionsListener,
  setUsersListener,
} from "services/listeners/users";
import {
  batchDismissUserCount,
  batchUpdateUsers,
  dismissUserCount,
  editTableColumns,
  getTableColumns,
  getUserTransactionFile,
  getUsers,
  updateCountConfirmedStatus,
  updateNoteSuggestions,
  updateTransactionsResolved,
  updateUser,
  updateUserConfirmedStatus,
} from "services/users";
import { COMMON, USER } from "utils/constants";
import actions from "./actions";

export interface GET_USERS_Payload {
  loadAllUsers: boolean;
  isNotFirstLoad?: boolean;
}
export function* GET_USERS(input: ReturnType<typeof actions.GET_USERS>) {
  const { isNotFirstLoad = false, loadAllUsers } = input.payload || {};
  if (!isNotFirstLoad) {
    yield put(
      actions.SET_STATE({
        loading: true,
      })
    );
  }

  const users: User[] = yield call(getUsers, loadAllUsers);
  yield put(
    actions.SET_STATE({
      users,
      loading: false,
    })
  );
}

export interface SUBSCRIBE_TO_USERS_payload {
  loadAllUsers: boolean;
  isNotFirstLoad?: boolean;
}

export function* SUBSCRIBE_TO_USERS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USERS>
) {
  const { loadAllUsers, isNotFirstLoad } = input.payload;

  if (!isNotFirstLoad) {
    yield put(
      actions.SET_STATE({
        loading: true,
        users: [],
      })
    );
  }

  const channel: EventChannel<boolean> = yield call(
    setUsersListener,
    loadAllUsers
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USERS);
    channel.close();
  });

  try {
    while (true) {
      let users: User[] = yield take(channel);
      if (users.length > 1) {
        users = users.map((doc) => ({
          ...doc,
          organizationName: doc.organizationId
            ? users.find((u) => u.id === doc.organizationId)?.name
            : undefined,
        }));

        yield put(
          actions.SET_STATE({
            users,
            loading: false,
          })
        );
      }
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    yield put(
      actions.SET_STATE({
        loading: false,
      })
    );
    if (c) {
      channel.close();
    }
  }
}

export interface SUBSCRIBE_TO_SELECTED_USERID_payload {
  userId: string;
}

export function* SUBSCRIBE_TO_SELECTED_USERID(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_SELECTED_USERID>
) {
  const { userId } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingSelectedUser: true,
      selectedUser: null,
    })
  );

  const channel: EventChannel<boolean> = yield call(
    setSelectedUserListener,
    userId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_TO_SELECTED_USERID);
    channel.close();
  });

  try {
    while (true) {
      const selectedUser: User = yield take(channel);
      yield put(
        actions.SET_STATE({
          selectedUser,
          loadingSelectedUser: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    yield put(
      actions.SET_STATE({
        loadingSelectedUser: false,
      })
    );
    if (c) {
      channel.close();
    }
  }
}

export interface DISMISS_USER_COUNT_Payload {
  userId: string;
  countId: string;
  userStatusNote: { data: string; archived: boolean }[];
}
function* DISMISS_USER_COUNT({
  payload,
}: ReturnType<typeof actions.DISMISS_USER_COUNT>) {
  const result: ServiceReturn = yield call(
    dismissUserCount,
    payload.userId,
    payload.countId,
    payload.userStatusNote
  );
  if (result.data) {
    yield put(
      alertActions.SUCCESS({
        message: USER.USER_DISMISSED,
        data: {
          payload: payload,
        },
      })
    );
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }
}

export interface DISMISS_ALL_USER_COUNT_Payload {
  users: User[];
  state: string;
}
function* DISMISS_ALL_USER_COUNT({
  payload,
}: ReturnType<typeof actions.DISMISS_ALL_USER_COUNT>) {
  const result: ServiceReturn = yield call(
    batchDismissUserCount,
    payload.users,
    payload.state
  );
  if (result.data) {
    yield put(
      alertActions.SUCCESS({
        message: USER.USER_DISMISSED,
        data: {
          payload: payload,
        },
      })
    );
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }
}

export interface UPDATE_USER_CONFIRMED_STATUS_Payload {
  userId: string;
  confirmedStatus: ConfirmedStatus;
}
function* UPDATE_USER_CONFIRMED_STATUS({
  payload,
}: ReturnType<typeof actions.UPDATE_USER_CONFIRMED_STATUS>) {
  const result: ServiceReturn = yield call(
    updateUserConfirmedStatus,
    payload.userId,
    payload.confirmedStatus
  );
  if (result.data) {
    yield put(
      alertActions.SUCCESS({
        message: USER.USER_CONFIRMED_STATUS,
        data: {
          payload: payload,
        },
      })
    );
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }
}

export interface UPDATE_COUNT_CONFIRMED_STATUS_Payload {
  userId: string;
  countId: string;
  confirmedStatus: ConfirmedStatus;
}
function* UPDATE_COUNT_CONFIRMED_STATUS({
  payload,
}: ReturnType<typeof actions.UPDATE_COUNT_CONFIRMED_STATUS>) {
  const result: ServiceReturn = yield call(
    updateCountConfirmedStatus,
    payload.userId,
    payload.countId,
    payload.confirmedStatus
  );
  if (result.data) {
    yield put(
      alertActions.SUCCESS({
        message: USER.USER_CONFIRMED_STATUS,
        data: {
          payload: payload,
        },
      })
    );
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }
}

export interface SUBSCRIBE_USER_TRANSACTIONS_Payload {
  userId: string;
  targetDate?: Date;
}

function* SUBSCRIBE_USER_TRANSACTIONS(
  input: ReturnType<typeof actions.SUBSCRIBE_USER_TRANSACTIONS>
) {
  const { userId, targetDate } = input.payload;
  yield put(
    actions.SET_STATE({
      loadingCategories: true,
      userTransactions: [],
    })
  );
  const channel: EventChannel<boolean> = yield call(
    setUserTransactionsListener,
    userId,
    targetDate
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_USER_TRANSACTIONS);
    channel.close();
  });

  try {
    while (true) {
      const userTransactions: APTransaction[] = yield take(channel);

      yield put(
        actions.SET_STATE({
          loadingCategories: false,
          userTransactions: userTransactions,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
    }
  }
}
export interface UPDATE_USER_Payload {
  user: Partial<User>;
  userId: string;
}

export function* UPDATE_USER(input: ReturnType<typeof actions.UPDATE_USER>) {
  const { user, userId } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingUpdate: true,
    })
  );

  const result: ServiceReturn = yield call(updateUser, user, userId);
  if (result.data) {
    yield put(actions.SET_STATE({ loadingUpdate: false }));
    yield put(
      alertActions.SUCCESS({
        message: "User data updated.",
        data: {
          payload: input.payload,
        },
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingUpdate: false }));
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: input.payload,
          error: result.error,
        },
      })
    );
  }
}

export interface BATCH_UPDATE_USER_Payload {
  usersArray: string[];
  data: DocumentData;
}

function* BATCH_UPDATE_USER(
  input: ReturnType<typeof actions.BATCH_UPDATE_USER>
) {
  const { usersArray, data } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingBulkEdit: true,
    })
  );

  const result: ServiceReturn = yield call(batchUpdateUsers, data, usersArray);
  if (result.data) {
    yield put(actions.SET_STATE({ loadingBulkEdit: false }));
    yield put(
      alertActions.SUCCESS({
        message: "User data updated.",
        data: {
          payload: input.payload,
        },
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingBulkEdit: false }));
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: input.payload,
          error: result.error,
        },
      })
    );
  }
}

export interface UPDATE_USERS_TRANSACTIONS_RESOLVED_Payload {
  transactionRefs: { ref: DocumentReference }[];
  resolved: boolean;
  resolvedBy: string;
}

function* UPDATE_USERS_TRANSACTIONS_RESOLVED({
  payload,
}: ReturnType<typeof actions.UPDATE_USERS_TRANSACTIONS_RESOLVED>) {
  const { transactionRefs, resolved, resolvedBy } = payload;

  yield put(
    actions.SET_STATE({
      loadingUpdate: true,
    })
  );

  // call service for update
  const result: ServiceReturn = yield call(
    updateTransactionsResolved,
    transactionRefs,
    resolved,
    resolvedBy
  );

  if (result.data) {
    yield put(
      alertActions.SUCCESS({
        message: resolved
          ? USER.TRANSACTION_RESOLVED
          : USER.TRANSACTION_UNRESOLVED,
        data: {
          payload: payload,
        },
      })
    );
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }

  yield put(
    actions.SET_STATE({
      loadingUpdate: false,
    })
  );
}

export interface GET_USER_TRANSACTION_DOWNLOAD_URL_Payload {
  paths: string[];
}

export function* GET_USER_TRANSACTION_DOWNLOAD_URL(
  input: ReturnType<typeof actions.GET_USER_TRANSACTION_DOWNLOAD_URL>
) {
  const { paths } = input.payload;
  for (let i = 0; i < paths.length; i++) {
    const result: ServiceReturn = yield call(getUserTransactionFile, paths[i]);

    if (result.data) {
      const fileName = paths[i].substring(paths[i].lastIndexOf("/") + 1);

      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = result.data;
      anchor.target = "_blank";
      anchor.click();
      anchor.remove();
    } else {
      yield put(
        alertActions.ERROR({
          message: result.error || COMMON.REQUEST_ERROR,
          data: {
            error: result.error,
            payload: input.payload,
            data: result.data,
          },
        })
      );
    }
  }
}

export interface GET_USER_TABLE_COLUMNS_Payload {
  module: string;
}

function* GET_USER_TABLE_COLUMNS(
  input: ReturnType<typeof actions.GET_USER_TABLE_COLUMNS>
) {
  const { module } = input.payload;
  yield put(
    actions.SET_STATE({
      loadingTableColumns: true,
    })
  );

  const result: ServiceReturn = yield call(getTableColumns, module);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        tableColumns: result.data,
        loadingTableColumns: false,
      })
    );
  }
}

export interface UPDATE_USER_TABLE_COLUMNS_Payload {
  data: Record<string, number>;
  module: string;
}

function* UPDATE_USER_TABLE_COLUMNS(
  input: ReturnType<typeof actions.UPDATE_USER_TABLE_COLUMNS>
) {
  const { data, module } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingSaveColumnOrder: true,
    })
  );

  const result: ServiceReturn = yield call(editTableColumns, data, module);
  if (result.data) {
    yield put(actions.SET_STATE({ loadingSaveColumnOrder: false }));
    yield put(
      alertActions.SUCCESS({
        message: "Table columns updated.",
        data: {
          payload: input.payload,
        },
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingSaveColumnOrder: false }));
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: input.payload,
          error: result.error,
        },
      })
    );
  }
}

export interface GET_USER_POS_ITEMS_BY_TYPE_Payload {
  userId: string;
  type: string;
}

function* SUBSCRIBE_NOTE_SUGGESTIONS() {
  yield put(
    actions.SET_STATE({
      loadingNoteSuggestions: true,
    })
  );

  const channel: EventChannel<boolean> = yield call(setNoteSuggestions);

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_NOTE_SUGGESTIONS);
    yield put(
      actions.SET_STATE({
        noteSuggestions: [],
      })
    );
    channel.close();
  });

  try {
    while (true) {
      const noteSuggestions: string[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          noteSuggestions: noteSuggestions,
          loadingNoteSuggestions: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loadingNoteSuggestions: false,
        })
      );
    }
  }
}

export interface UPDATE_NOTE_SUGGESTIONS_Payload {
  newNoteSuggestion: string;
}

function* UPDATE_NOTE_SUGGESTIONS({
  payload,
}: ReturnType<typeof actions.UPDATE_NOTE_SUGGESTIONS>) {
  const { newNoteSuggestion } = payload;

  yield put(
    actions.SET_STATE({
      loadingUpdateNoteSuggestions: true,
    })
  );

  // call service for update
  const result: ServiceReturn = yield call(
    updateNoteSuggestions,
    newNoteSuggestion
  );

  if (result.data) {
    yield put(alertActions.SUCCESS("Suggestions notes updated."));
  } else {
    yield put(
      alertActions.ERROR({
        message: result.error.message || COMMON.REQUEST_ERROR,
        data: {
          payload: payload,
          error: result.error,
        },
      })
    );
  }

  yield put(
    actions.SET_STATE({
      loadingUpdateNoteSuggestions: false,
    })
  );
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_USERS, GET_USERS),
    takeLatest(actions.UPDATE_USER, UPDATE_USER),
    takeLatest(actions.BATCH_UPDATE_USER, BATCH_UPDATE_USER),
    takeLatest(actions.DISMISS_ALL_USER_COUNT, DISMISS_ALL_USER_COUNT),
    takeLatest(actions.SUBSCRIBE_TO_USERS, SUBSCRIBE_TO_USERS),
    takeLatest(actions.DISMISS_USER_COUNT, DISMISS_USER_COUNT),
    takeLatest(
      actions.UPDATE_USER_CONFIRMED_STATUS,
      UPDATE_USER_CONFIRMED_STATUS
    ),
    takeLatest(
      actions.UPDATE_COUNT_CONFIRMED_STATUS,
      UPDATE_COUNT_CONFIRMED_STATUS
    ),
    takeLatest(
      actions.SUBSCRIBE_USER_TRANSACTIONS,
      SUBSCRIBE_USER_TRANSACTIONS
    ),
    takeLatest(
      actions.UPDATE_USERS_TRANSACTIONS_RESOLVED,
      UPDATE_USERS_TRANSACTIONS_RESOLVED
    ),
    takeLatest(
      actions.GET_USER_TRANSACTION_DOWNLOAD_URL,
      GET_USER_TRANSACTION_DOWNLOAD_URL
    ),
    takeLatest(actions.UPDATE_USER_TABLE_COLUMNS, UPDATE_USER_TABLE_COLUMNS),
    takeLatest(actions.GET_USER_TABLE_COLUMNS, GET_USER_TABLE_COLUMNS),
    takeLatest(actions.SUBSCRIBE_NOTE_SUGGESTIONS, SUBSCRIBE_NOTE_SUGGESTIONS),
    takeLatest(actions.UPDATE_NOTE_SUGGESTIONS, UPDATE_NOTE_SUGGESTIONS),
    takeLatest(
      actions.SUBSCRIBE_TO_SELECTED_USERID,
      SUBSCRIBE_TO_SELECTED_USERID
    ),
  ]);
}
