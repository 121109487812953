import { DocumentData } from "firebase/firestore";

export type IntegrationsState = {
  loading: boolean;
  integrations: Integration[];
  loadingManualIntegrations: boolean;
  manualIntegrations: Integration[];
};

export class Integration {
  id: string;
  active: string;
  connected: string;
  credentials: string;
  type: string;
  userId: string;
  userName: string;
  vendor: string;
  isManualIntegration: string;

  constructor(id: string, data: DocumentData, isManualIntegration: string) {
    this.id = id;
    this.active = data.active.toString() ?? "true";
    this.connected = data.connected.toString() ?? "true";
    this.credentials = data.credentials
      ? JSON.stringify(data.credentials)
      : "{}";
    this.type = data.type ?? "?";
    this.userId = data.userId ?? "?";
    this.userName = data.userName ?? "?";
    this.vendor = data.vendor ?? "?";
    this.isManualIntegration = isManualIntegration;
  }
}
