import { Attribute } from "components/edit-pinned-attributes";
import React, { JSX } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import Attributes from "../attributes";

type Props = {
  attrMap: Map<string, string>;
  pinnedCols: Attribute[];
  setPinnedCols: React.Dispatch<React.SetStateAction<Attribute[]>>;
};

const PinnedColumnsPanel = ({
  attrMap,
  pinnedCols,
  setPinnedCols,
}: Props): JSX.Element => {
  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const reorderedCols = reorder(
      pinnedCols,
      result.source.index,
      result.destination.index
    );
    setPinnedCols(reorderedCols);
  };

  const getItemStyle = (_: boolean, draggableStyle: any) => ({
    userSelect: "none",
    margin: `0 0 8px 0`,
    ...draggableStyle,
  });

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {pinnedCols
              .filter((col) => col.id !== 1)
              .map((attr, idx) => (
                <Draggable
                  key={idx}
                  draggableId={idx.toString()}
                  index={idx + 1}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Attributes
                        key={attr.name + idx}
                        attribute={attr.name}
                        attrMap={attrMap}
                        pinnedCols={pinnedCols}
                        setPinnedCols={setPinnedCols}
                        handleProps={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default PinnedColumnsPanel;
