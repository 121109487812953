import {
  Button,
  Collapse,
  Divider,
  Icon,
  NonIdealState,
  Tooltip,
} from "@blueprintjs/core";
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useAppSelector } from "redux/hooks";
import { UserListRef } from "redux/types";
import { User } from "redux/users/types";

type Props = {
  advancedFilteredUsers: User[];
  username: string;
  onButtonUserClick: (
    userIdToGo: string,
    isInvoiceVotingEnabled: boolean,
    state: string
  ) => void;
};

const UsersList = forwardRef(
  (
    { advancedFilteredUsers, username, onButtonUserClick }: Props,
    ref: Ref<UserListRef>
  ) => {
    const [totalUnresolvedInv, setTotalUnresolvedInv] = useState<number>(0);
    const authClaims = useAppSelector((state) => state.auth.authClaims);
    const invoiceLoading = useAppSelector((state) => state.invoices.loading);
    const loadingInvoiceVote = useAppSelector(
      (state) => state.invoices.loadingVotes
    );
    const loadingInvoice = invoiceLoading || loadingInvoiceVote;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useImperativeHandle(ref, () => {
      return {
        handleOpenList: () => setIsOpen(true),
        handleCloseList: () => setIsOpen(false),
      };
    });

    useEffect(() => {
      if (advancedFilteredUsers.length > 0) {
        const totalUnresolvedInvoices = advancedFilteredUsers
          .map((user) => user.invoicesCount?.unresolved ?? 0)
          .reduce((a, b) => a + b);

        setTotalUnresolvedInv(totalUnresolvedInvoices);
      }
    }, [advancedFilteredUsers]);

    const userWithVotingEnabled = useMemo(() => {
      const count = advancedFilteredUsers.filter(
        (user) => user.isInvoiceVotingEnabled
      ).length;
      const percentage = count
        ? ((count / advancedFilteredUsers.length) * 100).toFixed(2)
        : "0";
      return { count, percentage };
    }, [advancedFilteredUsers]);

    return (
      <>
        <div className="d-flex flex-row align-items-center mb-3">
          <div
            className="d-flex flex-row align-items-center"
            onClick={() => setIsOpen(!isOpen)}
            style={{ cursor: "pointer", userSelect: "none" }}
          >
            <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
            <p className="all_users_drop_down">
              Users ({advancedFilteredUsers.length}) [Voting enabled:{" "}
              {userWithVotingEnabled.count} ({userWithVotingEnabled.percentage}
              %)] [Unresolved: {totalUnresolvedInv}]
            </p>
          </div>
          <Divider className="w-100" />
        </div>
        <Collapse isOpen={isOpen} className="mb-3">
          <div className="d-flex flex-wrap mb-2" style={{ gap: "0.5rem" }}>
            {advancedFilteredUsers.length > 0 ? (
              advancedFilteredUsers.map((user, i) => (
                <Button
                  key={"invoice_button_" + i}
                  onClick={() =>
                    onButtonUserClick(
                      user.id,
                      user.isInvoiceVotingEnabled,
                      "unresolved"
                    )
                  }
                  text={
                    <div>
                      {`${user.name} (${user.invoicesCount?.unresolved ?? 0})`}
                      {user.resolvingUsers?.length > 0 && (
                        <>
                          <span> </span>
                          <span style={{ color: "#bb86fc" }}>
                            {user.resolvingUsers
                              .map((u: User) => u.name)
                              .join(", ")}
                          </span>
                        </>
                      )}{" "}
                      {user.isExcludedFromPriorityListAtInvoices && (
                        <Tooltip
                          content="Excluded from priority list"
                          position="bottom"
                        >
                          <Icon
                            icon="pause"
                            style={{
                              margin: "0 0 0 5px",
                              color: "#ffc33d",
                            }}
                          />
                        </Tooltip>
                      )}
                      {user.isNewAtInvoices && (
                        <Tooltip content="New user" position="bottom">
                          <Icon
                            icon="new-person"
                            style={{
                              margin: "0 0 0 5px",
                              color: "#ffc33d",
                            }}
                          />
                        </Tooltip>
                      )}
                      {user.isInvoiceVotingEnabled &&
                        (authClaims?.supervisor ||
                          authClaims?.admin ||
                          authClaims?.headDataManager) && (
                          <Tooltip content="Voting enabled" position="bottom">
                            <Icon
                              icon="take-action"
                              style={{
                                margin: "0 0 0 5px",
                                color: "#3dd46f",
                              }}
                            />
                          </Tooltip>
                        )}
                    </div>
                  }
                  outlined={user.name === username}
                  active={user.name === username}
                  loading={loadingInvoice && user.name === username}
                />
              ))
            ) : (
              <NonIdealState
                icon="user"
                title="No user found"
                description="You might need to change the filter."
              />
            )}
          </div>
        </Collapse>
      </>
    );
  }
);

export default UsersList;
