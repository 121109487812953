import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";

export type IntegrationMonitorState = {
  loading: boolean;
  scrapersLogs: {
    data: ScrapersLog[];
    lastDoc?: QueryDocumentSnapshot<DocumentData>;
    firstDoc?: QueryDocumentSnapshot<DocumentData>;
  };
  loadingScrapersLogs: boolean;
  isUpdating: boolean;
  updatingList: string[];
  inFocus: string | null;
  scrapersData: Record<string, ScrapersData>;
  loadingScrapersData: boolean;
  loadingImages: boolean;
  images: Record<
    string,
    { images: { url: string; name: string }[]; loadingImages: boolean }
  >;
  openedImage: { url: string; name: string }[];
  imagesLogId: string;
  loadingScrapersLogsData: boolean;
  posFiles: POSFile[];
  integrationsFields: IntegrationVendorSettingData;

  scraperDataToSummary: any[];
  scraperLogIds: {
    id: string;
    createdAt: Date;
    lastStep: { createdAt: Date; name: string };
    vendor: string;
  }[];
  loadingImMetrics: boolean;
  imMetrics: ImMatricData[];
  loadingAllSalesData: boolean;
};

/**
 * Type of Scrapers Data fetched from database
 * @category Integration Monitor
 */
export type ScrapersData = {
  /** Timestamp when the scrapers data is created */
  createdAt: Timestamp;
  /** Data scraped from scrapers vendor */
  data: object[];
  /** Log ID related into Scrapers Log */
  logId: string;
  /** Scrapers name who scrapes the data */
  scraperName: string;
  /** Integration user ID */
  userId: string;
  /** Indicates whether the scraping action is in process */
  loadingScrapersData: boolean;
};

/**
 * Type of Scrapers Log fetched from database
 * @category Integration Monitor
 */
export class ScrapersLog {
  /** Log ID of scrapers, we also use it on Scrapers Data */
  id: string;
  /** Firestore ref of scrapers log */
  ref: DocumentReference;
  /** Indicates whether the scraping action is success */
  success: boolean;
  /** Integration user ID */
  userId: string;
  /** List of scraping action steps */
  steps: {
    /** Timestamp when the scraping step was created */
    createdAt: Timestamp;
    /** Name of the scraping step */
    name: string;
    /** Screenshot image URL */
    image: string;
  }[];
  /** Type of the scrapers log, (POS, SUPPLIER, AP) */
  type: "POS" | "SUPPLIER" | "AP";
  /** Scrapers data ID, related into Scrapers Data ID (Firestore Docs ID) */
  dataId: string;
  /** Timestamp when the scraping logs was created */
  createdAt: Timestamp;
  /** Indicates whether will show it on notificatin show or not */
  notificationShow: boolean;
  /** Vendor name */
  name: string;
  /** Indicates whether the scraping action is login process */
  isLoginProcess?: boolean;
  /** Indicates whether the scraping action is manually executed or scheduled */
  isManual?: boolean;

  constructor(id: string, ref: DocumentReference, data: DocumentData) {
    this.id = id;
    this.ref = ref;
    this.success = data.success;
    this.userId = data.userId;
    this.steps = data.steps;
    this.type = data.type;
    this.dataId = data.dataId;
    this.createdAt = data.createdAt;
    this.notificationShow = data.notificationShow;
    this.name = data.name;
    this.isLoginProcess = data.isLoginProcess;
    this.isManual = data.isManual;
  }
}

/**
 * Type of POS (Point Of Sale) File
 * @category Integration Monitor
 */
export type POSFile = {
  /** Timestamp when the POS file was created */
  createdAt: Date;
  /** POS file data */

  data: any[];
  /** Period end timestamp of fetched POS file */
  periodEnd: Date;
  /** Period start timestamp of fetched POS file */
  periodStart: Date;
  /** POS user ID */
  userId: string;
  /** Vendor name */
  vendor: string;
};

/**
 * Type of field for add integration
 * @category Integration Monitor
 */
export type AddIntegrationField = {
  /** Vendor name */
  vendor: string;
  /** POS file data */
  credentials: AddIntegrationFieldCredential[];
};

/**
 * Type of credential for add integration field
 * @category Integration Monitor
 */
export type AddIntegrationFieldCredential = {
  /** Field data */
  [fieldName: string]: FieldData & {
    value: string;
  };
};

/**
 * Represents the properties of a field on .
 * @category Integration Monitor
 */
type FieldData = {
  /**
   * The order of the field.
   */
  order: number;
  /**
   * Indicates whether the field is required or not.
   */
  required: boolean;
  /**
   * Optional description for the field.
   */
  description?: string;
};

/**
 * Represents the type of a vendor.
 * Possible values: "POS", "SUPPLIER", "AP".
 * @category Integration Monitor
 */
type VendorType = "POS" | "SUPPLIER" | "AP";

/**
 * Represents an object from adminPanelSettings/integrations collection with its fields and type.
 * @category Integration Monitor
 */
type IntegrationVendorSetting = {
  /**
   * Indicates whether adding new integration is enabled or not.
   */
  enabled: boolean;
  /**
   * The fields of the service.
   */
  fields: AddIntegrationFieldCredential;
  /**
   * The type of the service.
   */
  type: VendorType;
  /**
   * Max user can be scraped at a time
   */
  limit?: number;
};

/**
 * Represents the data object return from adminPanelSettings/integrations collection containing multiple services.
 * @category Integration Monitor
 */
export type IntegrationVendorSettingData = {
  [vendorName: string]: IntegrationVendorSetting;
};

export type ImMatricData = {
  allCounts: LogsCount;
  manualCounts: LogsCount;
  scheduledCounts: LogsCount;
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export type LogsCount = {
  all: number;
  allScheduledIntegrationMustRun: number;
  run: number;
  notRun: number;
  success: number;
  fail: number;
  posNotRun: number;
  apNotRun: number;
  supplierNotRun: number;
  undefinedTypeNotRun: number;
  loginError: number;
  credentialsError: number;
  loginSuccess: number;
  scrapeFail: number;
  scrapeSuccessNoData: number;
  scrapeSuccessWithData: number;

  apiAll: number;
  allAPIScheduledIntegrationMustRun: number;
  apiRun: number;
  apiNotRun: number;
  apiSuccess: number;
  apiFail: number;
  apiPosNotRun: number;
  apiApNotRun: number;
  apiSupplierNotRun: number;
  apiUndefinedTypeNotRun: number;
  apiScrapeSuccessNoData: number;
  apiScrapeSuccessWithData: number;
};
