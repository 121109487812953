import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { ServiceReturn } from "redux/types";
import { setUserPrioritiesListener } from "services/listeners/user-priorities";
import { updateUserPriorities } from "services/user-priorities";
import { COMMON } from "utils/constants";
import actions from "./actions";

function* SUBSCRIBE_TO_USER_PRIORITIES() {
  yield put(actions.SET_STATE({ loading: true }));
  const channel: EventChannel<boolean> = yield call(setUserPrioritiesListener);

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USER_PRIORITIES);
    channel.close();
  });

  try {
    while (true) {
      const userPriorities: Record<string, string> = yield take(channel);
      yield put(
        actions.SET_STATE({
          userPriorities,
          loading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
    }
  }
}

export interface UPDATE_USER_PRIORITIES_Payload {
  userPriorities: Record<string, string>;
}

function* UPDATE_USER_PRIORITIES(
  input: ReturnType<typeof actions.UPDATE_USER_PRIORITIES>
) {
  const { userPriorities } = input.payload;

  const result: ServiceReturn = yield call(
    updateUserPriorities,
    userPriorities
  );
  if (result.data) {
    yield put(alertActions.SUCCESS("User priority labels updated."));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      actions.SUBSCRIBE_TO_USER_PRIORITIES,
      SUBSCRIBE_TO_USER_PRIORITIES
    ),
    takeLatest(actions.UPDATE_USER_PRIORITIES, UPDATE_USER_PRIORITIES),
  ]);
}
