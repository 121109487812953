import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";

const useDeletingLayout = () => {
  const deleting = useAppSelector(
    (state) => state.appConfig.deleteLayoutLoading
  );
  const [deletingId, setDeletingId] = useState<string>();

  useEffect(() => {
    if (!deleting) setDeletingId(undefined);
  }, [deleting]);

  return { deletingId, setDeletingId };
};

export default useDeletingLayout;
