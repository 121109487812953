import { Classes } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";

export function useDetectOutsideClick(ref: React.RefObject<HTMLInputElement>) {
  const [isItemsPopoverOpen, setItemsPopoverOpen] = useState<boolean>(false);
  const [isFeesPopoverOpen, setFeesPopoverOpen] = useState<boolean>(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */

    function handleClickOutside(event: { target: any }) {
      const targetClassName =
        typeof event.target.className === "string"
          ? event.target.className
          : "";
      const dataIcon =
        typeof event.target.getAttribute("data-icon") === "string"
          ? event.target.getAttribute("data-icon")
          : "";

      if (
        ref &&
        ref.current &&
        !ref.current.contains(event.target) &&
        !targetClassName.includes("strict-search-switch") &&
        !targetClassName.includes(Classes.CONTROL_INDICATOR) &&
        !dataIcon.includes("more")
      ) {
        setItemsPopoverOpen(false);
        setFeesPopoverOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return {
    isItemsPopoverOpen,
    setItemsPopoverOpen,
    isFeesPopoverOpen,
    setFeesPopoverOpen,
  };
}
