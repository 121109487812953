import { formatPeriodLong } from "components/user-status/helper";
import * as XLSX from "xlsx";

export const downloadSalesData = (
  data: unknown[][],
  userName: string,
  period: number[]
) => {
  if (
    data.some(
      (d) => d.includes("Name") || d.includes("Quantity") || d.includes("Netto")
    )
  ) {
    data.shift();
  }

  data.unshift(["Name", "Quantity", "Netto", "Group", "Brutto", "Discount"]);

  const ws = XLSX.utils.json_to_sheet(data, { skipHeader: true });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Data");
  XLSX.writeFile(
    wb,
    `${userName} Sales Report ${formatPeriodLong(period[0], period[1])}.xlsx`
  );
};
