import { createActions } from "redux/create-actions";
import { LOGIN_Payload } from "./sagas";
import { AuthState } from "./types";

const actions = createActions("auth", {
  SET_STATE: {} as Partial<AuthState>,
  LOGIN: {} as LOGIN_Payload,
  LOGOUT: undefined,
  GET_USER_CLAIMS: undefined,
  GET_CURRENT_USER: undefined,
  GET_AUTH_TOKEN: undefined,
  GET_ALL_USER_CLAIMS: undefined,
});

export default actions;
