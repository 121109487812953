import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { UserRecipe, UserRecipeUpdate } from "redux/pos-item-tasks/types";
import {
  addMaterialBookkeepingItem,
  setUserMaterialBookkeepingItemsListener,
  updateMaterialBookkeepingItem,
} from "services/material-bookkeeping-items";
import { COMMON } from "utils/constants";
import actions from "./actions";

export interface SUBSCRIBE_TO_USER_MATERIAL_BOOKKEEPING_ITEMS_payload {
  userId: string;
}
function* SUBSCRIBE_TO_USER_MATERIAL_BOOKKEEPING_ITEMS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USER_MATERIAL_BOOKKEEPING_ITEMS>
) {
  const { userId } = input.payload;

  yield put(
    actions.SET_STATE({
      loading: true,
    })
  );

  const channel: EventChannel<boolean> = yield call(
    setUserMaterialBookkeepingItemsListener,
    userId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_USER_MATERIAL_BOOKKEEPING_ITEMS);
    channel.close();
  });

  try {
    while (true) {
      const result: UserRecipe[] = yield take(channel);

      if (result) {
        yield put(
          actions.SET_STATE({
            loading: false,
            materialBookkeepingItems: result,
          })
        );
      }
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loading: false,
        })
      );
    }
  }
}

export interface ADD_MATERIAL_BOOKKEEPING_ITEM_payload {
  userId: string;
  newItem: UserRecipeUpdate;
}

function* ADD_MATERIAL_BOOKKEEPING_ITEM({
  payload,
}: ReturnType<typeof actions.ADD_MATERIAL_BOOKKEEPING_ITEM>) {
  yield put(actions.SET_STATE({ loadingCreate: true }));

  const { userId, newItem } = payload;

  const { data, error } = yield call(
    addMaterialBookkeepingItem,
    userId,
    newItem
  );

  if (data) {
    yield put(actions.SET_STATE({ loadingCreate: false }));
    yield put(
      alertActions.SUCCESS(
        `Material Bookkeeping Item ${newItem.name} Created Successfully.`
      )
    );
  } else if (error) {
    yield put(actions.SET_STATE({ loadingCreate: false }));
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_MATERIAL_BOOKKEEPING_ITEM_payload {
  userId: string;
  newItem: Partial<UserRecipe>;
}

function* UPDATE_MATERIAL_BOOKKEEPING_ITEM({
  payload,
}: ReturnType<typeof actions.UPDATE_MATERIAL_BOOKKEEPING_ITEM>) {
  yield put(actions.SET_STATE({ loadingSave: true }));

  const { userId, newItem } = payload;

  const { data, error } = yield call(
    updateMaterialBookkeepingItem,
    userId,
    newItem
  );

  if (data) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(
      alertActions.SUCCESS(
        `Material Bookkeeping Item ${newItem.name} Updated Successfully.`
      )
    );
  } else if (error) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      actions.SUBSCRIBE_TO_USER_MATERIAL_BOOKKEEPING_ITEMS,
      SUBSCRIBE_TO_USER_MATERIAL_BOOKKEEPING_ITEMS
    ),
    takeLatest(
      actions.ADD_MATERIAL_BOOKKEEPING_ITEM,
      ADD_MATERIAL_BOOKKEEPING_ITEM
    ),
    takeLatest(
      actions.UPDATE_MATERIAL_BOOKKEEPING_ITEM,
      UPDATE_MATERIAL_BOOKKEEPING_ITEM
    ),
  ]);
}
