import React from "react";
import "../index.css";

interface Props {
  targetRef: React.RefObject<HTMLCanvasElement>;
}

const FloatingButtonCanvas = ({ targetRef }: Props) => {
  return <canvas className={"canvas4"} ref={targetRef} />;
};

export default React.memo(FloatingButtonCanvas);
