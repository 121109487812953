import { useEffect, useState } from "react";
import { Invoice } from "redux/invoice/types";

export const useNumberOfDays = (data: Invoice) => {
  const [numberOfDays, setNumberOfDays] = useState<number>(0);

  useEffect(() => {
    if (data.deliveryDate && data.resolvedAt) {
      const milliseconds = Math.abs(
        new Date(data.deliveryDate).getTime() -
          new Date(data.resolvedAt).getTime()
      );
      const days = Math.ceil(milliseconds / (1000 * 60 * 60 * 24));
      setNumberOfDays(days);
    }
  }, [data.deliveryDate, data.resolvedAt]);

  return { numberOfDays };
};

export const useGrandTotal = (data: Invoice) => {
  const [grandTotal, setGrandTotal] = useState<number>(0);

  useEffect(() => {
    let total = 0;
    if (!!data.itemsList && !!data.items) {
      total = data.itemsList.reduce((a, b) => a + (b.total || 0), 0);
    }
    if (data.foodTotal) total += data.foodTotal;
    setGrandTotal(total);
  }, [data.foodTotal, data.items, data.itemsList]);

  return { grandTotal };
};
