import { Button, Collapse, Divider, Icon, Tooltip } from "@blueprintjs/core";
import { Ref, forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { UserListRef } from "redux/types";
import { User } from "redux/users/types";
import { getPriorityGroupColor, getUserPriorityGroups } from "utils/common";

type Props = {
  username: string;
  checkingPriorityList: User[];
  onButtonUserClick: (
    userIdToGo: string,
    isInvoiceVotingEnabled: boolean,
    state: string
  ) => void;
};

const CheckingPriorityList = forwardRef(
  (
    { onButtonUserClick, username, checkingPriorityList }: Props,
    ref: Ref<UserListRef>
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const authClaims = useAppSelector((state) => state.auth.authClaims);
    const isForSupervisorFilter = useAppSelector(
      (state) => state.invoices.isForSupervisorFilter
    );
    const invoiceLoading = useAppSelector((state) => state.invoices.loading);
    const loadingInvoiceVote = useAppSelector(
      (state) => state.invoices.loadingVotes
    );
    const loadingInvoice = invoiceLoading || loadingInvoiceVote;

    useImperativeHandle(ref, () => {
      return {
        handleOpenList: () => setIsOpen(true),
        handleCloseList: () => setIsOpen(false),
      };
    });

    const totalUncheckedInv = useMemo(() => {
      if (!checkingPriorityList.length) return 0;

      if (isForSupervisorFilter) {
        const totalUncheckedCheckingPriorityList = checkingPriorityList
          .map((user) => user.invoicesCount?.uncheckedForSupervisor ?? 0)
          .reduce((a, b) => a + b);
        return totalUncheckedCheckingPriorityList;
      }
      const totalUnchecked = checkingPriorityList
        .map((user) => user.invoicesCount?.unchecked ?? 0)
        .reduce((a, b) => a + b);
      return totalUnchecked;
    }, [checkingPriorityList, isForSupervisorFilter]);

    const checkingPriorityUserWithVotingEnabled = useMemo(() => {
      const count = checkingPriorityList.filter(
        (user) => user.isInvoiceVotingEnabled
      ).length;
      const percentage = count
        ? ((count / checkingPriorityList.length) * 100).toFixed(2)
        : "0";
      return { count, percentage };
    }, [checkingPriorityList]);

    const priorityGroups = useMemo(() => {
      const list = checkingPriorityList.filter((x) => {
        if (isForSupervisorFilter)
          return x.invoicesCount.uncheckedForSupervisor > 0;
        return true;
      });
      return getUserPriorityGroups(list);
    }, [checkingPriorityList, isForSupervisorFilter]);

    return (
      <>
        <div className="d-flex flex-row align-items-center mb-3">
          <Tooltip
            compact
            content={`active: true, is not (demo / access level: 0), has 'invoiceCount.unchecked'`}
          >
            <div
              className="d-flex flex-row align-items-center"
              onClick={() => setIsOpen(!isOpen)}
              style={{ cursor: "pointer", userSelect: "none" }}
            >
              <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
              <p className="all_users_drop_down">
                Checking Priority List (Users: {checkingPriorityList.length})
                [Voting enabled: {checkingPriorityUserWithVotingEnabled.count} (
                {checkingPriorityUserWithVotingEnabled.percentage}%)]
                [Unchecked: {totalUncheckedInv}]
              </p>
            </div>
          </Tooltip>
          <Divider className="w-100" />
        </div>
        <Collapse isOpen={isOpen} className="mb-3">
          <div
            className="d-flex mb-2 mt-3"
            style={{ flexDirection: "column", gap: "0.5rem" }}
          >
            {Object.keys(priorityGroups)
              .sort((a, b) => b.localeCompare(a))
              .map((priority) => {
                if (!priorityGroups[priority].length) return null;
                return (
                  <div
                    key={`priority-list-${priority}`}
                    style={{ display: "flex", gap: 20, marginBottom: 20 }}
                  >
                    <div style={{ display: "flex", gap: 5 }}>
                      <h5 style={{ margin: 0 }}>{priority}</h5>
                      <div
                        style={{
                          backgroundColor: getPriorityGroupColor(
                            Number(priority)
                          ),
                          minWidth: "15px",
                        }}
                      />
                    </div>
                    <div>
                      {priorityGroups[priority].map((user, i) => {
                        return (
                          <Button
                            key={"invoice_button_" + i}
                            className="me-2 mb-2"
                            onClick={() =>
                              onButtonUserClick(
                                user.id,
                                user.isInvoiceVotingEnabled,
                                "resolved"
                              )
                            }
                            text={
                              <div>
                                {`${user.name} (${
                                  user.invoicesCount?.unchecked ?? 0
                                })`}
                                {user.checkingUsers?.length > 0 && (
                                  <>
                                    <span> </span>
                                    <span style={{ color: "#bb86fc" }}>
                                      {user.checkingUsers
                                        .map((u: User) => u.name)
                                        .join(", ")}
                                    </span>
                                  </>
                                )}
                                {user.isExcludedFromPriorityListAtInvoices && (
                                  <Tooltip
                                    content="Excluded from priority list"
                                    position="bottom"
                                  >
                                    <Icon
                                      icon="pause"
                                      style={{
                                        margin: "0 0 0 5px",
                                        color: "#ffc33d",
                                      }}
                                    />
                                  </Tooltip>
                                )}
                                {user.isNewAtInvoices && (
                                  <Tooltip content="New user" position="bottom">
                                    <Icon
                                      icon="new-person"
                                      style={{
                                        margin: "0 0 0 5px",
                                        color: "#ffc33d",
                                      }}
                                    />
                                  </Tooltip>
                                )}
                                {user.isInvoiceVotingEnabled &&
                                  (authClaims?.supervisor ||
                                    authClaims?.admin ||
                                    authClaims?.headDataManager) && (
                                    <Tooltip
                                      content="Voting enabled"
                                      position="bottom"
                                    >
                                      <Icon
                                        icon="take-action"
                                        style={{
                                          margin: "0 0 0 5px",
                                          color: "#3dd46f",
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                              </div>
                            }
                            outlined={user.name === username}
                            active={user.name === username}
                            loading={loadingInvoice && user.name === username}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </Collapse>
      </>
    );
  }
);

export default CheckingPriorityList;
