import { Icon, Tooltip } from "@blueprintjs/core";
import { useAppSelector } from "redux/hooks";
import styles from "./index.module.scss";

const LoadActiveUsersIndicator = () => {
  const loadAllUsers = useAppSelector((state) => state.settings.loadAllUsers);

  if (loadAllUsers) return null;
  return (
    <div className={styles.indicator}>
      <Tooltip compact content="Only showing active users.">
        <Icon icon="info-sign" style={{ color: "#FFC23D" }} />
      </Tooltip>
      <span className={styles.indicator__text}>Only showing active users.</span>
    </div>
  );
};

export default LoadActiveUsersIndicator;
