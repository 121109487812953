import { Button, Classes, H3, InputGroup, Popover } from "@blueprintjs/core";
import ActionConfirmationDialogue from "components/action-confirmation-dialogue";
import { startCase } from "lodash";
import React, { useState } from "react";

type DoubleConfirmationProps = {
  hasDoubleConfirmation: true;
  onConfirm: () => void;
  title: string;
  confirmationText: string;
  children: React.ReactNode;
  secondConfirmationTitle: string;
  secondConfirmationDescription: string;
};

type SingleConfirmationProps = {
  hasDoubleConfirmation: false;
  onConfirm: () => void;
  title: string;
  confirmationText?: string;
  children: React.ReactNode;
  secondConfirmationTitle?: string;
  secondConfirmationDescription?: string;
};

type Props = DoubleConfirmationProps | SingleConfirmationProps;

const InputActionConfirmationDialogue = ({
  hasDoubleConfirmation,
  onConfirm,
  title,
  confirmationText = "CONFIRM",
  children,
  secondConfirmationTitle,
  secondConfirmationDescription,
}: Props) => {
  const [confirmText, setConfirmText] = useState("");

  return (
    <Popover
      onClose={() => setConfirmText("")}
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      content={
        <div>
          <H3>{title}</H3>
          <p>{`Type "${confirmationText}" to confirm.`}</p>
          <InputGroup
            placeholder={confirmationText}
            value={confirmText}
            onValueChange={(value) => setConfirmText(value)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              marginTop: 20,
            }}
          >
            <Button
              className={Classes.POPOVER_DISMISS}
              onClick={() => setConfirmText("")}
            >
              Cancel
            </Button>
            {hasDoubleConfirmation ? (
              <ActionConfirmationDialogue
                onConfirm={() => {
                  onConfirm();
                  setConfirmText("");
                }}
                onCancel={() => setConfirmText("")}
                text={secondConfirmationDescription ?? ""}
                title={secondConfirmationTitle ?? ""}
                actionName={startCase(confirmationText.toLowerCase())}
                disabled={confirmText !== confirmationText}
              >
                <Button
                  intent="danger"
                  disabled={confirmText !== confirmationText}
                >
                  Confirm
                </Button>
              </ActionConfirmationDialogue>
            ) : (
              <Button
                className={Classes.POPOVER_DISMISS}
                intent="danger"
                disabled={confirmText !== confirmationText}
                onClick={() => {
                  onConfirm();
                  setConfirmText("");
                }}
                onKeyDown={() => {
                  onConfirm();
                  setConfirmText("");
                }}
              >
                Confirm
              </Button>
            )}
          </div>
        </div>
      }
      placement="left"
    >
      {children}
    </Popover>
  );
};

export default InputActionConfirmationDialogue;
