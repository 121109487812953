import { createActions } from "redux/create-actions";
import {
  BATCH_UPDATE_USER_ITEMS_payload,
  QUERY_ITEMS_WITH_MULTI_SEARCH_Payload,
  UPDATE_GLOBAL_ITEM_Payload,
  UPDATE_USER_ITEM_Payload,
} from "./sagas";
import { ItemsState } from "./types";

const actions = createActions("items", {
  SET_STATE: {} as Partial<ItemsState>,
  QUERY_ITEMS_WITH_MULTI_SEARCH: {} as QUERY_ITEMS_WITH_MULTI_SEARCH_Payload,
  UPDATE_USER_ITEM: {} as UPDATE_USER_ITEM_Payload,
  UPDATE_GLOBAL_ITEM: {} as UPDATE_GLOBAL_ITEM_Payload,
  BATCH_UPDATE_USER_ITEMS: {} as BATCH_UPDATE_USER_ITEMS_payload,
});

export default actions;
