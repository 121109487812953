import {
  DocumentData,
  QuerySnapshot,
  addDoc,
  collection,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { UserRecipe, UserRecipeUpdate } from "redux/pos-item-tasks/types";
import { checkCounter } from "services/counter";
import { db } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export function setUserMaterialBookkeepingItemsListener(
  userId: string
): EventChannel<DocumentData> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const unsub = onSnapshot(
      collection(db, "users", userId, `materialBookkeepingItems`),
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data = snapshot.docs.map(
          (doc) => new UserRecipe(doc.id, doc.data())
        );
        emitter(data);
      }
    );

    return () => unsub();
  });
}

export function addMaterialBookkeepingItem(
  userId: string,
  newItem: UserRecipeUpdate
) {
  if (!db) return () => console.log("No DB connection");
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const collectionRef = collection(
    db,
    "users",
    userId,
    `materialBookkeepingItems`
  );

  return addDoc(collectionRef, { ...newItem })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export function updateMaterialBookkeepingItem(
  userId: string,
  newItem: Partial<UserRecipe>
) {
  if (!db) return () => console.log("No DB connection");
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  if (!userId || !newItem.id) return { data: null, error: "Invalid data" };

  const { id: itemId, ...data } = newItem;

  const itemRef = doc(db, "users", userId, `materialBookkeepingItems`, itemId);

  return updateDoc(itemRef, { ...data })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}
