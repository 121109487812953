import { createReducer } from "@reduxjs/toolkit";
import actions from "redux/docs/actions";
import { DocsState } from "redux/docs/types";

export const initialState: DocsState = {
  docs: [],
  adminDocs: [],
  hrmsDocs: [],
  loadingUpdate: false,
  loadingAdd: false,
  loading: false,
  loadingComment: false,
  loadingEditComment: false,
  activeDoc: null,
  loadingDoc: false,
  openFolders: [],
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
