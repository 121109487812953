import ItemCreate from "components/invoices/item-create";
import Fuse from "fuse.js";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  JSX,
} from "react";
import Draggable, { DraggableEventHandler } from "react-draggable";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Items } from "redux/invoice/types";

import {
  AnchorButton,
  Button,
  Card,
  Classes,
  Dialog,
  MenuItem,
  Popover,
  Switch,
} from "@blueprintjs/core";
import {
  ItemListPredicate,
  ItemRenderer,
  ItemRendererProps,
  MultiSelect,
  Suggest,
} from "@blueprintjs/select";
import invoiceActions from "redux/invoice/actions";
import { getUserCurrency } from "redux/users/helpers";
import { useDetectOutsideClick } from "./hooks";
import module from "./index.module.scss";

type Props = {
  onItemSelect: (
    selectedInvoiceItem: Items,
    searchQuery: string,
    isNew?: boolean
  ) => void;
  initialQuery: { value: string };
  feesInitialQuery?: string;
  isOpenLine: boolean;
  openItemCreate: boolean;
  setOpenItemCreate: (value: boolean) => void;
  fixed?: boolean;
  userItemsAndFees: Items[];
  setFeesInitialQuery?: React.Dispatch<React.SetStateAction<string>>;
  isVoting?: boolean;
  setNotFoundItemQuery?: React.Dispatch<React.SetStateAction<string>>;
  targetUserId?: string;
  onCreateClick?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    query: string
  ) => void;
  setOcrEmptyItemQuery?: () => void;
  isAutoFocus?: boolean;
  onSearchClick?: () => void;
  queryTrigger?: string;
};

const ItemSearch = forwardRef(function ItemSearch(
  {
    onItemSelect,
    initialQuery,
    isOpenLine,
    openItemCreate,
    setOpenItemCreate,
    fixed,
    userItemsAndFees,
    isVoting,
    setNotFoundItemQuery,
    targetUserId,
    onCreateClick,
    setOcrEmptyItemQuery,
    isAutoFocus = true,
    onSearchClick,
    queryTrigger,
  }: Props,
  ref: React.Ref<HTMLInputElement>
) {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState("");
  const [filteredUserItemsAndFees, setFilteredUserItemsAndFees] =
    useState<Items[]>(userItemsAndFees);
  const [includedItems, setIncludedItems] = useState<string[]>([]);
  const [excludedItems, setExcludedItems] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState<string[]>([]);

  const itemCreateWidth = useAppSelector(
    (state) => state.invoices.itemCreateWidth
  );
  const userId = useAppSelector((state) => state.invoices.userId);
  const isStrictSearchEnabled = useAppSelector(
    (state) => state.invoices.isStrictSearchEnabled
  );

  const processedUserItemsAndFees = useMemo(() => {
    return userItemsAndFees.filter((item) => {
      const isIncluded =
        includedItems.length > 0 ? includedItems.includes(item.type) : true;
      const isExcluded =
        excludedItems.length > 0 ? !excludedItems.includes(item.type) : true;
      return isIncluded && isExcluded;
    });
  }, [userItemsAndFees, includedItems, excludedItems]);

  useEffect(() => {
    setFilteredUserItemsAndFees([...processedUserItemsAndFees]);
  }, [processedUserItemsAndFees]);

  const currency = useMemo(() => getUserCurrency(userId), [userId]);
  const itemTypes = useMemo(
    () =>
      Array.from(
        new Set(processedUserItemsAndFees.map((item: Items) => item.type))
      ),
    [processedUserItemsAndFees]
  );

  const itemsPopoverRef = useRef<HTMLInputElement>(null);
  const { isItemsPopoverOpen, setItemsPopoverOpen } =
    useDetectOutsideClick(itemsPopoverRef);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const handleStop: DraggableEventHandler = (_, dragElement) => {
    setX(dragElement.x);
    setY(dragElement.y);
  };

  // Handle query from open item (bulk & normal)
  useEffect(() => {
    if (isOpenLine) return;
    if (initialQuery.value !== "") {
      setQuery(initialQuery.value);
      if (setOcrEmptyItemQuery) {
        setOcrEmptyItemQuery();
      }
    }
  }, [initialQuery.value, queryTrigger]);

  // Handle query from normal item search
  useEffect(() => {
    if (!isOpenLine) return;
    setQuery(initialQuery.value);
    if (
      filterItems(initialQuery.value, filteredUserItemsAndFees).length === 0 &&
      setNotFoundItemQuery
    ) {
      setNotFoundItemQuery((prev) => prev || initialQuery.value); // set not found item query when prev value is empty
    }
  }, [initialQuery, setNotFoundItemQuery]);

  function textFormatter(item: Items): JSX.Element {
    return (
      <div>
        {item.isFee ? (
          <>
            {item.name} <span className={Classes.TEXT_MUTED}>{item.type}</span>
          </>
        ) : (
          <>
            {item.name}, {item.size} {item.unit}[
            {Number(item.cost ?? 0).toFixed(2)}
            {currency}]{" "}
            <span className={Classes.TEXT_MUTED}>
              {item.type} - {item.variety}
            </span>
          </>
        )}
        {item.aliases && (
          <div className={Classes.TEXT_MUTED}>{item.aliases.join(" | ")}</div>
        )}
      </div>
    );
  }

  const fuse = useMemo(() => {
    return new Fuse<Items>(filteredUserItemsAndFees, {
      includeMatches: true,
      keys: [
        { name: "name", weight: 5 },
        "size",
        "type",
        "variety",
        "tags",
        { name: "aliases", weight: 2.5 },
      ],
      ignoreLocation: true,
      useExtendedSearch: true,
    });
  }, [filteredUserItemsAndFees]);

  const filterItems: ItemListPredicate<Items> = useCallback(
    (query) => {
      if (!query) return filteredUserItemsAndFees;
      if (isStrictSearchEnabled) {
        const results = filteredUserItemsAndFees.filter(function (item) {
          return (
            item.name?.toLowerCase().includes(query.toLowerCase()) ||
            item.type?.toLowerCase().includes(query.toLowerCase()) ||
            item.variety?.toLowerCase().includes(query.toLowerCase()) ||
            item.aliases?.some((alias) =>
              alias?.toLowerCase().includes(query.toLowerCase())
            )
          );
        });
        return results;
      }
      const results = fuse.search(query);
      return results.map((i) => i.item);
    },
    [filteredUserItemsAndFees, fuse, isStrictSearchEnabled]
  );

  // add this to lose focus on button click and then open modal
  function onCreateItemButtonClick(
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) {
    // maybe this (250) should be size of dialog window
    if (event.clientY > 250) {
      setY(-event.clientY);
    }
    event.currentTarget.blur();
    setOpenItemCreate(!openItemCreate);
  }

  function onItemCreated(item: Items) {
    setOpenItemCreate(false);
    onItemSelect(item, query, true);
    setQuery("");
  }

  function resetFilters() {
    setIncludedItems([]);
    setExcludedItems([]);
    setSelectedType([]);
    setFilteredUserItemsAndFees([...processedUserItemsAndFees]);
  }

  const ItemTypeFuse = useMemo(() => {
    return new Fuse(itemTypes, {
      includeMatches: true,
      ignoreLocation: true,
      useExtendedSearch: true,
    });
  }, [itemTypes]);

  const filterItemsType: ItemListPredicate<string> = useCallback(
    (query) => {
      if (query === "") return itemTypes;
      const result = ItemTypeFuse.search(query);
      return result.map((i) => i.item);
    },
    [itemTypes, ItemTypeFuse]
  );

  function onRemoveIncludedItems(item: string) {
    const newData = includedItems.filter((o) => {
      return o !== item;
    });
    const newSelected = selectedType.filter((o) => {
      return o !== item;
    });
    setIncludedItems(newData);
    setSelectedType(newSelected);
  }

  function onRemoveExcludedItems(item: string) {
    const newData = excludedItems.filter((o) => {
      return o !== item;
    });
    const newSelected = selectedType.filter((o) => {
      return o !== item;
    });
    setExcludedItems(newData);
    setSelectedType(newSelected);
  }
  // TO-DO: BIND A KEY TO WORK THE SAME WAY AS THE ENTER KEY
  // function handleKeyDown(event?: React.KeyboardEvent<HTMLElement>) {
  //   if (event?.code === "Space") {
  //     event?.preventDefault();
  //     const items: any[] = [];
  //     const filteredItems = filterItems(query, items);
  //     const item = filteredItems[0];
  //     setQuery(item.name);
  //     item.isNew = false;
  //     onItemSelect(item);
  //   }
  // }

  const renderItemTypes: ItemRenderer<string> = (
    item: string,
    { modifiers, handleClick }: ItemRendererProps
  ) => {
    if (!item) return null;
    return (
      <MenuItem
        active={modifiers.active}
        disabled={selectedType.findIndex((e: string) => e === item) >= 0}
        key={item}
        onClick={handleClick}
        roleStructure="listoption"
        text={item}
      />
    );
  };
  const renderItem: ItemRenderer<Items> = (
    item: Items,
    { modifiers, handleClick }: ItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        key={`${item.id}-${item.name}`}
        multiline
        onClick={handleClick}
        roleStructure="listoption"
        text={textFormatter(item)}
      />
    );
  };

  const itemFilters = () => (
    <>
      <div className={module.include__exclude__wrapper}>
        <div
          style={{
            display: "flex",
            flexDirection: `${isVoting ? "column" : "row"}`,
            gap: "8px",
            alignItems: `${isVoting ? "" : "flex-end"}`,
          }}
        >
          <div style={{ flexBasis: "100%" }}>
            <p>Included</p>
            <MultiSelect
              fill
              placeholder="Included"
              items={itemTypes}
              itemRenderer={renderItemTypes}
              onItemSelect={(item: string) => {
                setSelectedType([...selectedType, item]);
                setIncludedItems([...includedItems, item]);
              }}
              tagRenderer={(item) => item}
              selectedItems={includedItems}
              onRemove={onRemoveIncludedItems}
              itemListPredicate={filterItemsType}
              resetOnQuery={true}
              resetOnSelect={true}
            />
          </div>
          <div style={{ flexBasis: "100%" }}>
            <p>Excluded</p>
            <MultiSelect
              fill
              placeholder="Excluded"
              items={itemTypes}
              itemRenderer={renderItemTypes}
              onItemSelect={(item: string) => {
                setSelectedType([...selectedType, item]);
                setExcludedItems([...excludedItems, item]);
              }}
              tagRenderer={(item) => item}
              selectedItems={excludedItems}
              onRemove={onRemoveExcludedItems}
              itemListPredicate={filterItemsType}
              resetOnQuery={true}
              resetOnSelect={true}
            />
          </div>
          <div className="d-flex">
            <AnchorButton
              onClick={() => resetFilters()}
              icon="reset"
              minimal={true}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <Card
      elevation={2}
      style={{
        position: fixed ? "fixed" : "relative",
        background: isVoting && !isOpenLine ? "#212121" : "",
      }}
      className={`open-line-card ${fixed ? module.fixed : ""}`}
    >
      {isOpenLine && itemFilters()}
      <div className="d-flex">
        <div onClick={(e) => e.stopPropagation()}>
          <Popover
            content={
              <div style={{ padding: 20 }}>
                <Switch
                  checked={isStrictSearchEnabled}
                  label="Strict Search"
                  onChange={(e) => {
                    dispatch(
                      invoiceActions.SET_STATE({
                        isStrictSearchEnabled: e.currentTarget.checked,
                      })
                    );
                  }}
                  className="mb-1 strict-search-switch"
                />
              </div>
            }
          >
            <Button icon="more" minimal small style={{ marginTop: "3px" }} />
          </Popover>
        </div>
        <div
          style={{ width: "100%" }}
          onClick={(e) => {
            onSearchClick && e.stopPropagation();
            onSearchClick && onSearchClick();
          }}
        >
          <Suggest
            fill
            inputProps={{
              inputRef: ref,
              placeholder: "Search for items or fees...",
              title: "items-search-suggest",
              onFocus: () => setItemsPopoverOpen(true), // open popover on focus in
              onKeyDown: (e) => {
                if (e.key === "Escape") setItemsPopoverOpen(false); // close popover on escape key
              },
              onBlur:
                !isOpenLine && setOcrEmptyItemQuery
                  ? () => setOcrEmptyItemQuery() // on focus out reset item focus state
                  : undefined, // close popover on blur
              // TO-DO: BIND A KEY TO WORK THE SAME WAY AS THE ENTER KEY
              // onKeyDown: handleKeyDown,
            }}
            inputValueRenderer={() => query}
            itemListPredicate={filterItems}
            itemRenderer={renderItem}
            items={userItemsAndFees}
            menuProps={{
              className: isVoting
                ? module.popover_content_menu_voting
                : module.popover_content_menu,
            }}
            noResults={<MenuItem disabled={true} text="No results." />}
            onItemSelect={(item) => {
              setQuery(""); // reset query after item selected
              // DISABLED: Redux store objects are immutable
              // item.isNew = false;
              onItemSelect(item, query);
              setItemsPopoverOpen(false);
            }}
            onQueryChange={(q, event) => {
              if (event === undefined && !isAutoFocus) return;

              setQuery(q);
              if (q) setItemsPopoverOpen(true);
              else setItemsPopoverOpen(false);
            }}
            popoverProps={{
              matchTargetWidth: true,
              minimal: true,
              isOpen: isItemsPopoverOpen,
              popoverRef: itemsPopoverRef,
            }}
            query={query}
            resetOnQuery={true}
            resetOnSelect={true} // resets input box when item selected, still shows latest selected item
            scrollToActiveItem
          />
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <Button
            icon={"add"}
            minimal
            small
            style={{ marginTop: "3px" }}
            onClick={
              onCreateClick
                ? (event) => onCreateClick(event, query)
                : onCreateItemButtonClick
            }
          />
        </div>
      </div>
      <div>
        <Draggable position={{ x: x, y: y }} onStop={handleStop}>
          <div
            className="m-2 over-top"
            style={{
              width: itemCreateWidth > 0 ? itemCreateWidth : 400,
              zIndex: 10,
            }}
          >
            <Dialog
              isOpen={openItemCreate}
              onClose={() => setOpenItemCreate(!openItemCreate)}
              autoFocus
              enforceFocus
              usePortal={false}
              className="pb-0"
              title="Create Item"
              icon="add"
            >
              <ItemCreate
                name={query}
                initialQuery={initialQuery.value}
                onFinish={(item) => onItemCreated(item)}
                targetUserId={targetUserId}
                isFromInvoice
              />
            </Dialog>
          </div>
        </Draggable>
      </div>
    </Card>
  );
});

export default ItemSearch;
