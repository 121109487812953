import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import _, { startCase } from "lodash";
import { Field, LookerConfig, LookerEmbed, Module } from "redux/settings/types";
import { ServiceReturn } from "redux/types";
import { User } from "redux/users/types";
import { checkCounter } from "services/counter";
import { db } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export async function getSettings(): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, "adminPanelSettings", "settings");

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const querySnapshot = await getDoc(docRef);

    if (querySnapshot.exists()) {
      const data = querySnapshot.data();
      return { data: data, error: null };
    } else {
      return { data: null, error: "Error when fetching settings." };
    }
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updateSettings(
  data: DocumentData
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, "adminPanelSettings", "settings");

  if (!checkCounter) {
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  }

  return updateDoc(docRef, data)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function geDefaultUserProfile(): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, "defaults", "--profile");
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const querySnapshot = await getDoc(docRef);

    if (querySnapshot.exists()) {
      const data = querySnapshot.data();
      return { data: data, error: null };
    } else {
      return { data: null, error: "Error when fetching default user profile." };
    }
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getScrapersConfig(): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, "scrapersConfig", "--config");
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const querySnapshot = await getDoc(docRef);

    if (querySnapshot.exists()) {
      const data = querySnapshot.data();
      return { data: data, error: null };
    } else {
      return { data: null, error: "Error when fetching scrapers config." };
    }
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updateScrapersConfig(data: {
  frequency: number;
}): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, "scrapersConfig", "--config");

  if (!checkCounter) {
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  }

  return updateDoc(docRef, data)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function getAllFields(): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = collection(db, "docs-admin");

  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const querySnapshot = await getDocs(docRef);

    if (querySnapshot.empty) {
      console.log("empty");
      return { data: null, error: "empty" };
    } else {
      const modules: Module[] = [];
      const fields: Field = {};

      querySnapshot.docs.forEach((doc) => {
        const key = doc.id;
        modules.push({
          text: startCase(key),
          code: key,
        });
        const data = doc.data();

        fields[key] = Object.keys(data.keys);
      });
      return { data: { modules, fields }, error: null };
    }
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getLookerConfig(): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, "lookerConfig", "--config");
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const querySnapshot = await getDoc(docRef);

    if (querySnapshot.exists()) {
      const data = querySnapshot.data();
      return { data: data, error: null };
    } else {
      return { data: null, error: "Error when fetching looker config." };
    }
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updateLookerConfig(
  newLookerConfig: LookerConfig
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, "lookerConfig", "--config");

  if (!checkCounter) {
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  }

  return updateDoc(docRef, newLookerConfig)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function getLookerEmbeds(): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const collRef = collection(db, "lookerEmbed");
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const querySnapshot = await getDocs(collRef);

    if (querySnapshot.empty) {
      console.log("empty");
      return { data: null, error: "empty" };
    } else {
      const lookerEmbeds: DocumentData[] = [];

      querySnapshot.docs.forEach((doc) => {
        lookerEmbeds.push({
          id: doc.id,
          ref: doc.ref,
          ...doc.data(),
        });
      });
      return { data: lookerEmbeds, error: null };
    }
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updateDefaultUserProfile(
  newDefaultUserProfile: Partial<User>
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, "defaults", "--profile");

  if (!checkCounter) {
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  }

  return updateDoc(docRef, newDefaultUserProfile)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateLookerEmbeds(
  newLookerEmbeds: LookerEmbed[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter) {
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  }

  try {
    const batch = writeBatch(db);
    for (const embed of newLookerEmbeds) {
      batch.update(embed.ref, {
        ..._.omit(embed, ["ref", "id"]),
      });
    }

    await batch.commit();
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}
