import { Button } from "@blueprintjs/core";
import { cn } from "@stockifi/shared";
import { Timestamp } from "firebase/firestore";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import ReactJson from "react-json-view";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ScrapersData } from "redux/integration-monitor/types";
import { getUserName } from "redux/users/helpers";
import { downloadSalesData } from "../helpers";
import styles from "./index.module.scss";

type Props = {
  data: string | undefined;
  selectedScrapersData?: ScrapersData | null;
  userId: string;
  period: (Timestamp | undefined)[];
};

const DataDisplay = ({ data, selectedScrapersData, userId, period }: Props) => {
  const [colDepth, setCollapseDepth] = useState(0);
  const parsedData: string[][] = useMemo(() => {
    if (data) return JSON.parse(data);
    return [];
  }, [data]);

  if (!parsedData || isEmpty(parsedData) || !Array.isArray(parsedData))
    return <div>Invalid Data Format</div>;

  const renderTableAndCalculateSummary = () => {
    const summary = parsedData.reduce(
      (acc, row, index) => {
        if (index === 0) return acc; // Skip the header row

        const [, , netto, group, brutto, discount] = row;
        const groupSummary = acc[group] || { brutto: 0, netto: 0, discount: 0 };

        groupSummary.brutto += parseFloat(brutto) || 0;
        groupSummary.netto += parseFloat(netto) || 0;
        groupSummary.discount += parseFloat(discount) || 0;

        return { ...acc, [group]: groupSummary };
      },
      {} as Record<string, { brutto: number; netto: number; discount: number }>
    );

    const grandTotal = Object.values(summary).reduce(
      (total, groupSummary) => {
        total.brutto += groupSummary.brutto;
        total.netto += groupSummary.netto;
        total.discount += groupSummary.discount;
        return total;
      },
      { brutto: 0, netto: 0, discount: 0 }
    );

    return (
      <>
        <div className="row">
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <Button
              icon="download"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!period[0] || !period[1]) return;
                downloadSalesData(parsedData, getUserName(userId), [
                  period[0]?.toDate().getTime(),
                  period[1]?.toDate().getTime(),
                ]);
              }}
              text={`Download Sales Data`}
            />
          </div>
          {selectedScrapersData && (
            <div className="col" style={{ width: "100%" }}>
              <div className="row">
                <div className="col">
                  <h3> Scraped Data </h3>
                </div>
                <div
                  className="col d-flex"
                  style={{ justifyContent: "flex-end" }}
                >
                  <Button
                    minimal
                    icon="plus"
                    text="Expand All"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => setCollapseDepth(4)}
                  />
                  <Button
                    minimal
                    icon="minus"
                    text="Contract All"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => setCollapseDepth(1)}
                  />
                </div>
              </div>
              <div className="mb-2">
                <PerfectScrollbar
                  className="m-1"
                  style={{ backgroundColor: "rgb(30, 30, 30)" }}
                >
                  <div>
                    <ReactJson
                      style={{ wordBreak: "break-all" }}
                      theme={"twilight"}
                      sortKeys={true}
                      src={selectedScrapersData.data ?? []}
                      collapsed={colDepth}
                      groupArraysAfterLength={0}
                    />
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          )}
          {/* Summary Table */}
          <div
            className={cn(`${styles.alignDivEnd}`, "col")}
            style={{ margin: "0 0 30px 0" }}
          >
            <table className={`${styles.table}`}>
              <tbody>
                {parsedData.length > 0 && (
                  <>
                    <tr key={"Titles"}>
                      <th className={styles.th}>Group</th>
                      <th className={styles.th}>Netto</th>
                      <th className={styles.th}>Brutto</th>
                      <th className={styles.th}>Discount</th>
                    </tr>
                    {Object.entries(summary)
                      .sort((a, b) => a[0].localeCompare(b[0]))
                      .map(([group, groupSummary]) => (
                        <tr key={group}>
                          <td className={styles.td}>{group}</td>
                          <td className={styles.td}>
                            {groupSummary.netto.toFixed(2)}
                          </td>
                          <td className={styles.td}>
                            {groupSummary.brutto.toFixed(2)}
                          </td>
                          <td className={styles.td}>
                            {groupSummary.discount.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td className={styles.td}>Grand Total</td>
                      <td className={styles.td}>
                        {grandTotal.netto.toFixed(2)}
                      </td>
                      <td className={styles.td}>
                        {grandTotal.brutto.toFixed(2)}
                      </td>
                      <td className={styles.td}>
                        {grandTotal.discount.toFixed(2)}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Original Table */}
        <div className={cn(`${styles.alignDivEnd}`)}>
          <table className={styles.table}>
            <tbody>
              {parsedData.map((row, index) => {
                if (index === 0) {
                  return (
                    <tr key={index}>
                      {row.map((header, headerIndex) => {
                        return (
                          <th key={headerIndex} className={styles.th}>
                            {header}
                          </th>
                        );
                      })}
                    </tr>
                  );
                } else {
                  return (
                    <tr key={index}>
                      {/* Add key prop */}
                      {row.map((cell, cellIndex) => {
                        return (
                          <td key={cellIndex} className={styles.td}>
                            {cell}
                          </td>
                        );
                      })}
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  return <>{renderTableAndCalculateSummary()}</>;
};

export default DataDisplay;
