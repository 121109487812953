import {
  DocumentData,
  QuerySnapshot,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { Notification } from "redux/notification-centre/types";
import { db } from "services/firebase";

export function setNotificationsListener(
  userId: string
): EventChannel<Notification[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");
    const collRef = collection(db, "adminPanelNotifications");
    const notifQuery = query(collRef, where("data.userId", "==", userId));
    const unsub = onSnapshot(
      notifQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: Notification[] = snapshot.docs
          .map((x) => new Notification(x.id, x.data()))
          .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

        emitter(data);
      }
    );

    return () => unsub();
  });
}

export function setNavbarNotificationsListener(
  maxNotification = 5
): EventChannel<Notification[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");
    const collRef = collection(db, "adminPanelNotifications");
    const notifQuery = query(
      collRef,
      where("status", "==", "unread"),
      orderBy("createdAt", "desc"),
      limit(maxNotification)
    );
    const unsub = onSnapshot(
      notifQuery,
      (snapshot: QuerySnapshot<DocumentData>) => {
        const data: Notification[] = snapshot.docs.map(
          (x) => new Notification(x.id, x.data())
        );

        emitter(data);
      }
    );

    return () => unsub();
  });
}
