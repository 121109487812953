import { Icon, OverlayToaster, Tooltip } from "@blueprintjs/core";
import { useRef } from "react";

interface CopyIconProps {
  text: string | number;
}

export default function CopyIcon({ text }: CopyIconProps) {
  const toaster = useRef<OverlayToaster>(null);

  const handleCopy = () => {
    navigator.clipboard.writeText(text.toString());

    toaster.current?.show({
      message: `Copied "${text}" to clipboard`,
      intent: "success",
      icon: "tick",
    });
  };

  return (
    <>
      <Tooltip content={`Copy "${text}" to clipboard`}>
        <Icon
          icon="duplicate"
          style={{ cursor: "pointer" }}
          onClick={handleCopy}
        />
      </Tooltip>
      <OverlayToaster ref={toaster} position="top-right" />
    </>
  );
}
