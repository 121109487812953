import {
  DocumentData,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { isEqual } from "lodash";
import { Selection } from "redux/ocr/types";
import { ServiceReturn } from "redux/types";
import { checkCounter } from "services/counter";
import { db } from "services/firebase";
import { safeTimestamp } from "utils/common";
import { SERVER_COUNTS } from "utils/constants";

export async function saveOcrSelections(
  userId: string,
  invoiceId: string,
  data: Selection[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  // Get a new write batch
  const batch = writeBatch(db);

  data.forEach((d) => {
    const { ref, id, ...dataWithoutRef } = d;
    if (!db) return;

    if (d.ref) {
      if (ref) batch.update(ref, dataWithoutRef);
    } else {
      const newRef = doc(
        collection(
          db,
          "ocrSelections",
          userId,
          "selections",
          invoiceId,
          "ocrClicks"
        )
      );
      batch.set(newRef, dataWithoutRef);
    }
  });

  return await batch
    .commit()
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function getOcrSelection(
  userId: string,
  invoiceId: string,
  imageUrl: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  if (!userId) return { data: null, error: "No User ID." };
  if (!invoiceId) return { data: null, error: "No Invoice ID." };

  try {
    const data: DocumentData[] = [];
    const colRef = collection(
      db,
      "ocrSelections",
      userId,
      "selections",
      invoiceId,
      "ocrClicks"
    );
    const querySnapshot = await getDocs(
      query(colRef, where("imageUrl", "==", imageUrl), orderBy("timestamp"))
    );
    querySnapshot.forEach((doc) => {
      const docData = doc.data();
      data.push({
        ...docData,
        id: doc.id,
        ref: doc.ref,
        timestamp: safeTimestamp(docData.timestamp)?.toDate(),
      });
    });

    const filteredData = data.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.selectedItem === item.selectedItem &&
            t.selectedItemIndex === item.selectedItemIndex &&
            isEqual(t.texts, item.texts)
        )
    );

    return { data: filteredData, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}
