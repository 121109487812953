import {
  Button,
  Classes,
  Dialog,
  DialogBody,
  DialogFooter,
  FormGroup,
  H5,
  InputGroup,
  Intent,
  Popover,
  Tag,
} from "@blueprintjs/core";
import { cn } from "@stockifi/shared";
import React, { useState } from "react";
import alertActions from "redux/alert/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Invoice, InvoiceItem } from "redux/invoice/types";

type Props = {
  invoice: Invoice;
  item: InvoiceItem;
  newItemName: string;
  setNewItemName: React.Dispatch<React.SetStateAction<string>>;
  newAliases: {
    name: string;
    addedBy: string;
    isNew?: boolean;
    itemIndex: number;
  }[];
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSaveAliases: (
    newAliases: {
      name: string;
      addedBy: string;
      isNew?: boolean | undefined;
      itemIndex: number;
    }[]
  ) => void;
};

const ItemAddAlias = ({
  invoice,
  item,
  newItemName,
  setNewItemName,
  newAliases,
  isOpen,
  setIsOpen,
  handleSaveAliases,
}: Props) => {
  const dispatch = useAppDispatch();
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const userAuth = useAppSelector((state) => state.auth.user);
  const loading = useAppSelector((state) => state.invoices.loadingItemUpdate);
  const [aliasesToEdit, setAliasesToEdit] =
    useState<
      {
        name: string;
        addedBy: string;
        isNew?: boolean | undefined;
        itemIndex: number;
      }[]
    >(newAliases);
  const [newAlias, setNewAlias] = useState<string>("");

  function renderAddAliasPopover() {
    return (
      <div>
        <InputGroup
          placeholder="New Alias"
          value={newAlias}
          onChange={(e) => {
            setNewAlias(e.currentTarget.value);
          }}
        />
        <div className="button-display">
          <Button
            className={cn(Classes.POPOVER_DISMISS, "button-margin-right")}
          >
            Cancel
          </Button>
          <Button
            id="confirm-button"
            intent={Intent.DANGER}
            className={Classes.POPOVER_DISMISS}
            onClick={onConfirmAddAlias}
            onKeyDown={onConfirmAddAlias}
          >
            Add
          </Button>
        </div>
      </div>
    );
  }

  function onConfirmAddAlias() {
    if (newAlias !== "") {
      if (aliasesToEdit?.map((x) => x.name).includes(newAlias)) {
        dispatch(alertActions.ERROR("Alias already exists"));
        return { error: true };
      } else {
        const newAliasObject = {
          name: newAlias,
          addedBy: userAuth ?? "",
          itemIndex: item.index,
        };

        setAliasesToEdit((prev) => {
          const updatedAliases = Array.isArray(prev)
            ? [...prev, newAliasObject]
            : [newAliasObject];
          return updatedAliases;
        });

        setNewAlias("");
      }
    }
  }

  function isEditDisabled(alias: { name: string; addedBy: string }) {
    // Is admin or supervisor
    if (
      authClaims?.admin ||
      authClaims?.supervisor ||
      authClaims?.headDataManager
    )
      return false;

    // Is alias added by current user (in current invoice)
    if (
      invoice?.newAliases[item.id]?.find((x) => x.name === alias.name)
        ?.addedBy === userAuth
    )
      return false;

    // Is alias freshly added(not saved yet)
    if (!item.aliases?.includes(alias.name) && alias.addedBy === userAuth)
      return false;

    return true;
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => setIsOpen((prevState) => !prevState)}
      autoFocus
      enforceFocus
      icon="add"
      title="Add item alias"
    >
      <DialogBody>
        <div>
          <FormGroup label="Item name">
            <InputGroup
              style={{ textAlign: "left" }}
              id="name"
              name="name"
              value={item.isFee ? item.name.split(" - ")[0] : newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
              disabled={
                item.isFee ||
                !(
                  authClaims?.admin ||
                  authClaims?.supervisor ||
                  authClaims?.headDataManager
                )
              }
              tabIndex={1}
              autoComplete="off"
            />
          </FormGroup>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "15px",
            }}
          >
            <span>Aliases</span>
            <Popover
              popoverClassName={Classes.POPOVER_CONTENT_SIZING}
              content={renderAddAliasPopover()}
            >
              <Button icon="add" small text="Add Alias" />
            </Popover>
          </div>
          {aliasesToEdit &&
            aliasesToEdit
              // sort by isNew
              .sort((a, _) => (a.isNew ? -1 : 1))
              .map((alias, i) => (
                <div key={`alias_${i}`} className="d-flex align-items-center">
                  <InputGroup
                    id={`alias-${i}`}
                    name="alias"
                    value={alias.name}
                    tabIndex={1}
                    autoComplete="off"
                    onChange={(e) => {
                      const newAliasesCopy = [...aliasesToEdit];
                      newAliasesCopy[i].name = e.currentTarget.value;
                      setAliasesToEdit(newAliasesCopy);
                    }}
                    className="alias-input mb-0"
                    // disabled={isEditDisabled(alias)}
                    fill
                    leftElement={
                      alias.isNew ? (
                        <Tag minimal intent={Intent.PRIMARY}>
                          NEW
                        </Tag>
                      ) : undefined
                    }
                    rightElement={
                      !isEditDisabled(alias) ? (
                        <Popover
                          content={
                            <div>
                              <H5>
                                Are you sure you want to delete this alias?
                              </H5>
                              <div className="button-display">
                                <Button
                                  className={cn(
                                    Classes.POPOVER_DISMISS,
                                    "button-margin-right"
                                  )}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  id="confirm-button"
                                  intent={Intent.DANGER}
                                  className={Classes.POPOVER_DISMISS}
                                  onClick={() => {
                                    const newAliasesCopy = [
                                      ...aliasesToEdit,
                                    ].filter((x) => x.name !== alias.name);
                                    setAliasesToEdit(newAliasesCopy);
                                  }}
                                  onKeyDown={() => {
                                    const newAliasesCopy = [
                                      ...aliasesToEdit,
                                    ].filter((x) => x.name !== alias.name);
                                    setAliasesToEdit(newAliasesCopy);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          }
                          placement="right"
                          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                        >
                          <Button icon="cross" minimal />
                        </Popover>
                      ) : undefined
                    }
                  />
                </div>
              ))}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          className="w-100"
          disabled={!newItemName}
          loading={loading}
          onClick={() => {
            handleSaveAliases(aliasesToEdit.filter((x) => x.name));
          }}
          text="Save Changes"
        />
      </DialogFooter>
    </Dialog>
  );
};

export default ItemAddAlias;
