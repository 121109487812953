import { InputGroup } from "@blueprintjs/core";
import React from "react";
import { NumericFormat } from "react-number-format";

interface Props {
  successTimeout: number;
  errorTimeout: number;
  manualDismissTimeout: number;
  setSuccessTimeout: React.Dispatch<React.SetStateAction<number>>;
  setErrorTimeout: React.Dispatch<React.SetStateAction<number>>;
  setManualDismissTimeout: React.Dispatch<React.SetStateAction<number>>;
}

export default function AlertTimeout(props: Props) {
  const {
    successTimeout,
    errorTimeout,
    setSuccessTimeout,
    setErrorTimeout,
    manualDismissTimeout,
    setManualDismissTimeout,
  } = props;
  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="row">
        <div className="col">SUCCESS alert timeout (in ms):</div>
        <div className="col mb-2">
          <NumericFormat
            customInput={InputGroup}
            value={successTimeout}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSuccessTimeout(parseInt(e.currentTarget.value))
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col">ERROR alert timeout (in ms):</div>
        <div className="col mb-2">
          <NumericFormat
            customInput={InputGroup}
            value={errorTimeout}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setErrorTimeout(parseInt(e.currentTarget.value))
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col">MANUAL DISMISS alert timeout (in ms):</div>
        <div className="col mb-2">
          <NumericFormat
            customInput={InputGroup}
            value={manualDismissTimeout}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setManualDismissTimeout(parseInt(e.currentTarget.value))
            }
          />
        </div>
      </div>
    </div>
  );
}
