import _ from "lodash";
import { Filter } from "redux/config/types";

export const filtersSimilarityCheck = (a: Filter[], b: Filter[]) => {
  // Remove id from all filters to be compared
  const modifiedA = a.map(({ id, ...others }) => others).sort();
  const modifiedB = b.map(({ id, ...others }) => others).sort();
  return _.isEqual(modifiedA, modifiedB);
};

export const getExpression = (filters: Filter[]): string => {
  return filters
    .map((filter) => {
      const id = filter.id;
      const operand = filter.logical === "AND" ? "*" : "+";
      return `${operand}${id}`;
    })
    .join("")
    .replace(/^(\*|\+)/, "");
};

export const evaluateAlgebraicExpression = (expression: string) => {
  // split by )<any>*<any>(
  const [left, right] = expression.split(/\).*\*\.*\(/);

  // remove ( and ) from left and right
  const leftTerm = left.replace("(", "").replace(")", "");
  const rightTerm = right.replace("(", "").replace(")", "");

  // split left and right terms by + (trim spaces), use regex to remove all spaces
  const leftTerms = leftTerm.split("+").map((term) => term.replace(/\s/g, ""));
  const rightTerms = rightTerm
    .split("+")
    .map((term) => term.replace(/\s/g, ""));

  // evaluate left and right terms
  const resultArray = [];

  for (const leftChar of leftTerms) {
    for (const rightChar of rightTerms) {
      resultArray.push(`${leftChar}*${rightChar}`);
    }
  }

  return resultArray;
};
