import { all, call, put, select, takeLatest } from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { Items } from "redux/invoice/types";
import actions from "redux/items/actions";
import { RootState } from "redux/store";
import { ServiceReturn } from "redux/types";
import {
  batchUpdateUserItems,
  queryItemsWithMultiSearch,
  updateGlobalItem,
  updateUserItem,
} from "services/items";
import { COMMON } from "utils/constants";
import { UserItem } from "./types";

export interface QUERY_ITEMS_WITH_MULTI_SEARCH_Payload {
  query: string;
  perPage: number;
  page?: number;
  filters?: unknown[];
}

export function* QUERY_ITEMS_WITH_MULTI_SEARCH({
  payload,
}: ReturnType<typeof actions.QUERY_ITEMS_WITH_MULTI_SEARCH>) {
  const loadingState = payload.page ? "searchMoreLoading" : "itemLoading";
  yield put(
    actions.SET_STATE({
      [loadingState]: true,
    })
  );
  const currentItems: UserItem[] = yield select(
    (state: RootState) => state.items.items
  );

  const { data, error }: ServiceReturn = yield call(
    queryItemsWithMultiSearch,
    payload.query,
    payload.perPage,
    payload.page,
    payload.filters
  );

  if (data) {
    if (payload.page && payload.page > 1) {
      yield put(
        actions.SET_STATE({
          items: [...currentItems, ...data.data],
          queriedUserItemsCount: data.queriedUserItemsCount,
          [loadingState]: false,
        })
      );
    } else {
      yield put(
        actions.SET_STATE({
          items: data.data,
          queriedUserItemsCount: data.queriedUserItemsCount,
          [loadingState]: false,
        })
      );
    }
  } else if (error) {
    yield put(
      actions.SET_STATE({
        [loadingState]: false,
      })
    );
    yield put(alertActions.ERROR(error.message ?? COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_USER_ITEM_Payload {
  userId: string;
  itemId: string;

  newState: any;
}

export function* UPDATE_USER_ITEM({
  payload,
}: ReturnType<typeof actions.UPDATE_USER_ITEM>) {
  yield put(
    actions.SET_STATE({
      loadingSaving: true,
    })
  );
  const result: ServiceReturn = yield call(
    updateUserItem,
    payload.userId,
    payload.itemId,
    payload.newState
  );

  if (result.data) {
    yield put(actions.SET_STATE({ loadingSaving: false }));
    yield put(alertActions.SUCCESS("Item updated successfully"));
  } else {
    yield put(actions.SET_STATE({ loadingSaving: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface BATCH_UPDATE_USER_ITEMS_payload {
  itemsArray: Partial<Items>[];
  userId: string;
}
export function* BATCH_UPDATE_USER_ITEMS(
  input: ReturnType<typeof actions.BATCH_UPDATE_USER_ITEMS>
) {
  yield put(
    actions.SET_STATE({
      loadingSaving: true,
    })
  );
  const { itemsArray, userId } = input.payload;

  const result: ServiceReturn = yield call(
    batchUpdateUserItems,
    itemsArray,
    userId
  );

  if (result.data) {
    // set success alert
    yield put(alertActions.SUCCESS(`All items updated successfully`));
    yield put(
      actions.SET_STATE({
        loadingSaving: false,
      })
    );
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
    yield put(
      actions.SET_STATE({
        loadingSaving: false,
      })
    );
  }
}

export interface UPDATE_GLOBAL_ITEM_Payload {
  id: string;

  newState: any;
}

export function* UPDATE_GLOBAL_ITEM({
  payload,
}: ReturnType<typeof actions.UPDATE_GLOBAL_ITEM>) {
  yield put(
    actions.SET_STATE({
      loadingSaving: true,
    })
  );
  const result: ServiceReturn = yield call(
    updateGlobalItem,
    payload.id,
    payload.newState
  );

  if (result.data) {
    yield put(actions.SET_STATE({ loadingSaving: false }));
    yield put(alertActions.SUCCESS("Item updated successfully"));
  } else {
    yield put(actions.SET_STATE({ loadingSaving: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(
      actions.QUERY_ITEMS_WITH_MULTI_SEARCH,
      QUERY_ITEMS_WITH_MULTI_SEARCH
    ),
    takeLatest(actions.UPDATE_USER_ITEM, UPDATE_USER_ITEM),
    takeLatest(actions.UPDATE_GLOBAL_ITEM, UPDATE_GLOBAL_ITEM),
    takeLatest(actions.BATCH_UPDATE_USER_ITEMS, BATCH_UPDATE_USER_ITEMS),
  ]);
}
