import { InputGroup, NumericInput, Switch } from "@blueprintjs/core";

import { Text } from "@blueprintjs/core";
import CustomTypes from "components/users/edit-user-btn/custom-types";
import { Dispatch, SetStateAction, useMemo } from "react";
import { User } from "redux/users/types";
import style from "./index.module.scss";

type Props = {
  newDefaultUserProfile: Partial<User>;
  oldData: Partial<User>;
  setNewDefaultUserProfile: Dispatch<SetStateAction<Partial<User>>>;
};

type InputProps = {
  newDefaultUserProfile: Partial<User>;
  oldData: Partial<User>;
  setNewDefaultUserProfile: Dispatch<SetStateAction<Partial<User>>>;
  fieldKey: string;
  fieldType: string;
};

const DefaultProfileUserInput = ({
  fieldKey,
  fieldType,
  oldData,
  newDefaultUserProfile,
  setNewDefaultUserProfile,
}: InputProps) => {
  const inputFieldRenderer = () => {
    if (fieldType === "number") {
      return (
        <NumericInput
          fill
          value={newDefaultUserProfile[fieldKey as keyof User] as number}
          onValueChange={(valueAsNumber: number) =>
            setNewDefaultUserProfile({
              ...newDefaultUserProfile,
              [fieldKey]: valueAsNumber,
            })
          }
          id={`${fieldKey}-input`}
        />
      );
    } else if (fieldType === "string") {
      return (
        <InputGroup
          value={newDefaultUserProfile[fieldKey as keyof User] as string}
          onChange={(e) =>
            setNewDefaultUserProfile({
              ...newDefaultUserProfile,
              [fieldKey]: e.target.value,
            })
          }
          id={`${fieldKey}-input`}
        />
      );
    } else if (fieldType === "boolean") {
      return (
        <Switch
          checked={newDefaultUserProfile[fieldKey as keyof User] as boolean}
          onChange={(e) =>
            setNewDefaultUserProfile({
              ...newDefaultUserProfile,
              [fieldKey]: e.target.checked,
            })
          }
        />
      );
    } else if (fieldKey === "strictSearchSetting") {
      return (
        <Switch
          checked={
            newDefaultUserProfile.strictSearchSetting
              ?.isStrictSearchSettingEnabled as boolean
          }
          onChange={(e) =>
            setNewDefaultUserProfile({
              ...newDefaultUserProfile,
              [fieldKey as keyof string]: {
                ...newDefaultUserProfile[fieldKey],
                isStrictSearchSettingEnabled: e.target.checked,
              },
            })
          }
        />
      );
    } else if (fieldKey === "customTypes") {
      return (
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <CustomTypes
            oldData={oldData}
            newData={newDefaultUserProfile}
            setNewData={setNewDefaultUserProfile}
            formGroupProps={{}}
            CustomLabel={(key: string) => (
              <div style={{ width: "180px" }}>
                <Text>{key}</Text>
              </div>
            )}
          />
        </div>
      );
    } else <></>;
  };

  return (
    <div className={fieldKey !== "customTypes" ? style.form__group : ""}>
      {fieldKey !== "customTypes" && <div>{fieldKey}:</div>}
      {inputFieldRenderer()}
    </div>
  );
};

function DefaultProfileUser({
  newDefaultUserProfile,
  oldData,
  setNewDefaultUserProfile,
}: Props) {
  const attrArr = useMemo(() => {
    const result = [];
    for (const key in newDefaultUserProfile) {
      if (newDefaultUserProfile.hasOwnProperty(key)) {
        result.push({ key, type: typeof newDefaultUserProfile[key] });
      }
    }
    result.sort((a, b) => {
      if (a.key === "customTypes" || a.key === "customReportAreas") return 1;
      if (b.key === "customTypes" || b.key === "customReportAreas") return -1;
      return a.key.localeCompare(b.key);
    });
    return result;
  }, [newDefaultUserProfile]);

  return (
    <div>
      <div className="content">
        {attrArr.map((field) => {
          return (
            <DefaultProfileUserInput
              oldData={oldData}
              fieldKey={field.key}
              fieldType={field.type}
              newDefaultUserProfile={newDefaultUserProfile}
              setNewDefaultUserProfile={setNewDefaultUserProfile}
              key={`${field.key}-input`}
            />
          );
        })}
      </div>
    </div>
  );
}

export default DefaultProfileUser;
