import { DocumentData, Timestamp } from "firebase/firestore";

export type SuppliersState = {
  data: Supplier[];
  loading: boolean;
  loadingUpdate: boolean;
};

export class Supplier {
  id: string;
  createdAt: Timestamp;
  name: string;
  updatedAt: Timestamp;
  deleted: boolean;
  companyNumber: string;
  address: string;
  zip: string;
  city: string;
  country: string;

  constructor(id: string, data: DocumentData) {
    this.id = id;
    this.createdAt = data.createdAt ?? Timestamp.now();
    this.name = data.name;
    this.updatedAt = data.updatedAt ?? Timestamp.now();
    this.deleted = data.deleted ?? false;
    this.companyNumber = data.companyNumber ?? "";
    this.address = data.address ?? "";
    this.zip = data.zip ?? "";
    this.city = data.city ?? "";
    this.country = data.country ?? "";
  }
}
