export const getValueBackgroundColor = (
  colName: string,
  value: boolean,
  differentAttributes: {
    attribute: string;
    trueCount: number;
    falseCount: number;
  }[]
) => {
  const colWithDifferentValue = differentAttributes.find(
    (x) => x.attribute === colName
  );
  if (!colWithDifferentValue) return "#36E1EB";
  if (colWithDifferentValue.trueCount === colWithDifferentValue.falseCount)
    return "";
  if (colWithDifferentValue.trueCount !== colWithDifferentValue.falseCount) {
    if (colWithDifferentValue.trueCount > colWithDifferentValue.falseCount) {
      if (value) return "#36E1EB";
      else return "#FFC640";
    } else if (
      colWithDifferentValue.trueCount < colWithDifferentValue.falseCount
    ) {
      if (value) return "#FFC640";
      else return "#36E1EB";
    }
  } else return "";
};

export const getBorderColor = (
  colName: string,
  value: boolean,
  differentAttributes: {
    attribute: string;
    trueCount: number;
    falseCount: number;
  }[]
) => {
  const colWithDifferentValue = differentAttributes.find(
    (x) => x.attribute === colName
  );
  if (!colWithDifferentValue) return;
  if (colWithDifferentValue.trueCount === colWithDifferentValue.falseCount) {
    if (value) return "4px solid #00A2E8";
    else return "4px solid #FF7F27";
  } else return "";
};
