import { Button, Dialog, DialogBody, InputGroup } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import actions from "redux/access-levels/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";

function EditAccessLevel() {
  const dispatch = useAppDispatch();
  const accessLevels = useAppSelector(
    (state) => state.accessLevels.accessLevels
  );
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [labelChanges, setLabelChanges] = useState<Record<string, string>>({});

  useEffect(() => {
    dispatch(actions.SUBSCRIBE_TO_ACCESS_LEVELS());
    return () => {
      dispatch(actions.UNSUBSCRIBE_FROM_ACCESS_LEVELS());
    };
  }, [dispatch]);

  const saveData = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(actions.UPDATE_ACCESS_LEVELS({ accessLevels: labelChanges }));
    setOpenDialog(false);
  };

  return (
    <>
      <Button icon="edit" onClick={() => setOpenDialog(true)} />
      <Dialog
        isOpen={isOpenDialog}
        onClose={() => setOpenDialog(!isOpenDialog)}
        autoFocus
        enforceFocus
        usePortal={true}
        className="pb-0"
        title="Edit Access Levels"
        icon="edit"
      >
        <DialogBody>
          <form onSubmit={(e) => saveData(e)}>
            {Object.keys(accessLevels)
              .filter((accessLvlKey) => accessLvlKey !== "0")
              .map((accessLvlKey) => (
                <div className="d-flex align-items-center" key={accessLvlKey}>
                  <div className="col-1">{accessLvlKey}:</div>
                  <div className="col-11 my-2">
                    <InputGroup
                      defaultValue={accessLevels[accessLvlKey]}
                      onChange={(e) => {
                        setLabelChanges((labelChanges) => ({
                          ...labelChanges,
                          [accessLvlKey]: accessLevels[accessLvlKey],
                        }));
                        accessLevels[accessLvlKey] = e.currentTarget.value;
                      }}
                    />
                  </div>
                </div>
              ))}
            <Button className="mt-4 w-100" type="submit">
              Save
            </Button>
          </form>
        </DialogBody>
      </Dialog>
    </>
  );
}

export default EditAccessLevel;
