import { Button, Icon } from "@blueprintjs/core";
import _ from "lodash";
import React, { useRef } from "react";
import ReactJson from "react-json-view";
import { ViewportList } from "react-viewport-list";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/notification-centre/actions";
import { Notification } from "redux/notification-centre/types";
import style from "./index.module.scss";

type Props = {
  notifications: Notification[];
  setMaxNotification: React.Dispatch<React.SetStateAction<number>>;
};

const NotificationPopover = ({ notifications, setMaxNotification }: Props) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(
    (state) => state.notificationCentre.navbarNotificationsLoading
  );
  const viewportRef = useRef<HTMLDivElement>(null);

  const handleDismissNotification = (targetNotification: Notification) => {
    if (targetNotification.type === "toast") {
      dispatch(
        actions.UPDATE_TOAST_NOTIFICATION({
          id: targetNotification.id,
          operation: "update",
        })
      );
      return;
    }
    dispatch(
      actions.UPDATE_NOTIFICATION_STATUS({
        notificationId: targetNotification.id,
        newStatus: "read",
      })
    );
  };

  const renderer = (notification: Notification, idx: number) => {
    return (
      <div className={style.notification__card} key={`notification_${idx}`}>
        <div className="notification__content">
          <div className={style.notification__content__message}>
            {notification.message}
          </div>
          {!_.isEmpty(notification.data) && (
            <ReactJson
              style={{ wordBreak: "break-all", marginTop: 10 }}
              theme={"twilight"}
              sortKeys={true}
              src={notification.data}
              collapsed={0}
              groupArraysAfterLength={0}
            />
          )}
          <div
            style={{
              fontSize: "0.8rem",
              color: "rgba(255, 255, 255, 0.5)",
              marginTop: 10,
            }}
          >
            {notification.createdAt.toDateString()}
          </div>
        </div>
        <div className={style.dismiss__btn__wrapper}>
          <Icon
            icon="cross"
            className={style.dismiss__btn}
            onClick={() => handleDismissNotification(notification)}
          />
        </div>
      </div>
    );
  };

  if (notifications.length === 0) {
    return <div className={style.notification__popover}>No notifications</div>;
  }

  return (
    <div className={style.notification__popover} ref={viewportRef}>
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        You have {notifications.length} unread notifications
      </div>
      <ViewportList
        items={notifications}
        itemSize={100}
        viewportRef={viewportRef}
        overscan={4}
      >
        {renderer}
      </ViewportList>
      <Button
        fill
        loading={loading}
        text="Load More"
        onClick={() => setMaxNotification((prev) => prev + 5)}
      />
    </div>
  );
};

export default NotificationPopover;
