import { Button, Classes, Icon, Text } from "@blueprintjs/core";
import { cn } from "@stockifi/shared";
import InputActionConfirmationDialogue from "components/input-action-confirmation-dialogue";
import React, { Ref, forwardRef, useEffect, useState } from "react";
import { Filter, Layout } from "redux/config/types";
import { useAppSelector } from "redux/hooks";

type Props = {
  layout: Layout;
  style: React.CSSProperties;
  filters: Filter[];
  isDeleted: string | undefined;
  handleRemoveLayout: (id: string) => void;
  handleChooseLayout: (id: string) => void;
  getFilterCount: (filters: Filter[]) => Promise<number>;
  module: string;
  active: boolean;
};

const ButtonItem = forwardRef(
  (
    {
      layout,
      style,
      filters,
      isDeleted,
      handleRemoveLayout,
      handleChooseLayout,
      getFilterCount,
      module,
      active,
      ...props
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const deleting = useAppSelector(
      (state) => state.appConfig.deleteLayoutLoading
    );
    const [filterCount, setFilterCount] = useState<number>(0);
    useEffect(() => {
      (async () => {
        const num = await getFilterCount(layout.filters);
        setFilterCount(num);
      })();
    }, [getFilterCount, layout]);

    return (
      <div ref={ref} style={style}>
        <Button
          active={active}
          loading={deleting && isDeleted === layout.id}
          icon={
            <>
              <Icon icon="drag-handle-vertical" {...props} />
              {layout.isDefault && <Icon icon="pin" />}
            </>
          }
          rightIcon={
            <DeleteBtn
              layout={layout}
              handleRemoveLayout={handleRemoveLayout}
              module={module}
            />
          }
          text={
            <Text style={{ cursor: "pointer", whiteSpace: "nowrap" }}>
              {`${layout.name} (${filterCount})`}
            </Text>
          }
          onClick={() => handleChooseLayout(layout.id)}
        />
      </div>
    );
  }
);

interface DeleteBtnProps {
  layout: Layout;
  handleRemoveLayout: (id: string) => void;
  module: string;
}
const DeleteBtn = React.memo(
  ({ layout, handleRemoveLayout, module }: DeleteBtnProps) => (
    <div onClick={(e) => e.stopPropagation()}>
      <InputActionConfirmationDialogue
        hasDoubleConfirmation={false}
        onConfirm={() => handleRemoveLayout(layout.id)}
        title="Delete Layout"
        confirmationText="DELETE"
      >
        <Icon icon="cross" className={cn(Classes.POPOVER_TARGET, "mx-0")} />
      </InputActionConfirmationDialogue>
    </div>
  )
);

export default ButtonItem;
