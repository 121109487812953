import { Button, Text } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { Filter, Layout } from "redux/config/types";

interface Props {
  layout: Layout;
  filters: Filter[];
  handleChooseLayout: (id: string) => void;
  getFilterCount: (filters: Filter[]) => Promise<number>;
  active: boolean;
}
const NonDraggableBtn = React.memo(
  ({ layout, active, getFilterCount, handleChooseLayout }: Props) => {
    const [filterCount, setFilterCount] = useState<number>(0);
    useEffect(() => {
      (async () => {
        const num = await getFilterCount(layout.filters);
        setFilterCount(num);
      })();
    }, [getFilterCount, layout]);

    return (
      <Button
        active={active}
        icon={layout.isDefault ? "pin" : undefined}
        text={
          <Text style={{ cursor: "pointer" }}>
            {`${layout.name} (${filterCount})`}
          </Text>
        }
        onClick={() => handleChooseLayout(layout.id)}
      />
    );
  }
);

export default NonDraggableBtn;
