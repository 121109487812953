import React, { useCallback, useEffect, useState } from "react";
import { NumberFormatValues, SourceInfo } from "react-number-format";
import { InvoiceItem } from "redux/invoice/types";
import { updateValues } from "./helpers";

const useLocalItem = (
  item: InvoiceItem,
  onDataChange: (itm: InvoiceItem) => void,
  hasInputError: (val: boolean) => void,
  setCursor: React.Dispatch<React.SetStateAction<number | null>>,
  isResolvedInvoice: boolean
) => {
  const [localItem, setLocalItem] = useState<InvoiceItem>(item);
  const [isTotalFirst, setIsTotalFirst] = useState(item.autoTotal ?? false);
  const [isInitialLoad, setInitialLoad] = useState(true);
  const [isCalculateCost, setIsCalculateCost] = useState(
    isResolvedInvoice
      ? true
      : item.isFromFloatingButtons
        ? item.isFromFloatingButtons
        : item.autoTotal
          ? item.autoTotal
          : false
  );

  // Initiate arrow like current mechanism
  useEffect(() => {
    if (isTotalFirst) setIsCalculateCost(isTotalFirst);
  }, [isTotalFirst]);

  const handlePropertyChange = useCallback(
    (
      propertyName: string,
      valueInNumber: number,
      valueInString: string,
      initialItem: InvoiceItem
    ) => {
      const { copyItem, hasError } = updateValues(
        valueInString,
        valueInNumber,
        propertyName,
        initialItem,
        isTotalFirst,
        item.isSponsored ?? false,
        setIsTotalFirst,
        isCalculateCost
      );

      copyItem.changedProperty = "";
      setLocalItem(copyItem);
      onDataChange(copyItem);
      hasInputError(hasError);
    },
    [
      hasInputError,
      onDataChange,
      isTotalFirst,
      item.isSponsored,
      isCalculateCost,
    ]
  );

  useEffect(() => {
    const prop = item.changedProperty;
    const changables = ["cost", "quantity", "total"];
    if (prop && changables.includes(prop)) {
      const propValue = item[prop as keyof InvoiceItem];
      if (propValue) {
        handlePropertyChange(prop, +propValue, propValue?.toString(), item);
      }
    } else setLocalItem(item);
  }, [item, handlePropertyChange]);

  useEffect(() => {
    if (isInitialLoad) {
      setInitialLoad(false);
    } else {
      if (item.isSponsored) {
        handlePropertyChange("total", 0, "0", item);
      } else {
        const total = item.total ? item.total : item.cost * item.quantity;
        handlePropertyChange("total", total, total.toString(), item);
      }
    }
  }, [item.isSponsored]);

  useEffect(() => {
    if (
      (item.changedProperty === "total" &&
        localItem.total === 0 &&
        localItem.quantity === 0) ||
      (localItem.total !== 0 &&
        (localItem.fromVoting ||
          localItem.autoTotal ||
          (localItem.isNew && !localItem.cost)) &&
        localItem.quantity === 0)
    )
      setIsTotalFirst(true);
    else if (
      localItem.quantity !== 0 &&
      (localItem.fromVoting ||
        localItem.autoTotal ||
        (localItem.isNew && !localItem.cost))
    )
      setIsTotalFirst(false);
  }, [
    item.changedProperty,
    localItem.total,
    localItem.quantity,
    localItem.cost,
  ]);

  const onValueChange = useCallback(
    (values: NumberFormatValues, sourceInfo: SourceInfo) => {
      if (sourceInfo.source === "event") {
        sourceInfo.event?.preventDefault();
        const target = sourceInfo.event?.target as HTMLInputElement;
        setCursor(target.selectionStart);
        const numString = values.value;
        const numNumber = values.floatValue || +numString;
        const name = target.name;
        if (
          (name === "total" &&
            localItem.total === 0 &&
            localItem.quantity === 0) ||
          (localItem.total !== 0 &&
            (localItem.fromVoting ||
              localItem.autoTotal ||
              (localItem.isNew && !localItem.cost)) &&
            localItem.quantity === 0)
        )
          setIsTotalFirst(true);
        if (name === "total") setIsCalculateCost(true);
        handlePropertyChange(name, numNumber, numString, localItem);
      }
    },

    [localItem, handlePropertyChange]
  );

  return {
    localItem,
    onValueChange,
    isCalculateCost,
    setIsCalculateCost,
    handlePropertyChange,
  };
};

export default useLocalItem;
