import { Intent, Position } from "@blueprintjs/core";
import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { AlertPayload, AlertState } from "./types";

const initialState: AlertState = {
  alert: undefined,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));

  builder.addCase(actions.INFO, (state, action) => ({
    ...state,
    alert: createAlert(action.payload, Intent.PRIMARY),
  }));

  builder.addCase(actions.SUCCESS, (state, action) => ({
    ...state,
    alert: createAlert(action.payload, Intent.SUCCESS),
  }));

  builder.addCase(actions.ERROR, (state, action) => ({
    ...state,
    alert: createAlert(action.payload, Intent.DANGER),
  }));

  builder.addCase(actions.WARNING, (state, action) => ({
    ...state,
    alert: createAlert(action.payload, Intent.WARNING),
  }));

  builder.addDefaultCase((state) => state);
});

function createAlert(payload: AlertPayload, type: Intent) {
  if (typeof payload === "string") {
    return {
      type,
      message: payload,
      position: Position.TOP_RIGHT,
    };
  } else {
    return {
      type,
      message: payload.message,
      data: payload.data ?? {},
      position: payload.position ?? Position.TOP_RIGHT,
      isManualDismiss: payload.isManualDismiss,
    };
  }
}
