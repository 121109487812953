import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Callout, Classes, Dialog, DialogBody, DialogFooter, HTMLSelect, InputGroup, NumericInput, Popover, } from "@blueprintjs/core";
import { useCallback, useMemo, useState } from "react";
import ConfirmationPopover from "../confirmation-popover";
import styles from "./index.module.css";
function EditAttributeDialog({ cellProps, isOpen, onClose, }) {
    var _a, _b, _c, _d;
    const { row: { original }, column: { id: fieldName, columnDef }, table, } = cellProps;
    const fieldProps = (_a = table.options.meta) === null || _a === void 0 ? void 0 : _a.fieldProps;
    const identifierField = fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.identifierField;
    const identifier = original[(identifierField || Object.keys(original)[0])];
    const type = ((_b = columnDef === null || columnDef === void 0 ? void 0 : columnDef.meta) === null || _b === void 0 ? void 0 : _b.type) || "string";
    const value = original[fieldName];
    const options = (_c = fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.fieldOptions) === null || _c === void 0 ? void 0 : _c[fieldName];
    const onEdit = fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.onEdit;
    const onUpdate = onEdit &&
        ((value) => onEdit(original.id, fieldName, value));
    const needConfirmation = (_d = fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.fieldsToConfirm) === null || _d === void 0 ? void 0 : _d.includes(fieldName);
    const isLoading = fieldProps === null || fieldProps === void 0 ? void 0 : fieldProps.isLoading;
    const [newValue, setNewValue] = useState(null);
    const handleCancelUpdate = useCallback(() => {
        setNewValue(null);
        onClose();
    }, []);
    const renderEditAttributeValue = () => {
        var _a;
        if (options) {
            return (_jsx(HTMLSelect, { fill: true, options: options, onChange: (e) => setNewValue(e.currentTarget.value), defaultValue: value, name: fieldName, children: _jsx("option", { value: "", disabled: true, children: "Select your option" }) }));
        }
        if (type === "number") {
            return (_jsx(NumericInput, { fill: true, placeholder: fieldName, defaultValue: value, name: fieldName, min: 0, onValueChange: (valueAsNumber) => setNewValue(valueAsNumber) }));
        }
        else if (type === "string") {
            return (_jsx(InputGroup, { fill: true, placeholder: fieldName, defaultValue: value, name: fieldName, onChange: (e) => setNewValue(e.currentTarget.value) }));
        }
        else if (type === "boolean") {
            const options = [
                { label: "True", value: "true" },
                { label: "False", value: "false" },
            ];
            return (_jsx(HTMLSelect, { fill: true, options: options, onChange: (e) => setNewValue(e.currentTarget.value === "true"), defaultValue: (_a = value === null || value === void 0 ? void 0 : value.toString()) !== null && _a !== void 0 ? _a : "", name: fieldName, children: _jsx("option", { value: "", disabled: true, children: "Select your option" }) }));
        }
    };
    return (_jsxs(Dialog, { isOpen: isOpen, onClose: handleCancelUpdate, autoFocus: true, enforceFocus: true, className: "pb-0", icon: "edit", title: "Edit Attribute", children: [_jsxs(DialogBody, { children: [_jsxs(Callout, { title: "Warning", intent: "warning", className: "mb-3", children: ["Note that you're editing attribute", " ", _jsx("code", { className: styles.code, children: fieldName }), " for", " ", _jsx("code", { className: styles.code, children: identifier }), "."] }), _jsxs("div", { className: "d-flex align-items-center justify-content-between", children: [_jsxs("p", { className: "mb-0", children: [fieldName, ":"] }), _jsx("div", { children: renderEditAttributeValue() })] })] }), _jsx(DialogFooter, { actions: [
                    _jsx(Button, { onClick: handleCancelUpdate, text: "Cancel" }),
                    needConfirmation ? (_jsx(Popover, { content: _jsx(ConfirmationPopover, { fieldName: fieldName, value: newValue, onConfirm: () => onUpdate(newValue) }), usePortal: true, popoverClassName: Classes.POPOVER_CONTENT_SIZING, children: _jsx(Button, { intent: "danger", text: "Confirm Edit", disabled: newValue === null || newValue === "", loading: isLoading }) })) : (_jsx(Button, Object.assign({ intent: "danger", text: "Confirm Edit", disabled: !onUpdate || newValue === null || newValue === "", loading: isLoading }, (onUpdate && {
                        onClick: () => onUpdate(newValue),
                    })))),
                ] })] }));
}
export function CodeCell({ children }) {
    return _jsx("code", { className: styles.code, children: children });
}
export default function useEditAttributeDialog(cellProps) {
    const [isOpen, setOpen] = useState(false);
    const onOpen = useCallback(() => setOpen(true), []);
    const onClose = useCallback(() => setOpen(false), []);
    const DialogComponent = (_jsx(EditAttributeDialog, { isOpen: isOpen, onClose: onClose, cellProps: cellProps }));
    return useMemo(() => ({ onOpen, DialogComponent }), [onOpen, DialogComponent]);
}
