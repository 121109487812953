import { DocumentData } from "firebase/firestore";
import { all, call, put, takeLatest } from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { ServiceReturn } from "redux/types";
import {
  getUserCountAreas,
  getUserCounts,
  getVendorOtp,
  updateOtp,
} from "services/scripts";
import actions from "./actions";

export interface GET_USER_COUNTS_Payload {
  userId: string;
}

function* GET_USER_COUNTS(inp: ReturnType<typeof actions.GET_USER_COUNTS>) {
  const { userId } = inp.payload;

  yield put(
    actions.SET_STATE({
      loadingGetCounts: true,
    })
  );

  const { data }: ServiceReturn = yield call(getUserCounts, userId);

  if (data) {
    yield put(
      actions.SET_STATE({
        loadingGetCounts: false,
        userCounts: data,
      })
    );
  } else {
    yield put(
      actions.SET_STATE({
        loadingGetCounts: false,
      })
    );
  }
}

export interface GET_USER_COUNT_AREAS_payload {
  userId: string;
}

function* GET_USER_COUNT_AREAS(
  inp: ReturnType<typeof actions.GET_USER_COUNT_AREAS>
) {
  yield put(
    actions.SET_STATE({
      loadingGetCountAreas: true,
    })
  );

  const { userId } = inp.payload;
  const { data }: ServiceReturn = yield call(getUserCountAreas, userId);

  if (data) {
    yield put(
      actions.SET_STATE({
        userCountAreas: data,
        loadingGetCountAreas: false,
      })
    );
  } else {
    yield put(
      actions.SET_STATE({
        loadingGetCountAreas: false,
      })
    );
    yield put(alertActions.ERROR("Error getting user counts"));
  }
}

function* GET_USER_OTP() {
  yield put(
    actions.SET_STATE({
      loadingOtp: true,
    })
  );

  const { data }: ServiceReturn = yield call(getVendorOtp);

  if (data) {
    yield put(
      actions.SET_STATE({
        otp: data,
      })
    );
  } else {
    yield put(alertActions.ERROR("Error getting otp"));
  }

  yield put(
    actions.SET_STATE({
      loadingOtp: false,
    })
  );
}

export interface UPDATE_USER_OTP_payload {
  payloadData: DocumentData;
}
function* UPDATE_USER_OTP(inp: ReturnType<typeof actions.UPDATE_USER_OTP>) {
  yield put(actions.SET_STATE({ loadingOtp: true }));

  const { payloadData } = inp.payload;
  const { data }: ServiceReturn = yield call(updateOtp, payloadData);

  if (data) {
    yield put(actions.GET_USER_OTP());
  } else {
    yield put(alertActions.ERROR("Error updating otp"));
  }

  yield put(actions.SET_STATE({ loadingOtp: false }));
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_USER_COUNTS, GET_USER_COUNTS),
    takeLatest(actions.GET_USER_COUNT_AREAS, GET_USER_COUNT_AREAS),
    takeLatest(actions.GET_USER_OTP, GET_USER_OTP),
    takeLatest(actions.UPDATE_USER_OTP, UPDATE_USER_OTP),
  ]);
}
