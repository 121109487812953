import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import store from "redux/store";
import { Called, ServiceReturn } from "redux/types";
import { setUserTaskListener } from "services/listeners/pos-item-task";
import {
  SaveUserTask,
  batchCheckUserTask,
  batchCreateRecipe,
  batchUpdateUserTask,
  checkUserTask,
  createRecipe,
  getRecipesByUserId,
  getUserPosItems,
  saveUserTask,
  updatePosItemsGroup,
  updateRecipe,
  updateUserTask,
} from "services/pos-item-tasks";
import { COMMON } from "utils/constants";
import actions from "./actions";
import { POSItem, POSItemTask, UserRecipeUpdate } from "./types";

export interface SUBSCRIBE_TO_USER_TASK_Payload {
  userId: string;
}

function* SUBSCRIBE_TO_USER_TASK(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_USER_TASK>
) {
  yield put(
    actions.SET_STATE({
      loading: true,
      tasks: [],
    })
  );
  const { userId } = input.payload;
  const channel: EventChannel<boolean> = yield call(
    setUserTaskListener,
    userId
  );
  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_TO_USER_TASK);
    channel.close();
  });

  try {
    while (true) {
      const tasks: POSItemTask[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          tasks,
          loading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loading: false,
        })
      );
    }
  }
}

export interface UPDATE_USER_TASK_Payload {
  taskId: string;
  userId: string;
  task: POSItemTask;
}

function* UPDATE_USER_TASK(input: ReturnType<typeof actions.UPDATE_USER_TASK>) {
  const { taskId, userId, task } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingUpdate: true,
    })
  );

  const result: ServiceReturn = yield call(
    updateUserTask,
    taskId,
    userId,
    task
  );

  if (result.data) {
    yield put(actions.SET_STATE({ loadingUpdate: false }));
    yield put(alertActions.SUCCESS("User Task updated."));
  } else {
    yield put(actions.SET_STATE({ loadingUpdate: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface BATCH_UPDATE_USER_TASK_Payload {
  userId: string;
  tasks: POSItemTask[];
}

function* BATCH_UPDATE_USER_TASK(
  input: ReturnType<typeof actions.BATCH_UPDATE_USER_TASK>
) {
  const { userId, tasks } = input.payload;

  const result: ServiceReturn = yield call(batchUpdateUserTask, userId, tasks);

  if (result.data) {
    yield put(
      actions.GET_USER_POS_ITEMS({
        userId: input.payload.userId,
        loading: false,
      })
    );
    yield put(actions.SET_STATE({ loading: false }));
    yield put(alertActions.SUCCESS("Batch User Task updated."));
  } else {
    yield put(actions.SET_STATE({ loading: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface BATCH_CHECK_USER_TASK_Payload {
  userId: string;
  tasks: (POSItemTask & { newRecipe?: UserRecipeUpdate | undefined })[];
}

function* BATCH_CHECK_USER_TASK(
  input: ReturnType<typeof actions.BATCH_CHECK_USER_TASK>
) {
  const { userId, tasks } = input.payload;

  const result: ServiceReturn = yield call(batchCheckUserTask, userId, tasks);

  if (result.data) {
    yield put(
      actions.GET_USER_POS_ITEMS({
        userId: input.payload.userId,
        loading: false,
      })
    );
    yield put(
      actions.GET_USER_RECIPES({
        userId: input.payload.userId,
      })
    );
    yield put(actions.SET_STATE({ loading: false }));
    yield put(alertActions.SUCCESS("Batch User Task updated."));
  } else {
    yield put(actions.SET_STATE({ loading: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface GET_USER_POS_ITEMS_Payload {
  userId: string;
  loading?: boolean;
}
export function* GET_USER_POS_ITEMS({
  payload,
}: ReturnType<typeof actions.GET_USER_POS_ITEMS>) {
  const { userId, loading = true } = payload;
  yield put(
    actions.SET_STATE({
      loading,
    })
  );

  const items: POSItem[] = yield call(getUserPosItems, userId);
  yield put(
    actions.SET_STATE({
      items,
      loading: false,
    })
  );
}

export interface UPDATE_POS_ITEMS_GROUP_payload {
  posItems: {
    id: string;
    group: string;
  }[];
  userId: string;
}

function* UPDATE_POS_ITEMS_GROUP(
  input: ReturnType<typeof actions.UPDATE_POS_ITEMS_GROUP>
) {
  const { posItems, userId } = input.payload;
  yield put(
    actions.SET_STATE({
      loadingUpdate: true,
    })
  );

  const result: ServiceReturn = yield call(
    updatePosItemsGroup,
    posItems,
    userId
  );

  if (result.data) {
    const userPosItems = store.getState().posItemTasks.items;
    const newItems = userPosItems.map((item) => {
      if (posItems.some((posItem) => posItem.id === item.id)) {
        const newGroup = posItems.find(
          (posItem) => posItem.id === item.id
        )?.group;
        return {
          ...item,
          group: newGroup,
        };
      }
      return item;
    });
    yield put(
      actions.SET_STATE({
        items: newItems,
      })
    );
    yield put(
      actions.SET_STATE({
        loadingUpdate: false,
      })
    );
    yield put(alertActions.SUCCESS("POS Items updated."));
  } else {
    yield put(
      actions.SET_STATE({
        loadingUpdate: false,
      })
    );
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export type SAVE_USER_TASK_Payload = SaveUserTask;

function* SAVE_USER_TASK(input: ReturnType<typeof actions.SAVE_USER_TASK>) {
  const result: ServiceReturn = yield call(saveUserTask, input.payload);

  if (result.data) {
    yield put(
      actions.GET_USER_POS_ITEMS({
        userId: input.payload.userId,
        loading: false,
      })
    );
    yield put(alertActions.SUCCESS("User Task saved."));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export type CHECK_USER_TASK_Payload = {
  data: SaveUserTask;
  recipe?: UserRecipeUpdate;
};

function* CHECK_USER_TASK(input: ReturnType<typeof actions.CHECK_USER_TASK>) {
  const { data, recipe } = input.payload;
  const result: ServiceReturn = yield call(checkUserTask, data, recipe);

  if (result.data) {
    yield put(
      actions.GET_USER_POS_ITEMS({
        userId: data.userId,
        loading: false,
      })
    );
    if (recipe) {
      yield put(
        actions.GET_USER_RECIPES({
          userId: data.userId,
        })
      );
      yield put(alertActions.SUCCESS("New Menu Item Created."));
    }
    yield put(alertActions.SUCCESS("User Task saved."));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface GET_USER_RECIPES_Payload {
  userId: string;
}

export function* GET_USER_RECIPES(
  input: ReturnType<typeof actions.GET_USER_RECIPES>
) {
  const { userId } = input.payload;
  yield put(
    actions.SET_STATE({
      loadingRecipes: true,
    })
  );

  const recipes: Called<typeof getRecipesByUserId> = yield call(
    getRecipesByUserId,
    userId
  );
  if (recipes.data) {
    yield put(
      actions.SET_STATE({
        recipes: recipes.data,
        loadingRecipes: false,
      })
    );
  }
}

export interface CREATE_USER_RECIPE_Payload {
  userId: string;
  recipe: UserRecipeUpdate;
}

export function* CREATE_USER_RECIPE(
  input: ReturnType<typeof actions.CREATE_USER_RECIPE>
) {
  yield put(
    actions.SET_STATE({
      loadingRecipes: true,
    })
  );
  const { userId, recipe } = input.payload;

  const result: ServiceReturn = yield call(createRecipe, userId, recipe);

  if (result.data) {
    yield put(
      actions.GET_USER_RECIPES({
        userId: input.payload.userId,
      })
    );
    yield put(alertActions.SUCCESS("New Menu Item Recipe Created."));
  } else {
    yield put(
      actions.SET_STATE({
        loadingRecipes: false,
      })
    );
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface BATCH_CREATE_USER_RECIPE_Payload {
  userId: string;
  recipes: UserRecipeUpdate[];
}

export function* BATCH_CREATE_USER_RECIPE(
  input: ReturnType<typeof actions.BATCH_CREATE_USER_RECIPE>
) {
  yield put(
    actions.SET_STATE({
      loadingRecipes: true,
    })
  );
  const { userId, recipes } = input.payload;

  const result: ServiceReturn = yield call(batchCreateRecipe, userId, recipes);

  if (result.data) {
    yield put(
      actions.GET_USER_RECIPES({
        userId: input.payload.userId,
      })
    );
    yield put(alertActions.SUCCESS("New Menu(s) Item Recipe Created."));
  } else {
    yield put(
      actions.SET_STATE({
        loadingRecipes: false,
      })
    );
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_USER_RECIPE_Payload {
  userId: string;
  recipeId: string;
  recipe: Partial<UserRecipeUpdate>;
}

export function* UPDATE_USER_RECIPE(
  input: ReturnType<typeof actions.UPDATE_USER_RECIPE>
) {
  yield put(
    actions.SET_STATE({
      loadingRecipes: true,
    })
  );
  const { userId, recipeId, recipe } = input.payload;

  const result: ServiceReturn = yield call(
    updateRecipe,
    userId,
    recipeId,
    recipe
  );

  if (result.data) {
    yield put(
      actions.GET_USER_RECIPES({
        userId: input.payload.userId,
      })
    );
    yield put(alertActions.SUCCESS("Recipe successfully updated"));
  } else {
    yield put(
      actions.SET_STATE({
        loadingRecipes: false,
      })
    );
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBSCRIBE_TO_USER_TASK, SUBSCRIBE_TO_USER_TASK),
    takeLatest(actions.UPDATE_USER_TASK, UPDATE_USER_TASK),
    takeLatest(actions.UPDATE_POS_ITEMS_GROUP, UPDATE_POS_ITEMS_GROUP),
    takeLatest(actions.GET_USER_POS_ITEMS, GET_USER_POS_ITEMS),
    takeLatest(actions.GET_USER_RECIPES, GET_USER_RECIPES),
    takeLatest(actions.BATCH_UPDATE_USER_TASK, BATCH_UPDATE_USER_TASK),
    takeLatest(actions.SAVE_USER_TASK, SAVE_USER_TASK),
    takeLatest(actions.CREATE_USER_RECIPE, CREATE_USER_RECIPE),
    takeLatest(actions.UPDATE_USER_RECIPE, UPDATE_USER_RECIPE),
    takeLatest(actions.BATCH_CREATE_USER_RECIPE, BATCH_CREATE_USER_RECIPE),
    takeLatest(actions.CHECK_USER_TASK, CHECK_USER_TASK),
    takeLatest(actions.BATCH_CHECK_USER_TASK, BATCH_CHECK_USER_TASK),
  ]);
}
