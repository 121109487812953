import { Button, Dialog, DialogBody, InputGroup } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/user-priorities/actions";

function EditUserPriorities() {
  const dispatch = useAppDispatch();
  const userPriorities = useAppSelector(
    (state) => state.userPriorities.userPriorities
  );
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [labelChanges, setLabelChanges] = useState<Record<string, string>>({});
  const fallback = { 1: "", 2: "", 3: "", 4: "", 5: "" };

  useEffect(() => {
    dispatch(actions.SUBSCRIBE_TO_USER_PRIORITIES());
    return () => {
      dispatch(actions.UNSUBSCRIBE_FROM_USER_PRIORITIES());
    };
  }, [dispatch]);

  const saveData = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      actions.UPDATE_USER_PRIORITIES({
        userPriorities: labelChanges,
      })
    );
    setOpenDialog(false);
  };

  return (
    <>
      <Button icon="edit" onClick={() => setOpenDialog(true)} />
      <Dialog
        isOpen={isOpenDialog}
        onClose={() => setOpenDialog(!isOpenDialog)}
        autoFocus
        enforceFocus
        usePortal={true}
        className="pb-0"
        title="Edit User Priorities"
        icon="edit"
      >
        <DialogBody>
          <form onSubmit={(e) => saveData(e)}>
            {Object.keys(!userPriorities ? userPriorities : fallback).map(
              (userPrioKey) => (
                <div className="d-flex align-items-center" key={userPrioKey}>
                  <div className="col-1">{userPrioKey}:</div>
                  <div className="col-11 my-2">
                    <InputGroup
                      defaultValue={userPriorities[userPrioKey]}
                      onChange={(e) => {
                        setLabelChanges((labelChanges) => ({
                          ...labelChanges,
                          [userPrioKey]: userPriorities[userPrioKey],
                        }));
                        userPriorities[userPrioKey] = e.currentTarget.value;
                      }}
                    />
                  </div>
                </div>
              )
            )}
            <Button className="mt-4 w-100" type="submit">
              Save
            </Button>
          </form>
        </DialogBody>
      </Dialog>
    </>
  );
}

export default EditUserPriorities;
