import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { Notification } from "redux/notification-centre/types";
import { checkCounter } from "services/counter";
import { db } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export async function updateNotificationStatus(
  notificationId: string,
  newStatus: string
) {
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  if (!db) return { data: null, error: "No db connection" };

  const docRef = doc(db, "adminPanelNotifications/" + notificationId);

  return updateDoc(docRef, { status: newStatus })
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      console.log(err);
      return { data: null, error: err };
    });
}

export async function batchUpdateNotificationStatus(
  notificationIds: string[],
  newStatus: string
) {
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  if (!db) return { data: null, error: "No db connection" };

  const batch = writeBatch(db);

  notificationIds.forEach((notificationId) => {
    if (!db) return;
    const docRef = doc(db, "adminPanelNotifications/" + notificationId);
    batch.update(docRef, { status: newStatus });
  });

  return batch
    .commit()
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      console.log(err);
      return { data: null, error: err };
    });
}

export async function getDoneNotification() {
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  if (!db) return { data: null, error: "No db connection" };

  const currTime: Date = new Date();
  currTime.setDate(currTime.getDate() - 59);

  const collRef = collection(db, "adminPanelNotifications");
  const notifQuery = query(
    collRef,
    where("status", "==", "done"),
    where("createdAt", ">=", currTime),
    orderBy("createdAt", "desc")
  );
  try {
    const querySnapshot = await getDocs(notifQuery);
    const notifications = querySnapshot.docs.map(
      (x) => new Notification(x.id, x.data())
    );

    return { data: notifications, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}
