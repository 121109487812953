import { initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import {
  Firestore,
  getFirestore,
  initializeFirestore,
} from "firebase/firestore";
import { Functions, getFunctions } from "firebase/functions";
import { FirebaseStorage, getStorage } from "firebase/storage";

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const isTesting = Boolean(
  process.env.NODE_ENV === "test" || process.env.REACT_APP_TESTING === "true"
);

const config = {
  development: {
    apiKey: "AIzaSyCC8LX-1erjqbNGzGyX7rAzXkWv9QSKr3g",
    authDomain: "stocklio-playground.firebaseapp.com",
    projectId: "stocklio-playground",
    storageBucket: "stocklio-playground.appspot.com",
    messagingSenderId: "641852285608",
    appId: "1:641852285608:web:5a2da8299eddea7950189d",
    measurementId: "G-XBJ31LR1E0",
  },
  production: {
    apiKey: "AIzaSyCOHxylqUn6sgQrZ6QyQVXXQgvslRXA3IE",
    authDomain: "stocklio-beta.firebaseapp.com",
    databaseURL: "https://stocklio-beta.firebaseio.com",
    projectId: "stocklio-beta",
    storageBucket: "stocklio-beta.appspot.com",
    messagingSenderId: "62323116792",
    appId: "1:62323116792:web:1cc20090828a20c058090d",
    measurementId: "G-WC978VV3YF",
  },
};

const firebaseConfig = isLocalhost ? config.development : config.production;
// : await fetch("/__/firebase/init.json").then((response) => response.json());

// This will use our config to recognize the project and initialize authentication and database modules.
const app = isTesting ? null : initializeApp(firebaseConfig);

let auth: Auth | null = null;
let db: Firestore | null = null;
let storage: FirebaseStorage | null = null;
let functions: Functions | null = null;

if (app) {
  auth = getAuth(app);
  initializeFirestore(app, {
    ignoreUndefinedProperties: true,
  });
  db = getFirestore(app);
  storage = getStorage(app);
  functions = getFunctions(app);
}

const PROJECT_ID = app?.options.projectId;

export { db, storage, functions, auth, app, PROJECT_ID };
