import {
  Button,
  ButtonGroup,
  HTMLSelect,
  Intent,
  Position,
  Tooltip,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { Reducer, useEffect, useReducer } from "react";

/**
 * @alias PaginationProps
 * @memberof Pagination
 */
type Props = {
  /** The initial page to show */
  initialPage?: number;
  /** The total number of items */
  total: number;
  /** The number of items per page */
  size: number;
  /** Callback when the page changes */
  onPageChange: (page: number) => void;
  /** Callback when the size changes */
  onSizeChange: (size: number) => void;
};

interface InitialState {
  currentPage: number;
  size: number;
  total: number;
}

interface State extends InitialState {
  pages: number[];
  showEndEllipsis: boolean;
  showStartEllipsis: boolean;
  totalPages: number;
}

interface Actions {
  type: string;
  
  payload?: any;
}

const getState = ({ currentPage, size, total }: InitialState): State => {
  const totalPages = Math.ceil(total / size);

  const PAGES_TO_SHOW = 5;
  const PAGES_ON_EITHER_SIDE = 2;

  let showStartEllipsis = false;
  let showEndEllipsis = false;

  // create an array of pages to repeat in the pager control
  let startPage = 0;
  let endPage = 0;
  if (totalPages <= PAGES_TO_SHOW) {
    // less than PAGES_TO_SHOW total pages, so show all
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= PAGES_TO_SHOW - PAGES_ON_EITHER_SIDE) {
      // more than PAGINATION_THRESHOLD total pages so calculate start and end pages
      startPage = 1;
      endPage = PAGES_TO_SHOW;
      showEndEllipsis = true;
    } else if (currentPage + PAGES_ON_EITHER_SIDE >= totalPages) {
      // current page approaching the total pages
      startPage = totalPages - (PAGES_TO_SHOW - 1);
      endPage = totalPages;
      showStartEllipsis = true;
    } else {
      // current page is somewhere in the middle
      startPage = currentPage - PAGES_ON_EITHER_SIDE;
      endPage = currentPage + PAGES_ON_EITHER_SIDE;
      showStartEllipsis = true;
      showEndEllipsis = true;
    }
  }

  const pages = Array.from(
    { length: endPage + 1 - startPage },
    (_, i) => startPage + i
  );

  // Too large or small currentPage
  let correctCurrentPage = currentPage;
  if (currentPage > totalPages) {
    correctCurrentPage = totalPages;
  }
  if (currentPage <= 0) {
    correctCurrentPage = 1;
  }

  return {
    currentPage: correctCurrentPage,
    pages,
    showEndEllipsis,
    showStartEllipsis,
    size,
    total,
    totalPages,
  };
};

const reducer: Reducer<State, Actions> = (state, action) => {
  switch (action.type) {
    case "PAGE_CHANGE":
      return getState({
        ...state,
        currentPage: action.payload.page,
      });

    case "SIZE_CHANGE":
      return getState({
        ...state,
        size: action.payload.size,
      });
    case "TOTAL_CHANGE":
      return getState({
        ...state,
        total: action.payload.total,
      });
    default:
      throw new Error();
  }
};

/**
 * Pagination panel for tables or lists
 * ### Usage
 *
 * ```
 * import { Pagination } from "components/pagination";
 * ```
 * @component
 */
export const Pagination = ({
  initialPage = 1,
  total,
  size,
  onPageChange,
  onSizeChange,
}: Props) => {
  const [state, dispatch] = useReducer(
    reducer,
    { currentPage: initialPage, total, size, totalPages: 0 },
    getState
  );

  useEffect(() => {
    dispatch({ type: "TOTAL_CHANGE", payload: { total } });
  }, [total]);

  useEffect(() => {
    changePage(initialPage);
    
  }, [initialPage]);

  useEffect(() => {
    dispatch({ type: "SIZE_CHANGE", payload: { size } });
  }, [size]);

  const changePage = (page: number) => {
    dispatch({ type: "PAGE_CHANGE", payload: { page } });
    onPageChange(page);
  };

  const changeSize = (size: number) => {
    dispatch({ type: "SIZE_CHANGE", payload: { size } });
    onSizeChange(size);
  };

  //   if (state.totalPages === 1) {
  //     return null;
  //   }

  return (
    <div>
      <ButtonGroup>
        <Tooltip
          content="First Page"
          disabled={state.currentPage === 1}
          position={Position.TOP}
        >
          <Button
            disabled={state.currentPage === 1}
            icon={IconNames.DOUBLE_CHEVRON_LEFT}
            onClick={() => changePage(1)}
          />
        </Tooltip>
        <Tooltip
          content="Previous Page"
          disabled={state.currentPage === 1}
          position={Position.TOP}
        >
          <Button
            icon={IconNames.CHEVRON_LEFT}
            disabled={state.currentPage === 1}
            onClick={() => changePage(Math.max(1, state.currentPage - 1))}
          />
        </Tooltip>
        {state.showStartEllipsis && <Button disabled={true}>&#8230;</Button>}
        {state.pages.map((page) => (
          <Button
            key={page}
            intent={state.currentPage === page ? Intent.PRIMARY : Intent.NONE}
            onClick={() => changePage(page)}
          >
            {page}
          </Button>
        ))}
        {state.showEndEllipsis && <Button disabled={true}>&#8230;</Button>}
        <Tooltip
          content="Next Page"
          disabled={state.currentPage === state.totalPages}
          position={Position.TOP}
        >
          <Button
            icon={IconNames.CHEVRON_RIGHT}
            disabled={state.currentPage === state.totalPages}
            onClick={() =>
              changePage(Math.min(state.currentPage + 1, state.totalPages))
            }
          />
        </Tooltip>
        <Tooltip
          content="Last Page"
          disabled={state.currentPage === state.totalPages}
          position={Position.TOP}
        >
          <Button
            disabled={state.currentPage === state.totalPages}
            icon={IconNames.DOUBLE_CHEVRON_RIGHT}
            onClick={() => changePage(state.totalPages)}
          />
        </Tooltip>
        <Tooltip content="Page size">
          <HTMLSelect
            className="ms-2"
            options={[10, 20, 50, 100]}
            value={state.size}
            onChange={(e) => changeSize(parseInt(e.target.value))}
          />
        </Tooltip>
      </ButtonGroup>
    </div>
  );
};

type PaginationHookProps = {
  children: React.ReactNode;
  type: "top" | "bottom" | "both";
};

export function usePagination(totalItems: number, minimalist = false) {
  const [start, setStart] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const resetPage = React.useCallback(() => {
    setPage(1);
    setPageSize(10);
  }, []);

  function setDefaultPageSize(size: number) {
    setPageSize(size);
  }

  const Component = React.useCallback(
    ({ children, type }: PaginationHookProps) => {
      return (
        <div>
          {(type === "top" || type === "both") && (
            <div className="d-flex justify-content-between align-items-center mb-2">
              {!minimalist && (
                <span>{`Showing ${start + 1} - ${Math.min(
                  start + pageSize,
                  totalItems
                )} of ${totalItems}`}</span>
              )}
              <Pagination
                initialPage={page}
                total={totalItems}
                size={pageSize}
                onPageChange={(page) => {
                  setStart(pageSize * (page - 1));
                  setPage(page);
                }}
                onSizeChange={(size) => {
                  setPageSize(size);
                  setPage(1);
                }}
              />
            </div>
          )}
          {children}
          {(type === "bottom" || type === "both") && (
            <div className="d-flex justify-content-between align-items-center mt-2">
              {!minimalist && (
                <span>{`Showing ${start + 1} - ${Math.min(
                  start + pageSize,
                  totalItems
                )} of ${totalItems}`}</span>
              )}
              <Pagination
                initialPage={page}
                total={totalItems}
                size={pageSize}
                onPageChange={(page) => {
                  setStart(pageSize * (page - 1));
                  setPage(page);
                }}
                onSizeChange={(size) => {
                  setPageSize(size);
                  setPage(1);
                }}
              />
            </div>
          )}
        </div>
      );
    },
    
    [pageSize, totalItems, page]
  );

  return {
    Pagination: Component,
    start,
    end: Math.min(start + pageSize, totalItems),
    page: Math.ceil(start / pageSize) + 1,
    pageSize,
    resetPage,
    setPage,
    setDefaultPageSize,
  };
}

export default Pagination;
