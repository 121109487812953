import { DocumentData, DocumentReference, Timestamp } from "firebase/firestore";
import { Items } from "redux/invoice/types";

export type POSItemTasksState = {
  loading: boolean;
  tasks: POSItemTask[];
  items: POSItem[];
  recipes: UserRecipe[];
  loadingUpdate: boolean;
  loadingRecipes: boolean;
};

export type UserRecipeUpdate = Omit<Omit<UserRecipe, "id">, "note">;

export class UserRecipe {
  id: string;
  ref?: DocumentReference;
  isDish: boolean;
  cost: number;
  name: string;
  archived: boolean;
  unit: string;
  deleted: boolean;
  updatedAt: Timestamp;
  perUnit: boolean;
  variety: string;
  size: number;
  itemId: string | null;
  sortedItemIds: string[];
  type: string;
  createdAt: Timestamp;
  silhouetteName: string | null;
  itemsV2: Record<string, number>;
  note: string | null;

  constructor(id: string, data: DocumentData) {
    this.id = id;
    this.isDish = data.isDish ?? false;
    this.cost = data.cost ?? 0;
    this.name = data.name ?? "";
    this.archived = data.archived ?? false;
    this.unit = data.unit ?? "";
    this.deleted = data.deleted ?? false;
    this.updatedAt = data.updatedAt ?? Timestamp.now();
    this.perUnit = data.perUnit ?? false;
    this.size = data.size ?? 0;
    this.itemId = data.itemId ?? null;
    this.sortedItemIds = data.sortedItemIds ?? [];
    this.type = data.type ?? "RECIPE";
    this.variety = data.variety ?? "RECIPE";
    this.createdAt = data.createdAt ?? Timestamp.now();
    this.silhouetteName = data.silhouetteName ?? null;
    this.itemsV2 = data.itemsV2 ?? {};
    this.note = data.note ?? null;
  }
}

export type POSItem = {
  archived: boolean;
  id: string;
  items: Record<string, number>;
  posData: {
    active: boolean;
    articleGroup: { id: number; name: string };
    articleType: { id: number; name: string };
    measures: number;
    mode: string;
    modified: string;
    name: string;
    pluAndBarcodes: string[];
    price: number;
    priceLevelPrices: Record<string, number>;
    shortName: string;
    sku: number;
    netPrice?: number;
  };
  userId: string;
  group?: string;
  deleted?: boolean;
};

export type Ingredient = Items | UserRecipe;

export class POSItemTask {
  id?: string;
  deleted: boolean;
  path: string;
  title: string;
  isChecked: boolean;
  state: "unresolved" | "resolved" | undefined;
  createdAt: Date;
  checkedBy: string;
  resolvedBy: string;
  isForUser: boolean;
  taskComments: string;
  isForSupervisor: boolean;
  userId: string;
  newItems?: Record<string, number>;
  type?: string;

  constructor(data: DocumentData) {
    this.id = data.id;
    this.deleted = data.deleted;
    this.path = data.path;
    this.title = data.title;
    this.isChecked = !!data.deleted || !!data.isChecked;
    this.state = data.state ?? (data.deleted ? "resolved" : "unresolved");
    this.createdAt = data.createdAt
      ? typeof data.createdAt === "string"
        ? new Date(data.createdAt)
        : data.createdAt.toDate()
      : new Date();
    this.checkedBy = data.checkedBy ?? "";
    this.resolvedBy = data.resolvedBy ?? "";
    this.isForUser = data.isForUser ?? false;
    this.taskComments = data.taskComments;
    this.isForSupervisor = data.isForSupervisor ?? false;
    this.userId = data.userId ?? "";
    this.newItems = data.newItems;
    this.type = data.type;
  }
}
