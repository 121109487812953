import { Popover } from "@blueprintjs/core";
import { POPOVER_CONTENT } from "@blueprintjs/core/lib/esm/common/classes";
import React, { useMemo } from "react";
import { HexColorInput, RgbaStringColorPicker } from "react-colorful";
import { InvoiceHighlightColors } from "redux/settings/types";
import { cleanRgbaValue, hex2rgba, rgba2hex } from "../helper";
import style from "../style.module.scss";
import { highlightColorLabel, highlightColorTitleLabel } from "./constants";

interface HighlightColorSettingsProps {
  highlightColors: InvoiceHighlightColors;
  setHighlightColors: React.Dispatch<
    React.SetStateAction<InvoiceHighlightColors>
  >;
}

type ColorPair = {
  key: string;
  label: string;
  rgba: string;
  hex: string;
  alpha: number;
};

function HighlightColorSettings({
  highlightColors,
  setHighlightColors,
}: HighlightColorSettingsProps) {
  const parsedHighlightColors = useMemo(() => {
    if (!highlightColors) return [];

    const groupNames = Object.keys(highlightColors).sort((a, b) =>
      a.localeCompare(b)
    );
    const result: {
      key: string;
      label: string;
      colors: ColorPair[];
    }[] = [];

    for (const groupName of groupNames) {
      const colors = highlightColors[groupName];
      const colorEntries = Object.entries(colors);
      const colorPairs: ColorPair[] = [];

      for (const colorEntry of colorEntries) {
        const [colorName, colorValue] = colorEntry;
        const alpha = cleanRgbaValue(colorValue).split(",").pop();

        colorPairs.push({
          key: colorName,
          label:
            highlightColorLabel[colorName as keyof typeof highlightColorLabel],
          rgba: colorValue,
          hex: rgba2hex(colorValue),
          alpha: alpha ? Number(alpha) : 1,
        });
      }

      result.push({
        key: groupName,
        label:
          highlightColorTitleLabel[
            groupName as keyof typeof highlightColorTitleLabel
          ],
        colors: colorPairs.sort((a, b) => a.label.localeCompare(b.label)),
      });
    }
    return result;
  }, [highlightColors]);

  return (
    <div>
      <div style={{ display: "flex", gap: "4rem", flexWrap: "wrap" }}>
        {parsedHighlightColors.map((group, idx) => (
          <div key={`group-${group}-${idx}`}>
            <h2>{group.label}</h2>
            <table>
              <tbody className={style.highlightColor}>
                {group.colors.map((color) => (
                  <tr key={`row-${group.key}-${color.key}`}>
                    <td>
                      <label htmlFor={`${group.key}-${color.key}`}>
                        {color.label}
                      </label>
                    </td>
                    <td>
                      <Popover
                        className={POPOVER_CONTENT}
                        content={
                          <div
                            style={{
                              padding: "1.2rem",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "0.6rem",
                            }}
                          >
                            <RgbaStringColorPicker
                              color={color.rgba}
                              onChange={(newColor) => {
                                setHighlightColors((prev) => {
                                  const newColors = { ...prev };
                                  newColors[group.key][color.key] = newColor;
                                  return newColors;
                                });
                              }}
                            />
                            <span>Opacity : {color.alpha}</span>
                            <span>
                              #{" "}
                              <HexColorInput
                                style={{
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                }}
                                color={color.hex}
                                onChange={(newColor) => {
                                  setHighlightColors((prev) => {
                                    const newColors = { ...prev };
                                    newColors[group.key][color.key] = hex2rgba(
                                      newColor,
                                      color.alpha
                                    );
                                    return newColors;
                                  });
                                }}
                              />
                            </span>
                          </div>
                        }
                      >
                        <div
                          style={{
                            width: 32,
                            height: 32,
                            backgroundColor: color.rgba,
                            cursor: "pointer",
                          }}
                        ></div>
                      </Popover>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HighlightColorSettings;
