import { createActions } from "redux/create-actions";
import {
  ADD_SUPPLIER_Payload,
  SUBSCRIBE_TO_SUPPLIERS_Payload,
  UPDATE_SUPPLIER_Payload,
  UPDATE_SUPPLIERS_Payload,
} from "./sagas";
import { SuppliersState } from "./types";

const actions = createActions("suppliers", {
  SET_STATE: {} as Partial<SuppliersState>,
  GET_SUPPLIERS: undefined,
  UPDATE_SUPPLIER: {} as UPDATE_SUPPLIER_Payload,
  UPDATE_SUPPLIERS: {} as UPDATE_SUPPLIERS_Payload,
  ADD_SUPPLIER: {} as ADD_SUPPLIER_Payload,
  SUBSCRIBE_TO_SUPPLIERS: {} as SUBSCRIBE_TO_SUPPLIERS_Payload,
  UNSUBSCRIBE_FROM_SUPPLIERS: undefined,
});

export default actions;
