import { Permission, PermissionData } from "components/permissions/types";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { RootState } from "redux/store";
import { ServiceReturn } from "redux/types";
import {
  batchUpdateDefaultLayouts,
  batchUpdateOrderLayouts,
  batchUpdatePermissions,
  createFormatSet,
  createLayout,
  createPermission,
  deletePredefinedFilter,
  getFormatSets,
  getLayouts,
  getPermissions,
  getPredefinedFilterAttributes,
  getPredefinedFilters,
  removeFormatSet,
  removeLayout,
  updateFormatSet,
  updatePredefinedFilter,
  updatePredefinedFilterAttributes,
} from "services/config";
import { COMMON } from "utils/constants";
import actions from "./actions";
import {
  AssignedFilter,
  Filter,
  FormatProps,
  Layout,
  PredefinedFilterAttributes,
} from "./types";

export function* GET_LAYOUTS() {
  const result: ServiceReturn = yield call(getLayouts);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        layouts: result.data,
      })
    );
  } else {
    yield put(actions.SET_STATE({ layouts: [] }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface CREATE_LAYOUT_Payload {
  name: string;
  filters: Filter[];
  order: number;
  module: string;
  itemFilter?: string;
}

export function* CREATE_LAYOUT(
  input: ReturnType<typeof actions.CREATE_LAYOUT>
) {
  const { name, filters, order, module, itemFilter } = input.payload;
  yield put(
    actions.SET_STATE({
      createLayoutLoading: true,
    })
  );

  const result: ServiceReturn = yield call(
    createLayout,
    name,
    filters,
    order,
    module,
    itemFilter
  );
  if (result.data) {
    yield put(
      actions.SET_STATE({ layouts: result.data, createLayoutLoading: false })
    );
    yield put(alertActions.SUCCESS("Layout created."));
  } else {
    yield put(actions.SET_STATE({ createLayoutLoading: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_LAYOUT_ORDER_Payload {
  layouts: Layout[];
}

export function* UPDATE_LAYOUT_ORDER(
  input: ReturnType<typeof actions.UPDATE_LAYOUT_ORDER>
) {
  const { layouts } = input.payload;
  const result: ServiceReturn = yield call(batchUpdateOrderLayouts, layouts);
  if (result.data) {
    yield put(actions.SET_STATE({ layouts: result.data }));
    yield put(alertActions.SUCCESS("Order updated."));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface CHANGE_DEFAULT_LAYOUT_Payload {
  currentDefault?: Layout;
  newDefault: Layout;
}

export function* CHANGE_DEFAULT_LAYOUT(
  input: ReturnType<typeof actions.CHANGE_DEFAULT_LAYOUT>
) {
  const { currentDefault, newDefault } = input.payload;
  const updatedLayouts = [
    {
      ...newDefault,
      isDefault: true,
    },
  ];
  // New modules might not have a default layout at first
  if (currentDefault)
    updatedLayouts.push({
      ...currentDefault,
      isDefault: false,
    });
  const result: ServiceReturn = yield call(
    batchUpdateDefaultLayouts,
    updatedLayouts
  );
  if (result.data) {
    yield put(actions.SET_STATE({ layouts: result.data }));
    yield put(alertActions.SUCCESS("Default layout updated."));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface REMOVE_DEFAULT_LAYOUT_Payload {
  updatedLayouts: Layout[];
}

export function* REMOVE_DEFAULT_LAYOUT(
  input: ReturnType<typeof actions.REMOVE_DEFAULT_LAYOUT>
) {
  const { updatedLayouts } = input.payload;

  const result: ServiceReturn = yield call(
    batchUpdateDefaultLayouts,
    updatedLayouts
  );
  if (result.data) {
    yield put(actions.SET_STATE({ layouts: result.data }));
    yield put(alertActions.SUCCESS("Default layout successfully removed."));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface DELETE_LAYOUT_Payload {
  id: string;
}
export function* DELETE_LAYOUT(
  input: ReturnType<typeof actions.DELETE_LAYOUT>
) {
  const { id } = input.payload;
  yield put(
    actions.SET_STATE({
      deleteLayoutLoading: true,
    })
  );

  const result: ServiceReturn = yield call(removeLayout, id);
  if (result.data) {
    yield put(
      actions.SET_STATE({ layouts: result.data, deleteLayoutLoading: false })
    );
    yield put(alertActions.SUCCESS("Layout deleted."));
  } else {
    yield put(actions.SET_STATE({ deleteLayoutLoading: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export function* GET_PERMISSIONS() {
  yield put(
    actions.SET_STATE({
      permissionsLoading: true,
    })
  );
  const result: ServiceReturn = yield call(getPermissions);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        permissionsLoading: false,
        permissions: result.data,
      })
    );
  } else {
    yield put(
      actions.SET_STATE({ permissionsLoading: false, permissions: [] })
    );
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface CREATE_PERMISSION_Payload {
  permission: PermissionData;
}

export function* CREATE_PERMISSION(
  input: ReturnType<typeof actions.CREATE_PERMISSION>
) {
  const { permission } = input.payload;
  yield put(
    actions.SET_STATE({
      createPermissionLoading: true,
    })
  );

  const result: ServiceReturn = yield call(createPermission, permission);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        permissions: result.data,
        createPermissionLoading: false,
      })
    );
    yield put(alertActions.SUCCESS("Permission created."));
  } else {
    yield put(actions.SET_STATE({ createPermissionLoading: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_PERMISSIONS_Payload {
  permissions: Permission[];
}

export function* UPDATE_PERMISSIONS(
  input: ReturnType<typeof actions.UPDATE_PERMISSIONS>
) {
  const { permissions } = input.payload;
  yield put(
    actions.SET_STATE({
      updatePermissionLoading: true,
    })
  );

  const result: ServiceReturn = yield call(batchUpdatePermissions, permissions);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        permissions: result.data,
        updatePermissionLoading: false,
      })
    );
    yield put(alertActions.SUCCESS("Permissions updated."));
  } else {
    yield put(actions.SET_STATE({ updatePermissionLoading: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export function* GET_FORMAT_SETS() {
  const result: ServiceReturn = yield call(getFormatSets);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        formatSets: result.data,
      })
    );
  } else {
    yield put(actions.SET_STATE({ formatSets: [] }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface CREATE_FORMAT_SET_Payload {
  formattings: FormatProps[];
  module: string;
}

export function* CREATE_FORMAT_SET(
  input: ReturnType<typeof actions.CREATE_FORMAT_SET>
) {
  const { formattings, module } = input.payload;
  yield put(
    actions.SET_STATE({
      createFormatSetLoading: true,
    })
  );

  const result: ServiceReturn = yield call(
    createFormatSet,
    formattings,
    module
  );
  if (result.data) {
    yield put(
      actions.SET_STATE({
        formatSets: result.data,
        createFormatSetLoading: false,
      })
    );
    yield put(alertActions.SUCCESS("Format set created."));
  } else {
    yield put(actions.SET_STATE({ createFormatSetLoading: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_FORMAT_SET_Payload {
  id: string;
  formattings: FormatProps[];
}
export function* UPDATE_FORMAT_SET(
  input: ReturnType<typeof actions.UPDATE_FORMAT_SET>
) {
  const { id, formattings } = input.payload;
  yield put(
    actions.SET_STATE({
      editFormatSetLoading: true,
    })
  );

  const result: ServiceReturn = yield call(updateFormatSet, id, formattings);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        formatSets: result.data,
        editFormatSetLoading: false,
      })
    );
    yield put(alertActions.SUCCESS("Format set updated."));
  } else {
    yield put(actions.SET_STATE({ editFormatSetLoading: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface DELETE_FORMAT_SET_Payload {
  id: string;
}
export function* DELETE_FORMAT_SET(
  input: ReturnType<typeof actions.DELETE_FORMAT_SET>
) {
  const { id } = input.payload;
  yield put(
    actions.SET_STATE({
      deletingFormatSetId: id,
    })
  );

  const result: ServiceReturn = yield call(removeFormatSet, id);
  if (result.data) {
    yield put(
      actions.SET_STATE({ formatSets: result.data, deletingFormatSetId: "" })
    );
    yield put(alertActions.SUCCESS("Format set deleted."));
  } else {
    yield put(actions.SET_STATE({ deletingFormatSetId: "" }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export function* GET_PREDEFINED_FILTER_ATTRIBUTES() {
  yield put(actions.SET_STATE({ loadingPredefinedFilterAttributes: true }));
  const result: ServiceReturn = yield call(getPredefinedFilterAttributes);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        predefinedFilterAttributes: result.data,
      })
    );
  } else {
    yield put(actions.SET_STATE({ predefinedFilterAttributes: {} }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
  yield put(actions.SET_STATE({ loadingPredefinedFilterAttributes: false }));
}

export interface UPDATE_PREDEFINED_FILTER_ATTRIBUTES_payload {
  data: PredefinedFilterAttributes;
}

export function* UPDATE_PREDEFINED_FILTER_ATTRIBUTES(
  input: ReturnType<typeof actions.UPDATE_PREDEFINED_FILTER_ATTRIBUTES>
) {
  yield put(actions.SET_STATE({ loadingPredefinedFilterAttributes: true }));

  const { data } = input.payload;
  const result: ServiceReturn = yield call(
    updatePredefinedFilterAttributes,
    data
  );

  if (result.data) {
    yield put(
      actions.SET_STATE({
        predefinedFilterAttributes: result.data,
      })
    );
    yield put(alertActions.SUCCESS("Predefined filter attributes updated."));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }

  yield put(actions.GET_PREDEFINED_FILTER_ATTRIBUTES());
  yield put(actions.SET_STATE({ loadingPredefinedFilterAttributes: false }));
}

export interface GET_PREDEFINED_FILTERS_PAYLOAD {
  module: string;
}

export function* GET_PREDEFINED_FILTERS(
  input: ReturnType<typeof actions.GET_PREDEFINED_FILTERS>
) {
  yield put(actions.SET_STATE({ loadingPredefinedFilters: true }));

  const { module } = input.payload;
  const predefinedFilters: {
    [key: string]: AssignedFilter[];
  } = yield select((state: RootState) => state.appConfig.predefinedFilters);

  const result: ServiceReturn = yield call(getPredefinedFilters, module);
  if (result.data) {
    yield put(
      actions.SET_STATE({
        predefinedFilters: { ...predefinedFilters, [module]: result.data },
      })
    );
  } else {
    yield put(
      actions.SET_STATE({
        predefinedFilters: { ...predefinedFilters, [module]: [] },
      })
    );
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }

  yield put(actions.SET_STATE({ loadingPredefinedFilters: false }));
}

export interface UPDATE_PREDEFINED_FILTER_PAYLOAD {
  data: AssignedFilter;
}

export function* UPDATE_PREDEFINED_FILTER(
  input: ReturnType<typeof actions.UPDATE_PREDEFINED_FILTER>
) {
  const { data } = input.payload;
  yield put(
    actions.SET_STATE({
      loadingPredefinedFilters: true,
    })
  );

  const result: ServiceReturn = yield call(updatePredefinedFilter, data);
  if (result.data) {
    yield put(actions.GET_PREDEFINED_FILTERS({ module: data.module }));
    yield put(alertActions.SUCCESS("Predefined filters updated."));
  } else {
    yield put(actions.SET_STATE({ loadingPredefinedFilters: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface DELETE_PREDEFINED_FILTER_payload {
  id: string;
  module: string;
}

export function* DELETE_PREDEFINED_FILTER(
  input: ReturnType<typeof actions.DELETE_PREDEFINED_FILTER>
) {
  const { id, module } = input.payload;
  yield put(
    actions.SET_STATE({
      loadingPredefinedFilters: true,
    })
  );
  const result: ServiceReturn = yield call(deletePredefinedFilter, id);
  if (result.data) {
    yield put(alertActions.SUCCESS("Predefined filter deleted."));
    yield put(actions.GET_PREDEFINED_FILTERS({ module }));
  } else {
    yield put(actions.SET_STATE({ loadingPredefinedFilters: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export default function* rootSaga() {
  yield all([
    // Advanced Filter Panel Layouts
    takeLatest(actions.GET_LAYOUTS, GET_LAYOUTS),
    takeLatest(actions.CREATE_LAYOUT, CREATE_LAYOUT),
    takeLatest(actions.UPDATE_LAYOUT_ORDER, UPDATE_LAYOUT_ORDER),
    takeLatest(actions.CHANGE_DEFAULT_LAYOUT, CHANGE_DEFAULT_LAYOUT),
    takeLatest(actions.REMOVE_DEFAULT_LAYOUT, REMOVE_DEFAULT_LAYOUT),
    takeLatest(actions.DELETE_LAYOUT, DELETE_LAYOUT),
    // Permissions Module
    takeLatest(actions.GET_PERMISSIONS, GET_PERMISSIONS),
    takeLatest(actions.CREATE_PERMISSION, CREATE_PERMISSION),
    takeLatest(actions.UPDATE_PERMISSIONS, UPDATE_PERMISSIONS),
    // Conditional Formatting
    takeLatest(actions.GET_FORMAT_SETS, GET_FORMAT_SETS),
    takeLatest(actions.CREATE_FORMAT_SET, CREATE_FORMAT_SET),
    takeLatest(actions.UPDATE_FORMAT_SET, UPDATE_FORMAT_SET),
    takeLatest(actions.DELETE_FORMAT_SET, DELETE_FORMAT_SET),
    // Predefined Filters
    takeLatest(
      actions.GET_PREDEFINED_FILTER_ATTRIBUTES,
      GET_PREDEFINED_FILTER_ATTRIBUTES
    ),
    takeLatest(
      actions.UPDATE_PREDEFINED_FILTER_ATTRIBUTES,
      UPDATE_PREDEFINED_FILTER_ATTRIBUTES
    ),
    takeLatest(actions.GET_PREDEFINED_FILTERS, GET_PREDEFINED_FILTERS),
    takeLatest(actions.UPDATE_PREDEFINED_FILTER, UPDATE_PREDEFINED_FILTER),
    takeLatest(actions.DELETE_PREDEFINED_FILTER, DELETE_PREDEFINED_FILTER),
  ]);
}
