import { DocumentData } from "firebase/firestore";
import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import actions from "redux/docs/actions";
import { ServiceReturn } from "redux/types";
import {
  createAttribute,
  createDoc,
  createFolder,
  getDocsCollection,
  updateComments,
  updateDocDocument,
  updateMarkdown,
} from "services/docs";
import { setDocListener, setDocsListener } from "services/listeners/docs";
import { COMMON } from "utils/constants";
import { Comment, Doc, DocData, FolderData } from "./types";

export interface CREATE_ATTRIBUTE_Payload {
  refPath: string;
  data: Doc;
}

export function* CREATE_ATTRIBUTE(
  input: ReturnType<typeof actions.CREATE_ATTRIBUTE>
) {
  const { refPath, data } = input.payload;

  yield put(actions.SET_STATE({ loadingAdd: true }));

  const result: ServiceReturn = yield call(createAttribute, refPath, data);

  if (result.data) {
    yield put(alertActions.SUCCESS("Attribute Created"));
    yield put(actions.SET_STATE({ loadingAdd: false }));
  } else {
    yield put(alertActions.ERROR(result.error));
    yield put(actions.SET_STATE({ loadingAdd: false }));
  }
}

export interface CREATE_FOLDER_Payload {
  folderData: FolderData;
}

export function* CREATE_FOLDER(
  input: ReturnType<typeof actions.CREATE_FOLDER>
) {
  const { folderData } = input.payload;

  const result: ServiceReturn = yield call(createFolder, folderData);

  if (result.data) {
    yield put(alertActions.SUCCESS("Folder Created"));
  } else {
    yield put(alertActions.ERROR(result.error));
  }
}

export interface CREATE_DOC_Payload {
  docData: DocData;
}

export function* CREATE_DOC(input: ReturnType<typeof actions.CREATE_DOC>) {
  const { docData } = input.payload;

  const result: ServiceReturn = yield call(createDoc, docData);

  if (result.data) {
    yield put(alertActions.SUCCESS("Doc Created"));
  } else {
    yield put(alertActions.ERROR(result.error));
  }
}

export interface GET_DOCS_Payload {
  docName: string;
}

export function* GET_DOCS(input: ReturnType<typeof actions.GET_DOCS>) {
  const { docName } = input.payload;

  yield put(actions.SET_STATE({ loading: true }));

  const docs: DocumentData[] = yield call(getDocsCollection, docName);
  if (docName === "Admin Panel") {
    yield put(
      actions.SET_STATE({
        adminDocs: docs,
        loading: false,
      })
    );
  } else if (docName === "HRMS") {
    yield put(
      actions.SET_STATE({
        hrmsDocs: docs,
        loading: false,
      })
    );
  }
}

export interface SUBSCRIBE_TO_DOC_Payload {
  refPath: string;
}

function* SUBSCRIBE_TO_DOC(inp: ReturnType<typeof actions.SUBSCRIBE_TO_DOC>) {
  const { refPath } = inp.payload;

  yield put(
    actions.SET_STATE({
      loadingDoc: true,
      activeDoc: null,
    })
  );

  const channel: EventChannel<boolean> = yield call(setDocListener, refPath);

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_DOC);
    channel.close();
  });

  try {
    while (true) {
      const doc: Doc = yield take(channel);
      yield put(
        actions.SET_STATE({
          activeDoc: doc,
          loadingDoc: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loadingDoc: false }));
    }
  }
}

function* SUBSCRIBE_TO_DOCS() {
  yield put(actions.SET_STATE({ loading: true, docs: [] }));

  const channel: EventChannel<boolean> = yield call(setDocsListener, "docs");

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_ADMIN_DOCS);
    channel.close();
  });

  try {
    while (true) {
      const docs: FolderData[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          docs,
          loading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loading: false }));
    }
  }
}

function* SUBSCRIBE_TO_ADMIN_DOCS() {
  yield put(actions.SET_STATE({ loading: true, adminDocs: [] }));

  const channel: EventChannel<boolean> = yield call(
    setDocsListener,
    "docs-admin"
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_ADMIN_DOCS);
    channel.close();
  });

  try {
    while (true) {
      const adminDocs: Doc[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          adminDocs,
          loading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loading: false }));
    }
  }
}

function* SUBSCRIBE_TO_HRMS_DOCS() {
  yield put(actions.SET_STATE({ loading: true, hrmsDocs: [] }));

  const channel: EventChannel<boolean> = yield call(
    setDocsListener,
    "docs-hrms"
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_HRMS_DOCS);
    channel.close();
  });

  try {
    while (true) {
      const hrmsDocs: Doc[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          hrmsDocs,
          loading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(actions.SET_STATE({ loading: false }));
    }
  }
}

export interface UPDATE_DOC_Payload {
  refPath: string;
  newData: Comment[];
}

export function* UPDATE_DOC(input: ReturnType<typeof actions.UPDATE_DOC>) {
  const { refPath, newData } = input.payload;
  yield put(actions.SET_STATE({ loadingUpdate: true }));

  const result: ServiceReturn = yield call(updateDocDocument, refPath, newData);

  if (result.data) {
    yield put(actions.SET_STATE({ loadingUpdate: false }));
    yield put(alertActions.SUCCESS("Doc Updated"));
  } else {
    yield put(actions.SET_STATE({ loadingUpdate: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_MARKDOWN_Payload {
  refPath: string;
  newData: string;
  position: string;
}
export function* UPDATE_MARKDOWN(
  input: ReturnType<typeof actions.UPDATE_MARKDOWN>
) {
  const { refPath, newData, position } = input.payload;
  yield put(actions.SET_STATE({ loadingUpdate: true }));

  const result: ServiceReturn = yield call(
    updateMarkdown,
    refPath,
    newData,
    position
  );

  if (result.data) {
    yield put(actions.SET_STATE({ loadingUpdate: false }));
    yield put(alertActions.SUCCESS("Markdown Updated"));
  } else {
    yield put(actions.SET_STATE({ loadingUpdate: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_COMMENTS_Payload {
  refPath: string;
  data: Comment[];
}
export function* UPDATE_COMMENTS(
  input: ReturnType<typeof actions.UPDATE_COMMENTS>
) {
  const { refPath, data } = input.payload;
  yield put(actions.SET_STATE({ loadingComment: true }));

  const result: ServiceReturn = yield call(updateComments, refPath, data);

  if (result.data) {
    yield put(actions.SET_STATE({ loadingComment: false }));
    yield put(alertActions.SUCCESS("Comments have been updated"));
  } else {
    yield put(actions.SET_STATE({ loadingComment: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface UPDATE_EDIT_COMMENTS_Payload {
  refPath: string;
  data: Comment[];
}
export function* UPDATE_EDIT_COMMENTS(
  input: ReturnType<typeof actions.UPDATE_EDIT_COMMENTS>
) {
  const { refPath, data } = input.payload;
  yield put(actions.SET_STATE({ loadingEditComment: true }));

  const result: ServiceReturn = yield call(updateComments, refPath, data);

  if (result.data) {
    yield put(actions.SET_STATE({ loadingEditComment: false }));
    yield put(alertActions.SUCCESS("Comments have been edited"));
  } else {
    yield put(actions.SET_STATE({ loadingEditComment: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.CREATE_ATTRIBUTE, CREATE_ATTRIBUTE),
    takeLatest(actions.CREATE_FOLDER, CREATE_FOLDER),
    takeLatest(actions.CREATE_DOC, CREATE_DOC),
    takeLatest(actions.GET_DOCS, GET_DOCS),
    takeLatest(actions.SUBSCRIBE_TO_DOC, SUBSCRIBE_TO_DOC),
    takeLatest(actions.SUBSCRIBE_TO_DOCS, SUBSCRIBE_TO_DOCS),
    takeLatest(actions.SUBSCRIBE_TO_ADMIN_DOCS, SUBSCRIBE_TO_ADMIN_DOCS),
    takeLatest(actions.SUBSCRIBE_TO_HRMS_DOCS, SUBSCRIBE_TO_HRMS_DOCS),
    takeLatest(actions.UPDATE_DOC, UPDATE_DOC),
    takeLatest(actions.UPDATE_MARKDOWN, UPDATE_MARKDOWN),
    takeLatest(actions.UPDATE_COMMENTS, UPDATE_COMMENTS),
    takeLatest(actions.UPDATE_EDIT_COMMENTS, UPDATE_EDIT_COMMENTS),
  ]);
}
