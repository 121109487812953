import { Icon, MenuItem, TagProps } from "@blueprintjs/core";
import {
  ItemRenderer,
  ItemRendererProps,
  MultiSelect,
} from "@blueprintjs/select";
import InputActionConfirmationDialogue from "components/input-action-confirmation-dialogue";
import React, { useState } from "react";
import { DocsType } from "redux/settings/types";

type Props = {
  docsTypes: DocsType[];
  newDocsTypes: DocsType[];
  setNewDocsTypes: React.Dispatch<React.SetStateAction<DocsType[]>>;
};

const DocsTypes = ({ docsTypes, newDocsTypes, setNewDocsTypes }: Props) => {
  const [query, setQuery] = useState("");

  const getTagProps = (_value: React.ReactNode, _: number): TagProps => {
    const item = (_value as React.ReactElement).toString();
    return {
      interactive: true,
      minimal: true,
      onRemove: undefined,
      rightIcon: renderDeleteIcon(item),
    };
  };

  function getItemLabel(type: string) {
    return newDocsTypes.find((docsType: DocsType) => docsType.text === type)
      ?.text;
  }

  function deleteType(type: string) {
    setNewDocsTypes(
      newDocsTypes.map((obj) =>
        obj.text === type ? { ...obj, isSelected: false } : obj
      )
    );
  }

  const renderItemTags: ItemRenderer<string> = (
    item: string,
    { handleClick }: ItemRendererProps
  ) => {
    if (!item) return null;
    return (
      <MenuItem
        active={newDocsTypes.find((type) => type.text === item)?.isSelected}
        key={item}
        onClick={handleClick}
        roleStructure="listoption"
        text={getItemLabel(item)}
      />
    );
  };

  const renderDeleteIcon = (item: any) => {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <InputActionConfirmationDialogue
          hasDoubleConfirmation={false}
          onConfirm={() => deleteType(item)}
          title="Delete Docs Type"
          confirmationText="DELETE"
        >
          <Icon icon="cross" />
        </InputActionConfirmationDialogue>
      </div>
    );
  };

  const addNewOption = (type: string) => {
    return type;
  };

  const handleAddNewType = (type: string) => {
    setQuery("");
    setNewDocsTypes([
      ...newDocsTypes,
      {
        text: type,
        isSelected: true,
      },
    ]);
  };

  const handleItemSelect = (type: string) => {
    setNewDocsTypes(
      newDocsTypes.map((obj) =>
        obj.text === type ? { ...obj, isSelected: !obj.isSelected } : obj
      )
    );
  };

  const handleItemRemove = (type: string, index: number) => {
    const typesInUsage = docsTypes.filter((type) => type.isSelected === true);
    if (index < typesInUsage.length) {
      return undefined;
    } else {
      setNewDocsTypes(
        newDocsTypes.map((obj) =>
          obj.text === type ? { ...obj, isSelected: false } : obj
        )
      );
    }
  };

  const handleQueryChange = (query: string) => {
    setQuery(query);
  };

  const filterItem = (query: string, item: any) => {
    return item.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  return (
    <div>
      <p>Docs Types Options:</p>
      <MultiSelect
        className="mt-2"
        placeholder="Docs Types"
        resetOnQuery={true}
        resetOnSelect={true}
        createNewItemFromQuery={addNewOption}
        createNewItemRenderer={(val) => {
          return (
            <MenuItem
              key={val}
              text={val}
              icon="add"
              roleStructure="listoption"
              onClick={() => handleAddNewType(val)}
            />
          );
        }}
        selectedItems={newDocsTypes
          .filter((item) => item.isSelected)
          .map((item) => item.text)}
        tagRenderer={(item) => getItemLabel(item)}
        tagInputProps={{
          tagProps: getTagProps,
        }}
        items={newDocsTypes.map((type) => type.text)}
        itemRenderer={renderItemTags}
        itemPredicate={filterItem}
        onItemSelect={handleItemSelect}
        onRemove={handleItemRemove}
        popoverProps={{ minimal: true }}
        onQueryChange={handleQueryChange}
        query={query}
      />
    </div>
  );
};

export default DocsTypes;
