import { createActions } from "redux/create-actions";
import { UPDATE_ACCESS_LEVELS_Payload } from "./sagas";
import { AccessLevelState } from "./types";

const actions = createActions("access-levels", {
  SET_STATE: {} as Partial<AccessLevelState>,
  SUBSCRIBE_TO_ACCESS_LEVELS: undefined,
  UNSUBSCRIBE_FROM_ACCESS_LEVELS: undefined,
  UPDATE_ACCESS_LEVELS: {} as UPDATE_ACCESS_LEVELS_Payload,
});

export default actions;
