import React, { useEffect, useMemo, useState } from "react";

// COMPONENTS
import { Loader } from "@stockifi/shared";
import AdvancedFilterPanel from "components/advanced-filter-panel";
import type { Attribute } from "components/edit-pinned-attributes";
import Table from "components/table";

import actionsAccessLevels from "redux/access-levels/actions";
import alertActions from "redux/alert/actions";
// REDUX
import { useAppDispatch, useAppSelector } from "redux/hooks";
import invoiceActions from "redux/invoice/actions";
import { CheckedInvoice, Invoice, Items } from "redux/invoice/types";
import userActions from "redux/users/actions";
import { User } from "redux/users/types";

// STYLES
import { Button } from "@blueprintjs/core";
import ShowItemList from "./show-item-list-btn";
import ShowItems from "./show-items-btn";

import { createSelector } from "@reduxjs/toolkit";
import InputActionConfirmationDialogue from "components/input-action-confirmation-dialogue";
// UTILS
import moment from "moment";
import { RootState } from "redux/store";
import { getUserName } from "redux/users/helpers";
import { claims, hasAccess } from "../../../permissions";
import { ITEM_TAGS, ItemTag } from "../tag.helpers";
import ExpandedContent from "./expanded-content";

const defaultCols = [
  { id: 1, name: "username" },
  { id: 2, name: "state" },
  { id: 3, name: "isChecked" },
  { id: 4, name: "createdAt" },
  { id: 5, name: "deliveryDate" },
  { id: 6, name: "tags" },
];

const excludedProps = [
  "items",
  "ocr",
  "thumbnails",
  "updatedCosts",
  "files",
  "itemsList",
  "url",
  "ref",
  "documentAIInvoiceDataFiles",
  "documentAIDataFiles",
  "createdAt",
  "updatedAt",
  "dueDate",
  "startedAt",
  "resolvedAt",
  "deliveryDate",
  "ocrDataFiles",
  "orientation",
  "path",
];

type InvoiceWithUsernameTags = Invoice & { username: string; tags: string[] };

const TableView = ({
  setModification,
}: {
  setModification: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const dispatch = useAppDispatch();

  const users = useAppSelector((state) => state.users.users);
  const tableColumns = useAppSelector((state) => state.users.tableColumns);
  const userAuth = useAppSelector((state) => state.auth.user);
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const loading = useAppSelector((state) => state.invoices.loadingInvoice);
  const tableViewInvoicesState = (state: RootState) =>
    state.invoices.tableViewInvoices;
  const invoicesSelector = createSelector(
    [tableViewInvoicesState],
    (tableViewInvoicesState) => tableViewInvoicesState?.data ?? []
  );
  const invoices = useAppSelector(invoicesSelector);
  const dateFrom = useAppSelector(
    (state) => state.invoices.tableViewInvoices?.dateFrom
  );
  const dateTo = useAppSelector(
    (state) => state.invoices.tableViewInvoices?.dateTo
  );
  const suppliers = useAppSelector((state) => state.suppliers.data);
  const userItems = useAppSelector((state) => state.invoices.userItems);

  const [pinnedCols, setPinnedCols] = useState<Attribute[]>(defaultCols);
  const [attrMap, setAttrMap] = useState<Map<string, string>>(new Map());
  const [mappedInvoice, setMappedInvoice] = useState<InvoiceWithUsernameTags[]>(
    []
  );
  const [isSelectedAll, setSelectedAll] = useState<Record<number, boolean>>({});
  const [selectedInvoices, setSelectedInvoices] = useState<
    Record<number, string[]>
  >({});
  const [page, setPage] = useState<number>(1);
  const [sortedInvoices, setSortedInvoices] =
    useState<InvoiceWithUsernameTags[]>(mappedInvoice);
  const [filterFrom, setFilterFrom] = useState<number>(0);
  const [filterPerPage, setFilterPerPage] = useState<number>(10);

  const [advancedFilteredInvoices, setAdvancedFilteredInvoices] = useState<
    InvoiceWithUsernameTags[]
  >([]);

  const [currentlyResolving, setCurrentlyResolving] = useState<{
    id: string;
    state: string;
  } | null>(null);
  const [previewNext, setPreviewNext] = useState<string>("");

  useEffect(() => {
    dispatch(invoiceActions.SUBSCRIBE_TO_TABLE_VIEW_INVOICES({}));
    dispatch(actionsAccessLevels.SUBSCRIBE_TO_ACCESS_LEVELS());
    return () => {
      dispatch(invoiceActions.UNSUBSCRIBE_FROM_TABLE_VIEW_INVOICES());
      dispatch(actionsAccessLevels.UNSUBSCRIBE_FROM_ACCESS_LEVELS());
      dispatch(invoiceActions.SET_STATE({ userId: undefined }));
      resetIndicator();
    };
  }, []);

  useEffect(() => {
    if (Object.keys(tableColumns).length !== 0) {
      setPinnedCols(getUserColumns(tableColumns));
    }
  }, [tableColumns]);

  const getUserColumns = (invColumns: Record<string, number>) => {
    if (!invColumns) return [];
    const userCols = [{ id: 1, name: "id" }];
    Object.entries(invColumns).forEach(([name, id]) => {
      userCols.push({ id, name });
    });
    return userCols.sort((a, b) => a.id - b.id);
  };

  useEffect(() => {
    if (!loading) {
      const targetInvoices: Invoice[] = invoices;
      setMappedInvoice(
        targetInvoices.map((invoice: Invoice) => {
          const itemsList = invoice.itemsList;

          let total = 0;
          if (!!invoice.itemsList && !!invoice.items) {
            total = invoice.itemsList.reduce((a, b) => a + (b.total || 0), 0);
          }
          if (invoice.foodTotal) total += invoice.foodTotal;

          const getNumberofItems = (tag: ItemTag) => {
            return itemsList?.filter((i) => tag.filterFn(i)).length;
          };

          const invoiceTags = [];
          for (const tag of ITEM_TAGS) {
            const tagCount = getNumberofItems(tag);

            if (tagCount > 0) {
              invoiceTags.push(tag.name);
            }
          }

          if (total < 500 && invoice.state !== "unresolved") {
            invoiceTags.push("Grand Total < 500");
          }
          if (invoice.isOpenSupplier) {
            invoiceTags.push("openSupplier");
          }

          return {
            ...invoice,
            username: getUserName(invoice.userId),
            tags: invoiceTags,
          };
        })
      );
    }
  }, [invoices]);

  useEffect(() => {
    if (mappedInvoice.length && !loading) {
      getAllAttributes();
    }
  }, [mappedInvoice]);

  useEffect(() => {
    if (currentlyResolving) {
      setIndicator();
      setPreviewNext(currentlyResolving.id);
    } else {
      setPreviewNext("close");
      resetIndicator();
    }
  }, [currentlyResolving]);

  useEffect(() => {
    if (
      userAuth &&
      (hasAccess(authClaims, claims.resolvingInvoices) ||
        hasAccess(authClaims, claims.checkingPriorityList))
    ) {
      window.addEventListener("unload", resetOnClose);
      return () => {
        window.removeEventListener("unload", resetOnClose);
      };
    }
  }, [userAuth]);

  const resetOnClose = () => {
    resetIndicator();
    navigator.sendBeacon(
      "https://us-central1-stocklio-playground.cloudfunctions.net/users-resetResolvingUserId",
      JSON.stringify({ userId: userAuth })
    );
  };

  const resetIndicator = () => {
    if (!userAuth) return;
    dispatch(
      userActions.UPDATE_USER({
        user: { resolvingInvoiceId: null, checkingInvoiceId: null },
        userId: userAuth,
      })
    );
  };

  const setIndicator = () => {
    if (!userAuth) return;
    dispatch(
      userActions.UPDATE_USER({
        user: {
          resolvingInvoiceId:
            currentlyResolving?.state === "unresolved"
              ? currentlyResolving.id
              : null,
          checkingInvoiceId:
            currentlyResolving?.state === "resolved"
              ? currentlyResolving.id
              : null,
        },
        userId: userAuth,
      })
    );
  };

  const getInvoices = (direction?: "next" | "prev") => {
    dispatch(
      invoiceActions.SUBSCRIBE_TO_TABLE_VIEW_INVOICES({
        direction,
        dateFrom: dateFrom,
        dateTo: dateTo,
      })
    );
  };

  const getAllAttributes = () => {
    const allAttributes = new Map();
    mappedInvoice.forEach((invoice: Invoice) => {
      Object.keys(invoice).forEach((property) => {
        const propValue = invoice[property as keyof typeof invoice];
        if (
          propValue !== null &&
          propValue !== undefined &&
          propValue !== "" &&
          (typeof propValue === "string" ||
            typeof propValue === "boolean" ||
            (typeof propValue === "number" && !isNaN(propValue)) ||
            property === "tags")
        ) {
          if (!allAttributes.has(property)) {
            allAttributes.set(property, typeof propValue);
          }
        }
      });
    });
    setAttrMap(allAttributes);
  };

  const handleSelectAll = (e: React.FormEvent<HTMLInputElement>) => {
    const isChecked = e.currentTarget.checked;
    setSelectedAll((prev) => ({ ...prev, [page]: isChecked }));
    if (isChecked) {
      setSelectedInvoices((prev) => ({
        ...prev,
        [page]: sortedInvoices
          .slice(filterFrom, filterFrom + filterPerPage)
          .filter((invoice) => invoice.state !== "unresolved")
          .map((li: Invoice) => li.id),
      }));
    } else {
      setSelectedInvoices((prev) => ({ ...prev, [page]: [] }));
    }
  };

  const handleSelectInvoice = (e: React.FormEvent<HTMLInputElement>) => {
    const { id, checked } = e.currentTarget;
    const updatedCheckArray = [...(selectedInvoices[page] ?? []), id];
    setSelectedInvoices((prev) => ({ ...prev, [page]: updatedCheckArray }));
    if (!checked) {
      setSelectedInvoices((prev) => ({
        ...prev,
        [page]: prev[page].filter((invoiceId) => invoiceId !== id),
      }));
      setSelectedAll((prev) => ({ ...prev, [page]: false }));
    } else if (checked && updatedCheckArray.length === invoices.length) {
      setSelectedAll((prev) => ({ ...prev, [page]: true }));
    }
  };

  const getExpandedContent = (datum: any, expanded: boolean, index: number) => {
    return <ExpandedContent datum={datum} index={index} />;
  };

  const getFieldValue = (field: string, value: any) => {
    switch (field) {
      case "createdAt":
        if (value === null) return "";
        return moment(value).format("ddd MMMM DD YYYY hh:mm:ss");
      case "deliveryDate":
        if (value === null) return "";
        return moment(value).format("ddd MMMM DD YYYY hh:mm:ss");
      case "tags":
        if (value.length === 0) return "";
        return value
          .map(
            (tag: string) => ITEM_TAGS.find((x) => x.name === tag)?.text ?? tag
          )
          .join(", ");
      default:
        if (value === null) return "";
        if (value === undefined) return "";
        if (typeof value === "boolean") {
          return value ? "Yes" : "No";
        }
        return value.toString();
    }
  };

  const advancedFilteredInvoiceWithUser = useMemo(() => {
    const mappedInvoice = advancedFilteredInvoices.map((invoice) => ({
      ...invoice,
      resolvingInvoices: users.filter((user) =>
        user.resolvingInvoiceId?.includes(invoice.id)
      ),
      checkingInvoices: users.filter((user) =>
        user.checkingInvoiceId?.includes(invoice.id)
      ),
    }));

    return hasAccess(authClaims, claims.checkInvoice)
      ? mappedInvoice
      : mappedInvoice.filter((invoice) => !invoice.isChecked);
  }, [advancedFilteredInvoices, users]);

  const getActions = (
    data: Invoice & {
      resolvingInvoices: User[] | undefined;
      checkingInvoices: User[] | undefined;
    }
  ) => {
    return (
      <div
        className="d-flex"
        style={{ gap: 8 }}
        onClick={(e) => e.stopPropagation()}
      >
        <Button
          icon={
            data.state === "resolved"
              ? hasAccess(authClaims, claims.checkInvoice)
                ? "tick"
                : "eye-open"
              : "edit"
          }
          intent={
            data.isChecked && hasAccess(authClaims, claims.checkInvoice)
              ? "success"
              : undefined
          }
          onClick={() => {
            const user = users?.find((user) => user.id === data.userId);
            setModification(
              user && user.isInvoiceVotingEnabled
                ? "dataVoting"
                : "dataLabeling"
            );

            const currentResolve = { id: data.id, state: data.state };
            setCurrentlyResolving((prev) =>
              prev?.id === data.id ? null : currentResolve
            );
            dispatch(invoiceActions.SET_STATE({ userId: data.userId }));
          }}
          title={
            data.state === "resolved" ? "Check Invoice" : "Resolve Invoice"
          }
          loading={
            userAuth && currentlyResolving?.id === data.id
              ? currentlyResolving.state === "resolved"
                ? !data.checkingInvoices
                    ?.map((inv) => inv.id)
                    .includes(userAuth)
                : !data.resolvingInvoices
                    ?.map((inv) => inv.id)
                    .includes(userAuth)
              : !!(
                  data.checkingInvoices?.length ||
                  data.resolvingInvoices?.length
                )
          }
        />
        <ShowItems items={data?.items} disabled={!data?.items} />
        <ShowItemList itemList={data?.itemsList} disabled={!data?.itemsList} />
      </div>
    );
  };

  function markAllAsChecked() {
    const invoicesArray: CheckedInvoice[] = [];
    const updateAliasPayload: {
      [key: string]: Items[];
    } = {};
    const checkedBy =
      (users.find((x) => x.id === userAuth)?.name as string) ?? userAuth;
    advancedFilteredInvoices
      .filter(
        (invoice) =>
          selectedInvoices[page]?.includes(invoice.id) &&
          !invoice.deleted &&
          !invoice.tags?.includes("openItem") &&
          !invoice.tags?.includes("openSupplier")
      )
      .map((invoice) => {
        if (!invoice.isChecked) {
          invoice.isChecked = true;
          invoice.checkedAt = new Date();
          invoice.checkedBy = checkedBy;
          let supplierId = invoice.supplierId;
          if (invoice.supplierId === invoice.supplierName) {
            const supplier = suppliers.find(
              (supplier) => supplier.name === invoice.supplierId
            );
            if (supplier) {
              supplierId = supplier.id;
            }
          }

          for (const itemId of Object.keys(invoice.newAliases)) {
            const userItem = userItems?.find((x: Items) => x.id === itemId);
            const newAlias = invoice.newAliases[itemId];
            if (userItem && newAlias) {
              const userItemAliases = userItem.aliases ?? [];
              if (!updateAliasPayload[invoice.id]) {
                updateAliasPayload[invoice.id] = [];
              }
              updateAliasPayload[invoice.id].push({
                ...userItem,
                aliases: Array.from(
                  new Set([...userItemAliases, ...newAlias.map((x) => x.name)])
                ),
              });
            }
          }
          invoice.newAliases = {};

          invoicesArray.push({
            isChecked: true,
            checkedAt: new Date(),
            newAliases: {},
            supplierName: invoice.supplierName,
            supplierId: supplierId,
            checkedBy,
            invoiceId: invoice.id,
            userId: invoice.userId,
            items: invoice.items,
            updatedAt: new Date(),
          });
        }
        return invoice;
      });

    dispatch(
      invoiceActions.BATCH_CHECK_INVOICES({
        invoicesArray,
        updateAliasPayload: updateAliasPayload,
      })
    );

    const openItemInvoices = invoices.filter((x) =>
      x.tags?.includes("openItem")
    );
    const openSupplierInvoices = invoices.filter((x) =>
      x.tags?.includes("openSupplier")
    );
    if (openItemInvoices.length > 0)
      dispatch(
        alertActions.ERROR(
          `Can't check ${openItemInvoices.length} invoices due to having open item`
        )
      );

    if (openSupplierInvoices.length > 0)
      dispatch(
        alertActions.ERROR(
          `Can't check ${openSupplierInvoices.length} invoices due to having open supplier`
        )
      );
  }

  return (
    <div className="mt-4 mb-3">
      <AdvancedFilterPanel
        module="invoices-table-view"
        users={mappedInvoice}
        filteredUsers={advancedFilteredInvoices}
        setFilteredUsers={setAdvancedFilteredInvoices}
        excludedProps={excludedProps}
      />

      <h4>
        Query Page :{" "}
        {`${moment(dateFrom).format("YYYY-MM-DD")} - ${moment(dateTo).format(
          "YYYY-MM-DD"
        )}`}
      </h4>

      <div
        className="mb-4"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <Button
            style={{ marginRight: 4 }}
            disabled={
              moment(dateTo).format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD")
            }
            text="Jump to latest invoice"
            onClick={() => {
              getInvoices();
            }}
          />
          <Button
            disabled={
              moment(dateTo).format("YYYY-MM-DD") ===
              moment(new Date()).format("YYYY-MM-DD")
            }
            text="Go to newer invoice"
            onClick={() => {
              getInvoices("prev");
            }}
          />
          <Button
            text="Go to older invoice"
            onClick={() => {
              getInvoices("next");
            }}
          />
        </div>
        <div>
          {hasAccess(authClaims, claims.markAllInvAsChecked) && (
            <InputActionConfirmationDialogue
              hasDoubleConfirmation={false}
              onConfirm={markAllAsChecked}
              title={"Mark all as checked"}
              confirmationText={"CHECKED"}
            >
              <Button
                icon="tick"
                text="Mark all as checked"
                disabled={selectedInvoices[page]?.length === 0}
              />
            </InputActionConfirmationDialogue>
          )}
        </div>
      </div>

      <Loader loading={loading}>
        <Table
          module="invoices-table-view"
          data={advancedFilteredInvoiceWithUser}
          firstColumn="username"
          attrMap={attrMap}
          pinnedCols={pinnedCols}
          setPinnedCols={setPinnedCols}
          isSelectedAll={isSelectedAll}
          handleSelectAll={handleSelectAll}
          selectedData={selectedInvoices[page] ?? []}
          page={page}
          setPage={setPage}
          handleSelectRow={handleSelectInvoice}
          getFieldValue={getFieldValue}
          getActions={getActions}
          sortedData={sortedInvoices}
          setSortedData={setSortedInvoices}
          filterPerPage={filterPerPage}
          setFilterPerPage={setFilterPerPage}
          filterFrom={filterFrom}
          setFilterFrom={setFilterFrom}
          expandedContent={getExpandedContent}
          previewNext={previewNext}
        />
      </Loader>
    </div>
  );
};

export default TableView;
