import { collection, onSnapshot, query, where } from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { POSItemTask } from "redux/pos-item-tasks/types";
import { db } from "services/firebase";

export function setUserTaskListener(
  userId: string
): EventChannel<POSItemTask[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const coll = collection(db, "users/" + userId + "/tasks/");
    const unsub = onSnapshot(
      query(coll, where("type", "==", "updatedPosItem")),
      (snapshot) => {
        const data: POSItemTask[] = snapshot.docs.map(
          (x) =>
            new POSItemTask({
              ...x.data(),
              userId: x.ref.parent.parent?.id,
            })
        );

        emitter(data);
      }
    );
    return () => unsub();
  });
}
