export type WorkerStatusItem =
  | "IDLE"
  | "RUNNING"
  | "ERROR"
  | "SUCCESS"
  | "EXPIRED";

type WorkerStatusType = {
  IDLE: "IDLE";
  RUNNING: "RUNNING";
  ERROR: "ERROR";
  SUCCESS: "SUCCESS";
  EXPIRED: "EXPIRED";
};

export const WorkerStatus: WorkerStatusType = {
  IDLE: "IDLE",
  RUNNING: "RUNNING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
  EXPIRED: "EXPIRED",
};

export const getStatusColor = (status: WorkerStatusItem) => {
  switch (status) {
    case WorkerStatus.IDLE:
    case WorkerStatus.SUCCESS:
      return "green";
    case WorkerStatus.RUNNING:
      return "orange";
    case WorkerStatus.ERROR:
    case WorkerStatus.EXPIRED:
      return "red";
  }
};
