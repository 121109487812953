import { Button, Icon, Popover } from "@blueprintjs/core";
import { createSelector } from "@reduxjs/toolkit";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/notification-centre/actions";
import { RootState } from "redux/store";
import NotificationPopover from "./notification-popover";

const NotificationDropdown = () => {
  const dispatch = useAppDispatch();
  const selectToastsNotifications = (state: RootState) =>
    state.notificationCentre.toastsNotifications;

  const selectUnreadToastsNotifications = createSelector(
    [selectToastsNotifications],
    (toastNotifications) =>
      toastNotifications.filter((x) => x.status === "unread")
  );

  const unreadNotifications = useAppSelector(
    (state) => state.notificationCentre.navbarNotifications
  );
  const unreadToastsNotifications = useAppSelector(
    selectUnreadToastsNotifications
  );

  const allUnreadNotifications = useMemo(() => {
    return [...unreadToastsNotifications, ...unreadNotifications];
  }, [unreadNotifications, unreadToastsNotifications]);

  const [maxNotification, setMaxNotification] = useState(5);

  useEffect(() => {
    dispatch(actions.SUBSCRIBE_TO_NAVBAR_NOTIFICATIONS({ maxNotification }));

    return () => {
      dispatch(actions.UNSUBSCRIBE_FROM_NAVBAR_NOTIFICATIONS());
    };
  }, [dispatch, maxNotification]);

  return (
    <Popover
      portalClassName="inbox-btn-popover"
      content={
        <NotificationPopover
          setMaxNotification={setMaxNotification}
          notifications={allUnreadNotifications}
        />
      }
      placement="bottom-start"
    >
      <Button
        icon={
          <Icon
            icon={allUnreadNotifications.length > 0 ? "inbox-update" : "inbox"}
            intent={allUnreadNotifications.length > 0 ? "danger" : "none"}
          />
        }
        minimal
      />
    </Popover>
  );
};

export default NotificationDropdown;
