import { DocumentData, DocumentReference, Timestamp } from "firebase/firestore";

export type APTransactionsState = {
  image: TransactionImage[];
  loadingImages: boolean;
  loadingFirstImages: boolean;
  loadingUpload: boolean;
  userStatus: UserStatusTransactions;
  apMetrics: ClassificationCount[];
  loadingApMetrics: boolean;
  loadingUploadAndCreateTransactions: boolean;
};

export type UserStatusTransactions = {
  unresolved: APTransaction[];
  resolved: APTransaction[];
  loadingUnresolved: boolean;
  loadingResolved: boolean;
};

/**
 * AP Transaction Image
 * @category AP Transactions
 */
export type TransactionImage = {
  /** Image ID */
  id: string;
  /** Image loading status */
  loading: boolean;
  /** Image data */
  data: APImageData[] | null;
};

/**
 * AP Transaction Image Data
 * @category AP Transactions
 */
export interface APImageData {
  /** Image file source URL */
  source: string;
  /** Page of the image */
  page: number;
  /** URL of the image */
  url: string;
  /** Error that occurs on conversion process */
  error?: Error;
}

export interface APImageDataUpload extends APImageData {
  /** Indicates which invoice the image belongs to */
  invoiceCounter: number;
}

export class APTransaction {
  id: string;
  ref: DocumentReference;

  amount: number;
  createdAt?: Timestamp;
  files?: string[];
  invoiceNumber: string;
  invoiceId?: string;
  isSensitive?: boolean;
  path?: string;
  resolved: boolean;
  transactionHeader: {
    transactionSource: {
      description: string | number;
    };
  };
  transactionType: string;
  updatedAt?: Timestamp;
  userId: string;
  vendor: string;
  resolvedBy?: string;
  oldestDate?: Timestamp;
  oldestDatestring?: string;
  selectedImages?: APImageData[];
  deleted: boolean;

  constructor(id: string, ref: DocumentReference, data: DocumentData) {
    this.id = id;
    this.ref = ref;
    this.amount = data.amount;
    this.createdAt = data.createdAt;
    this.files = data.files;
    this.invoiceNumber = data.invoiceNumber;
    this.invoiceId = data.invoiceId;
    this.isSensitive = data.isSensitive ?? false;
    this.path = data.path;
    this.resolved = data.resolved ?? false;
    this.transactionHeader = data.transactionHeader;
    this.transactionType = data.transactionType;
    this.updatedAt = data.updatedAt;
    this.userId = data.userId;
    this.vendor = data.vendor;
    this.resolvedBy = data.resolvedBy;
    this.oldestDate = data.oldestDate;
    this.oldestDatestring = data.oldestDatestring;
    this.selectedImages = data.selectedImages;
    this.deleted = data.deleted ?? false;
  }
}

export type ClassificationCount = {
  allWithClassify: number;
  classifyError: number;
  invoiceClassified: number;
  notInvoiceClassified: number;
  correctInvoiceClassified: number;
  correctNotInvoiceClassified: number;
};

export type ClassificationCountData = ClassificationCount & {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};
