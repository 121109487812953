import { ContextMenu, Menu, MenuItem } from "@blueprintjs/core";
import {
  DndContext,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { createWorkerFactory } from "@shopify/web-worker";
import React, { useCallback, useEffect, useState } from "react";
import { Filter } from "redux/config/types";
import { useAppSelector } from "redux/hooks";
import { claims, hasAccess } from "../../../permissions";
import {
  evaluateAlgebraicExpression,
  getExpression,
} from "../helpers/filter-helper";
import ButtonItem from "./btn-item";
import useDeletingLayout from "./hooks/useDeletingLayout";
import useLayouts from "./hooks/useLayouts";
import NonDraggableBtn from "./non-draggable-btn";
import SortableItem from "./sortable-btn";

const createRefilter = createWorkerFactory(
  () => import("../helpers/filter-worker")
);

type Props = {
  users: object[];
  module: string;
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  handleRefilter: (filters: Filter[]) => Promise<void>;
  handleResetFilter: () => void;
  isFilterComponentApplied?: boolean;
};

const LayoutsBtnPanel = ({
  users,
  module,
  filters,
  setFilters,
  handleRefilter,
  handleResetFilter,
  isFilterComponentApplied = false,
}: Props) => {
  const {
    layouts,
    setLayouts,
    changeOrder,
    changeDefault,
    removeDefault,
    deleteLayout,
  } = useLayouts(module);
  const { deletingId, setDeletingId } = useDeletingLayout();
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [activeLayoutIds, setActiveLayoutIds] = useState<UniqueIdentifier[]>(
    []
  );
  const [isFilterComponentFirstLoad, setIsFilterComponentFirstLoad] =
    useState(true);

  // DND-KIT
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  useEffect(() => {
    if (isFirstLoad && layouts.length > 0) {
      const defaultLayout = layouts.find((l) => l.isDefault === true);
      if (defaultLayout) {
        handleChooseLayout(defaultLayout.id);
      }
      setIsFirstLoad(false);
    }
  }, [layouts]);

  useEffect(() => {
    if (isFilterComponentApplied && layouts.length === 0) return;
    if (isFilterComponentApplied && isFilterComponentFirstLoad) {
      setIsFilterComponentFirstLoad(false);
      return;
    }
    if (activeLayoutIds.length === 0) {
      setFilters([]);
      handleResetFilter();
      return;
    }
    const activeLayouts = layouts.filter((l) => activeLayoutIds.includes(l.id));
    const expressions = activeLayouts.map((l) => getExpression(l.filters));
    const activeFilters = activeLayouts.map((l) => l.filters).flat();

    // evaluate all expressions sequentially
    while (expressions.length > 1) {
      const left = expressions.shift();
      const right = expressions.shift();

      if (left && right) {
        const expression = `(${left})*(${right})`;
        const algebraResult = evaluateAlgebraicExpression(expression);

        expressions.unshift(algebraResult.join("+"));
      }
    }

    if (expressions.length === 0) return;

    const algebraResult = expressions[0].split("+");
    const filterAlgebra: Array<Filter[]> = [];

    for (const r of algebraResult) {
      const ids = r.split("*");
      const filters: Filter[] = activeFilters
        .filter((f) => ids.includes(String(f.id)))
        .map((f) => ({ ...f, logical: "AND" }));
      filterAlgebra.push(filters);
    }

    const result: Filter[] = [];
    for (const algebra of filterAlgebra) {
      algebra[0].logical = "OR";
      result.push(...algebra);
    }
    delete result[0].logical;

    setFilters(result);
    handleRefilter(result);
  }, [activeLayoutIds, layouts, isFilterComponentApplied]);

  const handleChooseLayout = (filterId: string) => {
    // add filter to activeLayoutIds if not exist
    if (!activeLayoutIds.includes(filterId)) {
      setActiveLayoutIds((prev) => [...prev, filterId]);
    } else {
      // remove filter from activeLayoutIds if exist
      setActiveLayoutIds((prev) => prev.filter((id) => id !== filterId));
    }
  };

  const handleRemoveLayout = (id: string) => {
    setDeletingId(id);
    deleteLayout(id);
    handleResetFilter();
  };

  const getFilterCount = useCallback(
    async (filters: Filter[]) => {
      const filterAgent = createRefilter();
      const refiltered = await filterAgent.refilter(users, filters);
      return refiltered.length;
    },
    [users]
  );

  const handleDragStart = (event: DragStartEvent) =>
    setActiveId(event.active.id);
  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setLayouts((items) => {
        const oldIndex = items.findIndex((i) => i.id === active.id);
        const newIndex = items.findIndex((i) => i.id === over.id);
        const moved = arrayMove(items, oldIndex, newIndex);
        return moved.map((layout, i) => ({
          ...layout,
          order: i,
        }));
      });
    }
  };

  const handleDragEnd = () => {
    setActiveId(null);
    changeOrder(layouts);
  };

  return hasAccess(authClaims, claims.editSavedAdvancedFilters) ? (
    <DndContext
      autoScroll={false}
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={() => setActiveId(null)}
    >
      <SortableContext
        items={layouts.map((i) => i.id)}
        strategy={rectSortingStrategy}
      >
        <div
          className="d-flex flex-row align-items-center mb-3 flex-wrap"
          style={{ gap: "0.5rem" }}
        >
          {layouts
            .sort((a, b) => a.order - b.order)
            .map((layout) => (
              <ContextMenu
                key={layout.id}
                content={
                  <Menu>
                    {layout.isDefault ? (
                      <MenuItem
                        icon="pin"
                        text="Remove default"
                        onClick={() => removeDefault()}
                      />
                    ) : (
                      <MenuItem
                        icon="pin"
                        text="Set as default"
                        onClick={() => changeDefault(layout.id)}
                      />
                    )}
                  </Menu>
                }
              >
                <SortableItem
                  layout={layout}
                  filters={filters}
                  isDeleted={deletingId}
                  handleRemoveLayout={handleRemoveLayout}
                  handleChooseLayout={handleChooseLayout}
                  getFilterCount={getFilterCount}
                  module={module}
                  active={activeLayoutIds.includes(layout.id)}
                />
              </ContextMenu>
            ))}
        </div>
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <ButtonItem
            layout={layouts.find((layout) => layout.id === activeId)!}
            style={{ opacity: "50%" }}
            filters={filters}
            isDeleted={deletingId}
            handleRemoveLayout={handleRemoveLayout}
            handleChooseLayout={handleChooseLayout}
            getFilterCount={getFilterCount}
            module={module}
            active={activeLayoutIds.includes(activeId)}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  ) : (
    <div
      className="d-flex flex-row align-items-center mb-3 flex-wrap"
      style={{ gap: "0.5rem" }}
    >
      {layouts
        .sort((a, b) => a.order - b.order)
        .map((layout) => (
          <NonDraggableBtn
            key={layout.id}
            layout={layout}
            filters={filters}
            handleChooseLayout={handleChooseLayout}
            getFilterCount={getFilterCount}
            active={activeLayoutIds.includes(layout.id)}
          />
        ))}
    </div>
  );
};

export default React.memo(LayoutsBtnPanel);
