import store from "redux/store";

/**
 * Get the currently logged in user's ID.
 * @return {string} The user ID of the currently logged in user.
 */
export function getAuthUserID() {
  return store.getState().auth?.user;
}

/**
 * Get the currently logged in user's auth claims.
 * @return {AuthClaims} The auth claims of the currently logged in user.
 */
export function getAuthUserClaims() {
  return store.getState().auth?.authClaims;
}
