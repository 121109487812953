import React, {
  MouseEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

// EXTERNAL PACKAGES
import moment from "moment";

import { formatNumber, getDurationString, safeTimestamp } from "utils/common";
// UTILITY FUNCTIONS AND CONSTANTS
import { INVOICE } from "utils/constants";

import ActionConfirmationDialogue from "components/action-confirmation-dialogue";
import DataLabelingComments from "components/data-labeling-comments";
// COMPONENTS
import InvoiceComponent from "components/invoices/invoice";
import InvoiceNotes from "components/invoices/invoice-notes";
import PriceChanges from "components/invoices/invoice-price-changes";
import InvoiceTags from "components/invoices/invoice-tags";
import Timer from "components/invoices/timer";

import alertActions from "redux/alert/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import invoiceActions from "redux/invoice/actions";
// REDUX
import {
  CheckedInvoice,
  Fee,
  Invoice,
  InvoiceItem,
  Items,
} from "redux/invoice/types";
import actionsUser from "redux/users/actions";

// STYLES
import {
  Button,
  Callout,
  Card,
  Checkbox,
  Elevation,
  HotkeysProvider,
  Popover,
  Tag,
} from "@blueprintjs/core";
import "./index.css";
import { User } from "redux/users/types";
import { claims, hasAccess } from "../../../permissions";
import InvoiceListItemSummary from "../invoice/invoice-listitem-summary";

import { createSelector } from "@reduxjs/toolkit";
import { cn } from "@stockifi/shared";
import _ from "lodash";
import { DataLabelingContext } from "pages/data-labeling";
import { useIdleTimer } from "react-idle-timer";
import { useSearchParams } from "react-router-dom";
import { RootState } from "redux/store";
import { getUserName } from "redux/users/helpers";
import { calculateTotals } from "../invoice/helpers";
import InvoiceListItemResolverStatus from "./invoice-listItem-resolver-status";

type Props = {
  data: Invoice & {
    resolvingInvoices?: User[];
    checkingInvoices?: User[];
  };
  userName: string;
  searchInput: string;
  userId: string;
  index: number;
  renderListNumber: boolean;
  isTable?: boolean;
  currentResolving?: string;
  setCurrentResolving?: React.Dispatch<React.SetStateAction<string>>;
  setCurrentChecking?: React.Dispatch<React.SetStateAction<string>>;
};

function InvoiceListItem({
  data,
  userName,
  searchInput,
  userId,
  index,
  renderListNumber,
  isTable,
  currentResolving,
  setCurrentResolving,
  setCurrentChecking,
}: Props) {
  const userAuth = useAppSelector((state) => state.auth.user);

  const items = useAppSelector((state) => state.invoices.userItems);
  const userFees = useAppSelector((state) => state.invoices.fees);
  const itemsTable = useAppSelector(
    (state) => state.invoices.userItemsTable[data.userId]?.userItems
  );
  const feesTable = useAppSelector(
    (state) => state.invoices.userFeesTable[data.userId]?.userFees
  );
  const userItems = isTable ? itemsTable : items;
  const fees = isTable ? feesTable : userFees;
  const suppliers = useAppSelector((state) => state.suppliers.data);
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const resolvingDraftInvoice = useAppSelector(
    (state) => state.invoices.resolvingDraftInvoice
  );
  const selectInvoiceVotes = (state: RootState) => state.invoices.invoiceVotes;
  const selectValidInvoiceVotes = createSelector(
    [selectInvoiceVotes],
    (invoiceVotes) =>
      invoiceVotes
        .filter(
          (x) =>
            x.invoiceRefPath === `users/${data.userId}/invoices/${data.id}` &&
            !x.deleted
        )
        .map((x) => ({
          ...x,
          itemsList: x.itemsList.filter((y) => !y.isDuplicate),
        }))
  );
  const invoicesFromVote = useAppSelector(selectValidInvoiceVotes);
  const loadingInvoiceUpdate = useAppSelector((x) => x.invoices.loadingSave);

  const [urlParams, setParams] = useSearchParams();
  const params: Record<string, string> = useMemo(() => {
    let paramObj = {};
    for (const [key, value] of urlParams) {
      paramObj = { ...paramObj, [key]: value };
    }

    return paramObj;
  }, [urlParams]);

  const { invoiceId } = params;

  const [dataVisible, setDataVisible] = useState(
    isTable || resolvingDraftInvoice === data.invoiceId ? true : false
  );
  const [imageLoading, setImageLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(
    data.files && data.files.length ? data.files[0] : data.url || ""
  );
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [matFoodTotal, setMatFoodTotal] = useState<number>(0);
  const [drinkTotal, setDrinkTotal] = useState<number>(0);
  const [diverseTotal, setDiverseTotal] = useState<number>(0);
  const [feeTotal, setFeeTotal] = useState<number>(0);
  const dispatch = useAppDispatch();
  const [isInvoiceRunning, setIsInvoiceRunning] = useState(false);
  const [divScrollPosition, setDivScrollPosition] = useState(0);
  const [realTimerValue, setRealTtmerValue] = useState(
    data.state === "unresolved"
      ? data.resolvingDuration ?? 0
      : data.editedDuration?.find((x) => x.editorId === userAuth)?.duration ?? 0
  );

  const { modification } = useContext(DataLabelingContext);

  const existingVoteNumbers = useMemo(() => {
    return invoicesFromVote
      .filter((vote) => vote.number !== "" && vote.isDuplicate)
      .map((x) => x.number);
  }, [invoicesFromVote]);

  const invoiceSource = useMemo(() => {
    if (data.isFromApTransaction && !data.isFromAdminPanel)
      return "AP Transaction";
    else if (!data.isFromApTransaction && data.isFromAdminPanel)
      return "Manual Upload";
    else if (!data.isFromApTransaction && !data.isFromAdminPanel)
      return "User Upload";
    else "Unknown";
  }, [data]);

  const handleTimeUpdate = (newTime: number) => {
    setRealTtmerValue(newTime);
  };

  function showData() {
    if (dataVisible) setParams({ ...params, invoiceId: "" });
    else setParams({ ...params, invoiceId: data.id });

    setDataVisible((prev) => {
      if (userAuth) {
        const isInvoiceResolved = data.state === "resolved";

        if (setCurrentResolving && isInvoiceResolved)
          setCurrentResolving(prev ? "" : data.id);

        if (setCurrentChecking && isInvoiceResolved)
          setCurrentChecking(prev ? "" : data.id);

        dispatch(
          actionsUser.UPDATE_USER({
            user: {
              resolvingInvoiceId: isInvoiceResolved
                ? null
                : prev
                  ? null
                  : data.id,
              checkingInvoiceId: !isInvoiceResolved
                ? null
                : prev
                  ? null
                  : data.id,
            },
            userId: userAuth,
          })
        );
      }
      return !prev;
    });
  }

  function getImgSrc(file: string, invoice: Invoice): string {
    if (invoice.thumbnails && invoice.thumbnails[file])
      return data.thumbnails[file];

    return file;
  }
  function getImgOrientation(file: string, invoice: Invoice): number {
    if (invoice.orientation && invoice.orientation[file])
      return data.orientation[file];
    return 0;
  }
  function clickImage(e: MouseEvent, imgFile: string) {
    e.stopPropagation();
    setDataVisible(true);
    setSelectedImage(imgFile);
    setParams({ ...params, invoiceId: data.id });
  }

  function getSupplierName(id: string) {
    const found = suppliers.find((supplier) => supplier.id === id);
    if (found) return found.name;

    return "";
  }

  function handleDeleteImage(data: Invoice, imageFile: string) {
    dispatch(
      invoiceActions.DELETE_IMAGE({
        id: data.id,
        userId: data.userId,
        imageFile: imageFile,
      })
    );
  }

  function updateDeleteFieldInvoice(isDelete: boolean) {
    // dispatch to save on firebase
    dispatch(
      invoiceActions.UPDATE_INVOICE({
        invoice: { deleted: isDelete },
        refPath: data.refPath,
        checkData: false,
      })
    );
    if (modification === "dataVoting") {
      dispatch(
        invoiceActions.DELETE_VOTES({
          invoiceRefPath: data.refPath,
          userId: data.userId,
        })
      );
    }
  }

  const renderActionButton = () => {
    if (authClaims?.admin || authClaims?.dev) {
      return (
        <>
          <div>
            <Button
              text={dataVisible ? "Close Invoice" : "Open Invoice"}
              icon={dataVisible ? "minimize" : "maximize"}
              intent={dataVisible ? "danger" : "success"}
              className="invoice-button"
              onClick={showData}
            />
          </div>
          <div />
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <Button
              text={isInvoiceRunning ? "Pause Invoice" : "Start Invoice"}
              icon={isInvoiceRunning ? "pause" : "play"}
              intent={isInvoiceRunning ? "warning" : "success"}
              onClick={_.debounce((e) => {
                e.stopPropagation();
                if (!isInvoiceRunning) startTimer();
                else stopTimer();
              }, 1000)}
            />
            {dataVisible && !isInvoiceRunning && (
              <Button
                text={"Close Invoice"}
                icon="cross"
                intent="danger"
                onClick={() => {
                  setDataVisible(false);
                }}
              />
            )}
          </div>
          <Timer
            onTimeUpdate={handleTimeUpdate}
            isInvoiceRunning={isInvoiceRunning}
            resolvingDuration={
              data.state === "unresolved"
                ? data.resolvingDuration ?? 0
                : data.editedDuration?.find((x) => x.editorId === userAuth)
                    ?.duration ?? 0
            }
          />
        </>
      );
    }
  };

  useEffect(() => {
    if (invoiceId && invoiceId === data.id) {
      setDataVisible(true);

      if (!(authClaims?.admin || authClaims?.dev)) {
        if (data.state === "resolved" && !isInvoiceRunning) {
          setIsInvoiceRunning(true);
        } else if (data.state === "unresolved" && !isInvoiceRunning) {
          startTimer();
        }
      }

      const element = document.getElementById(data.id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }, 1500);
        setDivScrollPosition(element.scrollHeight);
      }
    }
  }, [invoiceId]);

  const processedInitialItems = useMemo(() => {
    const isValidNumber = (n: number) => {
      return !isNaN(n) && isFinite(n) && n !== null && n !== undefined;
    };
    const items: InvoiceItem[] = [];
    const itemsSource = data.itemsList ?? data.items ?? {};
    if (userItems.length > 0 && fees.length > 0) {
      Object.entries(itemsSource).forEach(([key, value], i) => {
        if (value.type === "fee") {
          const fee = getFee(fees, value.id);
          items.push({
            ...value,
            name: fee.name ?? "",
            aliases: fee.aliases ?? [],
            taxDeduction: value.taxDeduction ?? 0,
            totalAfterTax: value.totalAfterTax ?? value.total,
          });
          return;
        }

        const itemId = data.itemsList ? value.id : key;
        const item = getItem(userItems, itemId);
        let isNew = false;

        if (data.items && data.items[itemId]) {
          isNew = data.items[itemId].isNew;
        }

        const quantity = isValidNumber(value.quantity) ? value.quantity : 0;
        const cost = isValidNumber(value.cost) ? value.cost : 0;
        const total = isValidNumber(value.total)
          ? value.total
          : quantity * cost;

        let oldCost = cost;
        if (data.updatedCosts && data.updatedCosts[itemId]) {
          oldCost = isValidNumber(data.updatedCosts[itemId].old)
            ? data.updatedCosts[itemId].old
            : 0;
        }

        const invoiceItem: InvoiceItem = {
          id: itemId,
          name:
            value.isOpenItem || !itemId
              ? value.name
                ? value.name
                : value.ocrQuery ?? ""
              : item?.name,
          cost: cost,
          oldCost: oldCost,
          quantity: quantity,
          total: total,
          isNew: !!isNew,
          type: item?.type,
          size: item?.size,
          unit: item?.unit,
          variety: item?.variety,
          errorCost: "",
          errorQuantity: "",
          errorTotal: "",
          changedProperty: "",
          index: value.index != null ? value.index : i,
          isOpenItem: value.isOpenItem,
          wasOpenItem: value.wasOpenItem ?? false,
          nameChanged: value.nameChanged,
          autoTotal: value.autoTotal,
          autoQuantity: value.autoQuantity,
          totalComputed: value.totalComputed,
          invoicePage: value.invoicePage ? value.invoicePage : undefined,
          uuid: value.uuid,
          hasError: value.hasError ?? false,
          aliases: item?.aliases ?? [],
          searchQuery: value.searchQuery,
          isSponsored: value.isSponsored,
          taxDeduction: value.taxDeduction ?? 0,
          totalAfterTax: value.totalAfterTax ?? total,
          isFee: false,
          ocrQuery: value.ocrQuery,
        };

        items.push(invoiceItem);
      });
    }

    return items;
  }, [data, userItems, fees]);

  const totals = useMemo(() => {
    return calculateTotals(processedInitialItems, data.foodTotal);
  }, [processedInitialItems, data.foodTotal]);

  function getItem(list: Items[], id: string): Items {
    const ret = list.find((item: Items) => item.id === id);
    if (ret == null) return {} as Items;
    return ret as Items;
  }

  function getFee(list: Fee[], id: string): Fee {
    const ret = list.find((item: Fee) => item.id === id);
    if (ret == null) return {} as Fee;
    return ret as Fee;
  }

  function checkInvoice() {
    const updateAliasPayload: Items[] = [];
    const updateFeeAliasPayload: Fee[] = [];

    if (!hasAccess(authClaims, claims.checkInvoice)) {
      dispatch(alertActions.ERROR("You don't have access to check invoices"));
      return;
    }

    if (data.tags?.includes("openItem")) {
      dispatch(alertActions.ERROR("You can't check an open item invoice"));
      return;
    }
    if (data.tags?.includes("openSupplier")) {
      dispatch(alertActions.ERROR("You can't check an open supplier invoice"));
      return;
    }

    const {
      isChecked,
      checkedBy,
      newAliases,
      supplierName,
      supplierId,
      id,
      userId,
      checkedAt,
      items,
    } = data;
    const invoice: CheckedInvoice | Invoice =
      modification === "dataVoting"
        ? {
            ...data,
            invoiceId: id,
            userId,
            checkedAt,
            items,
            updatedAt: new Date(),
          }
        : {
            isChecked,
            checkedBy,
            newAliases,
            supplierName,
            supplierId,
            invoiceId: id,
            userId,
            checkedAt,
            state: "resolved",
            items,
            updatedAt: new Date(),
          };

    if (!invoice.isChecked) {
      invoice.isChecked = true;
      invoice.checkedBy = getUserName(userAuth ?? "");
      invoice.checkedAt = new Date();
    } else {
      invoice.isChecked = false;
      invoice.checkedBy = "";
      invoice.checkedAt = null;
    }

    if (invoice.isChecked && !invoice.checkedBy) {
      dispatch(
        alertActions.ERROR("User auth not found, failed to check invoice")
      );
      return;
    }

    if (invoice.isChecked) {
      Object.keys(invoice.newAliases).forEach((itemId) => {
        const userItem = userItems?.find((x: Items) => x.id === itemId);
        const newAlias = invoice.newAliases[itemId];
        if (userItem && newAlias) {
          const userItemAliases = userItem.aliases ?? [];

          updateAliasPayload.push({
            ...userItem,
            aliases: Array.from(
              new Set([...userItemAliases, ...newAlias.map((x) => x.name)])
            ),
          });
        } else if (!userItem) {
          const fee = fees.find((x) => x.id === itemId);
          const feeAliases = fee?.aliases ?? [];
          if (fee) {
            updateFeeAliasPayload.push({
              ...fee,
              aliases: Array.from(
                new Set([...feeAliases, ...newAlias.map((x) => x.name)])
              ),
            });
          }
        }
      });
      invoice.newAliases = {};
    }

    if (invoice.supplierId === invoice.supplierName) {
      const supplierId = suppliers.find(
        (x) => x.name === invoice.supplierId
      )?.id;
      if (supplierId) {
        invoice.supplierId = supplierId;
      }
    }
    dispatch(
      invoiceActions.CHECK_INVOICE({
        invoice: invoice,
        updateAliasPayload: updateAliasPayload,
        updateFeeAliasPayload: updateFeeAliasPayload,
      })
    );
  }

  const hasUpdatedCosts = () => {
    const { items, updatedCosts } = data;
    const hasUpdatedCosts = Object.values(updatedCosts ?? {}).some(
      (x) => x.old.toFixed(2) !== x.new.toFixed(2)
    );
    const hasNewItems = Object.values(items ?? {}).some((x) => !!x.isNew);
    return hasUpdatedCosts || hasNewItems;
  };

  function formatTimeTaken(startedAt: Date, resolvedAt: Date) {
    const startTime = moment(startedAt, "HH:mm:ss a");
    const endTime = moment(resolvedAt, "HH:mm:ss a");

    const duration = moment.duration(endTime.diff(startTime));
    const hours =
      duration.hours() < 10 ? "0" + duration.hours() : duration.hours();
    const minutes =
      duration.minutes() < 10 ? "0" + duration.minutes() : duration.minutes();
    const seconds =
      duration.seconds() < 10 ? "0" + duration.seconds() : duration.seconds();
    return [hours, minutes, seconds].join(":");
  }

  const copyText = (e: string) => {
    navigator.clipboard
      .writeText(e)
      .then(() => {
        dispatch(alertActions.SUCCESS(e + " copied to clipboard"));
      })
      .catch((err) => {
        dispatch(alertActions.ERROR(err.message || "Error!"));
      });
  };

  const handleClickComment = (comment: string) => {
    dispatch(
      invoiceActions.UPDATE_INVOICE({
        invoice: {
          invoiceComments: comment ? comment : "",
          commentedBy: userAuth,
        },
        refPath: data.refPath,
        checkData: false,
      })
    );
  };

  useEffect(() => {
    if (currentResolving && currentResolving !== data.id && isInvoiceRunning) {
      stopTimer();
      setDataVisible(false);
    }
  }, [currentResolving]);

  useEffect(() => {
    setDrinkTotal(totals.drinkTotal);
    setGrandTotal(totals.grandTotal);
    setMatFoodTotal(totals.matFoodTotal);
    setDiverseTotal(totals.diverseTotal);
    setFeeTotal(totals.feeTotal.total);
  }, [totals]);

  function startTimer() {
    if (!userAuth) return;
    if (!dataVisible) setDataVisible(true);
    if (setCurrentResolving) setCurrentResolving(data.id);
    setIsInvoiceRunning(true);
    setParams({ ...params, invoiceId: data.id });

    dispatch(
      actionsUser.UPDATE_USER({
        user: { resolvingUserId: userId, resolvingInvoiceId: data.id },
        userId: userAuth,
      })
    );
  }

  function stopTimer(saveDuration = true) {
    if (!userAuth) return;
    setIsInvoiceRunning(false);
    setParams({ ...params, invoiceId: "" });

    if (
      hasAccess(authClaims, claims.resolvingInvoices) &&
      saveDuration &&
      modification !== "dataVoting"
    ) {
      const time = realTimerValue ?? 0;
      if (data.state === "unresolved") {
        dispatch(
          invoiceActions.UPDATE_INVOICE({
            invoice: { resolvingDuration: time },
            notify: false,
            refPath: data.refPath,
            checkData: false,
          })
        );
      }
      if (data.state === "resolved") {
        const editedDurationToUpdate = (data.editedDuration || []).map(
          (editor) =>
            editor.editorId === userAuth
              ? { ...editor, duration: time }
              : editor
        );

        if (
          !data.editedDuration ||
          !data.editedDuration.find((x) => x.editorId === userAuth)
        ) {
          editedDurationToUpdate.push({
            editorId: userAuth || "",
            duration: time,
          });
        }

        dispatch(
          invoiceActions.UPDATE_INVOICE({
            invoice: { editedDuration: editedDurationToUpdate },
            notify: false,
            refPath: data.refPath,
            checkData: false,
          })
        );
      }
    }

    dispatch(
      actionsUser.UPDATE_USER({
        user: { resolvingUserId: null, resolvingInvoiceId: null },
        userId: userAuth,
      })
    );
  }

  useIdleTimer({
    // default timeout is 20 min, modify the first segment
    timeout: 20 * 60 * 1000,
    onIdle: () => {
      if (dataVisible && isInvoiceRunning) stopTimer();
    },
    debounce: 500,
  });

  function handleConfirmation(): void {
    dispatch(
      invoiceActions.UPDATE_INVOICE({
        invoice: {
          isForSupervisor: true,
          isForSupervisorMarkedBy: userAuth,
        },
        refPath: data.refPath,
        checkData: false,
      })
    );
  }

  return (
    <HotkeysProvider>
      <div className={isTable ? "" : "row mb-2"}>
        <Card
          elevation={Elevation.TWO}
          className={cn(
            "border-colored",
            data.isChecked && "border-checked-green"
          )}
          id={data.id}
        >
          {data.deleted === false &&
            hasAccess(authClaims, claims.deleteInvoice) && (
              <div className="row delete-button-margin">
                <div className="col-11">&nbsp;</div>
                <div className="col-1">
                  <ActionConfirmationDialogue
                    title="Confirm Deletion"
                    text={INVOICE.CONFIRM_DELETE_INVOICE}
                    onConfirm={() => updateDeleteFieldInvoice(true)}
                  >
                    <Button
                      className="delete-button invoice-button"
                      intent="danger"
                      text="Delete Invoice"
                    />
                  </ActionConfirmationDialogue>
                </div>
              </div>
            )}
          {data.deleted === true &&
            hasAccess(authClaims, claims.undeleteInvoice) && (
              <div className="row delete-button-margin">
                <div className="col-11">&nbsp;</div>
                <div className="col-1">
                  <ActionConfirmationDialogue
                    title="Confirm Undeletion"
                    text={INVOICE.CONFIRM_UNDELETE_INVOICE}
                    onConfirm={() => updateDeleteFieldInvoice(false)}
                    actionName={"Undelete"}
                  >
                    <Button
                      className="delete-button invoice-button"
                      intent="warning"
                      text="Undelete Invoice"
                    />
                  </ActionConfirmationDialogue>
                </div>
              </div>
            )}
          {!data?.isForSupervisor && (
            <div className="row for-supervisor-button-margin">
              <div className="col-md-11  ">&nbsp;</div>
              <div className="col-md-1 ">
                <ActionConfirmationDialogue
                  text={""}
                  title={"Confirm Invoice For Supervisor"}
                  onConfirm={handleConfirmation}
                  actionName="Confirm"
                >
                  <Button
                    text="For Supervisor"
                    className="invoice-button for-supervisor-button"
                    loading={loadingInvoiceUpdate}
                  />
                </ActionConfirmationDialogue>
              </div>
            </div>
          )}

          <div>
            <div className="row">
              <div className="col-md-5 col-sm-6">
                {renderListNumber && (
                  <h3 onClick={(e) => e.stopPropagation()}>{index}.</h3>
                )}
                <InvoiceListItemResolverStatus
                  isTable={isTable}
                  data={{
                    state: data.state,
                    voters: data.voters,
                    id: data.id,
                  }}
                  modification={modification}
                />
                {data.createdAt && (
                  <h5 onClick={(e) => e.stopPropagation()}>
                    {moment(data.createdAt).format("MMM DD, YYYY, HH:mm:ss")}
                  </h5>
                )}
                {data.comments ? (
                  <>
                    <h5 className="mb-2" onClick={(e) => e.stopPropagation()}>
                      User Comments
                    </h5>
                    <p
                      className="comments p-2 mt-0 me-5"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {data.comments}
                    </p>
                  </>
                ) : (
                  ""
                )}

                {data.deleted && (
                  <Callout
                    style={{ fontSize: "14px" }}
                    icon="warning-sign"
                    intent="warning"
                    title="Invoice Deleted"
                  />
                )}

                <div className="row p-2 invoice-thumbnail-wrapper">
                  {(data.files?.length > 0 ? data.files : [data.url]).map(
                    (imgFile: string, j: number) => (
                      <div
                        key={"thumbnail-" + imgFile}
                        className="col-xl-3 col-md-3 col-sm-6 p-1"
                      >
                        <div className="mb-2">
                          <Tag
                            intent={
                              imgFile === selectedImage ? "primary" : "none"
                            }
                            interactive
                            onClick={(event: MouseEvent) => {
                              if (
                                authClaims?.admin ||
                                authClaims?.dev ||
                                data.state === "resolved" ||
                                (data.state === "unresolved" &&
                                  isInvoiceRunning)
                              )
                                clickImage(event, imgFile);
                            }}
                          >
                            {j + 1}
                          </Tag>
                          {hasAccess(authClaims, claims.deleteInvoiceImage) && (
                            <span>
                              <ActionConfirmationDialogue
                                title="Confirm Deletion"
                                text={INVOICE.CONFIRM_DELETE_IMAGE}
                                onConfirm={() =>
                                  handleDeleteImage(data, imgFile)
                                }
                              >
                                <Button
                                  icon={"trash"}
                                  small={true}
                                  className="delete-icon"
                                />
                              </ActionConfirmationDialogue>
                            </span>
                          )}
                        </div>
                        {dataVisible ? (
                          <Button
                            onClick={(event: MouseEvent) => {
                              if (
                                authClaims?.admin ||
                                authClaims?.dev ||
                                isInvoiceRunning
                              )
                                clickImage(event, imgFile);
                            }}
                            outlined={imgFile === selectedImage}
                            loading={imageLoading}
                          >
                            <img
                              className="image"
                              loading="lazy"
                              src={getImgSrc(imgFile, data)}
                              style={{
                                transform:
                                  "rotate(" +
                                  getImgOrientation(imgFile, data) +
                                  "deg)",
                              }}
                              alt="empty"
                              onLoad={() => {
                                setImageLoading(false);
                              }}
                              onError={() => {
                                setImageLoading(false);
                              }}
                            />
                          </Button>
                        ) : (
                          <Popover
                            enforceFocus={true}
                            content={
                              <div
                                style={{
                                  padding: "0.5rem",
                                  width: 900,
                                  height: 900,
                                  overflow: "auto",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    transform:
                                      "rotate(" +
                                      getImgOrientation(imgFile, data) +
                                      "deg)",
                                  }}
                                  loading="lazy"
                                  src={imgFile}
                                  alt="empty"
                                />
                              </div>
                            }
                          >
                            <Button style={{ marginRight: "1rem" }}>
                              <img
                                className="image"
                                loading="lazy"
                                src={getImgSrc(imgFile, data)}
                                style={{
                                  transform:
                                    "rotate(" +
                                    getImgOrientation(imgFile, data) +
                                    "deg)",
                                }}
                                alt="empty"
                                onLoad={() => {
                                  setImageLoading(false);
                                }}
                                onError={() => {
                                  setImageLoading(false);
                                }}
                              />
                            </Button>
                          </Popover>
                        )}
                      </div>
                    )
                  )}
                </div>
                {hasUpdatedCosts() && (
                  <div className="row mt-3">
                    <div
                      className="col-4"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <PriceChanges
                        data={data}
                        userName={userName}
                        isTable={isTable}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="col h-100">
                <InvoiceNotes userId={userId} />
                <DataLabelingComments
                  commentData={data.invoiceComments}
                  saveCommentHandler={handleClickComment}
                  title="Invoice Comments"
                />
              </div>
              <div className="col-md-4">
                <InvoiceListItemSummary
                  summaryKey={data.number && "Invoice # :"}
                  summaryValue={data.number && "" + data.number}
                  onClickFunction={() => copyText(data.number)}
                />
                <InvoiceListItemSummary
                  summaryKey={data.supplierId && "Supplier :"}
                  summaryValue={
                    data.supplierId === data.supplierName
                      ? data.supplierName
                      : getSupplierName(data.supplierId)
                  }
                  onClickFunction={() =>
                    copyText(getSupplierName(data.supplierId))
                  }
                />
                <InvoiceListItemSummary
                  summaryKey={data.deliveryDate && "Delivery Date :"}
                  summaryValue={
                    data.deliveryDate &&
                    "" + moment(data.deliveryDate).format("dddd, DD MMM, YYYY")
                  }
                  onClickFunction={() =>
                    copyText(
                      moment(data.deliveryDate).format("dddd, DD MMM, YYYY")
                    )
                  }
                />
                {data.oldestDate && (
                  <InvoiceListItemSummary
                    summaryKey={"Oldest Date Assumption :"}
                    summaryValue={moment(
                      safeTimestamp(data.oldestDate)?.toDate()
                    ).format("dddd, DD MMM, YYYY")}
                    onClickFunction={() =>
                      copyText(
                        moment(safeTimestamp(data.oldestDate)?.toDate()).format(
                          "dddd, DD MMM, YYYY"
                        )
                      )
                    }
                  />
                )}
                {data.commentedBy && (
                  <InvoiceListItemSummary
                    summaryKey={"Commented by :"}
                    summaryValue={getUserName(data.commentedBy)}
                    onClickFunction={() =>
                      copyText(getUserName(data.commentedBy ?? ""))
                    }
                  />
                )}
                {data.state === "resolved" && (
                  <>
                    <InvoiceListItemSummary
                      summaryKey="Grand Total :"
                      summaryValue={formatNumber(grandTotal, 2)}
                      onClickFunction={() =>
                        copyText(formatNumber(grandTotal, 2))
                      }
                    />
                    <InvoiceListItemSummary
                      summaryKey="Food Total :"
                      summaryValue={formatNumber(matFoodTotal, 2)}
                      onClickFunction={() =>
                        copyText(formatNumber(matFoodTotal, 2))
                      }
                    />
                    <InvoiceListItemSummary
                      summaryKey="Drink Total :"
                      summaryValue={drinkTotal}
                      onClickFunction={() =>
                        copyText(formatNumber(drinkTotal, 2))
                      }
                    />
                    <InvoiceListItemSummary
                      summaryKey="Diverse Total :"
                      summaryValue={diverseTotal}
                      onClickFunction={() =>
                        copyText(formatNumber(diverseTotal, 2))
                      }
                    />
                    <InvoiceListItemSummary
                      summaryKey="Fee Total :"
                      summaryValue={feeTotal}
                      onClickFunction={() =>
                        copyText(formatNumber(feeTotal, 2))
                      }
                    />
                    <InvoiceListItemSummary
                      summaryKey="Resolved at :"
                      summaryValue={
                        data.resolvedAt &&
                        moment(data.resolvedAt).format("dddd, DD MMM, YYYY")
                      }
                      onClickFunction={() =>
                        copyText(
                          moment(data.resolvedAt).format("dddd, DD MMM, YYYY")
                        )
                      }
                    />
                    <InvoiceListItemSummary
                      summaryKey="Resolved by :"
                      summaryValue={
                        data.resolvedBy
                          ? `${getUserName(data.resolvedBy)} / ${
                              data.resolvingDuration
                                ? getDurationString(data.resolvingDuration)
                                : data.startedAt && data.resolvedAt
                                  ? formatTimeTaken(
                                      data.startedAt,
                                      data.resolvedAt
                                    )
                                  : "-"
                            }`
                          : "-"
                      }
                      onClickFunction={() =>
                        copyText(
                          data.resolvedBy
                            ? `${getUserName(data.resolvedBy)} / ${
                                data.resolvingDuration
                                  ? getDurationString(data.resolvingDuration)
                                  : data.startedAt && data.resolvedAt
                                    ? formatTimeTaken(
                                        data.startedAt,
                                        data.resolvedAt
                                      )
                                    : "-"
                              }`
                            : "-"
                        )
                      }
                    />
                    {data.editedDuration && data.editedDuration.length > 0 ? (
                      data.editedDuration.map((editor) => {
                        return (
                          <InvoiceListItemSummary
                            summaryKey="Edited by :"
                            summaryValue={`${getUserName(
                              editor.editorId
                            )} / ${getDurationString(editor.duration)}`}
                            onClickFunction={() =>
                              copyText(
                                `${getUserName(
                                  editor.editorId
                                )} / ${getDurationString(editor.duration)}`
                              )
                            }
                          />
                        );
                      })
                    ) : (
                      <InvoiceListItemSummary
                        summaryKey="Edited by :"
                        summaryValue={
                          data.editedBy ? getUserName(data.editedBy) : "-"
                        }
                        onClickFunction={() =>
                          copyText(
                            data.editedBy ? getUserName(data.editedBy) : "-"
                          )
                        }
                      />
                    )}

                    <InvoiceListItemSummary
                      summaryKey="Total Time Taken :"
                      summaryValue={
                        data.resolvingDuration &&
                        data.editedDuration &&
                        data.editedDuration.length > 0
                          ? getDurationString(
                              data.editedDuration.reduce(
                                (sum, currentItem) =>
                                  sum + currentItem.duration,
                                0
                              ) + data.resolvingDuration
                            )
                          : data.resolvingDuration
                            ? getDurationString(data.resolvingDuration)
                            : data.startedAt && data.resolvedAt
                              ? formatTimeTaken(data.startedAt, data.resolvedAt)
                              : "-"
                      }
                      onClickFunction={() =>
                        copyText(
                          data.resolvingDuration &&
                            data.editedDuration &&
                            data.editedDuration.length > 0
                            ? getDurationString(
                                data.editedDuration.reduce(
                                  (sum, currentItem) =>
                                    sum + currentItem.duration,
                                  0
                                ) + data.resolvingDuration
                              )
                            : data.resolvingDuration
                              ? getDurationString(data.resolvingDuration)
                              : data.startedAt && data.resolvedAt
                                ? formatTimeTaken(
                                    data.startedAt,
                                    data.resolvedAt
                                  )
                                : "-"
                        )
                      }
                    />
                    <InvoiceListItemSummary
                      summaryKey="Invoice items count :"
                      summaryValue={data.itemsList?.length ?? "-"}
                      onClickFunction={() =>
                        copyText(data.itemsList?.length.toString() ?? "-")
                      }
                    />
                    {data.resolvedBy === "Auto Resolver" && (
                      <InvoiceListItemSummary
                        summaryKey="Voters :"
                        summaryValue={
                          data.voters?.map((s) => getUserName(s)).join(", ") ??
                          "-"
                        }
                        onClickFunction={() =>
                          copyText(
                            data.voters
                              ?.map((s) => getUserName(s))
                              .join(", ") ?? "-"
                          )
                        }
                      />
                    )}
                  </>
                )}
              </div>
              <div className="col-md-1 tag-column">
                <div>Source: {invoiceSource}</div>
                <div
                  className="tags-wrapper"
                  onClick={(e) => e.stopPropagation()}
                >
                  Tags: <br />
                  <InvoiceTags
                    initialItems={processedInitialItems}
                    data={data}
                    existingVoteNumbers={existingVoteNumbers}
                  />
                </div>
                {renderActionButton()}
              </div>
            </div>
            {hasAccess(authClaims, claims.checkInvoice) &&
              data.state === "resolved" && (
                <div>
                  {data.isChecked && data.checkedAt && (
                    <div
                      className="d-flex justify-content-end"
                      style={{ marginBottom: 15 }}
                    >
                      Checked at:{" "}
                      {data.checkedAt
                        ? moment(data.checkedAt).format("dddd, DD MMM, YYYY")
                        : "-"}
                    </div>
                  )}
                  {data.isChecked && data.checkedBy && (
                    <div
                      className="d-flex justify-content-end"
                      style={{ marginBottom: 15 }}
                    >
                      Checked by: {data.checkedBy || "-"}
                    </div>
                  )}

                  <Checkbox
                    checked={data.isChecked ?? false}
                    onChange={checkInvoice}
                    className="d-flex justify-content-end"
                    disabled={
                      data.tags?.includes("openItem") ||
                      data.tags?.includes("openSupplier")
                    }
                  >
                    Checked
                  </Checkbox>
                </div>
              )}
          </div>
          {dataVisible && (
            <div
              style={{
                pointerEvents:
                  !(authClaims?.admin || authClaims?.dev) &&
                  data.state === "unresolved" &&
                  !isInvoiceRunning
                    ? "none"
                    : "auto",
                userSelect:
                  !(authClaims?.admin || authClaims?.dev) &&
                  data.state === "unresolved" &&
                  !isInvoiceRunning
                    ? "none"
                    : "auto",
              }}
            >
              <InvoiceComponent
                data={data}
                initialItems={processedInitialItems}
                invoicePageNumber={data.files?.indexOf(selectedImage)}
                setSelectedImage={setSelectedImage}
                imgFile={selectedImage}
                visible={dataVisible}
                updateGrandTotal={(val) => setGrandTotal(val)}
                updateMatFoodTotal={(val) => setMatFoodTotal(val)}
                updateDrinkTotal={(val) => setDrinkTotal(val)}
                updateDiverseTotal={(val) => setDiverseTotal(val)}
                updateFeeTotal={(val) => setFeeTotal(val)}
                searchInput={searchInput}
                isTable={isTable}
                time={!isTable ? realTimerValue : undefined}
                divScrollPosition={divScrollPosition}
                invoicesFromVote={invoicesFromVote}
                existingVoteNumbers={existingVoteNumbers}
              />
            </div>
          )}
        </Card>
      </div>
    </HotkeysProvider>
  );
}

export default InvoiceListItem;
