import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { ServiceReturn } from "redux/types";
import {
  setNavbarNotificationsListener,
  setNotificationsListener,
} from "services/listeners/notification";
import {
  batchUpdateNotificationStatus,
  getDoneNotification,
  updateNotificationStatus,
} from "services/notification-center";
import { COMMON } from "utils/constants";
import actions from "./actions";
import { Notification } from "./types";

export interface SUBSCRIBE_TO_NOTIFICATIONS_Payload {
  userId: string;
}

function* SUBSCRIBE_TO_NOTIFICATIONS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_NOTIFICATIONS>
) {
  yield put(
    actions.SET_STATE({
      loading: true,
    })
  );
  const { userId } = input.payload;

  const channel: EventChannel<boolean> = yield call(
    setNotificationsListener,
    userId
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_NOTIFICATIONS);
    yield put(
      actions.SET_STATE({
        notifications: [],
      })
    );
    channel.close();
  });

  try {
    while (true) {
      const notifications: Notification[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          notifications: notifications,
          loading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loading: false,
        })
      );
    }
  }
}

export interface SUBSCRIBE_TO_NAVBAR_NOTIFICATIONS_Payload {
  maxNotification?: number;
}
export function* SUBSCRIBE_TO_NAVBAR_NOTIFICATIONS({
  payload,
}: ReturnType<typeof actions.SUBSCRIBE_TO_NAVBAR_NOTIFICATIONS>) {
  yield put(
    actions.SET_STATE({
      navbarNotificationsLoading: true,
    })
  );

  const channel: EventChannel<boolean> = yield call(
    setNavbarNotificationsListener,
    payload.maxNotification
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_NAVBAR_NOTIFICATIONS);
    channel.close();
  });

  try {
    while (true) {
      const notifications: Notification[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          navbarNotifications: notifications,
          navbarNotificationsLoading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          navbarNotificationsLoading: false,
        })
      );
    }
  }
}

export interface UPDATE_NOTIFICATION_STATUS_Payload {
  notificationId: string;
  newStatus: string;
}

export function* UPDATE_NOTIFICATION_STATUS({
  payload,
}: ReturnType<typeof actions.UPDATE_NOTIFICATION_STATUS>) {
  const result: ServiceReturn = yield call(
    updateNotificationStatus,
    payload.notificationId,
    payload.newStatus
  );

  if (result.data) {
    yield put(alertActions.SUCCESS("Notification status updated successfully"));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export interface BATCH_UPDATE_NOTIFICATION_STATUS_Payload {
  notificationIds: string[];
  newStatus: string;
}

export function* BATCH_UPDATE_NOTIFICATION_STATUS({
  payload,
}: ReturnType<typeof actions.BATCH_UPDATE_NOTIFICATION_STATUS>) {
  const result: ServiceReturn = yield call(
    batchUpdateNotificationStatus,
    payload.notificationIds,
    payload.newStatus
  );

  if (result.data) {
    yield put(
      alertActions.SUCCESS("Batch notification status updated successfully")
    );
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export function* GET_DONE_NOTIFICATIONS() {
  yield put(actions.SET_STATE({ loading: true }));

  const result: ServiceReturn = yield call(getDoneNotification);

  if (result.error) {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  } else {
    const notifications: Notification[] = yield select(
      (state) => state.notificationCentre.notifications
    );
    yield put(
      actions.SET_STATE({
        notifications: [...notifications, ...result.data],
      })
    );
  }

  yield put(actions.SET_STATE({ loading: false }));
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBSCRIBE_TO_NOTIFICATIONS, SUBSCRIBE_TO_NOTIFICATIONS),
    takeLatest(
      actions.SUBSCRIBE_TO_NAVBAR_NOTIFICATIONS,
      SUBSCRIBE_TO_NAVBAR_NOTIFICATIONS
    ),
    takeLatest(actions.UPDATE_NOTIFICATION_STATUS, UPDATE_NOTIFICATION_STATUS),
    takeLatest(
      actions.BATCH_UPDATE_NOTIFICATION_STATUS,
      BATCH_UPDATE_NOTIFICATION_STATUS
    ),
    takeLatest(actions.GET_DONE_NOTIFICATIONS, GET_DONE_NOTIFICATIONS),
  ]);
}
