import { Checkbox, Icon, Intent, Text } from "@blueprintjs/core";
import { Attribute } from "components/edit-pinned-attributes";
import { useSearch } from "layouts";
import { getBorderColor, getValueBackgroundColor } from "pages/users/helpers";
import React, { useEffect, useRef, useState, JSX } from "react";
import { useSearchParams } from "react-router-dom";
import style from "../index.module.scss";
import TableCell from "../table-cell";

type Props = {
  idx: number;
  module: string;

  datum: any;
  firstColumn: string;

  selectedData: any;
  isNumberedList: boolean;
  handleSelectRow: (e: React.FormEvent<HTMLInputElement>) => void;
  pinnedCols: Attribute[];
  getActions: (
    datum: any,
    expanded: boolean,
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  ) => JSX.Element;

  getFieldValue: (field: string, value: any) => string;
  expandedContent?: (
    datum: any,
    expanded: boolean,
    index: number,

    rowRef: React.RefObject<any>
  ) => JSX.Element;
  attrMap: Map<string, string>;
  previewNext?: string;
  differentAttributes?: {
    attribute: string;
    trueCount: number;
    falseCount: number;
  }[];
  showMisMatch?: boolean;
  allDataLength?: number;
};

const TableRow = ({
  idx,
  module,
  datum,
  firstColumn,
  selectedData,
  isNumberedList,
  handleSelectRow,
  pinnedCols,
  getActions,
  getFieldValue,
  expandedContent,
  attrMap,
  previewNext,
  differentAttributes,
  showMisMatch,
  allDataLength,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const [hoveredValue, setHoveredValue] = useState<any>({
    key: "",
    val: "",
  });
  const [params, setParams] = useSearchParams();
  const { searchProps } = useSearch();

  const tableRowRef = useRef<any>(null);

  useEffect(() => {
    if (module === "ap-transactions") {
      const transactionId = params.get("transactionId");
      if (transactionId === datum.id) {
        setExpanded(true);
      }
      if (expanded && transactionId !== datum.id) {
        setParams({ transactionId: datum.id });
      }
      if (searchProps.value === "" && transactionId) {
        if (transactionId === datum.id) setExpanded(false);
        setParams();
      }
    }
  }, [module, datum.id, expanded, searchProps.value]);

  useEffect(() => {
    if (previewNext !== "") {
      if (datum.id === previewNext) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
    }
  }, [datum.id, previewNext]);

  useEffect(() => {
    if (module === "invoices-table-view") return;
    if (idx === 0 && !datum.resolved) setExpanded(true);
  }, [idx, datum.id]);

  return (
    <>
      <tr className={style.tr} ref={tableRowRef}>
        <td
          className={style.td}
          style={{
            backgroundColor: selectedData.includes(datum.id) ? "#525b69" : "",
          }}
        >
          <div className="d-flex align-items-center" style={{ gap: 6 }}>
            {/* Check Each Row */}
            <Checkbox
              disabled={
                (module === "integration-monitor" && datum.success) ||
                (module === "invoices-table-view" &&
                  (datum.state === "unresolved" ||
                    datum.tags?.includes("openItem")))
              }
              style={{ margin: 0 }}
              id={datum.id}
              onChange={(e) => handleSelectRow(e)}
              checked={selectedData.includes(datum.id)}
            />
            {module === "counts" && (
              <Icon
                size={20}
                icon={
                  datum.deleted === true
                    ? "delete"
                    : datum.state !== "complete"
                      ? "play"
                      : "history"
                }
                style={{ marginLeft: "5px" }}
                intent={
                  datum.deleted === true
                    ? Intent.DANGER
                    : datum.state !== "complete"
                      ? Intent.WARNING
                      : Intent.SUCCESS
                }
                title={
                  datum.state.charAt(0).toUpperCase() +
                  datum.state.slice(1) +
                  (datum.deleted ? " - Deleted" : "")
                }
              />
            )}
            {module === "ap-transactions" &&
              isNumberedList &&
              allDataLength && <Text>{allDataLength - idx}.</Text>}
            {module === "invoices-table-view" &&
            (datum?.resolvingInvoices || datum?.checkingInvoices) ? (
              <span style={{ color: "#bb86fc" }}>
                {datum.state === "unresolved"
                  ? datum.resolvingInvoices.map((u: any) => u.name).join(", ")
                  : datum.checkingInvoices.map((u: any) => u.name).join(", ")}
              </span>
            ) : null}
          </div>
        </td>
        <td
          className={style.td}
          style={{
            backgroundColor: selectedData.includes(datum.id) ? "#525b69" : "",
          }}
        >
          {firstColumn !== "id" ? (
            <div
              onMouseEnter={() =>
                setHoveredValue({ key: firstColumn, val: datum[firstColumn] })
              }
              onMouseLeave={() => setHoveredValue({ key: "", val: "" })}
            >
              <TableCell
                hoveredValue={hoveredValue}
                getFieldValue={getFieldValue}
                datum={datum}
                col={{ name: firstColumn }}
                module={module}
                attrMap={attrMap}
              />
            </div>
          ) : (
            <span>{datum[firstColumn]}</span>
          )}
        </td>
        {pinnedCols
          .filter((col) => col.id !== 1)
          .sort()
          .map((col, idxCol) => (
            <td
              key={idxCol}
              className={style.td}
              style={{
                backgroundColor: selectedData.includes(datum.id)
                  ? module === "users" &&
                    showMisMatch &&
                    typeof datum[col.name] === "boolean" &&
                    differentAttributes
                    ? getValueBackgroundColor(
                        col.name,
                        datum[col.name],
                        differentAttributes
                      )
                    : "#525b69"
                  : "",
                border:
                  selectedData.includes(datum.id) &&
                  module === "users" &&
                  showMisMatch &&
                  typeof datum[col.name] === "boolean" &&
                  differentAttributes
                    ? `${getBorderColor(
                        col.name,
                        datum[col.name],
                        differentAttributes
                      )}`
                    : "",
              }}
            >
              <div
                onMouseEnter={() =>
                  setHoveredValue({ key: col.name, val: datum[col.name] })
                }
                onMouseLeave={() => setHoveredValue({ key: "", val: "" })}
              >
                <TableCell
                  hoveredValue={hoveredValue}
                  getFieldValue={getFieldValue}
                  datum={datum}
                  col={col}
                  module={module}
                  attrMap={attrMap}
                />
              </div>
            </td>
          ))}
        <td
          className={style.td}
          style={{
            backgroundColor: selectedData.includes(datum.id) ? "#525b69" : "",
          }}
        >
          {getActions(datum, expanded, setExpanded)}
        </td>
      </tr>

      {expanded && expandedContent && (
        <tr>
          <td colSpan={pinnedCols.length + 2}>
            {expandedContent(datum, expanded, idx, tableRowRef)}
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
