import { DocumentData, doc, onSnapshot } from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { db } from "services/firebase";

export function setAccessLevelsListener(): EventChannel<DocumentData[]> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const unsub = onSnapshot(doc(db, "accessLevels", "--levels"), (doc) => {
      const levels: DocumentData[] = [];
      const temp = doc.data();

      for (const level in temp) {
        levels.push(temp[level]);
      }

      emitter(levels);
    });

    return () => unsub();
  });
}
