import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { IntegrationsState } from "./types";

export const initialState: IntegrationsState = {
  loading: false,
  integrations: [],
  loadingManualIntegrations: false,
  manualIntegrations: [],
};

export default createReducer(initialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
