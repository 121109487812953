import createSagaMiddleware from "@redux-saga/core";
import { Action, ThunkAction } from "@reduxjs/toolkit";
import { createReduxEnhancer } from "@sentry/react";
import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./reducers";
import mySaga from "./sagas";

// for Redux DevTools
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

// create store
const store = createStore(
  rootReducer(),
  composeEnhancers(applyMiddleware(...middlewares), createReduxEnhancer())
);

// run saga
sagaMiddleware.run(mySaga);

export function getStoreWithState(preloadedState?: Partial<RootState>) {
  return createStore(
    rootReducer(),
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}

// types definition
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// store export
export default store;
