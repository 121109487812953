import { Collapse, Divider, Icon, Tooltip } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { claims, hasAccess } from "../../permissions";
import useAttributesMap from "./hooks/useAttributesMap";
import useFilteredItems from "./hooks/useFilteredItems";
import style from "./index.module.scss";
import LayoutsBtnPanel from "./layouts-btn-panel";
import AdvancedFilterMultiSelect from "./multiselect";
import { AdvancedFilterProps } from "./types";

export type AttributeMap = Map<string, string[] | number[]>;

const AdvancedFilterPanel = ({
  users,
  setFilteredUsers,
  module,
  setCountFilters,
  setUserStatusFilters,
  setSelectedFilter,
  excludedProps,
  filterFromComponent,
  handleCopyFilter,
  filterable = false,
  isActive = true,
}: AdvancedFilterProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // HOOKS
  const { attrMap, getAllKeys, keyStatusColor } =
    useAttributesMap(excludedProps);
  const {
    activeFilters,
    setActiveFilters,
    numberOfFilteredItems,
    statusColor,
    setFilteredItems,
    handleRefilter,
    handleResetFilter,
  } = useFilteredItems(users, setFilteredUsers, module);
  // REDUX STATE
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const [filterComponentApplied, setFilterComponentApplied] =
    useState<boolean>(false);

  useEffect(() => {
    if (setCountFilters != null) {
      setCountFilters(
        activeFilters.filter(
          (i) =>
            i.attribute === "deleted" ||
            i.attribute === "locked" ||
            i.attribute === "state" ||
            i.attribute === "stockDownloaded" ||
            i.attribute === "varianceDownloaded" ||
            i.attribute === "dismissed"
        )
      );
    }
    if (setUserStatusFilters != null) {
      setUserStatusFilters(
        activeFilters.filter(
          (i) =>
            i.attribute === "deleted" ||
            i.attribute === "locked" ||
            i.attribute === "state" ||
            i.attribute === "stockDownloaded" ||
            i.attribute === "varianceDownloaded" ||
            i.attribute === "dismissed"
        )
      );
    }
    if (
      ((module === "items-user" || module === "items-global") &&
        setSelectedFilter &&
        activeFilters.length > 0) ||
      (module === "user-status" && setSelectedFilter)
    ) {
      setSelectedFilter(activeFilters);
    }
  }, [activeFilters, setCountFilters, setUserStatusFilters, setSelectedFilter]);

  useEffect(() => {
    if (!isActive) return;
    const noDemoUsers = users.filter((i) => !i.demo);
    getAllKeys(noDemoUsers);

    if (activeFilters.length === 0) setFilteredItems(users);
    else handleRefilter(activeFilters);
  }, [users, isActive]);

  useEffect(() => {
    if (!isActive) return;
    if (filterFromComponent && !filterComponentApplied) {
      setActiveFilters(filterFromComponent);
      const fetchData = async () => {
        await handleRefilter(filterFromComponent);
        setFilterComponentApplied(true);
      };
      fetchData();
    }
  }, [filterFromComponent, isActive]);

  return (
    <>
      {hasAccess(authClaims, claims.viewSavedAdvancedFilters) && (
        <LayoutsBtnPanel
          module={module}
          users={users}
          filters={activeFilters}
          setFilters={setActiveFilters}
          handleRefilter={handleRefilter}
          handleResetFilter={handleResetFilter}
          isFilterComponentApplied={filterComponentApplied}
        />
      )}
      {hasAccess(authClaims, claims.advancedFilter) && (
        <>
          <AdvancedFilterHeader
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            num={numberOfFilteredItems}
            keyStatusColor={keyStatusColor}
            statusColor={statusColor}
          />

          <Collapse isOpen={isOpen} className="mb-4">
            <div className={style.card}>
              <AdvancedFilterMultiSelect
                attrMap={attrMap}
                module={module}
                filters={activeFilters}
                setFilters={setActiveFilters}
                handleRefilter={handleRefilter}
                handleResetFilter={handleResetFilter}
                handleCopyFilter={handleCopyFilter}
                filterable={filterable}
                keyStatusColor={keyStatusColor}
              />
            </div>
          </Collapse>
        </>
      )}
    </>
  );
};

interface AdvancedFilterHeaderProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  num: number;
  keyStatusColor: string;
  statusColor: string;
}

const getStatusText = (color: string) => {
  switch (color) {
    case "green":
      return "Success";
    case "red":
      return "Error";
    case "orange":
      return "Working";
    default:
      return "Unknown";
  }
};

const AdvancedFilterHeader = React.memo(
  ({
    isOpen,
    setIsOpen,
    num,
    keyStatusColor,
    statusColor,
  }: AdvancedFilterHeaderProps) => {
    return (
      <div className="d-flex flex-row align-items-center">
        <div
          className="d-flex flex-row align-items-center"
          onClick={() => setIsOpen((p) => !p)}
          style={{ cursor: "pointer", userSelect: "none" }}
        >
          <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
          <p className={style.adv_filter_txt}>Advanced filters ({num})</p>
          <Tooltip
            compact
            content={
              <span>
                Attribute worker status:{" "}
                <strong>{getStatusText(keyStatusColor)}</strong>
              </span>
            }
          >
            <Icon icon="dot" color={keyStatusColor} />
          </Tooltip>
          <Tooltip
            compact
            content={
              <span>
                Filter worker status:{" "}
                <strong>{getStatusText(statusColor)}</strong>
              </span>
            }
          >
            <Icon icon="dot" color={statusColor} />
          </Tooltip>
        </div>
        <Divider className="w-100" />
      </div>
    );
  }
);

export default React.memo(AdvancedFilterPanel);
