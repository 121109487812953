import { Button, ContextMenu, Menu, MenuItem } from "@blueprintjs/core";
import { JSX, useState } from "react";

type Props = {
  defaultChild: JSX.Element;
  inputField: JSX.Element;
  onSave: () => void;
  menuItemDisable?: boolean;
  itemLength?: number;
};

const ItemContext = ({
  defaultChild,
  inputField,
  onSave,
  menuItemDisable,
  itemLength,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <ContextMenu
      content={
        <Menu>
          <MenuItem
            text="Edit"
            icon="edit"
            onClick={() => {
              setIsOpen(true);
            }}
            disabled={menuItemDisable}
          />
        </Menu>
      }
    >
      {isOpen ? (
        <div className="d-grid g-template-move-item" style={{ width: "90px" }}>
          <div>{inputField}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {itemLength && `/${itemLength}`}
            <Button
              onClick={() => {
                onSave();
                setIsOpen(false);
              }}
              icon="floppy-disk"
              minimal
              small
            ></Button>
          </div>
        </div>
      ) : (
        defaultChild
      )}
    </ContextMenu>
  );
};

export default ItemContext;
