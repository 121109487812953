import { Button, InputGroup, Label } from "@blueprintjs/core";
import { useState } from "react";
import ItemContext from "./ItemContext";

type Props = {
  aliasName: string;
  saveNewAlias: (aliasName: string, newAliasName: string) => void;
  deleteNewAlias: (aliasName: string) => void;
};

const EditAliasContext = ({
  aliasName,
  saveNewAlias,
  deleteNewAlias,
}: Props) => {
  const [newName, setNewName] = useState(aliasName);
  return (
    <ItemContext
      defaultChild={
        <>
          <span>{aliasName}</span>{" "}
          <span className="alias-new-tag">{"NEW ALIAS"}</span>
        </>
      }
      inputField={
        <>
          <div className="col-10 p-1">
            <Label className="m-1">
              <InputGroup
                value={newName}
                onChange={(e) => setNewName(e.currentTarget.value)}
                placeholder={aliasName}
                fill={true}
              />
            </Label>
          </div>
          <div className="col p-1 mt-2">
            <Button
              icon="delete"
              onClick={() => deleteNewAlias(aliasName)}
              minimal
              small
            />
          </div>
        </>
      }
      onSave={() => {
        saveNewAlias(aliasName, newName);
      }}
    />
  );
};

export default EditAliasContext;
