export const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) ?? [
    0, 0, 0,
  ];
  return `rgba(${r},${g},${b},${alpha})`;
};

export const cleanRgbaValue = (rgba: string) => {
  return rgba.match(/\(([^)]+)\)/)?.[1] ?? "";
};

export const rgba2hex = (rgba: string) => {
  const cleanRgba = cleanRgbaValue(rgba); // removing parentheses
  if (!cleanRgba) return "#000000";
  const [r, g, b] = cleanRgba.split(",").map((x) => parseInt(x, 10));

  const toHex = (x: number) => x.toString(16).padStart(2, "0");

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export const getContrastColor = (rgba: string) => {
  const cleanRgba = rgba.match(/\(([^)]+)\)/); // removing parentheses
  if (!cleanRgba) return "#000000";
  const [r, g, b] = cleanRgba[1].split(",").map((x, idx) => {
    if (idx === 3) {
      return parseFloat(x);
    }
    return parseInt(x, 10);
  });
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000000" : "#ffffff";
};
