import { createActions } from "redux/create-actions";
import {
  BATCH_CHECK_USER_TASK_Payload,
  BATCH_CREATE_USER_RECIPE_Payload,
  BATCH_UPDATE_USER_TASK_Payload,
  CHECK_USER_TASK_Payload,
  CREATE_USER_RECIPE_Payload,
  GET_USER_POS_ITEMS_Payload,
  GET_USER_RECIPES_Payload,
  SAVE_USER_TASK_Payload,
  SUBSCRIBE_TO_USER_TASK_Payload,
  UPDATE_POS_ITEMS_GROUP_payload,
  UPDATE_USER_RECIPE_Payload,
  UPDATE_USER_TASK_Payload,
} from "./sagas";
import { POSItemTasksState } from "./types";

const actions = createActions("pos-item-tasks", {
  SET_STATE: {} as Partial<POSItemTasksState>,
  SUBSCRIBE_TO_USER_TASK: {} as SUBSCRIBE_TO_USER_TASK_Payload,
  UNSUBSCRIBE_TO_USER_TASK: undefined,
  UPDATE_USER_TASK: {} as UPDATE_USER_TASK_Payload,
  GET_USER_POS_ITEMS: {} as GET_USER_POS_ITEMS_Payload,
  SAVE_USER_TASK: {} as SAVE_USER_TASK_Payload,
  UPDATE_POS_ITEMS_GROUP: {} as UPDATE_POS_ITEMS_GROUP_payload,
  GET_USER_RECIPES: {} as GET_USER_RECIPES_Payload,
  BATCH_UPDATE_USER_TASK: {} as BATCH_UPDATE_USER_TASK_Payload,
  CREATE_USER_RECIPE: {} as CREATE_USER_RECIPE_Payload,
  UPDATE_USER_RECIPE: {} as UPDATE_USER_RECIPE_Payload,
  BATCH_CREATE_USER_RECIPE: {} as BATCH_CREATE_USER_RECIPE_Payload,
  CHECK_USER_TASK: {} as CHECK_USER_TASK_Payload,
  BATCH_CHECK_USER_TASK: {} as BATCH_CHECK_USER_TASK_Payload,
});

export default actions;
