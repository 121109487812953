import { Icon, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import React from "react";
import { Filter, LogicalOperator } from "redux/config/types";
import { DEFAULT_SELECTOR_PROPS, MultiSelectItem } from "../multiselect";

const logicalOperatorIconMap = new Map<LogicalOperator, any>([
  ["AND", "intersection"],
  ["OR", "resolve"],
]);

type Props = {
  selItem: MultiSelectItem;
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  handleRefilter: (filters: Filter[]) => Promise<void>;
};

const LogicalOpSelector = ({
  selItem,
  filters,
  setFilters,
  handleRefilter,
}: Props) => {
  if (!selItem.logical) return <></>;
  const handleLogicalChange = async (item: LogicalOperator) => {
    const newFilters = filters.map((filter) => {
      if (filter.id !== selItem.id) return filter;
      else
        return {
          ...filter,
          logical: item,
        };
    });
    setFilters(newFilters);
    await handleRefilter(newFilters);
  };
  return (
    <div
      className="d-flex align-items-center px-1 me-2"
      style={{
        gap: 8,
        boxShadow: "inset 0 0 0 1px rgb(17 20 24 / 80%)",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Select<LogicalOperator>
        {...DEFAULT_SELECTOR_PROPS}
        filterable={false}
        items={["AND", "OR"]}
        itemRenderer={(item, { handleClick, handleFocus, modifiers }) => (
          <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={`logic-op-${item}`}
            onClick={handleClick}
            onFocus={handleFocus}
            roleStructure="listoption"
            selected={selItem.logical === item}
            text={item}
          />
        )}
        onItemSelect={handleLogicalChange}
      >
        <Icon
          className="me-2"
          color="#ffffff80"
          icon={logicalOperatorIconMap.get(selItem.logical as LogicalOperator)}
        />
        {selItem.logical}
      </Select>
    </div>
  );
};

export default LogicalOpSelector;
