import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { POSItemTasksState } from "./types";

export const posItemTasksInitialState: POSItemTasksState = {
  loading: false,
  tasks: [],
  items: [],
  recipes: [],
  loadingUpdate: false,
  loadingRecipes: false,
};

export default createReducer(posItemTasksInitialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
