import {
  FormGroup,
  InputGroup,
  NumericInput,
  Switch,
  Text,
} from "@blueprintjs/core";
import { Attribute } from "components/edit-pinned-attributes";
import React from "react";
import { Supplier } from "redux/suppliers/types";
import module from "./index.module.scss";

type Props = {
  attribute: string;
  attrMap: Map<string, string>;
  oldData: Supplier;
  newData: Supplier;
  setNewData: React.Dispatch<Supplier>;
  pinnedProps: Attribute[];
};

const AttributeEditor = ({
  attribute,
  attrMap,
  oldData,
  newData,
  setNewData,
  pinnedProps,
}: Props) => {
  const handleSwitchChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked, name } = e.currentTarget;
    if (!checked && !Object.prototype.hasOwnProperty.call(oldData, name)) {
      delete newData[name as keyof Supplier];
      setNewData({ ...newData });
    } else {
      setNewData({ ...newData, [name]: checked });
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;
    if (value === "" && !Object.prototype.hasOwnProperty.call(oldData, name)) {
      delete newData[name as keyof Supplier];
      setNewData({ ...newData });
    } else {
      setNewData({ ...newData, [name]: value });
    }
  };

  const handleNumericInputChange = (
    valueAsNumber: number,
    _: string,
    inputElement: HTMLInputElement | null
  ) => {
    if (inputElement)
      setNewData({ ...newData, [inputElement?.name]: valueAsNumber });
  };

  const CustomLabel = (key: string) => (
    <div
      className="d-flex flex-row align-items-center"
      style={{ width: "180px" }}
    >
      <Text
        className={
          pinnedProps.find((prop) => prop.name === key) ? "" : module.blur
        }
      >
        {key}
      </Text>
    </div>
  );

  const formGroupProps = {
    inline: true,
    className: "mb-2",
    contentClassName: "w-100",
  };

  // Default renderer
  if (attrMap.get(attribute) === "boolean") {
    return (
      <FormGroup
        key={"switch-" + attribute}
        {...formGroupProps}
        label={CustomLabel(attribute)}
      >
        <Switch
          alignIndicator="right"
          name={attribute}
          checked={newData[attribute as keyof Supplier] as boolean}
          onChange={handleSwitchChange}
        />
      </FormGroup>
    );
  } else if (attrMap.get(attribute) === "number") {
    return (
      <FormGroup
        {...formGroupProps}
        key={"num-" + attribute}
        label={CustomLabel(attribute)}
      >
        <NumericInput
          fill
          placeholder={attribute}
          name={attribute}
          value={Number(newData[attribute as keyof Supplier])}
          onValueChange={handleNumericInputChange}
          min={0}
        />
      </FormGroup>
    );
  } else {
    return (
      <FormGroup
        {...formGroupProps}
        key={"input-" + attribute}
        label={CustomLabel(attribute)}
      >
        <InputGroup
          fill
          placeholder={attribute}
          name={attribute}
          value={newData[attribute as keyof Supplier]?.toString()}
          onChange={handleInputChange}
          disabled={attribute === "id"}
        />
      </FormGroup>
    );
  }
};

export default AttributeEditor;
