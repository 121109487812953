import {
  Button,
  ButtonGroup,
  MenuItem,
  Popover,
  Spinner,
  SpinnerSize,
  Tooltip,
} from "@blueprintjs/core";
import {
  ItemPredicate,
  ItemRenderer,
  ItemRendererProps,
  MultiSelect,
} from "@blueprintjs/select";
import React, { useEffect, useState } from "react";
import { Filter } from "redux/config/types";
import type { LogicalOperator } from "redux/config/types";
import { useAppSelector } from "redux/hooks";
import { claims, hasAccess } from "../../../permissions";
import { WorkerStatus, getStatusColor } from "../helpers/worker-status";
import LogicalOpSelector from "../logical-op-selector";
import MultiSelectMenuItem from "../multiselect-menuitem";
import MultiSelectTag from "../multiselect-tag";
import SaveLayoutDialog from "../save-layout-dialog";
import style from "./index.module.scss";

export const DEFAULT_SELECTOR_PROPS = {
  filterable: false,
  popoverProps: { minimal: true },
};

export type MultiSelectItem = {
  id?: string;
  attribute: string;
  operator?: string;
  values?: (string | number)[];
  logical?: string;
};

export type KeyItem = {
  attribute: string;
  value: string | number;
};

export type OperatorItem = {
  attribute: string;
  value: string | number;
  operator: string;
};

interface SelectedItems {
  values: (string | number)[];
  id?: string | undefined;
  attribute: string;
  operator: string;
  value: string | number;
  logical?: LogicalOperator | undefined;
}

type Props = {
  attrMap: Map<string, string[] | number[]>;
  module: string;
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  handleRefilter: (filters: Filter[]) => Promise<void>;
  handleResetFilter: () => void;
  handleCopyFilter?: () => void;
  filterable?: boolean;
  keyStatusColor?: string;
};

const AdvancedFilterMultiSelect = ({
  attrMap,
  module,
  filters,
  setFilters,
  handleRefilter,
  handleResetFilter,
  handleCopyFilter,
  filterable = false,
  keyStatusColor,
}: Props) => {
  const multiselectPopover: React.RefObject<Popover<any>> = React.createRef();
  const inputRef = React.createRef<HTMLInputElement>();
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [inputQuery, setInputQuery] = useState<string>("");
  const createLayoutLoading = useAppSelector(
    (state) => state.appConfig.createLayoutLoading
  );
  const authClaims = useAppSelector((state) => state.auth.authClaims);

  const [selectedItems, setSelectedItems] = useState<SelectedItems[]>([]);

  useEffect(() => {
    setInputQuery("");
  }, [selectedItems]);

  const renderTag = (selItem: MultiSelectItem) => {
    const key = selItem.attribute;
    const value: string | number | undefined = selItem.values
      ? selItem.values[0]
      : "";

    return (
      <div className="d-flex flex-row">
        <LogicalOpSelector
          selItem={selItem}
          filters={filters}
          setFilters={setFilters}
          handleRefilter={handleRefilter}
        />
        <MultiSelectTag
          key={"op-" + key + "-" + value}
          selItem={selItem}
          filters={filters}
          setFilters={setFilters}
          handleRefilter={handleRefilter}
          value={value}
        />
      </div>
    );
  };

  const render: ItemRenderer<MultiSelectItem> = (
    item,
    itemProps: ItemRendererProps
  ) => {
    const valuesAreObject = item.values?.some((val) => typeof val === "object");
    const normalizedQuery = inputQuery.toLowerCase();
    const isAttributeMatched =
      item.attribute.toLowerCase().indexOf(normalizedQuery) >= 0;

    const sortAndUnique = () => {
      const newValues = item.values
        ?.map((value) => {
          if (typeof value === "string") {
            // if value is not a number with leading zeros, convert to number
            if (!/0+\d+$/.test(value)) {
              const num = Number(value);
              if (!isNaN(num)) return num;
            }
          }
          return value;
        })
        .filter((value) => {
          if (isAttributeMatched) return true;
          if (typeof value === "string") {
            return value.toLowerCase().indexOf(normalizedQuery) >= 0;
          } else if (typeof value === "number") {
            return value.toString().indexOf(normalizedQuery) >= 0;
          }
          return false;
        })
        .sort();

      // create unique values
      const uniqueValues = new Set(newValues);
      const uniqueValuesArray = Array.from(uniqueValues);

      item.values = uniqueValuesArray;
    };

    if (!valuesAreObject) {
      sortAndUnique();
    } else {
      // get all values of the attribute
      const values = item.values;
      const valuesAreArray = values?.some((value) => Array.isArray(value));

      // flatten array of arrays
      if (valuesAreArray) {
        item.values = values?.flat();
        sortAndUnique();
      }

      // sort object by key
      if (!valuesAreArray) {
        // convert object to array their values
        const values = item.values;
        const valuesArray = values?.map((value) => {
          if (value) return Object.values(value);
          return [];
        });

        // flatten array of arrays
        item.values = valuesArray?.flat();

        // sort array of arrays
        sortAndUnique();
      }
    }

    return (
      <MultiSelectMenuItem
        key={"key-" + item.attribute}
        item={item}
        itemProps={itemProps}
        filters={filters}
        setFilters={setFilters}
        handleRefilter={handleRefilter}
        filterable={filterable}
      />
    );
  };

  const filterAttr: ItemPredicate<MultiSelectItem> = (
    query,
    item,
    _index,
    exactMatch
  ) => {
    const normalizedTitle = item.attribute.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    const values = item.values;

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      const valuesMatch = values?.some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().indexOf(normalizedQuery) >= 0;
        } else if (typeof value === "number") {
          return value.toString().indexOf(normalizedQuery) >= 0;
        } else {
          return false;
        }
      });

      const titleMatch = normalizedTitle.indexOf(normalizedQuery) >= 0;

      return Boolean(titleMatch || valuesMatch);
    }
  };

  useEffect(() => {
    const returned = filters.map((filterValue) => {
      return {
        ...filterValue,
        values: [filterValue.value],
      };
    });

    setSelectedItems(returned);
  }, [filters]);

  const handleRemoveAttributeTag = async (
    value: MultiSelectItem,
    _: number
  ) => {
    const newFilters = filters
      // Remove tag of the selected ID
      .filter((filter) => filter.id !== value.id)
      // Remove the logical operator of the first filter
      .map(({ logical, ...filter }, i) => {
        if (i === 0) return filter;
        else return { ...filter, logical };
      });
    if (newFilters.length === 0) {
      handleResetFilter();
    } else {
      setFilters(newFilters);
      await handleRefilter(newFilters);
    }
  };

  return (
    <>
      <MultiSelect<MultiSelectItem>
        fill={true}
        itemPredicate={filterAttr}
        itemRenderer={render}
        items={Array.from(attrMap.keys())
          .sort()
          .map((key) => ({
            attribute: key,
            values: attrMap.get(key),
          }))}
        menuProps={{
          "aria-label": "advanced-filter",
          className: `${style.popover_content_menu}`,
        }}
        noResults={
          keyStatusColor === getStatusColor(WorkerStatus.RUNNING) ? (
            <MenuItem
              disabled={true}
              text={
                <div className="d-flex">
                  <Spinner size={SpinnerSize.SMALL} />
                  <span style={{ marginLeft: 10 }}>Loading...</span>
                </div>
              }
              roleStructure="listoption"
            />
          ) : (
            <MenuItem
              disabled={true}
              text="No filter found."
              roleStructure="listoption"
            />
          )
        }
        onItemSelect={() => void 0}
        onRemove={(value: MultiSelectItem, index: number) => {
          handleRemoveAttributeTag(value, index);
        }}
        placeholder="No active filter."
        popoverProps={{ minimal: true }}
        popoverRef={multiselectPopover}
        resetOnQuery={false}
        tagRenderer={renderTag}
        onQueryChange={(e) => setInputQuery(e)}
        query={inputQuery}
        tagInputProps={{
          tagProps: {
            interactive: true,
            minimal: true,
            large: false,
          },
          large: true,
          leftIcon: "filter",
          rightElement:
            selectedItems.length > 0 ? (
              <ButtonGroup minimal>
                {(module === "items-user" || module === "items-global") &&
                handleCopyFilter ? (
                  <Tooltip
                    compact
                    content={
                      module === "items-global"
                        ? "Copy Global-Item Filters to User-Item"
                        : "Copy User-Item Filters to Global-Item"
                    }
                  >
                    <Button
                      icon="duplicate"
                      onClick={() => handleCopyFilter()}
                      minimal
                    />
                  </Tooltip>
                ) : (
                  <></>
                )}
                {hasAccess(authClaims, claims.createAdvancedFilters) && (
                  <Tooltip content="Save Layout">
                    <Button
                      icon="floppy-disk"
                      loading={createLayoutLoading}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsOpenDialog(true);
                      }}
                    />
                  </Tooltip>
                )}
                <Button
                  icon="cross"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleResetFilter();
                  }}
                />
              </ButtonGroup>
            ) : (
              <></>
            ),
          inputRef: inputRef,
        }}
        selectedItems={selectedItems}
      />
      <SaveLayoutDialog
        module={module}
        filters={filters}
        isOpenDialog={isOpenDialog}
        setIsOpenDialog={setIsOpenDialog}
      />
    </>
  );
};

export default React.memo(AdvancedFilterMultiSelect);
