import { Callout } from "@blueprintjs/core";
import _ from "lodash";
import { claims, hasAccess } from "permissions";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  lazy,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { UserListRef } from "redux/types";
import userAction from "redux/users/actions";
import { getUserName } from "redux/users/helpers";
import { User } from "redux/users/types";
import CheckingPriorityList from "../checking-priority-list";
import { getUnresolvedCount } from "../count.helpers";
import PriorityList from "../priority-list";
import UsersList from "../users-list";

const DynamicFilters = lazy(() => import("components/dynamic-filter"));
const InvoicesMetric = lazy(() => import("components/invoices/metric"));

type Props = {
  setTabContext: Dispatch<SetStateAction<string>>;
  setModificationUsed: Dispatch<SetStateAction<string>>;
  filterByLocale: Set<string>;
  usersListRef: RefObject<UserListRef>;
  priorityListRef: RefObject<UserListRef>;
  checkingPriorityListRef: RefObject<UserListRef>;
  userId: string;
  tabContext: string;
};

function InvoicePageUsersList({
  setTabContext,
  setModificationUsed,
  filterByLocale,
  usersListRef,
  priorityListRef,
  checkingPriorityListRef,
  userId,
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const users = useAppSelector((state) => state.users.users);
  const loadAllUsers = useAppSelector((state) => state.settings.loadAllUsers);
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const isForSupervisorFilter = useAppSelector(
    (state) => state.invoices.isForSupervisorFilter
  );
  const [advancedFilteredUsers, setAdvancedFilteredUsers] = useState<User[]>(
    []
  );
  const userName = useMemo(() => (userId ? getUserName(userId) : ""), [userId]);

  const usersFilteredByLocale = useMemo(() => {
    const usersFilteredByLocale = users
      .filter((user) => {
        if (loadAllUsers) return true;
        return (
          user.active && !user.demo && !user.organization && !user.employee
        );
      })
      .filter((user) => filterByLocale.has(user.language))
      .map((user) => ({
        ...user,
        resolvingUsers: users.filter(
          (u) => u.resolvingUserId === user.id && u.active === true
        ),
      }));
    return usersFilteredByLocale;
  }, [users, filterByLocale, loadAllUsers]);

  const usersToFilter = useMemo(() => {
    if (!usersFilteredByLocale.length) return [];
    const usersToFilter = [...usersFilteredByLocale].sort((a, b) =>
      a.name?.localeCompare(b.name)
    );
    return usersToFilter;
  }, [usersFilteredByLocale]);

  const checkingPriorityList = useMemo(() => {
    if (!usersToFilter.length) return [];
    const checkingPriority = usersToFilter
      .filter(
        (user) =>
          (authClaims?.admin
            ? (user.invoicesCount?.unchecked ?? 0)
            : (user.invoicesCount?.uncheckedNonAdmin ?? 0)) &&
          !(user.accessLevel === 0 || !user.accessLevel || !!user.demo) &&
          user.active === true
      )
      .sort((a, b) => {
        const priorityA = a.priority ?? 1;
        const priorityB = b.priority ?? 1;
        if (priorityA === priorityB) {
          return (
            (authClaims?.admin
              ? (b.invoicesCount?.unchecked ?? 0)
              : (b.invoicesCount?.uncheckedNonAdmin ?? 0)) -
            (authClaims?.admin
              ? (a.invoicesCount?.unchecked ?? 0)
              : (a.invoicesCount?.uncheckedNonAdmin ?? 0))
          );
        }
        return priorityB - priorityA;
      })
      .map((user) => ({
        ...user,
        checkingUsers: users.filter((u) => u.checkingUserId === user.id),
      }));

    const checkingUsersByPriority = _.groupBy(checkingPriority, "priority");

    for (const key in checkingUsersByPriority) {
      checkingUsersByPriority[key] = checkingUsersByPriority[key]?.sort(
        (a, b) => {
          if (
            typeof a.invoiceOldestDeliveryDate?.toMillis === "function" &&
            typeof b.invoiceOldestDeliveryDate?.toMillis === "function"
          ) {
            const aMilisDeliveryDate = a.invoiceOldestDeliveryDate?.toMillis();
            const bMilisDeliveryDate = b.invoiceOldestDeliveryDate?.toMillis();
            if (aMilisDeliveryDate && bMilisDeliveryDate) {
              return aMilisDeliveryDate - bMilisDeliveryDate;
            }
          }
          if (
            typeof a.checkingOldestInvoiceDate?.toMillis !== "function" ||
            typeof b.checkingOldestInvoiceDate?.toMillis !== "function"
          )
            return 0;
          const aMilis = a.checkingOldestInvoiceDate?.toMillis();
          const bMilis = b.checkingOldestInvoiceDate?.toMillis();
          if (aMilis && bMilis) {
            return aMilis - bMilis;
          }
          return 0;
        }
      );
    }

    const sortedCheckingPriorityKeys = Object.keys(
      checkingUsersByPriority
    ).sort((a, b) => Number(b) - Number(a));

    const finalCheckingPriority = sortedCheckingPriorityKeys.flatMap(
      (key) => checkingUsersByPriority[key] ?? []
    );

    return finalCheckingPriority;
  }, [usersToFilter, authClaims, users]);

  const priorityList = useMemo(() => {
    if (!usersToFilter.length) return [];
    const usersToPrioritize = usersToFilter
      .filter(
        (user) =>
          getUnresolvedCount(
            authClaims,
            isForSupervisorFilter,
            user.invoicesCount
          ) > 0 &&
          !(
            Number(user.accessLevel) === 0 ||
            Number(user.accessLevel) === 1 ||
            !!user.demo
          ) &&
          user.active === true
      )
      .sort((a, b) => {
        const priorityA = a.priority ?? 1;
        const priorityB = b.priority ?? 1;
        if (priorityA === priorityB) {
          return (
            getUnresolvedCount(
              authClaims,
              isForSupervisorFilter,
              b.invoicesCount
            ) -
            getUnresolvedCount(
              authClaims,
              isForSupervisorFilter,
              a.invoicesCount
            )
          );
        }
        return priorityB - priorityA;
      });
    const usersByPriority = _.groupBy(usersToPrioritize, "priority");
    const sortedUsersByPriorityKeys = Object.keys(usersByPriority).sort(
      (a, b) => Number(b) - Number(a)
    );
    const finalUsersToPrioritize = sortedUsersByPriorityKeys.flatMap(
      (key) => usersByPriority[key] ?? []
    );

    return !authClaims?.admin &&
      !authClaims?.supervisor &&
      !authClaims?.headDataManager
      ? finalUsersToPrioritize.filter(
          (u) => !u.isExcludedFromPriorityListAtInvoices && !u.isNewAtInvoices
        )
      : finalUsersToPrioritize;
  }, [usersToFilter, authClaims, isForSupervisorFilter]);

  const onButtonUserClick = (
    userIdToGo: string,
    isInvoiceVotingEnabled: boolean,
    state: string
  ) => {
    if (!userId || userId !== userIdToGo) {
      if (!userId) dispatch(userAction.UNSUBSCRIBE_FROM_USERS());
      if (isInvoiceVotingEnabled) {
        setModificationUsed("dataVoting");
      } else {
        setModificationUsed("dataLabeling");
      }
      setTabContext(state);
      if (userId && userId !== userIdToGo)
        dispatch(
          userAction.GET_USERS({ isNotFirstLoad: true, loadAllUsers: true })
        );
      dispatch(userAction.SUBSCRIBE_TO_SELECTED_USERID({ userId: userIdToGo }));
      navigate(`/data-labeling/invoices/${userIdToGo}`);
      return;
    } else {
      dispatch(userAction.UNSUBSCRIBE_TO_SELECTED_USERID());
      dispatch(
        userAction.SUBSCRIBE_TO_USERS({
          loadAllUsers: true,
          isNotFirstLoad: true,
        })
      );
      navigate(`/data-labeling/invoices`);
      return;
    }
  };

  useEffect(() => {
    const user = users?.find((user) => user.id === userId);
    if (user) {
      if (user.isInvoiceVotingEnabled) {
        setModificationUsed("dataVoting");
      } else {
        setModificationUsed("dataLabeling");
      }
    }
  }, [users, userId, setModificationUsed]);

  return (
    <div>
      <InvoicesMetric priorityLists={priorityList} />
      {userId && (
        <Callout title="Warning" intent="warning">
          <span className="mt-2">
            Users lists are not being updated in realtime when there’s a
            selected user
          </span>
        </Callout>
      )}
      <PriorityList
        username={userName}
        ref={priorityListRef}
        priorityList={priorityList}
        onButtonUserClick={onButtonUserClick}
      />

      {hasAccess(authClaims, claims.checkingPriorityList) && (
        <CheckingPriorityList
          username={userName}
          onButtonUserClick={onButtonUserClick}
          ref={checkingPriorityListRef}
          checkingPriorityList={checkingPriorityList}
        />
      )}

      <DynamicFilters
        module="invoices-users"
        advancedFilterModuleTitle="invoices"
        items={usersFilteredByLocale}
        filteredItems={advancedFilteredUsers}
        setFilteredItems={setAdvancedFilteredUsers}
      />

      <UsersList
        advancedFilteredUsers={advancedFilteredUsers}
        username={userName}
        ref={usersListRef}
        onButtonUserClick={onButtonUserClick}
      />
    </div>
  );
}

export default InvoicePageUsersList;
