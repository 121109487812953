import React, { JSX } from "react";

const CountEditor = React.lazy(
  () => import("components/counts/attribute-editor")
);
const UserEditor = React.lazy(
  () => import("components/users/attribute-editor")
);
const SuppliersEditor = React.lazy(
  () => import("components/suppliers/attribute-editor")
);

export interface Attribute {
  id: number;
  name: string;
}

type Props = {
  module: string;
  attrMap: Map<string, string>;

  oldData: any;

  newData: any;

  setNewData: React.Dispatch<any>;
  pinnedProps: Attribute[];
  setPinnedProps: React.Dispatch<React.SetStateAction<Attribute[]>>;
};

const PinnedAttributesEditorPanel = ({
  module,
  attrMap,
  oldData,
  newData,
  setNewData,
  pinnedProps,
  setPinnedProps,
}: Props): JSX.Element => {
  return (
    <div>
      {pinnedProps.map((attr, idx) => (
        <div key={attr.name + idx}>
          <React.Suspense fallback={<></>}>
            {module === "counts" && (
              <CountEditor
                key={attr.name + idx}
                attribute={attr.name}
                attrMap={attrMap}
                oldData={oldData}
                newData={newData}
                setNewData={setNewData}
                pinnedProps={pinnedProps}
                setPinnedProps={setPinnedProps}
              />
            )}
            {module === "users" && (
              <UserEditor
                key={attr.name + idx}
                attribute={attr.name}
                attrMap={attrMap}
                oldData={oldData}
                newData={newData}
                setNewData={setNewData}
                pinnedProps={pinnedProps}
              />
            )}
            {module === "suppliers" && (
              <SuppliersEditor
                key={attr.name + idx}
                attribute={attr.name}
                attrMap={attrMap}
                oldData={oldData}
                newData={newData}
                setNewData={setNewData}
                pinnedProps={pinnedProps}
              />
            )}
          </React.Suspense>
        </div>
      ))}
    </div>
  );
};

export default PinnedAttributesEditorPanel;
