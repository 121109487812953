import { createActions } from "redux/create-actions";
import { IntegrationsState } from "./types";

const actions = createActions("integrations", {
  SET_STATE: {} as Partial<IntegrationsState>,
  SUBSCRIBE_TO_INTEGRATIONS: undefined,
  UNSUBSCRIBE_FROM_INTEGRATIONS: undefined,
  SUBSCRIBE_TO_MANUAL_INTEGRATIONS: undefined,
  UNSUBSCRIBE_FROM_MANUAL_INTEGRATIONS: undefined,
});

export default actions;
