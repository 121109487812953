import { Permission, PermissionData } from "components/permissions/types";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  AssignedFilter,
  Filter,
  FormatProps,
  FormatSet,
  Layout,
  PredefinedFilterAttributes,
} from "redux/config/types";
import { ServiceReturn } from "redux/types";
import { checkCounter } from "services/counter";
import { db, functions } from "services/firebase";
import { CALLABLE_FUNCTIONS } from "utils/callable-functions/constants";
import { SERVER_COUNTS } from "utils/constants";

export async function getLayouts(): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const data: Layout[] = [];
  const layoutsRef = collection(db, "adminPanelLayouts");
  const q = query(layoutsRef, where("deleted", "==", false));
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const newdoc: DocumentData = doc.data();
      newdoc.ref = doc.ref;
      newdoc.id = doc.id;
      data.push(newdoc as Layout);
    });
    return { data, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function createLayout(
  name: string,
  filters: Filter[],
  order: number,
  module: string,
  itemFilter?: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const layoutRef = doc(collection(db, "adminPanelLayouts"));

  const layoutId = layoutRef.id;
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    await setDoc(layoutRef, {
      id: layoutId,
      name,
      filters,
      order,
      deleted: false,
      module,
      itemFilter,
    });
    return getLayouts();
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function removeLayout(id: string): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const layoutRef = doc(db, "adminPanelLayouts", id);

    await updateDoc(layoutRef, {
      deleted: true,
    });
    return getLayouts();
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function batchUpdateOrderLayouts(
  data: Layout[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const batch = writeBatch(db);
    for (const datum of data) {
      batch.update(datum.ref, {
        order: datum.order,
      });
    }
    await batch.commit();
    return getLayouts();
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function batchUpdateDefaultLayouts(
  data: Layout[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const batch = writeBatch(db);
    for (const datum of data) {
      batch.update(datum.ref, {
        isDefault: datum.isDefault,
      });
    }
    await batch.commit();
    return getLayouts();
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getPermissions(): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const data: Permission[] = [];
    const permissionsRef = collection(db, "adminPanelPermissions");
    const querySnapshot = await getDocs(permissionsRef);
    querySnapshot.forEach((doc) => {
      const newdoc: DocumentData = doc.data();
      newdoc.ref = doc.ref;
      newdoc.id = doc.id;
      data.push(newdoc as Permission);
    });
    return { data, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function createPermission(
  permission: PermissionData
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!functions) return { data: null, error: "Functions is null" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const { name, code, type, roles, userIds } = permission;
    const createPermissionCloud = httpsCallable(
      functions,
      CALLABLE_FUNCTIONS.createPermission
    );
    const response = await createPermissionCloud({
      name,
      code,
      type,
      roles,
      userIds,
    });
    const result = response.data as { error: boolean; message: string };
    if (result.error) throw new Error(result.message);
    return getPermissions();
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function batchUpdatePermissions(
  data: Permission[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!functions) return { data: null, error: "Functions is null" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const batchUpdate = httpsCallable(
      functions,
      CALLABLE_FUNCTIONS.batchUpdatePermissions
    );
    const response = await batchUpdate(data.map(({ ref, ...datum }) => datum));
    const result = response.data as { error: boolean; message: string };
    if (result.error) throw new Error(result.message);
    return getPermissions();
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updateDefaultInvoiceItemTags(
  data: DocumentData
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const newData = Object.assign({}, { defaultInvoiceItemTags: data });
  return await updateDoc(doc(db, "adminPanelSettings", "settings"), newData)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function getFormatSets(): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const data: FormatSet[] = [];
  const formattingsRef = collection(db, "adminPanelFormattings");
  const q = query(formattingsRef, where("deleted", "==", false));
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const newdoc: DocumentData = doc.data();
      newdoc.ref = doc.ref;
      newdoc.id = doc.id;
      data.push(newdoc as FormatSet);
    });
    return { data, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updateAlertTimeout(
  data: DocumentData
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const newData = Object.assign({}, { alertTimeout: data });
  return await updateDoc(doc(db, "adminPanelSettings", "settings"), newData)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function createFormatSet(
  formattings: FormatProps[],
  module: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const formattingRef = doc(collection(db, "adminPanelFormattings"));

  const formattingId = formattingRef.id;
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    await setDoc(formattingRef, {
      id: formattingId,
      deleted: false,
      formattings,
      module,
    });
    return getFormatSets();
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updateFormatSet(
  id: string,
  formattings: FormatProps[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const formattingRef = doc(db, "adminPanelFormattings", id);

    await updateDoc(formattingRef, {
      formattings,
    });
    return getFormatSets();
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function removeFormatSet(id: string): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const formattingRef = doc(db, "adminPanelFormattings", id);

    await updateDoc(formattingRef, {
      deleted: true,
    });
    return getFormatSets();
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getPredefinedFilters(
  module: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const docRef = collection(db, "predefinedFilters");
    const docQuery = query(
      docRef,
      where("module", "==", module),
      where("deleted", "==", false)
    );
    const predefinedFilters = await getDocs(docQuery);
    return {
      data: predefinedFilters.docs.map((doc) => doc.data()),
      error: null,
    };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updatePredefinedFilter(
  assignedFilter: AssignedFilter
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const { id } = assignedFilter;
    // if id is empty, create a new document
    if (!id) {
      const newId = doc(collection(db, "predefinedFilters")).id;
      assignedFilter.id = newId;

      const docRef = doc(db, "predefinedFilters", newId);
      await setDoc(docRef, assignedFilter);
      return { data: true, error: null };
    }

    // otherwise update the existing document
    const docRef = doc(db, "predefinedFilters", id);
    await updateDoc(docRef, assignedFilter);
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function deletePredefinedFilter(
  id: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const docRef = doc(db, "predefinedFilters", id);
    await updateDoc(docRef, { deleted: true });
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getPredefinedFilterAttributes(): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    const docRef = doc(db, "adminPanelSettings", "predefinedFilterAttributes");
    const attributes = await getDoc(docRef);
    return { data: attributes.data(), error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updatePredefinedFilterAttributes(
  payload: PredefinedFilterAttributes
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };

  try {
    const isConfigFileExist = await getDoc(
      doc(db, "adminPanelSettings", "predefinedFilterAttributes")
    ).then((doc) => doc.exists());

    if (!isConfigFileExist) {
      await setDoc(
        doc(db, "adminPanelSettings", "predefinedFilterAttributes"),
        payload
      );
      return { data: true, error: null };
    }

    await updateDoc(
      doc(db, "adminPanelSettings", "predefinedFilterAttributes"),
      payload
    );
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}
