export const selections = {
  InvoiceNumber: "InvoiceNumber",
  Supplier: "Supplier",
  DeliveryDate: "DeliveryDate",
  FoodTotal: "FoodTotal",
  Item: "Item",
  LastItemQuantity: "LastItemQuantity",
  LastItemTotal: "LastItemTotal",
  AddOpenItem: "AddOpenItem",
  AddDiverseItem: "AddDiverseItem",
  AutoDetectDiverseItems: "AutoDetectDiverseItems",
  LastItemCost: "LastItemCost",
  FillSearchInsideItem: "FillSearchInsideItem",
};
