import { jsx as _jsx } from "react/jsx-runtime";
import TableCell from "./table-cell";
export default function getColumns(cols, rowSelection, module, getValue) {
    if (!cols)
        return [];
    const colsData = cols.map((x) => {
        if (x.name === "createdAt")
            return Object.assign(Object.assign({ id: x.name, header: x.name, accessorKey: x.name, meta: { type: x.type } }, (getValue && { accessorFn: (row) => getValue(x.name, row) })), { sortingFn: (rowA, rowB, columnId) => {
                    const rowASelected = rowSelection[String(rowA.getValue("id"))];
                    const rowBSelected = rowSelection[String(rowB.getValue("id"))];
                    if (rowASelected || rowBSelected) {
                        return 0;
                    }
                    const rowATime = new Date(rowA.getValue(columnId)).getTime();
                    const rowBTime = new Date(rowB.getValue(columnId)).getTime();
                    return rowATime < rowBTime ? 1 : rowATime > rowBTime ? -1 : 0;
                } });
        else if (x.name === "level")
            return Object.assign(Object.assign({ id: x.name, header: x.name, accessorKey: x.name, meta: { type: x.type } }, (getValue && { accessorFn: (row) => getValue(x.name, row) })), { sortingFn: (rowA, rowB, columnId) => {
                    const rowASelected = rowSelection[String(rowA.getValue("id"))];
                    const rowBSelected = rowSelection[String(rowB.getValue("id"))];
                    if (rowASelected || rowBSelected) {
                        return 0;
                    }
                    const rowAValue = Number(rowA.getValue(columnId));
                    const rowBValue = Number(rowB.getValue(columnId));
                    return rowBValue - rowAValue;
                } });
        else
            return Object.assign(Object.assign({ id: x.name, header: x.name, accessorKey: x.name, meta: { type: x.type } }, (getValue && { accessorFn: (row) => getValue(x.name, row) })), { sortingFn: (rowA, rowB, columnId) => {
                    const rowASelected = rowSelection[String(rowA.getValue("id"))];
                    const rowBSelected = rowSelection[String(rowB.getValue("id"))];
                    if (rowASelected || rowBSelected) {
                        return 0;
                    }
                    else {
                        const rowAValue = String(rowA.getValue(columnId));
                        const rowBValue = String(rowB.getValue(columnId));
                        return rowAValue.localeCompare(rowBValue);
                    }
                } });
    });
    if (module === "notificationsTable") {
        const userIdColumn = Object.assign({ id: "userId", header: "userId", accessorKey: "userId" }, (getValue && { accessorFn: (row) => getValue("userId", row) }));
        return [...colsData, userIdColumn];
    }
    return colsData;
}
export const defaultColumn = () => ({
    cell: (ctx) => _jsx(TableCell, { cellProps: ctx }),
});
export const getColumnVisibility = (cols, visibleCols) => {
    // If visibleCols is not defined, then all columns are visible
    if (!visibleCols)
        return {};
    // Otherwise, return an object with the visible columns set to true and the rest set to false
    return visibleCols.reduce((acc, col) => {
        acc[col.name] = true;
        return acc;
    }, 
    // Initialize the object with all columns set to false
    cols.reduce((acc, col) => {
        acc[col.name] = false;
        return acc;
    }, {}) || {});
};
