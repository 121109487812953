import { Button, Classes, MenuItem } from "@blueprintjs/core";
import {
  ItemListPredicate,
  ItemRenderer,
  ItemRendererProps,
  Select,
} from "@blueprintjs/select";
import { getPercentageTextColor } from "components/invoices/color.helpers";
import Fuse from "fuse.js";
import React, {
  JSX,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppSelector } from "redux/hooks";
import { InvoiceItem, Items } from "redux/invoice/types";

type Props = {
  currency: string;
  targetItem: InvoiceItem;
  onItemSelect(item: Items, searchQuery?: string): void;
  idx: number;
  onCreateItemButtonClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    idx: number,
    query: string
  ) => void;
  userItemsAndFees: Items[];
  saveQuery: boolean;
  initialQuery?: string;
  onButtonClick?: () => void;
  setPopoverSearchItemSelectOpen?: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  renderedIndex?: number;
};

const ItemSelectButton = forwardRef(function ItemSelectButton(
  {
    currency,
    targetItem,
    onItemSelect,
    idx,
    onCreateItemButtonClick,
    userItemsAndFees,
    initialQuery,
    onButtonClick,
    saveQuery,
    setPopoverSearchItemSelectOpen,
    renderedIndex,
  }: Props,
  ref: React.Ref<HTMLInputElement>
) {
  const [query, setQuery] = useState<string>("");
  const isStrictSearchEnabled = useAppSelector(
    (state) => state.invoices.isStrictSearchEnabled
  );

  useEffect(() => {
    if (initialQuery) {
      setQuery(initialQuery);
    }
  }, [initialQuery]);

  const fuse = useMemo(() => {
    return new Fuse<Items>(userItemsAndFees, {
      includeMatches: true,
      keys: [
        { name: "name", weight: 5 },
        "size",
        "type",
        "variety",
        "tags",
        { name: "aliases", weight: 2.5 },
      ],
      ignoreLocation: true,
      useExtendedSearch: true,
    });
  }, [userItemsAndFees]);

  const filterItems: ItemListPredicate<Items> = useCallback(
    (query) => {
      if (!query) return userItemsAndFees;
      if (isStrictSearchEnabled) {
        const results = userItemsAndFees.filter(function (item) {
          return (
            item.name?.toLowerCase().includes(query.toLowerCase()) ||
            item.type?.toLowerCase().includes(query.toLowerCase()) ||
            item.variety?.toLowerCase().includes(query.toLowerCase()) ||
            item.aliases?.some((alias) =>
              alias?.toLowerCase().includes(query.toLowerCase())
            )
          );
        });
        return results;
      }
      const results = fuse.search(query);
      return results.map((i) => i.item);
    },
    [userItemsAndFees, fuse, isStrictSearchEnabled]
  );

  const renderItem: ItemRenderer<Items> = (
    item: Items,
    { modifiers, handleClick }: ItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) return null;
    return (
      <MenuItem
        key={`${item.id}-${item.name}`}
        active={modifiers.active}
        multiline
        onClick={handleClick}
        roleStructure="listoption"
        text={textFormatter(item)}
      />
    );
  };

  function textFormatter(item: Items): JSX.Element {
    return (
      <div>
        {item.isFee ? (
          <>
            {item.name} <span className={Classes.TEXT_MUTED}>fees</span>
          </>
        ) : (
          <>
            {item.name}, {item.size} {item.unit}[
            {typeof item.cost === "number" && !isNaN(item.cost)
              ? item.cost.toFixed(2)
              : "NaN"}
            {currency}]{" "}
            <span className={Classes.TEXT_MUTED}>
              {item.type} - {item.variety}
            </span>
          </>
        )}
        {item.aliases && (
          <div className={Classes.TEXT_MUTED}>{item.aliases.join(" | ")}</div>
        )}
      </div>
    );
  }
  return (
    <div
      className="d-grid g-template-item-card-header col-11-5 p-0"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="item-select-button p-0">
        <Select
          filterable={true}
          query={query}
          items={userItemsAndFees}
          itemListPredicate={filterItems}
          itemRenderer={renderItem}
          onItemSelect={(item) => {
            saveQuery ? onItemSelect(item, query) : onItemSelect(item);
            setPopoverSearchItemSelectOpen &&
              setPopoverSearchItemSelectOpen(null);
            setQuery("");
          }}
          resetOnQuery={true}
          resetOnSelect={true} // resets input box when item selected, still shows latest selected item
          noResults={<MenuItem disabled={true} text="No results." />}
          menuProps={{ className: "dropdown-menu" }}
          popoverProps={{
            matchTargetWidth: true,
            minimal: true,
            placement: "bottom",
            positioningStrategy: "fixed",
            rootBoundary: "viewport",
            onOpened: () =>
              setPopoverSearchItemSelectOpen &&
              renderedIndex !== undefined &&
              setPopoverSearchItemSelectOpen(renderedIndex),
            onClosed: () =>
              setPopoverSearchItemSelectOpen &&
              setPopoverSearchItemSelectOpen(null),
          }}
          inputProps={{
            placeholder: "Item search...",
            inputRef: ref,
          }}
        >
          <Button
            minimal
            alignText="left"
            rightIcon="double-caret-vertical"
            className="w-100"
            onClick={() => onButtonClick && onButtonClick()}
          >
            {targetItem.name.toLowerCase() === "diverse item" ||
            targetItem.isFee ||
            targetItem.type === "fee" ? (
              <span>
                {targetItem.name}
                {targetItem.isFee && ` /fee`}
              </span>
            ) : (
              targetItem.name && (
                <span>
                  {`${targetItem.name}, ${targetItem.size} ${targetItem.unit}, `}
                  <span className={Classes.TEXT_MUTED}>
                    {`${targetItem.type}/${targetItem.variety} `}
                  </span>
                  <span
                    className={
                      targetItem.oldCost === 0
                        ? "span-not-visible"
                        : targetItem.cost - targetItem.oldCost
                          ? "text-line-through"
                          : ""
                    }
                  >
                    {targetItem.oldCost && +targetItem.oldCost.toFixed(2)}
                    {currency}
                    {", "}
                  </span>
                  <span
                    className={
                      targetItem.cost - targetItem.oldCost
                        ? ""
                        : "span-not-visible"
                    }
                  >
                    {targetItem.cost && +targetItem.cost.toFixed(2)}
                    {currency},{" "}
                    <span
                      className={
                        targetItem.oldCost
                          ? getPercentageTextColor(
                              targetItem.cost,
                              targetItem.oldCost
                            )
                          : ""
                      }
                    >
                      {targetItem.oldCost
                        ? (
                            ((targetItem.cost - targetItem.oldCost) /
                              targetItem.oldCost) *
                            100
                          ).toFixed(2) + "%"
                        : ""}
                    </span>
                  </span>
                </span>
              )
            )}
          </Button>
        </Select>
      </div>
      <Button
        icon="add"
        minimal
        small
        text={undefined}
        onClick={(event) => onCreateItemButtonClick(event, idx, "")}
        className="col-md-auto"
      />
    </div>
  );
});

export default ItemSelectButton;
