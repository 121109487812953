import { DocumentData } from "firebase/firestore";

export type NotificationState = {
  notifications: Notification[];
  navbarNotifications: Notification[];
  toastsNotifications: Notification[];
  loading: boolean;
  navbarNotificationsLoading: boolean;
};

/** A Notification object
 * @category Notification Centre
 * @param {string} id - The notification ID.
 * @param {DocumentReference} ref - The Firestore reference.
 * @param {DocumentData} data - The notification data.
 */
export class Notification {
  id: string;
  status: "unread" | "read" | "done";
  message: string;
  level: number;
  data: Record<string, unknown>;
  createdAt: Date;
  type: string;

  constructor(id: string, data: DocumentData) {
    /** Unique ID
     * @type {string}
     */
    this.id = id;
    /** Indicates the status of the notification
     * @type {boolean}
     * @default false
     */
    this.status = data.status ?? "unread";
    /** The notification message
     * @type {string}
     * @default "?"
     */
    this.message = data.message ?? "?";
    /** The notification level
     * @type {number}
     * @default 0
     */
    this.level = data.level ?? 0;
    /** The notification data - you can store anything regarding the notification (e.g. a link to a page)
     * @type {Record<string, unknown>}
     * @default {}
     */
    this.data = data.data ?? {};
    /** The notification creation date
     * @type {Date}
     * @default new Date()
     */
    this.createdAt = data.createdAt.toDate() ?? new Date();
    /** The notification creation date
     * @type {string}
     * @default "?"
     */
    this.type = data.type ?? "?";
  }
}
