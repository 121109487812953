import OpenReplay from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";
import { isLocalhost } from "services/firebase";

const tracker = !isLocalhost
  ? new OpenReplay({
      projectKey: "m5EbkOAiUK4b78q0KHV2",
      ingestPoint: "https://openreplay.stockifi.io/ingest",
      // Disable sanitizing data
      obscureInputDates: false,
      obscureInputEmails: false,
      obscureInputNumbers: false,
      obscureTextEmails: false,
      obscureTextNumbers: false,
      defaultInputMode: 0,
      canvas: {
        disableCanvas: true,
      },
    })
  : null;

if (tracker)
  tracker.use(
    trackerAssist({
      callConfirm: {
        text: "Do you want to accept the call from service agent?",
        style: {
          background: "#383e47",
          boxShadow:
            "0 0 0 1px rgb(17 20 24 / 20%), 0 4px 8px rgb(17 20 24 / 40%), 0 18px 46px 6px rgb(17 20 24 / 40%)",
          color: "#f6f7f9",
        },
      },
      controlConfirm: {
        text: "Do you want to allow remote control from service agent?",
        style: {
          background: "#383e47",
          boxShadow:
            "0 0 0 1px rgb(17 20 24 / 20%), 0 4px 8px rgb(17 20 24 / 40%), 0 18px 46px 6px rgb(17 20 24 / 40%)",
          color: "#f6f7f9",
        },
      },
    })
  );

export default tracker;
