import { InvoiceItem } from "redux/invoice/types";
import { Selection } from "redux/ocr/types";
import { ocrClicksFilteredItem } from "../invoice/helpers";

export function setItemOcrInFocus(
  ocrClicks: Selection[] | undefined,
  item: InvoiceItem,
  allImages: string[],
  selectedImageUrl: string,
  setInvoiceOcrInFocus: (obj: Selection[]) => void,
  items: InvoiceItem[]
) {
  // check if ocrSelections exist and ocrSelections.ocrClicks contains
  const itemsImageUrl = item.invoicePage
    ? allImages[item.invoicePage - 1]
    : selectedImageUrl;

  const isSomeMatchSelectedUUID = items.some((item) =>
    ocrClicks?.find((c) => c.selectedUuid === item.uuid)
  );
  if (ocrClicks && itemsImageUrl === selectedImageUrl) {
    const clicks = ocrClicksFilteredItem(
      ocrClicks,
      item,
      isSomeMatchSelectedUUID
    );

    if (clicks.length > 0) {
      setInvoiceOcrInFocus(clicks);
    }
  }
}

export function setItemOcrInFocusClear(
  setInvoiceOcrInFocus: (obj: Selection[]) => void
) {
  setInvoiceOcrInFocus([]);
}
