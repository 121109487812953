import { DocumentData, doc, onSnapshot } from "firebase/firestore";
import { EventChannel, eventChannel } from "redux-saga";
import { db } from "services/firebase";

export function setUserPrioritiesListener(): EventChannel<DocumentData> {
  return eventChannel((emitter) => {
    if (!db) return () => console.log("No DB connection");

    const unsub = onSnapshot(
      doc(db, "userPriorities", "--priorities"),
      (doc) => {
        const temp = doc.data();
        if (temp === undefined) {
          emitter({});
        } else {
          emitter(temp);
        }
      }
    );

    return () => unsub();
  });
}
