import { createActions } from "redux/create-actions";
import {
  CREATE_ATTRIBUTE_Payload,
  CREATE_DOC_Payload,
  CREATE_FOLDER_Payload,
  GET_DOCS_Payload,
  SUBSCRIBE_TO_DOC_Payload,
  UPDATE_COMMENTS_Payload,
  UPDATE_DOC_Payload,
  UPDATE_EDIT_COMMENTS_Payload,
  UPDATE_MARKDOWN_Payload,
} from "./sagas";
import { DocsState } from "./types";

const actions = createActions("docs", {
  SET_STATE: {} as Partial<DocsState>,
  GET_DOCS: {} as GET_DOCS_Payload,
  CREATE_DOC: {} as CREATE_DOC_Payload,
  CREATE_FOLDER: {} as CREATE_FOLDER_Payload,
  CREATE_ATTRIBUTE: {} as CREATE_ATTRIBUTE_Payload,
  SUBSCRIBE_TO_DOC: {} as SUBSCRIBE_TO_DOC_Payload,
  UNSUBSCRIBE_FROM_DOC: {} as SUBSCRIBE_TO_DOC_Payload,
  SUBSCRIBE_TO_DOCS: undefined,
  UNSUBSCRIBE_FROM_DOCS: undefined,
  SUBSCRIBE_TO_ADMIN_DOCS: undefined,
  UNSUBSCRIBE_FROM_ADMIN_DOCS: undefined,
  SUBSCRIBE_TO_HRMS_DOCS: undefined,
  UNSUBSCRIBE_FROM_HRMS_DOCS: undefined,
  UPDATE_DOC: {} as UPDATE_DOC_Payload,
  UPDATE_MARKDOWN: {} as UPDATE_MARKDOWN_Payload,
  UPDATE_COMMENTS: {} as UPDATE_COMMENTS_Payload,
  UPDATE_EDIT_COMMENTS: {} as UPDATE_EDIT_COMMENTS_Payload,
});

export default actions;
