import { InputGroup } from "@blueprintjs/core";
import React from "react";
import { NumericFormat } from "react-number-format";

type Props = {
  scraperFrequency: number;
  setScraperFrequency: React.Dispatch<React.SetStateAction<number>>;
};

const ScrapersConfig = (props: Props) => {
  const { scraperFrequency, setScraperFrequency } = props;

  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="row">
        <div className="col">AP Scrapers Frequency (in minutes):</div>
        <div className="col mb-2">
          <NumericFormat
            aria-label="scraper-frequency"
            customInput={InputGroup}
            value={scraperFrequency}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setScraperFrequency(parseInt(e.currentTarget.value))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ScrapersConfig;
