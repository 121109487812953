import { Button, Icon, Intent, Popover } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import type { Field as FieldType, Module } from "redux/settings/types";
import Field from "./Field";
import styles from "./index.module.scss";

export interface Editables {
  module: Module;
  editableFields: string[];
}

type Props = {
  editableFields: FieldType;
  fields: FieldType;
  setFields: React.Dispatch<React.SetStateAction<FieldType>>;
};

export default function EditableField({
  editableFields,
  fields,
  setFields,
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editables, setEditables] = useState<Editables[]>([]);
  const allFields = useAppSelector((state) => state.settings.allFields);
  const modules = useAppSelector((state) => state.settings.modules);

  const handleFieldChange = (code: string, newFields: string[]) => {
    const newEditableFields = { ...fields, [code]: newFields };
    setFields(newEditableFields);
  };

  useEffect(() => {
    const newEditable: Editables[] = Object.keys(editableFields)
      .map((key) => {
        const module = modules.find((i) => i.code === key) as Module;
        const newEditableFields = editableFields[key];

        return {
          module: module,
          editableFields: newEditableFields,
        };
      })
      .filter(
        (i) =>
          i.editableFields[0] !== "" &&
          i.editableFields.length > 0 &&
          i.module?.code !== undefined
      )
      .sort((a, b) => a.module?.code.localeCompare(b.module?.code));

    setEditables(newEditable);
  }, [editableFields, modules]);

  const addNew = (module: Module) => {
    const isExist = editables
      .map((editable) => editable.module.code)
      .includes(module.code);
    if (isExist) return;

    const newEditable = {
      module,
      editableFields: [],
    };

    setEditables([...editables, newEditable]);

    setIsOpen(false);
  };

  return (
    <div>
      <div className={styles.editables}>
        {editables.map((editable) => (
          <Field
            key={editable.module.code}
            module={editable.module}
            editableFields={editable.editableFields}
            allFields={allFields[editable.module?.code]}
            onChange={handleFieldChange}
          />
        ))}
      </div>
      <div className={styles.content}>
        {!(modules.length === editables.length) && (
          <Popover
            content={
              <div className={styles.popover_content}>
                {modules
                  .filter((i) => !editables.map((e) => e.module).includes(i))
                  .map((module, idx) => (
                    <Button
                      key={idx}
                      intent={Intent.NONE}
                      onClick={() => {
                        addNew(module);
                      }}
                    >
                      {module.text}
                    </Button>
                  ))}
              </div>
            }
            position="right"
            usePortal
            interactionKind="hover"
          >
            <Button
              className={styles.add_button}
              onClick={() => setIsOpen(!isOpen)}
              disabled={modules.length === editables.length}
            >
              <Icon icon="add" />
              <span style={{ marginLeft: "5px" }}>Add New</span>
            </Button>
          </Popover>
        )}
      </div>
    </div>
  );
}
