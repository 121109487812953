import { DocumentData } from "firebase/firestore";

export type SilhouetteState = {
  loadingSave: boolean;
  loadingDelete: boolean;
  loadingConnectItems: boolean;
  silhouettes: Silhouette[];
};

export class Silhouette {
  id: string;
  refPath: string;
  imageUrl: string;
  name: string;
  path: string;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(
    id: string,
    refPath: string,
    imageUrl: string,
    data: DocumentData
  ) {
    this.id = id;
    this.refPath = refPath;
    this.imageUrl = imageUrl;
    this.name = data.name;
    this.path = data.path;
    this.deleted = data.deleted ?? false;
    this.createdAt = data.createdAt ? data.createdAt.toDate() : null;
    this.updatedAt = data.updatedAt ? data.updatedAt.toDate() : null;
  }
}
