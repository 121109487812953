import {
  Button,
  Classes,
  Dialog,
  DialogFooter,
  Tooltip,
} from "@blueprintjs/core";
import React, { Dispatch, useEffect, useState } from "react";
import alertActions from "redux/alert/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/invoice/actions";
import { Invoice } from "redux/invoice/types";
import "./index.css";

type Props = {
  data: Invoice;
  setNewInvoice: Dispatch<React.SetStateAction<Invoice>>;
};

function UploadImage({ data, setNewInvoice }: Props) {
  const dispatch = useAppDispatch();
  const isUploading = useAppSelector((state) => state.invoices.isUploading);
  const downloadURL = useAppSelector((state) => state.invoices.downloadURL);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [imageAsFile, setImageAsFile] = useState<File>();
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (!imageAsFile) {
      setUrl("");
      return;
    }

    const objectUrl = URL.createObjectURL(imageAsFile);
    setUrl(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [imageAsFile]);

  useEffect(() => {
    if (downloadURL !== "") {
      dispatch(
        actions.ADD_DOWNLOAD_URL({
          id: data.id,
          userId: data.userId,
          downloadURL: downloadURL,
        })
      );
      setNewInvoice((prev) => ({
        ...prev,
        files: [...prev.files, downloadURL],
      }));
      setIsDialogOpen(false);
    }
  }, [downloadURL]);

  function handleImageAsFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files !== null && e.target.files.length > 0) {
      const image = e.target.files[0];
      if (image.type.split("/")[0] === "image") {
        setImageAsFile(image);
        setIsDialogOpen(true);
      } else {
        dispatch(alertActions.ERROR("Not a valid image file."));
      }
    }
  }

  async function handleFireBaseUpload(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (!imageAsFile) return;
    dispatch(
      actions.FILE_UPLOAD({
        userId: data.userId,
        file: imageAsFile,
      })
    );
  }

  return (
    <>
      <label htmlFor={"file-upload-" + data.id} className={Classes.BUTTON}>
        Upload Image
      </label>
      <input
        id={"file-upload-" + data.id}
        type="file"
        accept="image/*"
        onChange={handleImageAsFile}
      />
      <Dialog
        isOpen={isDialogOpen}
        title="Upload Image"
        onClose={() => setIsDialogOpen(false)}
      >
        <div className="invoice-upload-image">
          {imageAsFile && (
            <img src={url} alt="file-upload" height="60%" width="100%" />
          )}
        </div>
        <DialogFooter
          actions={[
            <Tooltip content="Close this dialog.">
              <Button className="m-2" onClick={() => setIsDialogOpen(false)}>
                Close
              </Button>
            </Tooltip>,
            <Button
              loading={isUploading}
              className="m-2"
              onClick={handleFireBaseUpload}
            >
              Upload
            </Button>,
          ]}
        />
      </Dialog>
    </>
  );
}
export default UploadImage;
