import {
  Button,
  Classes,
  Dialog,
  DialogFooter,
  Icon,
  IconName,
  Tab,
  Tabs,
} from "@blueprintjs/core";
import { Loader } from "@stockifi/shared";
import {
  JSX,
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import docsActions from "redux/docs/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/settings/actions";
import {
  DocsType,
  Field,
  InvoiceHighlightColors,
  LookerConfig,
  LookerEmbed,
  SettingsState,
  UserStatusDescriptions,
  VertexSettings,
} from "redux/settings/types";
import { SettingsRef } from "redux/types";
import styles from "./index.module.scss";
import "./index.scss";
import { ErrorBoundary } from "@sentry/react";
import _ from "lodash";
import { User } from "redux/users/types";
import AlertTimeout from "./alert-timeout";
import DataVotingConfig from "./data-voting";
import DefaultProfileUser from "./default-profile-user";
import DocsTypes from "./docs-types";
import EditableField from "./editable-field";
import Invoices from "./invoices";
import { initialHighlightColorSettings } from "./invoices/highlight-color/constants";
import LookerConfigSection from "./looker-config-section";
import ScrapersConfig from "./scrapers-config";
import UserStatusValues from "./user-status-values";

const Settings = forwardRef((_props, ref: Ref<SettingsRef>) => {
  const loading = useAppSelector((state) => state.settings.loading);
  const loadingSave = useAppSelector((state) => state.settings.loadingSave);
  const editableFields = useAppSelector(
    (state) => state.settings.editableFields
  );
  const docsTypes = useAppSelector((state) => state.settings.docsTypes);
  const defaultTags = useAppSelector(
    (state) => state.settings.defaultInvoiceItemTags
  );
  const dbInvoicesSortingField = useAppSelector(
    (state) => state.settings.invoicesSortByField
  );
  const invoiceHighlightColors = useAppSelector(
    (state) => state.settings.invoiceHighlightColors
  );

  const alertTimeout = useAppSelector((state) => state.settings.alertTimeout);
  const userStatusValues = useAppSelector(
    (state) => state.settings.userStatusValues
  );
  const scrapersConfig = useAppSelector(
    (state) => state.settings.scrapersConfig
  );
  const lookerConfig = useAppSelector((state) => state.settings.lookerConfig);
  const lookerEmbeds = useAppSelector((state) => state.settings.lookerEmbeds);
  const dataVotingConfig = useAppSelector(
    (state) => state.settings.dataVotingConfig
  );
  const defaultUserProfile = useAppSelector(
    (state) => state.settings.defaultUserProfile
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(docsActions.GET_DOCS({ docName: "Admin Panel" }));
    dispatch(actions.GET_ALL_FIELDS());
    dispatch(actions.GET_SETTINGS());
    dispatch(actions.GET_SCRAPERS_CONFIG());
    dispatch(actions.GET_LOOKER_CONFIG());
    dispatch(actions.GET_LOOKER_EMBEDS());
    dispatch(actions.GET_DEFAULT_USER_PROFILE());
  }, [dispatch]);

  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [fields, setFields] = useState<Field>(editableFields);
  const [selectedInvoiceTags, setSelectedInvoiceTags] = useState<string[]>([]);
  const [invoicesSortByField, setInvoicesSortByField] =
    useState<SettingsState["invoicesSortByField"]>("createdAt");
  const [successTimeout, setSuccessTimeout] = useState<number>(0);
  const [errorTimeout, setErrorTimeout] = useState<number>(0);
  const [manualDismissTimeout, setManualDismissTimeout] = useState<number>(0);
  const [newDocsTypes, setNewDocsTypes] = useState<DocsType[]>([]);
  const [scraperFrequency, setScraperFrequency] = useState<number>(0);
  const [maxVotes, setMaxVotes] = useState(0);
  const [minConsensus, setMinConsensus] = useState(1);
  const [voterBotWeight, setVoterBotWeight] = useState(0);
  const [vertexBotWeight, setVertexBotWeight] = useState(0);
  const [geminiProVisionWeight, setGeminiProVisionWeight] = useState(0);
  const [vertexSettings, setVertexSettings] = useState<VertexSettings>(
    dataVotingConfig.vertexSettings?.settings as VertexSettings
  );
  const [vertexTemplate, setVertexTemplate] = useState<string>(
    dataVotingConfig.vertexSettings?.template ?? ""
  );
  const [highlightColors, setHighlightColors] =
    useState<InvoiceHighlightColors>(initialHighlightColorSettings);

  const [userStatusDescriptions, setUserStatusDescriptions] =
    useState<UserStatusDescriptions>({
      mainValue: "",
      extraValues: {
        difference: "",
        differencePercent: "",
        sales: "",
        salesDifference: "",
        purchases: "",
        purchasesDifference: "",
      },
    });

  const [newLookerConfig, setNewLookerConfig] =
    useState<LookerConfig>(lookerConfig);
  const [newLookerEmbeds, setNewLookerEmbeds] =
    useState<LookerEmbed[]>(lookerEmbeds);
  const [newDefaultUserProfile, setNewDefaultUserProfile] =
    useState<Partial<User>>(defaultUserProfile);

  useEffect(() => {
    if (defaultUserProfile) setNewDefaultUserProfile(defaultUserProfile);
  }, [defaultUserProfile]);

  useEffect(() => {
    if (docsTypes) setNewDocsTypes(docsTypes);
  }, [docsTypes]);

  useEffect(() => {
    if (editableFields) setFields(editableFields);
  }, [editableFields]);

  useEffect(() => {
    if (lookerConfig) setNewLookerConfig(lookerConfig);
  }, [lookerConfig]);

  useEffect(() => {
    if (lookerEmbeds) setNewLookerEmbeds(lookerEmbeds);
  }, [lookerEmbeds]);

  useEffect(() => {
    if (dataVotingConfig?.maxVotes) setMaxVotes(dataVotingConfig.maxVotes);
    if (dataVotingConfig?.minConsensus)
      setMinConsensus(dataVotingConfig.minConsensus);
    if (dataVotingConfig?.geminiProVisionWeight)
      setGeminiProVisionWeight(dataVotingConfig.geminiProVisionWeight);
    if (dataVotingConfig?.voterBotWeight)
      setVoterBotWeight(dataVotingConfig.voterBotWeight);
    if (dataVotingConfig?.vertexBotWeight)
      setVertexBotWeight(dataVotingConfig.vertexBotWeight);
    if (dataVotingConfig?.vertexSettings?.settings)
      setVertexSettings(dataVotingConfig.vertexSettings.settings);
    if (dataVotingConfig?.vertexSettings?.template)
      setVertexTemplate(
        // replace more than 3 spaces with new line
        dataVotingConfig.vertexSettings.template.replaceAll(/ {3,}/g, "\n")
      );
  }, [dataVotingConfig]);

  useEffect(() => {
    if (invoiceHighlightColors)
      setHighlightColors(_.cloneDeep(invoiceHighlightColors));
    else setHighlightColors(initialHighlightColorSettings);
  }, [invoiceHighlightColors]);

  const SETTING_TABS: {
    text: string;
    icon: IconName;
    element: JSX.Element;
  }[] = [
    {
      text: "Editable Fields",
      icon: "edit",
      element: (
        <ErrorBoundary fallback={<p>Data is not available.</p>}>
          <EditableField
            editableFields={editableFields}
            fields={fields}
            setFields={setFields}
          />
        </ErrorBoundary>
      ),
    },
    {
      text: "Invoices",
      icon: "tag",
      element: (
        <ErrorBoundary fallback={<p>Data is not available.</p>}>
          <Invoices
            selectedTags={selectedInvoiceTags}
            setSelectedTags={setSelectedInvoiceTags}
            selectedSortByField={invoicesSortByField}
            setSelectedSortByField={setInvoicesSortByField}
            highlightColors={highlightColors}
            setHighlightColors={setHighlightColors}
          />
        </ErrorBoundary>
      ),
    },
    {
      text: "Alert Timeout",
      icon: "time",
      element: (
        <ErrorBoundary fallback={<p>Data is not available.</p>}>
          <AlertTimeout
            successTimeout={successTimeout}
            errorTimeout={errorTimeout}
            setSuccessTimeout={setSuccessTimeout}
            setErrorTimeout={setErrorTimeout}
            manualDismissTimeout={manualDismissTimeout}
            setManualDismissTimeout={setManualDismissTimeout}
          />
        </ErrorBoundary>
      ),
    },
    {
      text: "Docs Types",
      icon: "document",
      element: (
        <ErrorBoundary fallback={<p>Data is not available.</p>}>
          <DocsTypes
            docsTypes={docsTypes}
            newDocsTypes={newDocsTypes}
            setNewDocsTypes={setNewDocsTypes}
          />
        </ErrorBoundary>
      ),
    },
    {
      text: "User Status",
      icon: "document",
      element: (
        <ErrorBoundary fallback={<p>Data is not available.</p>}>
          <UserStatusValues
            userStatusDescriptions={userStatusDescriptions}
            setUserStatusDescriptions={setUserStatusDescriptions}
          />
        </ErrorBoundary>
      ),
    },
    {
      text: "Scrapers Config",
      icon: "stopwatch",
      element: (
        <ErrorBoundary fallback={<p>Data is not available.</p>}>
          <ScrapersConfig
            scraperFrequency={scraperFrequency}
            setScraperFrequency={setScraperFrequency}
          />
        </ErrorBoundary>
      ),
    },
    {
      text: "Looker Config",
      icon: "presentation",
      element: (
        <ErrorBoundary fallback={<p>Data is not available.</p>}>
          <LookerConfigSection
            newLookerConfig={newLookerConfig}
            setNewLookerConfig={setNewLookerConfig}
            newLookerEmbeds={newLookerEmbeds}
            setNewLookerEmbeds={setNewLookerEmbeds}
          />
        </ErrorBoundary>
      ),
    },
    {
      text: "Data Voting Config",
      icon: "double-caret-vertical",
      element: (
        <ErrorBoundary fallback={<p>Data is not available.</p>}>
          <DataVotingConfig
            maxVotes={maxVotes}
            setMaxVotes={setMaxVotes}
            minConsensus={minConsensus}
            setMinConsensus={setMinConsensus}
            geminiProVisionWeight={geminiProVisionWeight}
            setGeminiProVisionWeight={setGeminiProVisionWeight}
            voterBotWeight={voterBotWeight}
            setVoterBotWeight={setVoterBotWeight}
            vertexBotWeight={vertexBotWeight}
            setVertexBotWeight={setVertexBotWeight}
            vertexSettings={vertexSettings}
            setVertexSettings={setVertexSettings}
            vertexTemplate={vertexTemplate}
            setVertexTemplate={setVertexTemplate}
          />
        </ErrorBoundary>
      ),
    },
    {
      text: "Default Profile User",
      icon: "mugshot",
      element: (
        <ErrorBoundary fallback={<p>Data is not available.</p>}>
          <DefaultProfileUser
            setNewDefaultUserProfile={setNewDefaultUserProfile}
            newDefaultUserProfile={newDefaultUserProfile}
            oldData={defaultUserProfile}
          />
        </ErrorBoundary>
      ),
    },
  ];

  useEffect(() => {
    setScraperFrequency(scrapersConfig.frequency);
  }, [scrapersConfig]);

  useEffect(() => {
    setErrorTimeout(alertTimeout.error ?? 5_000);
    setSuccessTimeout(alertTimeout.success ?? 5_000);
    setManualDismissTimeout(alertTimeout.manualDismiss ?? 3_000_000);
  }, [alertTimeout]);

  useEffect(() => {
    setUserStatusDescriptions(userStatusValues);
  }, [userStatusValues]);

  const handleOpenDialog = () => {
    setErrorTimeout(alertTimeout.error ?? 5_000);
    setSuccessTimeout(alertTimeout.success ?? 5_000);
    setManualDismissTimeout(alertTimeout.manualDismiss ?? 3_000_000);
    setSelectedInvoiceTags(defaultTags);
    setInvoicesSortByField(dbInvoicesSortingField);
    setDialogOpen(true);
  };

  useImperativeHandle(ref, () => {
    return { handleOpenDialog: handleOpenDialog };
  });

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setFields(editableFields);
    setSuccessTimeout(alertTimeout.success ?? 5_000);
    setErrorTimeout(alertTimeout.error ?? 5_000);
    setManualDismissTimeout(alertTimeout.manualDismiss ?? 3_000_000);
    setSelectedInvoiceTags(defaultTags);
    setNewDocsTypes(docsTypes);
    setNewLookerConfig(lookerConfig);
    setNewLookerEmbeds(lookerEmbeds);
  };

  const handleSaveSettings = () => {
    const newData = {
      alertTimeout: {
        success: successTimeout,
        error: errorTimeout,
        manualDismiss: manualDismissTimeout,
      },
      defaultInvoiceItemTags: selectedInvoiceTags,
      invoicesSortByField: invoicesSortByField,
      invoiceHighlightColors: highlightColors,
      docsTypes: newDocsTypes,
      editableAttributes: fields,
      userStatusValues: userStatusDescriptions,
      dataVotingConfig: {
        maxVotes,
        minConsensus,
        voterBotWeight,
        geminiProVisionWeight,
        vertexBotWeight,
        vertexSettings: {
          settings: vertexSettings,
          template: vertexTemplate,
        },
      },
    };

    const newScraperConfig = {
      frequency: scraperFrequency,
    };

    dispatch(
      actions.UPDATE_SETTINGS({
        data: newData,
      })
    );

    if (!_.isEqual(newScraperConfig, scrapersConfig)) {
      dispatch(actions.UPDATE_SCRAPERS_CONFIG(newScraperConfig));
    }

    if (!_.isEqual(newLookerConfig, lookerConfig)) {
      dispatch(
        actions.UPDATE_LOOKER_CONFIG({
          newLookerConfig: newLookerConfig,
        })
      );
    }

    if (!_.isEqual(newLookerEmbeds, lookerEmbeds)) {
      const newData = _.differenceWith(
        newLookerEmbeds,
        lookerEmbeds,
        _.isEqual
      );
      dispatch(
        actions.UPDATE_LOOKER_EMBEDS({
          newLookerEmbeds: newData,
        })
      );
    }
    if (!_.isEqual(newDefaultUserProfile, defaultUserProfile)) {
      dispatch(
        actions.UPDATE_DEFAULT_USER_PROFILE({
          newDefaultUserProfile: newDefaultUserProfile,
        })
      );
    }
  };

  return (
    <Dialog
      isOpen={isDialogOpen}
      onClose={handleCloseDialog}
      autoFocus
      className="pb-0"
      icon="cog"
      title="Application Settings"
      lazy
      style={{ width: "80vw", height: "80vh" }}
    >
      <Tabs
        animate={true}
        selectedTabId={activeTab}
        onChange={(tabId: number) => setActiveTab(tabId)}
        className={Classes.DIALOG_BODY}
        vertical
      >
        {SETTING_TABS.map((tab, index) => (
          <Tab
            key={index}
            id={index}
            title={
              <div>
                <Icon icon={tab.icon} />
                <span style={{ marginLeft: "10px" }}>{tab.text}</span>
              </div>
            }
            panel={<Loader loading={loading}>{tab.element}</Loader>}
            panelClassName={
              loading ? styles.tab_content_loading : styles.tab_content
            }
          />
        ))}
      </Tabs>

      <DialogFooter
        actions={[
          <Button key="cancel-btn" onClick={handleCloseDialog} text="Cancel" />,
          <Button
            key="save-btn"
            onClick={handleSaveSettings}
            intent="danger"
            loading={loadingSave}
            text="Save Changes"
            style={{ minWidth: "100px" }}
          />,
        ]}
      />
    </Dialog>
  );
});

export default Settings;
