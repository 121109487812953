import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import store from "redux/store";
import {
  setIntegrationsListener,
  setManualIntegrationsListener,
} from "services/integrations";
import actions from "./actions";
import { Integration } from "./types";

function* SUBSCRIBE_TO_INTEGRATIONS() {
  yield put(actions.SET_STATE({ loading: true }));
  const channel: EventChannel<boolean> = yield call(setIntegrationsListener);

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_INTEGRATIONS);
    channel.close();
  });

  try {
    while (true) {
      const integrations: Integration[] = yield take(channel);
      const users = store.getState().users.users;
      const integrationsWithUsers = integrations.map((integration) => {
        const userName =
          users.find((user) => user.id === integration.userId)?.name ?? "";
        return { ...integration, userName };
      });
      yield put(
        actions.SET_STATE({
          integrations: integrationsWithUsers,
          loading: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    yield put(actions.SET_STATE({ loading: false }));
    if (c) {
      channel.close();
    }
  }
}

function* SUBSCRIBE_TO_MANUAL_INTEGRATIONS() {
  yield put(actions.SET_STATE({ loading: true }));
  const channel: EventChannel<boolean> = yield call(
    setManualIntegrationsListener
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_MANUAL_INTEGRATIONS);
    channel.close();
  });

  try {
    while (true) {
      const integrations: Integration[] = yield take(channel);
      const users = store.getState().users.users;
      const integrationsWithUsers = integrations.map((integration) => {
        const userName =
          users.find((user) => user.id === integration.userId)?.name ?? "";
        return { ...integration, userName };
      });
      yield put(
        actions.SET_STATE({
          manualIntegrations: integrationsWithUsers,
          loadingManualIntegrations: false,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    yield put(actions.SET_STATE({ loadingManualIntegrations: false }));
    if (c) {
      channel.close();
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBSCRIBE_TO_INTEGRATIONS, SUBSCRIBE_TO_INTEGRATIONS),
    takeLatest(
      actions.SUBSCRIBE_TO_MANUAL_INTEGRATIONS,
      SUBSCRIBE_TO_MANUAL_INTEGRATIONS
    ),
  ]);
}
