import { useLocation } from "react-router-dom";

export function useActivePath() {
  const { pathname } = useLocation();
  // Only get first segment, example: /data-labeling/randomId => /data-labeling
  const firstSegment = pathname.match(/^\/[^/]*/)?.[0];
  return (path: string) => path === firstSegment;
}

const SIDE_PANEL_MODULES = ["data-labeling", "data-voting"];
export function useSidePanel() {
  const { pathname } = useLocation();
  const sidePanelActive = SIDE_PANEL_MODULES.includes(pathname.split("/")[1]);
  return sidePanelActive;
}

const INVOICE_FLOATING_MODULES = ["data-labeling"];
export function useInvoiceFloatingButton() {
  const { pathname } = useLocation();
  const invoiceFloatingButtonActive = INVOICE_FLOATING_MODULES.includes(
    pathname.split("/")[1]
  );
  return invoiceFloatingButtonActive;
}
