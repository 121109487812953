import { Icon, MenuItem, Popover } from "@blueprintjs/core";
import {
  ItemListRendererProps,
  ItemRenderer,
  ItemRendererProps,
  Select,
} from "@blueprintjs/select";
import { uuidv4 } from "@firebase/util";
import React from "react";
import { ViewportList } from "react-viewport-list";
import { Filter, LogicalOperator } from "redux/config/types";
import {
  DEFAULT_SELECTOR_PROPS,
  KeyItem,
  MultiSelectItem,
} from "../multiselect";
import module from "./index.module.scss";

type Props = {
  item: MultiSelectItem;
  values?: (string | number)[];
  itemProps: ItemRendererProps;
  filters: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  handleRefilter: (filters: Filter[]) => void;
  filterable?: boolean;
};

const MultiSelectMenuItem = ({
  item,
  itemProps,
  filters,
  setFilters,
  handleRefilter,
  filterable = false,
}: Props) => {
  const attributeDropdown: React.RefObject<Popover<any>> = React.createRef();
  const viewportRef = React.useRef<HTMLDivElement>(null);

  const handleAttributeSelect = (value: string | number, key: string) => {
    const newFilter =
      filters.length > 0
        ? {
            id: uuidv4(),
            attribute: key,
            operator: "equal to",
            value,
            logical: "AND" as LogicalOperator,
          }
        : {
            id: uuidv4(),
            attribute: key,
            operator: "equal to",
            value,
          };
    const newFilters = [...filters, newFilter];
    setFilters(newFilters);
    handleRefilter(newFilters);
  };

  const renderKey: ItemRenderer<KeyItem> = (
    { attribute, value },
    { handleClick, handleFocus, modifiers }: ItemRendererProps
  ) => {
    const selectedVals = filters
      .filter((val) => val.attribute === attribute)
      ?.map((value) => value.value);
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={`key-${attribute}-${value}-${typeof value}`}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        selected={selectedVals?.includes(value) || false}
        shouldDismissPopover={false}
        text={value.toString()}
      />
    );
  };

  const itemListRenderer = ({
    filteredItems,
    renderItem,
  }: ItemListRendererProps<KeyItem>) => {
    return (
      <div className={module.popover_content_menu} ref={viewportRef}>
        <ViewportList
          items={filteredItems}
          itemSize={0}
          viewportRef={viewportRef}
        >
          {renderItem}
        </ViewportList>
      </div>
    );
  };

  return (
    <Select<KeyItem>
      {...DEFAULT_SELECTOR_PROPS}
      filterable={filterable}
      itemPredicate={(query, item) =>
        item.value.toString().toLowerCase().includes(query.toLowerCase())
      }
      itemRenderer={renderKey}
      itemListRenderer={itemListRenderer}
      items={(item.values || []).map((value) => ({
        attribute: item.attribute,
        value: value,
      }))}
      menuProps={{
        "aria-label": "attribute",
        className: `${module.popover_content_menu}`,
      }}
      onItemSelect={(it) => handleAttributeSelect(it.value, item.attribute)}
      popoverProps={{
        ...DEFAULT_SELECTOR_PROPS.popoverProps,
        placement: "right-start",
      }}
      popoverRef={attributeDropdown}
    >
      <MenuItem
        active={itemProps.modifiers.active}
        disabled={itemProps.modifiers.disabled}
        key={item.attribute}
        labelElement={<Icon icon="caret-right" />}
        onClick={itemProps.handleClick}
        onFocus={itemProps.handleFocus}
        roleStructure="listoption"
        selected={filters
          .map((filter) => filter.attribute)
          .includes(item.attribute)}
        shouldDismissPopover={false}
        text={item.attribute}
      />
    </Select>
  );
};

export default MultiSelectMenuItem;
