import { Classes, PortalProvider } from "@blueprintjs/core";
import * as Sentry from "@sentry/react";
import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import "style/index.scss";
import { Provider } from "react-redux";
import store from "redux/store";
import { APP_VERSION } from "utils/constants";
import Router from "./router";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://81d1dd7d4a1a403fae630d030f9748e6@o1107087.ingest.sentry.io/6448371",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  enabled: process.env.NODE_ENV !== "development",
  release: APP_VERSION.toString(),
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// <React.StrictMode> removed because of blueprint https://github.com/palantir/blueprint/issues/3979
const container = document.getElementById("root");
if (!container) throw new Error("Element with id 'root' not found.");
container.classList.add(Classes.DARK);

const root = createRoot(container);
root.render(
  <StrictMode>
    <PortalProvider portalClassName={Classes.DARK}>
      <Provider store={store}>
        <Router />
      </Provider>
    </PortalProvider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
