import React from "react";
import { LookerConfig, LookerEmbed } from "redux/settings/types";
import LookerConfigurations from "./looker-config";
import LookerEmbeds from "./looker-embeds";

type Props = {
  newLookerConfig: LookerConfig;
  setNewLookerConfig: React.Dispatch<React.SetStateAction<LookerConfig>>;
  newLookerEmbeds: LookerEmbed[];
  setNewLookerEmbeds: React.Dispatch<React.SetStateAction<LookerEmbed[]>>;
};

const LookerConfigSection = ({
  newLookerConfig,
  setNewLookerConfig,
  newLookerEmbeds,
  setNewLookerEmbeds,
}: Props) => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="row" style={{ marginBottom: 50 }}>
        <LookerConfigurations
          newLookerConfig={newLookerConfig}
          setNewLookerConfig={setNewLookerConfig}
        />
      </div>
      <div className="row">
        <LookerEmbeds
          newLookerEmbeds={newLookerEmbeds}
          setNewLookerEmbeds={setNewLookerEmbeds}
        />
      </div>
    </div>
  );
};

export default LookerConfigSection;
