import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { Supplier } from "redux/suppliers/types";
import { ServiceReturn } from "redux/types";
import { checkCounter } from "services/counter";
import { db } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export async function getSuppliers() {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const data: Supplier[] = [];
  const coll = collection(db, "suppliers");
  const q = query(coll, where("deleted", "==", false));
  const querySnapshot = await getDocs(q);
  for (const doc of querySnapshot.docs) {
    const supplier = new Supplier(doc.id, doc.data());
    data.push(supplier);
  }
  return data;
}

export async function addSupplier(supplier: Supplier): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    await addDoc(collection(db, "suppliers"), supplier);
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updateSuppliers(
  suppliers: Supplier[]
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
  try {
    for (const supplier of suppliers) {
      await setDoc(doc(db, "suppliers/" + supplier.id), supplier);
    }
    return { data: true, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function updateSupplier(
  id: string,
  data: Partial<Supplier>
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return updateDoc(doc(db, "suppliers/" + id), data)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function isSupplierDeleted(name: string): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const coll = collection(db, "suppliers");
    const q = query(
      coll,
      where("name", "==", name),
      where("deleted", "==", true),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    return { data: querySnapshot.docs[0].ref, error: null };
  } catch (error) {
    return { data: null, error: error };
  }
}
