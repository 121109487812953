import { MenuItem } from "@blueprintjs/core";
import {
  ItemRenderer,
  ItemRendererProps,
  MultiSelect,
} from "@blueprintjs/select";
import React from "react";
import module from "./index.module.scss";

type Props = {
  hiddenUsers: hiddenUser[];
  setHiddenUsers: React.Dispatch<React.SetStateAction<hiddenUser[]>>;
  
  data: any[];
};

type hiddenUser = {
  id: string;
  name: string;
};

const EditHiddenUser = ({ hiddenUsers, setHiddenUsers, data }: Props) => {
  const renderItemTags: ItemRenderer<hiddenUser> = (
    item: hiddenUser,
    { handleClick }: ItemRendererProps
  ) => {
    if (!item) return null;
    return (
      <MenuItem
        active={hiddenUsers.some((user) => user.name === item.name)}
        key={item.id}
        onClick={handleClick}
        roleStructure="listoption"
        text={item.name}
      />
    );
  };

  const filterTag = (query: string, item: hiddenUser) => {
    const word = new RegExp(query, "gi");
    return word.test(item.name);
  };

  return (
    <MultiSelect
      className="w-100 multi-select-menu"
      placeholder="Hidden User"
      resetOnQuery={true}
      resetOnSelect={true}
      selectedItems={hiddenUsers}
      tagRenderer={(item) => item.name}
      tagInputProps={{
        tagProps: {
          interactive: true,
          minimal: true,
        },
      }}
      menuProps={{ className: `${module.popover_content_menu}` }}
      itemPredicate={filterTag}
      noResults={
        <MenuItem
          disabled={true}
          text="No results."
          roleStructure="listoption"
        />
      }
      items={data}
      itemRenderer={renderItemTags}
      onItemSelect={(tag: hiddenUser) => {
        hiddenUsers.some((user) => user.id === tag.id)
          ? setHiddenUsers(hiddenUsers.filter((i) => i.id !== tag.id))
          : setHiddenUsers([...hiddenUsers, tag]);
      }}
      onClear={() => setHiddenUsers([])}
      onRemove={(tag) => {
        setHiddenUsers(hiddenUsers.filter((i) => i.id !== tag.id));
      }}
    />
  );
};

export default EditHiddenUser;
