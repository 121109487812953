import { Intent, OverlayToaster, ToasterPosition } from "@blueprintjs/core";
import { useCallback, useEffect, useRef, useState } from "react";
import actions from "redux/alert/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import notificationsActions from "redux/notification-centre/actions";

function Alert() {
  const alert = useAppSelector((state) => state.alert.alert);
  const alertTimeout = useAppSelector((state) => state.settings.alertTimeout);
  const [position, setPosition] = useState<ToasterPosition>("top-right");

  // reference to blueprint toaster
  const toaster = useRef<OverlayToaster>(null);
  const getTimeout = useCallback(
    (type: Intent, isManualDismiss?: boolean) => {
      if (!isManualDismiss)
        return type === "success" ? alertTimeout.success : alertTimeout.error;
      return alertTimeout.manualDismiss;
    },
    [alertTimeout.success, alertTimeout.error, alertTimeout.manualDismiss]
  );

  // set alert to undefined after display
  const dispatch = useAppDispatch();
  const removeAlert = useCallback(() => {
    dispatch(actions.SET_STATE({ alert: undefined }));
  }, [dispatch]);

  useEffect(() => {
    const dismissAllAlerts = () => {
      toaster.current?.clear();
    };
    if (alert) {
      setPosition(alert.position);
      const message = alert.isManualDismiss
        ? `${alert.message} [${new Date()
            .getHours()
            .toString()
            .padStart(2, "0")}:${new Date()
            .getMinutes()
            .toString()
            .padStart(2, "0")}]`
        : alert.message;
      toaster.current?.show({
        message: message,
        intent: alert.type,
        timeout: getTimeout(alert.type, alert.isManualDismiss),
        onDismiss: () => removeAlert(),
        action: {
          onClick: dismissAllAlerts,
          icon: "disable",
        },
      });

      if (alert.type === "success" || alert.type === "danger") {
        dispatch(
          notificationsActions.UPDATE_TOAST_NOTIFICATION({
            operation: "add",
            message: alert.message,
            data: alert.data,
          })
        );
      }
    }
  }, [dispatch, alert, getTimeout, removeAlert]);

  return <OverlayToaster position={position} ref={toaster} />;
}

export default Alert;
