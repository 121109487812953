import {
  Button,
  Callout,
  Classes,
  DialogBody,
  DialogFooter,
  FormGroup,
  InputGroup,
  MenuItem,
  Tooltip,
} from "@blueprintjs/core";
import {
  ItemListPredicate,
  ItemRenderer,
  ItemRendererProps,
  Select,
  Suggest,
} from "@blueprintjs/select";
import Fuse from "fuse.js";
import React, {
  useEffect,
  useRef,
  useState,
  JSX,
  useMemo,
  useCallback,
} from "react";
import { NumericFormat } from "react-number-format";
import alertActions from "redux/alert/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import actions from "redux/invoice/actions";
import { Items } from "redux/invoice/types";
import { getUserCurrency } from "redux/users/helpers";
import { searchItems } from "services/items";
import { ITEM_UNITS } from "utils/constants";
import { useDetectOutsideClick } from "../items-search/hooks";
import SelectTypeVariety from "./select-type-variety";

type Props = {
  name?: string;
  onFinish: (createdItem: Items, searchquery?: string) => void;
  targetUserId?: string;
  addingCost?: boolean;
  initialQuery?: string;
  isFromInvoice?: boolean;
};

function ItemCreate({
  onFinish,
  name,
  targetUserId,
  addingCost,
  initialQuery,
  isFromInvoice = false,
}: Props) {
  const dispatch = useAppDispatch();
  const [selectedUnit, setSelectedUnit] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");
  const [selectedVariety, setSelectedVariety] = useState<string>("");
  const [suggestItems, setSuggestItems] = useState<Items[]>([]);
  const [query, setQuery] = useState<string>(name ?? "");
  const [sugggestLoading, setSuggestLoading] = useState<boolean>(false);
  const [suggestMoreLoading, setSuggestMoreLoading] = useState<boolean>(false);
  const [queryPage, setQueryPage] = useState<number>(0);
  const loading = useAppSelector((state) => state.invoices.loadingItemCreate);
  const newItemId = useAppSelector((state) => state.invoices.newItemId);
  const userId = useAppSelector((state) => state.invoices.userId);
  const ref = useRef<HTMLInputElement>(null);
  const itemsPopoverRef = useRef<HTMLInputElement>(null);
  const { isItemsPopoverOpen, setItemsPopoverOpen } =
    useDetectOutsideClick(itemsPopoverRef);
  const [errorItemExist, setErrorItemExist] = useState("");
  const [newItem, setNewItem] = useState<Items>({
    id: "",
    cost: 0,
    archived: false,
    deleted: false,
    isFee: false,
    isNew: true,
    name: name ?? "",
    size: 0,
    type: "",
    unit: "ml",
    used: true,
    variety: "",
    nameChanged: false,
    autoTotal: false,
    autoQuantity: false,
    aliases: [],
    silhouettePath: null,
    isCreatedFromAlias: false,
  });

  useEffect(() => {
    setSuggestItems([]);
    setQueryPage(0);
  }, []);

  useEffect(() => {
    if (newItemId !== "") {
      newItem.id = newItemId;
      dispatch(actions.SET_STATE({ newItemId: "" }));
      const addedAliases =
        initialQuery && initialQuery !== newItem.name ? [initialQuery] : [];

      onFinish(
        {
          ...newItem,
          aliases: addedAliases,
        },
        initialQuery
      );
    }
  }, [newItemId, newItem, initialQuery, onFinish, dispatch]);

  useEffect(() => {
    if (selectedUnit === "") return;
    setNewItem((prev) => ({
      ...prev,
      unit: selectedUnit,
    }));
  }, [selectedUnit]);

  useEffect(() => {
    if (selectedType === "") return;

    setNewItem((prev) => ({
      ...prev,
      type: selectedType,
    }));
  }, [selectedType]);

  useEffect(() => {
    if (selectedVariety === "") return;
    setNewItem((prev) => ({
      ...prev,
      variety: selectedVariety,
    }));
  }, [selectedVariety]);

  function saveItem() {
    let errString = "";

    if (!newItem.name.trim()) errString += " Name";
    if (!newItem.size || +newItem.size < 0) errString += " Size";
    if (!newItem.unit) errString += " Unit";
    if (!newItem.type || newItem.type.trim() === "") errString += " Type";
    if (!newItem.variety || newItem.variety.trim() === "")
      errString += " Variety";
    // check if data is missing
    if (errString !== "") {
      dispatch(alertActions.ERROR("Didn't save. " + errString + " is missing"));
      return;
    }

    if (!targetUserId && !userId) {
      dispatch(alertActions.ERROR("Couldn't save. User not found"));
      return;
    }
    const addedAliases =
      initialQuery && initialQuery !== newItem.name ? [initialQuery] : [];
    // dispatch item creation

    dispatch(
      actions.CREATE_ITEM({
        item: {
          ...newItem,
          aliases: addedAliases,
          hidden: newItem.isCreatedFromAlias ? false : isFromInvoice,
        },
        userId: targetUserId ?? userId,
      })
    );
  }

  const handleChange = (
    e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>
  ) => {
    setNewItem({
      ...newItem,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleChangeNumericValue = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const numNumber = +value;
    setNewItem({
      ...newItem,
      [e.currentTarget.name]: numNumber,
    });
  };

  const currency = useMemo(() => getUserCurrency(userId), [userId]);

  function textFormatter(item: Items): JSX.Element {
    return (
      <div>
        {item.isFee ? (
          <>
            {item.name} <span className={Classes.TEXT_MUTED}>{item.type}</span>
          </>
        ) : (
          <>
            {item.name}, {item.size} {item.unit}[
            {Number(item.cost ?? 0).toFixed(2)}
            {currency}]{" "}
            <span className={Classes.TEXT_MUTED}>
              {item.type} - {item.variety}
            </span>
          </>
        )}
        {item.aliases && (
          <div className={Classes.TEXT_MUTED}>{item.aliases.join(" | ")}</div>
        )}
      </div>
    );
  }

  const mapToItems = (item: any): Items => {
    return {
      autoTotal: item.autoTotal,
      autoQuantity: false, // Assuming default value as it's not provided
      id: item.id,
      cost: item.cost,
      archived: item.archived,
      deleted: item.deleted,
      isFee: item.isFee,
      isNew: item.isNew,
      name: item.name,
      size: item.size,
      type: item.type,
      unit: item.unit,
      used: item.used,
      variety: item.variety,
      nameChanged: item.nameChanged,
      aliases: item.aliases,
      silhouettePath: null, // Assuming null as it's not provided
      bundleName: item.bundleName,
      hidden: item.hidden, // Optional property
      globalId: item.globalId, // Optional property
      silhouetteName: item.silhouetteName, // Optional property
      tags: item.tags, // Optional property
      itemId: item.itemId,
      createdAt: item.createdAt, // Optional property
      starred: item.starred, // Optional property
      avgCost: item.avgCost, // Optional property
      stock: item.stock, // Optional property
      itemsV2: item.itemsV2, // Optional property
      updatedAt: item.updatedAt, // Optional property
      timestamp: item.timestamp, // Optional property
      globalData: item.globalData, // Optional property
      userId: item.userId, // Optional property
    };
  };

  const fuse = useMemo(() => {
    return new Fuse<Items>(suggestItems, {
      includeMatches: true,
      keys: [
        { name: "name", weight: 5 },
        "size",
        "type",
        "variety",
        "tags",
        { name: "aliases", weight: 2.5 },
      ],
      ignoreLocation: true,
      useExtendedSearch: true,
    });
  }, [suggestItems]);

  const filterItems: ItemListPredicate<Items> = useCallback(
    (query) => {
      if (!query) return suggestItems;

      const results = fuse.search(query);
      return results.map((i) => i.item);
    },
    [suggestItems, fuse]
  );

  const renderItem: ItemRenderer<Items> = (
    item: Items,
    { modifiers, handleClick }: ItemRendererProps
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        key={`${item.id}-${item.name}`}
        multiline
        onClick={handleClick}
        roleStructure="listoption"
        text={textFormatter(item)}
      />
    );
  };

  const queryAllItems = async (query: string) => {
    if (!query) return;
    try {
      setSuggestLoading(true);
      const searchData = await searchItems(query, true);
      const resultItems = searchData.data?.map(mapToItems);
      if (resultItems) {
        setSuggestItems(resultItems);
        const isItemExist = resultItems?.find(
          (x) =>
            x.userId === userId &&
            (x.name === query || x.aliases?.includes(query))
        );
        if (isItemExist) {
          setErrorItemExist(isItemExist.name);
        }
      }
    } catch (error) {
      dispatch(alertActions.ERROR((error as Error).message || "Error!"));
    } finally {
      setQueryPage(1);
      setSuggestLoading(false);
      setItemsPopoverOpen(true);
    }
  };

  const loadMoreItemsQuery = async (query: string) => {
    if (!query) return;
    try {
      setSuggestMoreLoading(true);
      const searchData = await searchItems(query, true, queryPage + 1);
      const resultItems = searchData.data?.map(mapToItems);
      if (resultItems) {
        setSuggestItems([...suggestItems, ...resultItems]);
        const isItemExist = resultItems?.find(
          (x) =>
            x.userId === userId &&
            (x.name === query || x.aliases?.includes(query))
        );
        if (isItemExist) {
          setErrorItemExist(isItemExist.name);
        }
      }
    } catch (error) {
      dispatch(alertActions.ERROR((error as Error).message || "Error!"));
    } finally {
      setQueryPage(queryPage + 1);
      setSuggestMoreLoading(false);
      setItemsPopoverOpen(true);
    }
  };

  return (
    <>
      <DialogBody className="mb-0">
        {errorItemExist !== "" && (
          <Callout title="Warning" intent="warning" className="mb-3">
            User already has this item. Item Name: {errorItemExist}
          </Callout>
        )}
        <FormGroup label="Alias Search" labelFor="aliasSearch">
          <Suggest<Items>
            fill
            inputProps={{
              inputRef: ref,
              placeholder: "Search items From Alias",
              title: "search-items-from-alias",
              onKeyDown: async (e) => {
                if (e.key === "Enter") {
                  await queryAllItems(query);
                }
              },
              onFocus: () => {
                setItemsPopoverOpen(true);
              },
              rightElement: (
                <>
                  <Tooltip content={"Search"}>
                    <Button
                      icon="search"
                      minimal
                      disabled={suggestMoreLoading}
                      loading={sugggestLoading}
                      onClick={async () => await queryAllItems(query)}
                    />
                  </Tooltip>
                  <Tooltip content={"Load More"}>
                    <Button
                      id="loadMoreButton"
                      icon="more"
                      disabled={sugggestLoading || !suggestItems.length}
                      minimal
                      loading={suggestMoreLoading}
                      onClick={async () => await loadMoreItemsQuery(query)}
                    />
                  </Tooltip>
                </>
              ),
            }}
            inputValueRenderer={() => query}
            itemListPredicate={filterItems}
            itemRenderer={renderItem}
            items={suggestItems}
            noResults={
              <MenuItem
                disabled={true}
                text={sugggestLoading ? "Loading..." : "No results."}
              />
            }
            onItemSelect={(item) => {
              setNewItem({
                ...newItem,
                name: item.name,
                size: item.size,
                unit: item.unit,
                type: item.type,
                variety: item.variety,
                isCreatedFromAlias: true,
                ...(addingCost && { cost: item.cost }),
              });

              setSelectedType(item.type);
              setSelectedUnit(item.unit);
              setSelectedVariety(item.variety);
              setQuery("");
              setSuggestItems([]);
              setItemsPopoverOpen(false);
            }}
            onQueryChange={(q, event) => {
              if (event === undefined) return;
              if (queryPage !== 0) setQueryPage(0);
              if (suggestItems.length) setSuggestItems([]);
              setQuery(q);
            }}
            popoverProps={{
              matchTargetWidth: true,
              minimal: true,
              isOpen: isItemsPopoverOpen,
              popoverRef: itemsPopoverRef,
            }}
            query={query}
            resetOnQuery={true}
            resetOnSelect={true}
          />
        </FormGroup>
        <FormGroup label="Name" labelFor="name">
          <InputGroup
            id="name"
            name="name"
            value={newItem?.name}
            onChange={handleChange}
            tabIndex={1}
            autoComplete="off"
            onMouseDown={(e) => e.stopPropagation()}
          />
        </FormGroup>
        <FormGroup label="Size" labelFor="size">
          <NumericFormat
            id="size"
            name="size"
            customInput={InputGroup}
            thousandSeparator={false}
            decimalScale={0}
            value={newItem?.size ? String(newItem?.size) : undefined}
            onChange={handleChangeNumericValue}
            onMouseDown={(e: any) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();
              }
            }}
          />
        </FormGroup>
        {addingCost && (
          <FormGroup label="Cost" labelFor="cost">
            <NumericFormat
              id="cost"
              name="cost"
              customInput={InputGroup}
              thousandSeparator={false}
              decimalScale={2}
              value={newItem?.cost ? String(newItem?.cost) : undefined}
              onChange={handleChangeNumericValue}
              onKeyDown={(e) => {
                if (
                  e.key === "ArrowUp" ||
                  e.key === "ArrowDown" ||
                  e.key === ","
                ) {
                  e.preventDefault();
                }
              }}
            />
          </FormGroup>
        )}
        <FormGroup label="Unit" labelFor="unit">
          <Select
            items={ITEM_UNITS}
            fill
            onItemSelect={setSelectedUnit}
            popoverProps={{ matchTargetWidth: true, minimal: true }}
            filterable={false}
            itemRenderer={(unit, { handleClick, handleFocus, modifiers }) => {
              return (
                <MenuItem
                  key={"unit-" + unit}
                  active={modifiers.active}
                  onClick={handleClick}
                  onFocus={handleFocus}
                  roleStructure="listoption"
                  text={unit}
                />
              );
            }}
            menuProps={{
              style: {
                maxHeight: "300px",
                overflowY: "auto",
                minWidth: "300px",
              },
            }}
          >
            <Button
              text={selectedUnit || "Select unit"}
              alignText="left"
              rightIcon="double-caret-vertical"
              fill
              tabIndex={4}
            />
          </Select>
        </FormGroup>
        <FormGroup label="Type" labelFor="type">
          <SelectTypeVariety
            isForType={true}
            selectedUserId={userId}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            selectedVariety={selectedVariety}
            setSelectedVariety={setSelectedVariety}
            popoverProps={{ matchTargetWidth: true, minimal: true }}
            menuProps={{
              style: {
                maxHeight: "300px",
                overflowY: "auto",
                minWidth: "300px",
              },
            }}
            tabIndex={5}
            fill={true}
            fillButton={true}
            isForFiltering={false}
          />
        </FormGroup>
        <FormGroup label="Variety" labelFor="variety">
          <SelectTypeVariety
            isForType={false}
            selectedUserId={userId}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            selectedVariety={selectedVariety}
            setSelectedVariety={setSelectedVariety}
            popoverProps={{ matchTargetWidth: true, minimal: true }}
            isDisabled={!selectedType}
            menuProps={{
              style: {
                maxHeight: "300px",
                overflowY: "auto",
                minWidth: "300px",
              },
            }}
            tabIndex={6}
            fill={true}
            fillButton={true}
            isForFiltering={false}
          />
        </FormGroup>
      </DialogBody>
      <DialogFooter>
        <Button fill loading={loading} tabIndex={6} onClick={saveItem}>
          Save
        </Button>
      </DialogFooter>
    </>
  );
}

export default ItemCreate;
