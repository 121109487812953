import { createReducer } from "@reduxjs/toolkit";
import actions from "./actions";
import { SettingsState } from "./types";

export const settingInitialState: SettingsState = {
  loading: true,
  loadAllUsers: false,
  editableFields: {},
  allFields: {},
  modules: [],
  loadingSave: false,
  // Docs Types
  docsTypes: [],
  // Invoice Item Tags
  defaultInvoiceItemTags: [],
  // Invoices sorting field
  invoicesSortByField: "createdAt",
  // Highlight Colors
  invoiceHighlightColors: undefined,
  // Alert Timeouts
  alertTimeout: { error: 5_000, success: 5_000, manualDismiss: 3_000_000 },
  // User Status Values
  userStatusValues: {
    mainValue: "",
    extraValues: {
      difference: "",
      differencePercent: "",
      sales: "",
      salesDifference: "",
      purchases: "",
      purchasesDifference: "",
    },
  },
  // Data voting max votes
  dataVotingConfig: {},
  // Scraper Frequency
  scrapersConfig: { frequency: 180 },
  // Looker Config
  lookerConfig: {
    host: "",
    modelName: "",
    secretToken: "",
    sessionLength: 1800,
  },
  // Looker Embed
  lookerEmbeds: [],
  defaultUserProfile: {},
};

export default createReducer(settingInitialState, (builder) => {
  builder.addCase(actions.SET_STATE, (state, action) => ({
    ...state,
    ...action.payload,
  }));
  builder.addDefaultCase((state) => state);
});
