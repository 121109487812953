import {
  DocumentData,
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  POSItem,
  POSItemTask,
  UserRecipe,
  UserRecipeUpdate,
} from "redux/pos-item-tasks/types";
import { ServiceReturn } from "redux/types";
import { checkCounter } from "services/counter";
import { db } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export async function updateUserTask(
  taskId: string,
  userId: string,
  task: POSItemTask
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const docRef = doc(db, "users", userId, "tasks", taskId);
  try {
    delete task?.id;
    return await updateDoc(docRef, {
      ...task,
    }).then(() => {
      return { data: true, error: false };
    });
  } catch (error) {
    return { data: null, error: error };
  }
}

export interface SaveUserTask {
  taskId: string;
  userId: string;
  task: POSItemTask;
  posItemId: string;
  newItems: Record<string, number>;
  updatedAt?: Date;
  updatedBy?: string;
}

export async function saveUserTask(data: SaveUserTask) {
  try {
    if (!db) return { data: null, error: "No db connection" };
    if (!checkCounter())
      return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

    const batch = writeBatch(db);

    const { taskId, userId, task, posItemId, newItems, updatedAt, updatedBy } =
      data;
    const ingredientRef = doc(db, "users", userId, "posItems", posItemId);

    batch.update(ingredientRef, { items: newItems });

    const taskRef = doc(db, "users", userId, "tasks", taskId);
    batch.update(taskRef, { ...task, updatedAt, updatedBy });

    await batch.commit();
    return { data: true, error: false };
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function checkUserTask(
  data: SaveUserTask,
  recipe?: UserRecipeUpdate
) {
  try {
    if (!db) return { data: null, error: "No db connection" };
    if (!checkCounter())
      return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

    const batch = writeBatch(db);

    const { taskId, userId, task, posItemId, newItems } = data;
    const ingredientRef = doc(db, "users", userId, "posItems", posItemId);
    if (Object.keys(newItems).length > 1 && task.isChecked && recipe) {
      const docRef = collection(db, "users", userId, "recipes");
      const newRecipe = await addDoc(docRef, { ...recipe });
      const recipeId = newRecipe.id;
      const recipeObj: Record<string, number> = { [recipeId]: 1 };
      batch.update(ingredientRef, { items: recipeObj });
    } else {
      batch.update(ingredientRef, { items: newItems });
    }

    const taskRef = doc(db, "users", userId, "tasks", taskId);
    const updatedTask = task.isChecked
      ? {
          isChecked: task.isChecked,
          deleted: task.deleted,
          checkedBy: task.checkedBy,
        }
      : { isChecked: task.isChecked, deleted: task.deleted };

    batch.update(taskRef, updatedTask);

    await batch.commit();
    return { data: true, error: false };
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function batchUpdateUserTask(
  userId: string,
  tasks: POSItemTask[]
) {
  try {
    if (!db) return { data: null, error: "No db connection" };
    if (!checkCounter())
      return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
    const batch = writeBatch(db);

    tasks.forEach((task: POSItemTask) => {
      if (!db) return;
      if (task.state === "resolved") {
        const taskDocRef = doc(
          db,
          "users",
          userId,
          "tasks",
          task.path.split("/").slice(1)[1]
        );

        if (task.newItems) {
          const posItemId = task.path.split("/").slice(-1)[0];
          const ingredientRef = doc(db, "users", userId, "posItems", posItemId);
          batch.update(ingredientRef, { items: task.newItems });
          delete task.newItems;
        }
        batch.update(taskDocRef, { ...task });
      }
    });
    await batch.commit();
    return { data: true, error: false };
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function batchCheckUserTask(
  userId: string,
  tasks: (POSItemTask & { newRecipe?: UserRecipeUpdate | undefined })[]
) {
  try {
    if (!db) return { data: null, error: "No db connection" };
    if (!checkCounter())
      return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
    const batch = writeBatch(db);

    tasks.forEach(async (task) => {
      if (!db) return;
      if (task.state === "resolved") {
        const taskDocRef = doc(
          db,
          "users",
          userId,
          "tasks",
          task.path.split("/").slice(1)[1]
        );

        batch.update(taskDocRef, {
          isChecked: task.isChecked,
          checkedBy: task.checkedBy,
          deleted: task.deleted,
        });

        if (task.newItems) {
          const posItemId = task.path.split("/").slice(-1)[0];
          const ingredientRef = doc(db, "users", userId, "posItems", posItemId);
          if (
            Object.keys(task.newItems).length > 1 &&
            task.isChecked &&
            task.newRecipe
          ) {
            const docRef = collection(db, "users", userId, "recipes");
            const newRecipe = await addDoc(docRef, { ...task.newRecipe });
            const recipeId = newRecipe.id;
            const recipeObj: Record<string, number> = { [recipeId]: 1 };
            batch.update(ingredientRef, { items: recipeObj });
            delete task.newRecipe;
          } else {
            batch.update(ingredientRef, { items: task.newItems });
          }
          delete task.newItems;
        }
      }
    });
    await batch.commit();
    return { data: true, error: false };
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function getUserPosItems(userId: string) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const data: DocumentData[] = [];

  const querySnapshot = await getDocs(
    query(collection(db, "users", userId, "posItems"))
  );
  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id });
  });

  return data;
}

export async function getUserUndefinedPosItems(userId: string) {
  if (!db) return "No db connection";
  if (!checkCounter()) return SERVER_COUNTS.ERROR_MAX_COUNT;

  const tasksCol = collection(db, "users/" + userId + "/tasks/");
  const tasks = (await getDocs(
    query(
      tasksCol,
      where("type", "==", "updatedPosItem"),
      where("deleted", "==", false)
    )
  ).then((s) => s.docs.map((d) => d.data()))) as POSItemTask[];

  const posItemIds = tasks.map((x) => x.path.split("/")[4]);

  const posItems = (await getUserPosItems(userId)) as POSItem[];
  return posItems.filter((x) => posItemIds.includes(x.id));
}

export async function updatePosItemsGroup(
  posItems: {
    id: string;
    group: string;
  }[],
  userId: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  try {
    const batch = writeBatch(db);
    posItems.forEach((posItem) => {
      if (!db) return;
      const posItemRef = doc(db, "users", userId, "posItems", posItem.id);
      batch.update(posItemRef, { group: posItem.group });
    });
    await batch.commit();

    return { data: true, error: false };
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function getRecipesByUserId(userId: string) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const data: UserRecipe[] = [];

  const querySnapshot = await getDocs(
    query(collection(db, "users", userId, "recipes"))
  );
  querySnapshot.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id, type: "Recipe" } as UserRecipe);
  });

  return { data, error: null };
}

export async function createRecipe(userId: string, recipe: UserRecipeUpdate) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const docRef = collection(db, "users", userId, "recipes");

  try {
    return await addDoc(docRef, { ...recipe }).then(() => {
      return { data: true, error: null };
    });
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function batchCreateRecipe(
  userId: string,
  recipes: UserRecipeUpdate[]
) {
  try {
    if (!db) return { data: null, error: "No db connection" };
    if (!checkCounter())
      return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };
    const batch = writeBatch(db);

    recipes.forEach((recipe: UserRecipeUpdate) => {
      if (!db) return;
      const docRef = doc(collection(db, "users", userId, "recipes"));

      batch.set(docRef, { ...recipe });
    });
    await batch.commit();
    return { data: true, error: false };
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function updateRecipe(
  userId: string,
  recipeId: string,
  recipe: Partial<UserRecipeUpdate>
) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  const docRef = doc(db, "users", userId, "recipes", recipeId);

  try {
    return await updateDoc(docRef, { ...recipe }).then(() => {
      return { data: true, error: null };
    });
  } catch (error) {
    return { data: null, error: error };
  }
}
