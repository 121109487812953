import { DocumentData, DocumentReference } from "firebase/firestore";
import { EventChannel } from "redux-saga";
import {
  CancelledEffect,
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import actions from "redux/counts/actions";
import { ServiceReturn } from "redux/types";
import { batchUpdateCounts, updateCount } from "services/counts";
import { setCountsListener } from "services/listeners/counts";
import { COMMON } from "utils/constants";
import { Count } from "./types";

export interface GET_COUNTS_Payload {
  userId: string;
  includeDeleted?: boolean;
}

function* SUBSCRIBE_TO_COUNTS(
  input: ReturnType<typeof actions.SUBSCRIBE_TO_COUNTS>
) {
  yield put(
    actions.SET_STATE({
      loadingCounts: true,
      counts: [],
    })
  );
  const { userId, includeDeleted } = input.payload;
  const channel: EventChannel<boolean> = yield call(
    setCountsListener,
    userId,
    includeDeleted
  );

  yield fork(function* () {
    yield take(actions.UNSUBSCRIBE_FROM_COUNTS);
    yield put(actions.SET_STATE({ counts: [] }));
    channel.close();
  });

  try {
    while (true) {
      const counts: Count[] = yield take(channel);
      yield put(
        actions.SET_STATE({
          loadingCounts: false,
          counts,
        })
      );
    }
  } finally {
    const c: CancelledEffect = yield cancelled();
    if (c) {
      channel.close();
      yield put(
        actions.SET_STATE({
          loadingCounts: false,
        })
      );
    }
  }
}

export interface UPDATE_COUNT_Payload {
  countRef: DocumentReference;
  data: DocumentData;
}

export function* UPDATE_COUNT(input: ReturnType<typeof actions.UPDATE_COUNT>) {
  const { countRef, data } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingUpdate: true,
    })
  );

  const result: ServiceReturn = yield call(updateCount, countRef, data);
  if (result.data) {
    yield put(alertActions.SUCCESS("Count Updated"));
    yield put(actions.SET_STATE({ loadingUpdate: false }));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
    yield put(actions.SET_STATE({ loadingUpdate: false }));
  }
}

export interface BATCH_UPDATE_COUNTS_Payload {
  countsArray: string[];
  userId: string;
  data: DocumentData;
}

function* BATCH_UPDATE_COUNTS(
  input: ReturnType<typeof actions.BATCH_UPDATE_COUNTS>
) {
  const { countsArray, userId, data } = input.payload;

  yield put(
    actions.SET_STATE({
      loadingBulkEdit: true,
    })
  );

  const result: ServiceReturn = yield call(
    batchUpdateCounts,
    countsArray,
    userId,
    data
  );
  if (result.data) {
    yield put(
      alertActions.SUCCESS(
        `${countsArray.length} ${
          countsArray.length > 1 ? "counts have" : "count has"
        } been updated.`
      )
    );
    yield put(actions.SET_STATE({ loadingBulkEdit: false }));
  } else {
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
    yield put(actions.SET_STATE({ loadingBulkEdit: false }));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBSCRIBE_TO_COUNTS, SUBSCRIBE_TO_COUNTS),
    takeLatest(actions.UPDATE_COUNT, UPDATE_COUNT),
    takeLatest(actions.BATCH_UPDATE_COUNTS, BATCH_UPDATE_COUNTS),
  ]);
}
