const maxCount = 100;
const maxTime = 1000; // ms

export function checkCounter() {
  let counter = localStorage.getItem("counter");
  if (counter === null) {
    localStorage.setItem("counter", "1");
    counter = "1";
  } else {
    counter = (parseInt(counter) + 1).toString();
    localStorage.setItem("counter", counter);
  }
  let timestamp = localStorage.getItem("timestamp");
  if (timestamp === null) {
    timestamp = (+new Date()).toString();
    localStorage.setItem("timestamp", timestamp);
  }

  if (+counter > maxCount && +new Date() - +timestamp < maxTime) return false;

  // check if it has passed 1 second
  if (+new Date() - +timestamp > maxTime) {
    timestamp = (+new Date()).toString();
    localStorage.setItem("timestamp", timestamp);
    localStorage.setItem("counter", "1");
  }
  return true;
}
