import { AuthError } from "firebase/auth";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { Called } from "redux/types";
import {
  NOT_ACTIVE,
  getAllUserClaims,
  getAuthToken,
  getCurrentUser,
  getUserClaims,
  logInWithEmailAndPassword,
  logout,
} from "services/auth";
import actions from "./actions";

export function* LOGOUT() {
  yield call(logout);
  yield put(actions.SET_STATE({ user: undefined, authClaims: undefined }));
}

export interface LOGIN_Payload {
  email: string;
  password: string;
}

export function* LOGIN(action: ReturnType<typeof actions.LOGIN>) {
  const { email, password } = action.payload;
  yield put(actions.SET_STATE({ loading: true }));

  try {
    const { data: user, error }: Called<typeof logInWithEmailAndPassword> =
      yield call(logInWithEmailAndPassword, email, password);

    if (user) {
      yield put(
        actions.SET_STATE({
          user: user?.uid,
          loading: false,
          error: undefined,
        })
      );
    } else {
      yield put(
        actions.SET_STATE({
          loading: false,
          user: undefined,
          error: { code: "unknown error" },
        })
      );

      if (error === NOT_ACTIVE) {
        yield put(actions.LOGOUT());
      }
    }
  } catch (e) {
    yield put(
      actions.SET_STATE({
        error: e as AuthError,
        user: undefined,
        loading: false,
      })
    );
  }
}

export function* GET_USER_CLAIMS() {
  try {
    const authClaims: Called<typeof getUserClaims> = yield call(getUserClaims);
    if (authClaims) {
      yield put(actions.SET_STATE({ authClaims }));
    }
  } catch (e) {
    yield put(actions.SET_STATE({ authClaims: undefined }));
  }
}

export function* GET_ALL_USER_CLAIMS() {
  try {
    yield put(actions.SET_STATE({ allUserClaimsLoading: true }));
    const allUserClaims: Called<typeof getAllUserClaims> =
      yield call(getAllUserClaims);

    if (allUserClaims) {
      yield put(
        actions.SET_STATE({ allUserClaims, allUserClaimsLoading: false })
      );
    }
  } catch (e) {
    yield put(
      actions.SET_STATE({
        allUserClaims: undefined,
        allUserClaimsLoading: false,
      })
    );
  }
}

export function* GET_CURRENT_USER() {
  try {
    const user: Called<typeof getCurrentUser> = yield call(getCurrentUser);
    if (user) {
      yield put(actions.SET_STATE({ user: user.uid }));
    }
  } catch (e) {
    yield put(actions.SET_STATE({ user: undefined }));
  }
}

export function* GET_AUTH_TOKEN() {
  yield put(actions.SET_STATE({ loadingToken: true }));

  const token: Called<typeof getAuthToken> = yield call(getAuthToken);
  if (token.data) {
    yield put(
      actions.SET_STATE({
        token: token.data,
        loadingToken: false,
      })
    );
  } else {
    yield put(actions.SET_STATE({ loadingToken: false }));
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeLatest(actions.LOGOUT, LOGOUT),
    takeLatest(actions.GET_USER_CLAIMS, GET_USER_CLAIMS),
    takeLatest(actions.GET_CURRENT_USER, GET_CURRENT_USER),
    takeLatest(actions.GET_AUTH_TOKEN, GET_AUTH_TOKEN),
    takeLatest(actions.GET_ALL_USER_CLAIMS, GET_ALL_USER_CLAIMS),
  ]);
}
