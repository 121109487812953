import _ from "lodash";
import { useEffect, useState } from "react";
import actions from "redux/config/actions";
import { Layout } from "redux/config/types";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { claims, hasAccess } from "../../../../permissions";

const useLayouts = (module: string) => {
  const dispatch = useAppDispatch();
  const authClaims = useAppSelector((state) => state.auth.authClaims);
  const storeLayouts = useAppSelector((state) => state.appConfig.layouts);
  const [layouts, setLayouts] = useState<Layout[]>([]);

  useEffect(() => {
    dispatch(actions.GET_LAYOUTS());
    return () => {
      dispatch(actions.SET_STATE({ layouts: [] }));
    };
  }, [dispatch, module]);

  useEffect(() => {
    if (storeLayouts) {
      const filteredStoreLayouts = storeLayouts
        .filter((layout) => layout.module === module)
        .sort((a, b) => a.order - b.order);
      setLayouts(filteredStoreLayouts);
    }
  }, [storeLayouts, module]);

  const changeOrder = (layouts: Layout[]) => {
    const filteredStoreLayouts = storeLayouts
      .filter((layout) => layout.module === module)
      .sort((a, b) => a.order - b.order);
    if (!_.isEqual(layouts, filteredStoreLayouts)) {
      dispatch(
        actions.UPDATE_LAYOUT_ORDER({
          layouts: layouts,
        })
      );
    }
  };

  const changeDefault = (id: string) => {
    const currentDefault = layouts.find((layout) => layout.isDefault === true);
    const newDefault = layouts.find((layout) => layout.id === id);
    if (!newDefault) return;
    dispatch(
      actions.CHANGE_DEFAULT_LAYOUT({
        currentDefault,
        newDefault,
      })
    );
    // Optimistic update
    setLayouts((prev) =>
      prev.map((layout) => {
        if (layout.id === id) return { ...newDefault, isDefault: true };
        else if (layout.id === currentDefault?.id)
          return { ...currentDefault, isDefault: false };
        else return layout;
      })
    );
  };

  const removeDefault = () => {
    const updatedLayouts = layouts.map((layout) => ({
      ...layout,
      isDefault: false,
    }));

    dispatch(actions.REMOVE_DEFAULT_LAYOUT({ updatedLayouts }));
  };

  const deleteLayout = (id: string) => {
    if (hasAccess(authClaims, claims.editSavedAdvancedFilters)) {
      dispatch(actions.DELETE_LAYOUT({ id }));
    }
  };

  return {
    layouts,
    setLayouts,
    changeOrder,
    changeDefault,
    removeDefault,
    deleteLayout,
  };
};

export default useLayouts;
