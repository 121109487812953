import { Classes } from "@blueprintjs/core";
import { cn } from "@stockifi/shared";

export const getPercentageTextColor = (oldCost: number, cost: number) => {
  const percentage: number = ((cost - oldCost) / oldCost) * 100;
  if (percentage >= 15) return "text-green";
  else if (percentage <= -15) return "text-red";
  return "";
};

type GetBorderColorProps = {
  oldCost: number;
  cost: number;
  isNew: boolean;
  quantity: number;
  total: number;
  isOpenItem: boolean;
  nameChanged: boolean;
  wasOpenItem?: boolean;
  isActiveOCRItemCard?: boolean;
};

export const getBorderColor = ({
  oldCost,
  cost,
  isNew,
  quantity,
  total,
  isOpenItem,
  nameChanged,
  wasOpenItem,
  isActiveOCRItemCard,
}: GetBorderColorProps) => {
  const defaultClassName = cn(
    "border-default",
    Classes.ELEVATION_2,
    isActiveOCRItemCard ? "border-active-ocr-item" : ""
  );
  if (isOpenItem === true) return cn(defaultClassName, "border-open-item");
  if (wasOpenItem === true) return cn(defaultClassName, "border-was-open-item");
  if (isNew === true) return cn(defaultClassName, "border-yellow");

  const percentage: number =
    !!cost && !!oldCost && cost !== Infinity
      ? ((cost - oldCost) / oldCost) * 100
      : 0;
  if (cost < 0 || quantity < 0 || total < 0)
    return cn(defaultClassName, "border-red");
  else if (percentage >= 75 || percentage <= -75)
    return cn(defaultClassName, "border-dark-orange");
  else if (percentage >= 15 || percentage <= -15)
    return cn(defaultClassName, "border-orange");

  if (nameChanged) return cn(defaultClassName, "border-alt-name");
  return defaultClassName;
};
