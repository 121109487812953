import { InputGroup } from "@blueprintjs/core";
import React from "react";
import type { UserStatusDescriptions } from "redux/settings/types";

interface Props {
  userStatusDescriptions: UserStatusDescriptions;
  setUserStatusDescriptions: React.Dispatch<
    React.SetStateAction<UserStatusDescriptions>
  >;
}

export default function UserStatusValues(props: Props) {
  const { userStatusDescriptions, setUserStatusDescriptions } = props;

  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="row">
        <div className="col">Main Value:</div>
        <div className="col mb-2">
          <InputGroup
            fill
            onChange={(e) =>
              setUserStatusDescriptions((prev) => ({
                ...prev,
                mainValue: e.currentTarget.value,
              }))
            }
            value={userStatusDescriptions.mainValue}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">Difference:</div>
        <div className="col mb-2">
          <InputGroup
            fill
            onChange={(e) =>
              setUserStatusDescriptions((prev) => ({
                ...prev,
                extraValues: {
                  ...prev.extraValues,
                  difference: e.currentTarget.value,
                },
              }))
            }
            value={userStatusDescriptions.extraValues.difference}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">Difference %:</div>
        <div className="col mb-2">
          <InputGroup
            fill
            onChange={(e) =>
              setUserStatusDescriptions((prev) => ({
                ...prev,
                extraValues: {
                  ...prev.extraValues,
                  differencePercent: e.currentTarget.value,
                },
              }))
            }
            value={userStatusDescriptions.extraValues.differencePercent}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">Sales:</div>
        <div className="col mb-2">
          <InputGroup
            fill
            onChange={(e) =>
              setUserStatusDescriptions((prev) => ({
                ...prev,
                extraValues: {
                  ...prev.extraValues,
                  sales: e.currentTarget.value,
                },
              }))
            }
            value={userStatusDescriptions.extraValues.sales}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">Sales Difference %:</div>
        <div className="col mb-2">
          <InputGroup
            fill
            onChange={(e) =>
              setUserStatusDescriptions((prev) => ({
                ...prev,
                extraValues: {
                  ...prev.extraValues,
                  salesDifference: e.currentTarget.value,
                },
              }))
            }
            value={userStatusDescriptions.extraValues.salesDifference}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">Purchases:</div>
        <div className="col mb-2">
          <InputGroup
            fill
            onChange={(e) =>
              setUserStatusDescriptions((prev) => ({
                ...prev,
                extraValues: {
                  ...prev.extraValues,
                  purchases: e.currentTarget.value,
                },
              }))
            }
            value={userStatusDescriptions.extraValues.purchases}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">Purchases Difference %:</div>
        <div className="col mb-2">
          <InputGroup
            fill
            onChange={(e) =>
              setUserStatusDescriptions((prev) => ({
                ...prev,
                extraValues: {
                  ...prev.extraValues,
                  purchasesDifference: e.currentTarget.value,
                },
              }))
            }
            value={userStatusDescriptions.extraValues.purchasesDifference}
          />
        </div>
      </div>
    </div>
  );
}
