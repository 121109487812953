export const roles: {
  code: string;
  name: string;
}[] = [
  {
    code: "admin",
    name: "Admin",
  },
  {
    code: "dev",
    name: "Developer",
  },
  {
    code: "headDataSupervisor",
    name: "Head Data Supervisor",
  },
  {
    code: "supervisor",
    name: "Data Supervisor",
  },
  {
    code: "headDataManager",
    name: "Head Data Manager",
  },
  {
    code: "invoices",
    name: "Data Manager",
  },
];
