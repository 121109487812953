import React, { JSX } from "react";

import { Button, InputGroup } from "@blueprintjs/core";

type SearchProps = {
  value: string;
  placeholder: string;
  readOnly: boolean;
};

type NavSearchProps = {
  searchProps: SearchProps;
  setSearchProps: React.Dispatch<SearchProps>;
};

const NavSearchBar = ({ searchProps, setSearchProps }: NavSearchProps) => {
  const clearSearch = (): void =>
    setSearchProps({
      ...searchProps,
      value: "",
    });

  const getCloseButton = (): JSX.Element => {
    return searchProps.value === "" ? (
      <></>
    ) : (
      <Button icon="cross" minimal={true} onClick={clearSearch} />
    );
  };

  return (
    <InputGroup
      leftIcon="search"
      onChange={(e) =>
        setSearchProps({
          ...searchProps,
          value: e.target.value,
        })
      }
      placeholder={searchProps.placeholder}
      readOnly={searchProps.readOnly}
      rightElement={getCloseButton()}
      type="text"
      value={searchProps.value}
      id="search-bar"
    />
  );
};

export default NavSearchBar;
