import { Icon, Tag } from "@blueprintjs/core";
import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { Invoice, InvoiceItem } from "redux/invoice/types";
import { ITEM_TAGS, ItemTag, isItemPrefilled } from "../tag.helpers";
import "./index.scss";
import { cn } from "@stockifi/shared";

type Props = {
  data: Invoice;
  initialItems?: InvoiceItem[];
  items: InvoiceItem[];
  selectedItemTags: string[];
  setSelectedItemTags: React.Dispatch<React.SetStateAction<string[]>>;
  searchInput: string;
  modification?: string;
  inactive?: boolean;
};

function InvoiceItemTags({
  initialItems,
  data,
  items,
  selectedItemTags,
  setSelectedItemTags,
  searchInput,
  modification,
  inactive = false,
}: Props) {
  const [isDefaultFilterOn, setIsDefaultFilterOn] = useState<boolean>(true);
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
  const defaultItemTags = useAppSelector(
    (state) => state.settings.defaultInvoiceItemTags
  );

  const itemTags = useMemo<ItemTag[]>(() => {
    const existingItemsUuid = items.map((item) => item.uuid);
    const itemFromData = initialItems ?? [];
    const initialItemsToFiltered = itemFromData
      ? itemFromData.filter((item) => existingItemsUuid.includes(item.uuid))
      : [];

    return ITEM_TAGS.filter((tag) =>
      isInitialRender
        ? initialItemsToFiltered?.filter((i) => tag.filterFn(i)).length > 0
        : items?.filter((i) => tag.filterFn(i)).length > 0
    );
  }, [items, initialItems]);

  function filterTags(tag: string) {
    setSelectedItemTags((prev) =>
      prev.includes(tag) ? prev.filter((i) => i !== tag) : [...prev, tag]
    );
  }

  function hasFilter(tag: string) {
    return selectedItemTags.some((x) => x === tag);
  }

  useEffect(() => {
    if (searchInput) {
      setSelectedItemTags((prev) =>
        Array.from(new Set([...prev, "searchInput"]))
      );
    }
  }, [searchInput]);

  useEffect(() => {
    if (searchInput || !isDefaultFilterOn) return;
    if (data.state !== "unresolved" && modification !== "dataVoting") {
      if (isInitialRender && itemTags.length > 0) {
        setSelectedItemTags(
          [
            ...itemTags,
            {
              name: "newAliases",
            },
          ]
            .filter((tag) => defaultItemTags.includes(tag.name))
            .map((tag) => tag.name)
        );
        setIsInitialRender(false);
      }
    } else if (data.state === "unresolved" && modification === "dataVoting") {
      setSelectedItemTags((prev) => [...prev, "notPrefilled"]);
    } else {
      // If no items on selected tags, remove corresponding tags
      setSelectedItemTags((prev) =>
        prev.filter((tag) => itemTags.map((t) => t.name).includes(tag))
      );
    }
  }, [itemTags]);

  return (
    <div className="d-flex">
      <div className="tags-wrapper flex-grow-1" style={{ cursor: "default" }}>
        {data &&
          items.length > 0 &&
          data.newAliases &&
          Object.values(data.newAliases).flat(1).length > 0 && (
            <Tag
              className={cn(
                selectedItemTags.includes("newAliases") && "tag-active",
                "m-1 mb-2",
                "tag-light-green"
              )}
              onClick={() => filterTags("newAliases")}
            >
              New Aliases
              {Object.values(data.newAliases).flat(1).length > 1 && (
                <Tag
                  className={cn(
                    "m-1 mb-2",
                    "tag-stacked",
                    inactive && "tag-stacked-inactive"
                  )}
                  round={true}
                >
                  {Object.values(data.newAliases).flat(1).length}
                </Tag>
              )}
            </Tag>
          )}
        {modification === "dataVoting" &&
          items.length > 0 &&
          data.state === "unresolved" && (
            <Tag
              className={cn(
                selectedItemTags.includes("notPrefilled") && "tag-active",
                "m-1 mb-2",
                "tag-purple"
              )}
              onClick={() => filterTags("notPrefilled")}
            >
              Not Prefilled
              {items.filter((item) => !isItemPrefilled(item)).length > 1 && (
                <Tag
                  className={cn(
                    "m-1 mb-2",
                    "tag-stacked",
                    inactive && "tag-stacked-inactive"
                  )}
                  round={true}
                >
                  {items.filter((item) => !isItemPrefilled(item)).length}
                </Tag>
              )}
            </Tag>
          )}
        {itemTags.map((tag) => {
          const numberOfItems = items.filter((i) => tag.filterFn(i)).length;
          const activeClassName = hasFilter(tag.name) ? "tag-active" : "";
          const colorClassName = tag.bgColorClass;
          return (
            <Tag
              className={cn(activeClassName, colorClassName, "m-1 mb-2")}
              key={tag.name}
              onClick={() => filterTags(tag.name)}
            >
              {tag.text}
              {numberOfItems > 1 && (
                <Tag
                  className={cn(
                    "tag-stacked",
                    inactive && "tag-stacked-inactive"
                  )}
                  round={true}
                >
                  {" "}
                  {numberOfItems}
                </Tag>
              )}
            </Tag>
          );
        })}
        {searchInput &&
          items.filter((item) =>
            item.name?.toLowerCase().includes(searchInput.toLowerCase())
          ).length > 0 && (
            <Tag
              className={cn(
                selectedItemTags.includes("searchInput") && "tag-active",
                "m-1 mb-2"
              )}
              onClick={() => filterTags("searchInput")}
            >
              {searchInput}
            </Tag>
          )}
      </div>
      <div>
        <Icon
          onClick={() => {
            setSelectedItemTags([]);
            setIsDefaultFilterOn(false);
          }}
          icon={"small-cross"}
        />
      </div>
    </div>
  );
}

export default InvoiceItemTags;
