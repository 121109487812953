import { Loader } from "@stockifi/shared";
import InvoiceListItem from "components/invoices/invoice-listitem";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import invoiceActions from "redux/invoice/actions";
import { Invoice } from "redux/invoice/types";
import { getUserName } from "redux/users/helpers";

function ExpandedContent({ datum, index }: { datum: Invoice; index: number }) {
  const dispatch = useAppDispatch();
  const userInvoicesTable = useAppSelector(
    (state) => state.invoices.userInvoicesTable
  );
  const userItemsTable = useAppSelector(
    (state) => state.invoices.userItemsTable
  );
  const userFeesTable = useAppSelector((state) => state.invoices.userFeesTable);

  useEffect(() => {
    if (!userInvoicesTable[datum.userId]) {
      dispatch(
        invoiceActions.SUBSCRIBE_TO_USER_INVOICE_TABLE({
          userId: datum.userId,
        })
      );
      dispatch(
        invoiceActions.SUBSCRIBE_TO_USER_ITEMS_TABLE({
          userId: datum.userId,
        })
      );
      dispatch(
        invoiceActions.SUBSCRIBE_TO_USER_FEES_TABLE({
          userId: datum.userId,
        })
      );
      return () => {
        dispatch(invoiceActions.UNSUBSCRIBE_FROM_USER_INVOICE_TABLE());
        dispatch(invoiceActions.UNSUBSCRIBE_FROM_USER_ITEMS_TABLE());
        dispatch(invoiceActions.UNSUBSCRIBE_FROM_USER_FEES_TABLE());
      };
    }
  }, [datum.userId]);

  return userInvoicesTable[datum.userId] ? (
    <Loader
      loading={
        userInvoicesTable[datum.userId]?.loading ||
        userItemsTable[datum.userId]?.loading ||
        userFeesTable[datum.userId]?.loading
      }
    >
      <InvoiceListItem
        data={datum}
        userName={getUserName(datum.userId)}
        key={"invoice-" + datum.id}
        searchInput={""}
        userId={datum.userId}
        index={index}
        renderListNumber={false}
        isTable={true}
      />
    </Loader>
  ) : (
    <></>
  );
}

export default ExpandedContent;
