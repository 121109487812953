import { AuthClaims } from "redux/auth/types";

export function getUnresolvedCount(
  authClaims: AuthClaims | undefined,
  isForSupervisorFilter: boolean,
  invoicesCount: Record<string, number> | undefined
) {
  return isForSupervisorFilter
    ? invoicesCount?.unresolvedForSupervisor ?? 0
    : authClaims?.admin
      ? invoicesCount?.unresolved ?? 0
      : !authClaims?.supervisor && !authClaims?.headDataManager
        ? invoicesCount?.unresolvedDataManager ?? 0
        : invoicesCount?.unresolvedNonAdmin ?? 0;
}
