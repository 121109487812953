import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Collapse, Icon, Divider } from "@blueprintjs/core";
import classes from "./index.module.css";
import AttributeRow from "../attribute-row";
export default function InvisibleColsPanel({ columns, }) {
    const [isOpen, setIsOpen] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "d-flex flex-row align-items-center mt-2", children: [_jsxs("div", { className: "d-flex flex-row align-items-center", onClick: () => setIsOpen(!isOpen), style: { cursor: "pointer", userSelect: "none" }, children: [_jsx(Icon, { icon: isOpen ? "chevron-up" : "chevron-down" }), _jsx("p", { className: classes.more_txt, children: "More properties" })] }), _jsx(Divider, { className: "w-100" })] }), _jsx(Collapse, { isOpen: isOpen, className: "mb-0", children: _jsx("div", { className: "mt-3 mx-1", children: columns
                        .sort((a, b) => a.id.localeCompare(b.id))
                        .map((column, idx) => (_jsx("div", { className: "my-3", children: _jsx(AttributeRow, { column: column }) }, column.id + idx))) }) })] }));
}
