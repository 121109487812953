import { Link } from "react-router-dom";
import { NAV_ITEMS, NavItem } from "utils/constants";

// STYLES
import { Button, Divider, Popover, Tab, Tabs } from "@blueprintjs/core";
import "./index.scss";

import { cn } from "@stockifi/shared";
import { useActivePath } from "layouts/hooks";
import PopoverNav from "layouts/popover-nav";
import { useAppSelector } from "redux/hooks";
import { hasAccess } from "../../permissions";

const NavbarTabs = () => {
  const isActive = useActivePath();
  const authClaims = useAppSelector((state) => state.auth.authClaims);

  const NavItemsToRender = NAV_ITEMS.filter(
    (item) => !item.accessCheck || hasAccess(authClaims, item.accessCheck)
  );

  const RenderDivider = (groupId: number) => {
    const groupOfActiveTab = NAV_ITEMS.find((item) =>
      isActive(item.path)
    )?.group;

    return (
      <Divider
        key={`divider-${groupId}`}
        className={cn(
          "nav-divider",
          groupOfActiveTab === groupId && "tab-selected",
          `divider-group-${groupId}`
        )}
      />
    );
  };

  let currentGroup = 0; // Used to render divider when rendering the first nav item of the group
  const RenderTabMenu = (item: NavItem, idx: number) => {
    if (item.group !== currentGroup) {
      currentGroup = item.group;
      return [
        RenderDivider(item.group),
        <Tab
          key={idx}
          id={item.id}
          children={<Link to={item.path}>{item.name}</Link>}
          className={cn(
            isActive(item.path) && "tab-selected",
            `tab-group-${item.group}`
          )}
        />,
      ];
    } else {
      return (
        <Tab
          key={idx}
          id={item.id}
          children={<Link to={item.path}>{item.name}</Link>}
          className={cn(
            isActive(item.path) && "tab-selected",
            `tab-group-${item.group}`
          )}
        />
      );
    }
  };

  return (
    <div className="d-flex w-100">
      <div className="d-flex w-100">
        <Tabs
          id="navbar"
          animate={false}
          renderActiveTabPanelOnly={true}
          selectedTabId={NAV_ITEMS.find((item) => isActive(item.path))?.id}
          className="nav-tabs"
        >
          {NavItemsToRender.filter((item) => item.group < 4).map((item, idx) =>
            RenderTabMenu(item, idx)
          )}
        </Tabs>

        <div style={{ marginLeft: "auto" }}>
          <Popover
            content={
              <PopoverNav
                popoverNav={NavItemsToRender.filter((item) => item.group >= 1)}
              />
            }
            position="bottom"
          >
            <Button icon="more" minimal />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default NavbarTabs;
