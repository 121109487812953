import { useEffect, useState } from "react";
import { getDurationString } from "utils/common";

type Props = {
  resolvingDuration: number;
  isInvoiceRunning: boolean;
  onTimeUpdate: (time: number) => void;
};

const Timer = (props: Props) => {
  const { resolvingDuration, isInvoiceRunning, onTimeUpdate } = props;
  const [time, setTime] = useState(resolvingDuration);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;
    if (isInvoiceRunning) {
      interval = setInterval(() => {
        setTime((t) => {
          const newTime = t + 1;
          onTimeUpdate(newTime);
          return newTime;
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isInvoiceRunning, onTimeUpdate]);

  return <span>{getDurationString(time)}</span>;
};

export default Timer;
