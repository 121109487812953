import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  InputGroup,
} from "@blueprintjs/core";
import React, { useState } from "react";
import alertActions from "redux/alert/actions";
import actions from "redux/config/actions";
import { Filter } from "redux/config/types";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { filtersSimilarityCheck } from "../helpers/filter-helper";

type Props = {
  module: string;
  filters: Filter[];
  isOpenDialog: boolean;
  setIsOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

const SaveLayoutDialog = ({
  module,
  filters,
  isOpenDialog,
  setIsOpenDialog,
}: Props) => {
  const [newName, setNewName] = useState<string>("");
  const dispatch = useAppDispatch();
  const layouts = useAppSelector((state) => state.appConfig.layouts);

  const handleSaveLayout = (name: string, filters: Filter[]) => {
    const filteredLayouts = layouts
      .filter((layout) => layout.module === module)
      .sort((a, b) => a.order - b.order);
    if (filteredLayouts.map((layout) => layout.name).includes(name)) {
      dispatch(alertActions.ERROR("Name already exists"));
      return { error: true };
    }

    if (module === "items") {
      // Check if a similar layout exists
      const existed = !!filteredLayouts.find((layout) =>
        filtersSimilarityCheck(layout.filters, filters)
      );

      if (existed) {
        dispatch(
          alertActions.ERROR(
            "Layout with the same filters and logical operator already exists"
          )
        );
        return { error: true };
      }
      dispatch(
        actions.CREATE_LAYOUT({
          name,
          filters,
          order: filteredLayouts.length,
          module,
        })
      );
      return { error: false };
    } else {
      // Check if a similar layout exists
      const existed = !!filteredLayouts.find((layout) =>
        filtersSimilarityCheck(layout.filters, filters)
      );
      if (existed) {
        dispatch(
          alertActions.ERROR(
            "Layout with the same filters and logical operator already exists"
          )
        );
        return { error: true };
      }
      dispatch(
        actions.CREATE_LAYOUT({
          name,
          filters,
          order: filteredLayouts.length,
          module,
        })
      );
      return { error: false };
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    const { error } = handleSaveLayout(newName, filters);
    if (!error) {
      setIsOpenDialog(false);
      setNewName("");
    }
  };

  return (
    <Dialog
      autoFocus
      enforceFocus
      icon="floppy-disk"
      isOpen={isOpenDialog}
      onClose={() => {
        setIsOpenDialog(false);
        setNewName("");
      }}
      title="Save New Layout"
      usePortal={true}
    >
      <form>
        <DialogBody>
          <p>Set the name of the new layout.</p>
          <InputGroup
            value={newName}
            onChange={(e) => setNewName(e.currentTarget.value)}
            placeholder="New layout name"
          />
        </DialogBody>
        <DialogFooter>
          <Button
            className="w-100"
            type="submit"
            disabled={newName === ""}
            onClick={handleSubmit}
            text="Save"
          />
        </DialogFooter>
      </form>
    </Dialog>
  );
};

export default SaveLayoutDialog;
