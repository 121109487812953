// STYLING
import { Button, TextArea } from "@blueprintjs/core";
import { cn } from "@stockifi/shared";
import { useEffect, useState } from "react";
import style from "./index.module.scss";

type DataLabelingCommentsProps = {
  commentData: string;
  saveCommentHandler: (comment: string) => void;
  title: string;
  readonly?: boolean;
};
const DataLabelingComments = ({
  commentData,
  saveCommentHandler,
  title,
  readonly = false,
}: DataLabelingCommentsProps) => {
  const [editComment, setEditComment] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    setComment(commentData ?? "");
  }, [commentData]);

  const handleClick = () => {
    if (editComment === true) {
      saveCommentHandler(comment);
    }
    setEditComment((prevState) => !prevState);
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={readonly ? undefined : { marginTop: "20px" }}
    >
      <p>{title}</p>
      <div
        className={cn(
          style.comment,
          "col-xl-12 mt-1",
          editComment && style.comment_editable
        )}
      >
        <TextArea
          readOnly={readonly || !editComment}
          className={style.edit_comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Add comments"
          value={comment}
        />
        <Button
          minimal
          disabled={readonly}
          icon={editComment ? "floppy-disk" : "edit"}
          onClick={handleClick}
          className={style.button}
          title={editComment ? "Save" : "Edit"}
        />
      </div>
    </div>
  );
};

export default DataLabelingComments;
