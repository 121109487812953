import { all, call, put, takeEvery } from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import { ServiceReturn } from "redux/types";
import { getOcrSelection, saveOcrSelections } from "services/ocr";
import { OCR_SELECTION } from "utils/constants";
import actions from "./actions";
import { Selection } from "./types";

export interface SAVE_DATA_Payload {
  userId: string;
  data: Selection[];
  invoiceId: string;
}
export function* SAVE_DATA({ payload }: ReturnType<typeof actions.SAVE_DATA>) {
  const { userId, data, invoiceId } = payload;

  const result: ServiceReturn = yield call(
    saveOcrSelections,
    userId,
    invoiceId,
    data
  );

  if (result.data) {
    yield put(alertActions.SUCCESS(OCR_SELECTION.DATA_SAVED));
  } else {
    yield put(
      alertActions.ERROR(
        OCR_SELECTION.DATA_SAVE_ERROR + "\n" + result.error.message
      )
    );
  }
}

export interface GET_OCR_SELECTION_Payload {
  userId: string;
  invoiceId: string;
  imageUrl: string;
}
function* GET_OCR_SELECTION({
  payload,
}: ReturnType<typeof actions.GET_OCR_SELECTION>) {
  const { userId, invoiceId, imageUrl } = payload;

  const result: ServiceReturn = yield call(
    getOcrSelection,
    userId,
    invoiceId,
    imageUrl
  );

  if (result.data) {
    yield put(
      actions.PUSH_SELECTION({
        ocrClicks: result.data,
        invoiceId,
      })
    );
  } else {
    yield put(
      alertActions.ERROR(
        OCR_SELECTION.DATA_LOAD_ERROR + "\n" + result.error.message
      )
    );
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(actions.SAVE_DATA, SAVE_DATA),
    takeEvery(actions.GET_OCR_SELECTION, GET_OCR_SELECTION),
  ]);
}
