import {
  QueryConstraint,
  collection,
  doc,
  endBefore,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import moment from "moment";
import { DailySale, MunuFile } from "redux/sales/types";
import { ServiceReturn } from "redux/types";
import { db } from "services/firebase";

export async function getFirstAndLastSaleId(
  userId: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const qFirst = query(
    collection(db, "munuFiles"),
    where("userId", "==", userId),
    orderBy("periodEnd", "desc"),
    limit(1)
  );
  const qLast = query(
    collection(db, "munuFiles"),
    where("userId", "==", userId),
    orderBy("periodEnd", "asc"),
    limit(1)
  );

  try {
    const first = await getDocs(qFirst);
    const last = await getDocs(qLast);

    const firstSaleId = first.docs[0].id;
    const lastSaleId = last.docs[0].id;

    return { data: { firstSaleId, lastSaleId }, error: null };
  } catch (err) {
    return { data: null, error: err };
  }
}

export async function getUserSales(
  userId: string,
  pointerId?: string,
  direction?: string
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };

  const constraints: QueryConstraint[] = [
    where("userId", "==", userId),
    orderBy("periodEnd", "desc"),
  ];

  if (pointerId && direction) {
    const pointer = await getDoc(doc(db, "munuFiles", pointerId));
    if (direction === "next") constraints.push(startAfter(pointer));
    else if (direction === "previous") constraints.push(endBefore(pointer));
  }

  const q = query(collection(db, "munuFiles"), ...constraints, limit(20));

  try {
    return getDocs(q).then((data) => {
      const t = data.docs.map((doc) => ({
        ...doc.data(),
        deleted: doc.data().deleted ?? false,
        vendor: doc.data().vendor ?? "",
        id: doc.id,
      })) as MunuFile[];

      return { data: t, error: null };
    });
  } catch (error) {
    return { data: null, error: error };
  }
}

export async function getDailySales(
  userId: string,
  date: Date
): Promise<ServiceReturn> {
  if (!db) return { data: null, error: "No db connection" };
  const dateFrom = moment(date).startOf("day").toDate();
  const dateTo = moment(date).endOf("day").toDate();

  const q = query(
    collection(db, "dailySales"),
    where("userId", "==", userId),
    where("date", ">=", dateFrom),
    where("date", "<=", dateTo),
    limit(1)
  );

  try {
    return getDocs(q).then((data) => {
      if (data.docs.length === 0) {
        return {
          data: null,
          error: { message: "No daily sale found for selected date" },
        };
      }

      const doc = data.docs[0];
      const docData = doc.data();
      const date = docData.date.toDate();

      const dailySale: DailySale = {
        data: JSON.parse(docData.data).slice(1),
        date: date,
        updatedAt: docData.updatedAt?.toDate() ?? null,
        munuFileId: docData.munuFileId,
        userId: docData.userId,
      };

      return { data: dailySale, error: null };
    });
  } catch (error) {
    return { data: null, error: error };
  }
}
