import React, { useState } from "react";

import {
  Button,
  Collapse,
  Dialog,
  DialogBody,
  DialogFooter,
  Divider,
  Icon,
  Switch,
  Text,
} from "@blueprintjs/core";
import { Attribute } from "components/edit-pinned-attributes";
import Attributes from "./attributes";
import classes from "./index.module.scss";
import PinnedColumnsPanel from "./pinned-columns-panel";

const editDisabledAttributes = ["id", "isOnline"];

type Props = {
  module: string;
  pinnedCols: Attribute[];
  setPinnedCols: React.Dispatch<React.SetStateAction<Attribute[]>>;
  attrMap: Map<string, string>;
  
  handleSaveColumnOrder: any;
  loadingSaveColumnOrder: boolean;
  isGrouped?: boolean;
  setIsGrouped?: React.Dispatch<React.SetStateAction<boolean>>;
  isNumberedList: boolean;
  setIsNumberedList: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditColumnsButton = ({
  module,
  pinnedCols,
  setPinnedCols,
  attrMap,
  handleSaveColumnOrder,
  loadingSaveColumnOrder,
  isGrouped,
  setIsGrouped,
  isNumberedList,
  setIsNumberedList,
}: Props) => {
  // STATES
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        text="Edit Columns"
        onClick={() => setIsEditOpen(true)}
        icon="unlock"
      />
      <Dialog
        isOpen={isEditOpen}
        onClose={() => {
          setIsEditOpen(false);
        }}
        autoFocus
        enforceFocus
        usePortal={true}
        className="pb-0"
        icon="unlock"
        title="Edit Pinned Properties"
      >
        <DialogBody>
          {module === "ap-transactions" && (
            <div className="d-flex flex-row align-items-center justify-content-between mb-3">
              <Text>Options:</Text>
              <div>
                <Switch
                  checked={isNumberedList}
                  className="mb-0"
                  label="Numbered List"
                  onChange={() => setIsNumberedList((prevState) => !prevState)}
                  inline={true}
                />
                <Switch
                  checked={isGrouped}
                  className="m-0"
                  label="Group"
                  onChange={() => {
                    if (setIsGrouped) {
                      setIsGrouped((prevState) => !prevState);
                    }
                  }}
                  inline={true}
                />
              </div>
            </div>
          )}

          {/* DEFAULT ATTRIBUTES */}
          <PinnedColumnsPanel
            attrMap={attrMap}
            pinnedCols={pinnedCols}
            setPinnedCols={setPinnedCols}
          />

          {/* MORE ATTRIBUTES */}
          <div className="d-flex flex-row align-items-center mt-2">
            <div
              className="d-flex flex-row align-items-center"
              onClick={() => setIsOpen(!isOpen)}
              style={{ cursor: "pointer", userSelect: "none" }}
            >
              <Icon icon={isOpen ? "chevron-up" : "chevron-down"} />
              <p className={classes.more_txt}>More properties</p>
            </div>
            <Divider className="w-100" />
          </div>

          <Collapse isOpen={isOpen} className="mb-0">
            <div className="mt-3">
              {Array.from(attrMap.keys())
                .filter(
                  (attr) =>
                    !pinnedCols.find((prop) => prop.name === attr) &&
                    !editDisabledAttributes.includes(attr)
                )
                .sort()
                .map((attr, idx) => (
                  <Attributes
                    key={attr + idx}
                    attribute={attr}
                    attrMap={attrMap}
                    pinnedCols={pinnedCols}
                    setPinnedCols={setPinnedCols}
                  />
                ))}
            </div>
          </Collapse>
        </DialogBody>
        <DialogFooter
          actions={
            <Button
              onClick={handleSaveColumnOrder}
              loading={loadingSaveColumnOrder}
            >
              Save Column Order
            </Button>
          }
        />
      </Dialog>
    </>
  );
};

export default EditColumnsButton;
