import { Switch, Tooltip } from "@blueprintjs/core";
import { getContrastColor } from "components/settings/invoices/helper";
import { initialHighlightColorSettings } from "components/settings/invoices/highlight-color/constants";
import { useAppSelector } from "redux/hooks";
import styles from "./index.module.scss";

type Props = {
  selectedFloatingButton: string;
  toggleSelected: (btn: string) => void;
  handleAutoDetectMultipliersSwitch: () => void;
  isAutoDetectMultipliersOn: boolean;
};

const InvoiceFloatingButtons = ({
  selectedFloatingButton,
  toggleSelected,
  handleAutoDetectMultipliersSwitch,
  isAutoDetectMultipliersOn,
}: Props) => {
  const invoiceHighlightColors = useAppSelector(
    (state) => state.settings.invoiceHighlightColors
  );

  const getColor = (column: string) => {
    const color = invoiceHighlightColors ?? initialHighlightColorSettings;
    switch (column) {
      case "Names":
        return color.floatingButtons.name;
      case "Multipliers":
        return color.floatingButtons.multiplier;
      case "Quantities":
        return color.floatingButtons.quantity;
      case "Totals":
        return color.floatingButtons.total;
      default:
        return "red";
    }
  };

  return (
    <>
      <div className={styles.details__btn}>
        {["Names", "Quantities", "Totals", "Multipliers"].map((btn) => (
          <div
            key={`floating-btn-${btn}`}
            style={{
              color: getContrastColor(getColor(btn)),
              backgroundColor: getColor(btn),
              borderColor:
                selectedFloatingButton === btn ? "#000000" : "#ffffff",
            }}
            className={styles.btn}
            onClick={() => toggleSelected(btn)}
          >
            {btn === "Multipliers" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                {btn}
                <div onClick={(e) => e.stopPropagation()}>
                  <Tooltip
                    content={
                      "Automatically search for multipliers within the item names"
                    }
                    placement="right"
                  >
                    <Switch
                      alignIndicator={"right"}
                      checked={isAutoDetectMultipliersOn}
                      onClick={() => {
                        handleAutoDetectMultipliersSwitch();
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            ) : (
              btn
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default InvoiceFloatingButtons;
