import { createActions } from "redux/create-actions";
import {
  ADD_MATERIAL_BOOKKEEPING_ITEM_payload,
  SUBSCRIBE_TO_USER_MATERIAL_BOOKKEEPING_ITEMS_payload,
  UPDATE_MATERIAL_BOOKKEEPING_ITEM_payload,
} from "./sagas";
import { MaterialBookkeepingItemState } from "./types";

const actions = createActions("material-bookkeeping-items", {
  SET_STATE: {} as Partial<MaterialBookkeepingItemState>,
  SUBSCRIBE_TO_USER_MATERIAL_BOOKKEEPING_ITEMS:
    {} as SUBSCRIBE_TO_USER_MATERIAL_BOOKKEEPING_ITEMS_payload,
  UNSUBSCRIBE_FROM_USER_MATERIAL_BOOKKEEPING_ITEMS: undefined,
  ADD_MATERIAL_BOOKKEEPING_ITEM: {} as ADD_MATERIAL_BOOKKEEPING_ITEM_payload,
  UPDATE_MATERIAL_BOOKKEEPING_ITEM:
    {} as UPDATE_MATERIAL_BOOKKEEPING_ITEM_payload,
});

export default actions;
