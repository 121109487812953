import { Button, Dialog, DialogBody } from "@blueprintjs/core";
import { useState } from "react";
import ReactJson from "react-json-view";
import PerfectScrollbar from "react-perfect-scrollbar";
import { InvoiceItem } from "redux/invoice/types";

type Props = {
  itemList: InvoiceItem[];
  disabled?: boolean;
};

const ShowItemList = ({ itemList, disabled }: Props) => {
  const [showItemList, setShowItemList] = useState<boolean>(false);
  const [collapseDepth, setCollapseDepth] = useState<number>(1);

  return (
    <div
      style={{
        display: "inline-block",
        textAlign: "center",
        marginRight: "8px",
      }}
    >
      <Button
        disabled={disabled}
        icon="properties"
        onClick={() => setShowItemList((prev) => !prev)}
        title="View Item List"
      />
      <Dialog
        isOpen={showItemList}
        onClose={() => setShowItemList((prev) => !prev)}
        autoFocus
        enforceFocus
        usePortal={true}
        className="pb-0"
        title="Show Item List"
        icon="menu-open"
      >
        <DialogBody>
          <div className="d-flex justify-content-end mb-2">
            <Button
              icon="add"
              minimal
              small
              text="Expand All"
              onClick={() => setCollapseDepth(3)}
            />
            <Button
              icon="remove"
              minimal
              small
              text="Contract All"
              onClick={() => setCollapseDepth(1)}
            />
          </div>
          <PerfectScrollbar className="px-0">
            <div style={{ maxHeight: 770 }}>
              <div>
                <ReactJson
                  theme={"twilight"}
                  sortKeys={true}
                  src={itemList}
                  collapsed={collapseDepth}
                />
              </div>
            </div>
          </PerfectScrollbar>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default ShowItemList;
