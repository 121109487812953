import { createWorkerFactory } from "@shopify/web-worker";
import React, { useRef, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { AttributeMap } from "..";
import {
  WorkerStatus,
  WorkerStatusItem,
  getStatusColor,
} from "../helpers/worker-status";

const defaultExcludedProps = [
  "collections",
  "customTypes",
  "customPosItemsTypes",
  "deviceTokens",
  "downloadedReports",
  "foodoraItems",
  "ref",
];

const createGetAllKeys = createWorkerFactory(
  () => import("../helpers/key-worker")
);
/**
 * React hook to get all object attributes on AdvancedFilterPanel component.
 * @param {Object[]} excludedProps - An array of object properties to be excluded.
 * @category Advanced Filter Panel
 */
const useAttributesMap = (excludedProps?: string[]) => {
  const [attrMap, setAttrMap] = useState<AttributeMap>(new Map());
  // For web worker
  const hasPendingWork = useRef(false);
  const statusRef = useRef<WorkerStatusItem>(WorkerStatus.IDLE);

  const allObjects = useRef<any[]>([]);
  const users = useAppSelector((state) => state.users.users);
  const suppliers = useAppSelector((state) => state.suppliers.data);

  const allExcludedProps = React.useMemo(
    () => defaultExcludedProps.concat(excludedProps ?? []),
    [excludedProps]
  );

  const checkPendingWork = async () => {
    if (hasPendingWork.current) {
      hasPendingWork.current = false;
      await getKeys(allObjects.current);
    }
  };

  const findSupplier = React.useCallback(
    (id: string | number) => {
      const supplier = suppliers.find((supplier) => supplier.id === id);
      return supplier?.name;
    },
    [suppliers]
  );

  const findUser = React.useCallback(
    (id: string | number) => {
      const user = users.find((user) => user.id === id);
      return user?.name;
    },
    [users]
  );

  const getKeys = React.useCallback(
    async (objects: any[]) => {
      if (!objects || objects.length === 0) return;

      // let's search name for 'supplierId', 'editedBy', and 'resolvedBy'
      objects.forEach((object) => {
        if (object.supplierId && !object.supplierName) {
          object.supplierName = findSupplier(object.supplierId);
        }
        if (object.editedBy) {
          object.editedByName = findUser(object.editedBy);
        }
        if (object.resolvedBy) {
          object.resolvedByName = findUser(object.resolvedBy);
        }
      });

      // Update allObjects with latest objects data
      allObjects.current = objects;
      if (statusRef.current !== WorkerStatus.RUNNING) {
        try {
          statusRef.current = WorkerStatus.RUNNING;
          const getAllKeysAgent = createGetAllKeys();
          const keys = await getAllKeysAgent.getAllKeys(
            JSON.parse(JSON.stringify(objects)),
            allExcludedProps
          );
          setAttrMap(keys);
          statusRef.current = WorkerStatus.SUCCESS;
        } catch (err) {
          statusRef.current = WorkerStatus.ERROR;
        } finally {
          // Check if getKeys needs to be run again
          checkPendingWork();
        }
      } else {
        // Make it pending so that getKeys can run again
        // after the current run has finished
        hasPendingWork.current = true;
      }
    },

    [allExcludedProps, statusRef.current]
  );

  const keyStatusColor = getStatusColor(statusRef.current);

  return { attrMap, getAllKeys: getKeys, setAttrMap, keyStatusColor };
};

export default useAttributesMap;
