import { useEffect } from "react";
import "./index.scss";

interface Props {
  text: string;
}

export default function ImageSearch({ text }: Props) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cse.google.com/cse.js?cx=87287ca9f26b649aa";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    const input = document.getElementById("gsc-i-id1") as HTMLInputElement;
    const button = document.querySelector(
      ".gsc-search-button.gsc-search-button-v2"
    ) as HTMLButtonElement;
    if (input) input.value = text;
    if (button && text) button.click();
  }, [text]);

  return <div className="gcse-search"></div>;
}
