import {
  DocumentData,
  DocumentReference,
  doc,
  getDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { ActiveCount } from "redux/users/types";
import { checkCounter } from "services/counter";
import { db } from "services/firebase";
import { SERVER_COUNTS } from "utils/constants";

export async function updateCountIsDone(
  userId: string,
  count: ActiveCount | undefined,
  destId: string
) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  if (!count) {
    return { data: null, error: "You need to include a count" };
  }

  const countRef = doc(db, `users/${userId}/counts/${count.countId}`);

  const oldData = await getDoc(countRef).then((doc) => {
    return doc.data();
  });

  const newCount = {
    ...oldData,
    isDone: destId === "done" ? true : false,
  };

  return await updateDoc(countRef, newCount)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function updateCount(
  countRef: DocumentReference,
  data: DocumentData
) {
  if (!checkCounter())
    return { data: null, error: SERVER_COUNTS.ERROR_MAX_COUNT };

  return await updateDoc(countRef, data)
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}

export async function batchUpdateCounts(
  countsArray: string[],
  userId: string,
  data: DocumentData
) {
  if (!db) return { data: null, error: "No db connection" };
  if (!checkCounter())
    return { data: null, erro: SERVER_COUNTS.ERROR_MAX_COUNT };

  const batch = writeBatch(db);
  countsArray.forEach(async (countId) => {
    if (!db) return;
    const countRef = doc(db, "users", userId, "counts", countId);
    batch.update(countRef, {
      [data.attr]: data.value,
    });
  });

  return batch
    .commit()
    .then(() => {
      return { data: true, error: null };
    })
    .catch((err) => {
      return { data: null, error: err };
    });
}
