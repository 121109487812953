import { Card, Dialog, DialogBody, Elevation } from "@blueprintjs/core";
import React from "react";

import actions from "redux/auth/actions";
import { useAppDispatch, useAppSelector } from "redux/hooks";

type ViewAsDialogBoxProps = {
  isOpen: boolean;
  onClose: () => void;
  setViewAs: React.Dispatch<
    React.SetStateAction<{
      isViewing: boolean;
      viewAsRole: string;
    }>
  >;
};

const ViewAsDialogBox = ({
  isOpen,
  onClose,
  setViewAs,
}: ViewAsDialogBoxProps) => {
  const dispatch = useAppDispatch();
  const permissions = useAppSelector((state) => state.appConfig.permissions);
  const roleNamingChangeMap = new Map<string, string>([
    ["invoices", "Data Manager"],
    ["headDataManager", "Head Data Manager"],
    ["supervisor", "Data Supervisor"],
    ["headDataSupervisor", "Head Data Supervisor"],
  ]);
  const checkRoleNaming = (role: string) =>
    roleNamingChangeMap.has(role)
      ? roleNamingChangeMap.get(role) || ""
      : role.charAt(0).toUpperCase() + role.slice(1);

  const RoleCard = ({ role }: { role: string }) => (
    <Card
      className="d-flex flex-column align-items-center w-100"
      interactive={true}
      elevation={Elevation.TWO}
      onClick={() => {
        setViewAs({
          isViewing: true,
          viewAsRole: role,
        });
        dispatch(
          actions.SET_STATE({
            authClaims: {
              admin: false,
              headDataManager: role === checkRoleNaming("headDataManager"),
              invoices:
                role === checkRoleNaming("invoices") ||
                role === checkRoleNaming("headDataManager"),
              dev: false,
              headDataSupervisor:
                role === checkRoleNaming("headDataSupervisor"),
              supervisor:
                role === checkRoleNaming("supervisor") ||
                role === checkRoleNaming("headDataSupervisor"),
            },
          })
        );
        onClose();
      }}
    >
      View as {role}
    </Card>
  );

  const listOfRoles: string[] = React.useMemo(
    () =>
      [...new Set(permissions.map((p) => p.roles).flat())]
        .filter((role) => role !== "admin" && role !== "dev")
        .map((role) => checkRoleNaming(role)),

    [permissions]
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      autoFocus
      enforceFocus
      usePortal={true}
      className="pb-0"
      icon="user"
      title="Select a Role"
    >
      <DialogBody>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          {listOfRoles.map((role, i) => (
            <RoleCard key={i} role={role} />
          ))}
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default ViewAsDialogBox;
