import {
  ActionCreatorWithoutPayload,
  PayloadActionCreator,
  createAction,
} from "@reduxjs/toolkit";

type ActionCreator<P, T extends string> = [P] extends [undefined]
  ? ActionCreatorWithoutPayload<T>
  : PayloadActionCreator<P, T>;

type ActionType<
  NS extends string,
  
  ActionName extends keyof any,
> = ActionName extends string | number ? `${NS}/${ActionName}` : string;

type ActionCreators<ActionMap, NS extends string> = {
  [T in keyof ActionMap]: ActionCreator<ActionMap[T], ActionType<NS, T>>;
};

type Payload = object | string | number | boolean | undefined | null;

/**
 * Creates an action type for a given namespace and action name
 * @param namespace The namespace of the action
 * @param actionKey The action name
 * @returns The action type in the format of `${namespace}/${actionKey}`
 */
function getActionType<NS extends string, ActionName extends string>(
  namespace: NS,
  actionKey: ActionName
) {
  return `${namespace}/${actionKey}` as ActionType<NS, ActionName>;
}

/**
 * Creates action creators for a given namespace and action payload map
 * @param namespace The namespace of the action
 * @param actionPayloadMap The map of action names to their payload types
 * @returns An object with action creators
 */
export function createActions<
  ActionPayloadMap extends Record<string, Payload>,
  Namespace extends string = string,
>(namespace: Namespace, actionPayloadMap: ActionPayloadMap) {
  return Object.keys(actionPayloadMap).reduce(
    (acc, action) => {
      const type = getActionType(namespace, action);
      return { ...acc, [action]: createAction(type) };
    },
    {} as ActionCreators<ActionPayloadMap, Namespace>
  );
}
