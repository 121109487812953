import { DocumentData } from "firebase/firestore";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import alertActions from "redux/alert/actions";
import itemActions from "redux/items/actions";
import { UserItem } from "redux/items/types";
import { RootState } from "redux/store";
import { ServiceReturn } from "redux/types";
import {
  batchConnectUserItems,
  deleteSilhouette,
  getSilhouettes,
  updateSilhouette,
} from "services/silhouettes";
import { COMMON } from "utils/constants";
import actions from "./actions";

function* GET_SILHOUETTES() {
  const { data, error } = yield call(getSilhouettes);
  if (data) {
    yield put(
      actions.SET_STATE({
        silhouettes: data,
      })
    );
  } else if (error) {
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

export type UPDATE_SILHOUETTE_payload = {
  refPath: string;
  data: DocumentData;
};

function* UPDATE_SILHOUETTE({
  payload,
}: ReturnType<typeof actions.UPDATE_SILHOUETTE>) {
  yield put(actions.SET_STATE({ loadingSave: true }));

  const { data, error } = yield call(
    updateSilhouette,
    payload.refPath,
    payload.data
  );

  if (data) {
    yield put(actions.GET_SILHOUETTES());
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(alertActions.SUCCESS("Silhouette updated successfully"));
  } else if (error) {
    yield put(actions.SET_STATE({ loadingSave: false }));
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

export type DELETE_SILHOUETTE_payload = {
  refPath: string;
};

function* DELETE_SILHOUETTE({
  payload,
}: ReturnType<typeof actions.DELETE_SILHOUETTE>) {
  yield put(actions.SET_STATE({ loadingDelete: true }));

  const { data, error } = yield call(deleteSilhouette, payload.refPath);

  if (data) {
    yield put(actions.GET_SILHOUETTES());
    yield put(actions.SET_STATE({ loadingDelete: false }));
    yield put(alertActions.SUCCESS("Silhouette deleted successfully"));
  } else if (error) {
    yield put(actions.SET_STATE({ loadingDelete: false }));
    yield put(alertActions.ERROR(error.message || COMMON.REQUEST_ERROR));
  }
}

export interface BATCH_CONNECT_USER_ITEMS_payload {
  userId: string;
  itemIds: string[];
  silhouettePath: string;
  callback?: () => void;
  manualUpdateState?: boolean;
}

export function* BATCH_CONNECT_USER_ITEMS({
  payload,
}: ReturnType<typeof actions.BATCH_CONNECT_USER_ITEMS>) {
  yield put(
    actions.SET_STATE({
      loadingConnectItems: true,
    })
  );
  const result: ServiceReturn = yield call(
    batchConnectUserItems,
    payload.userId,
    payload.itemIds,
    payload.silhouettePath
  );

  if (result.data) {
    yield put(actions.SET_STATE({ loadingConnectItems: false }));
    yield put(alertActions.SUCCESS("Items connected successfully"));
    if (payload.callback) payload.callback();
    if (payload.manualUpdateState) {
      const searchItemsResult: UserItem[] = yield select(
        (state: RootState) => state.items.items
      );
      const updatedItems = searchItemsResult.map((item) => {
        if (payload.itemIds.includes(item.id)) {
          return { ...item, silhouettePath: payload.silhouettePath };
        }
        return item;
      });
      yield put(itemActions.SET_STATE({ items: updatedItems }));
    }
  } else {
    yield put(actions.SET_STATE({ loadingConnectItems: false }));
    yield put(alertActions.ERROR(result.error.message || COMMON.REQUEST_ERROR));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_SILHOUETTES, GET_SILHOUETTES),
    takeLatest(actions.UPDATE_SILHOUETTE, UPDATE_SILHOUETTE),
    takeLatest(actions.DELETE_SILHOUETTE, DELETE_SILHOUETTE),
    takeLatest(actions.BATCH_CONNECT_USER_ITEMS, BATCH_CONNECT_USER_ITEMS),
  ]);
}
